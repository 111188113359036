<div class="planWrap" *ngIf="!showQRcode || planPage" [ngClass]="allPanPage ? 'allPanPage':'popup'">
  <div class="container">
    <div class="ttl_head text-center">
      <h3>Subscription Plans</h3>
      <p>Choose the best plan for your business</p>
    </div>
    <ul class="nav nav-tabs mt-3" role="tablist">
      <li class="nav-item" [class.active]="plan === '1 Month'">
        <a class="nav-link pointer" (click)="planType('1 Month')">Monthly</a>
      </li>
      <li class="nav-item" [class.active]="plan === '1 Year'">
        <a class="nav-link pointer" (click)="planType('1 Year')">Yearly </a>
      </li>
    </ul>

    <div class="planListWrap">
      <div class="planBox" *ngFor="let data of planList; let i = index">
        <div class="card mb-5 mb-lg-0">
          <small class="autoRenewal" *ngIf="data?.autoRenewal===1">
            Auto Renewal Plan
          </small>
          <div class="card-header text-left">

            <h5 class="card-title">₹{{data.finalPriceWithGst}} / {{data.validity}}</h5>
            <h6 class="d-flex justify-content-between"><span class="">{{data.name}}</span>

            </h6>
            <h6 style="opacity: 0.5;" class="justify-content-between">Auto Renewal Price:
              {{data?.autoRenewal===1 ? (data?.totalPayableOnRenewal ? data?.totalPayableOnRenewal:''):''}}</h6>
          </div>
          <div class="card-body ">
            <ul class="list-unstyled">
              <li class="mb-3">
                <i *ngIf="data.subscription_list.businessProfileCreation===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.businessProfileCreation===0" class="fa fa-close"></i>
                Business Profile Creation
              </li>

              <li class="mb-3">
                <i *ngIf="data.subscription_list.businessLogoUpdate===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.businessLogoUpdate===0" class="fa fa-close"></i>
                Business Logo Update
              </li>

              <li class="mb-3">
                <i *ngIf="data.subscription_list.updateBannerImage===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.updateBannerImage===0" class="fa fa-close"></i>
                Upload Banner Image
              </li>

              <li class="mb-3">
                <i *ngIf="data.subscription_list.pinBusinessLocationOnMap===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.pinBusinessLocationOnMap===0" class="fa fa-close"></i>
                Pin Business location on the map
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.provideFullBusinessAddress===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.provideFullBusinessAddress===0" class="fa fa-close"></i>
                Provide Full Business Address
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.setWorkingHours===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.setWorkingHours===0" class="fa fa-close"></i>
                Set Business Working Hours
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.modesOfPaymentAccepted===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.modesOfPaymentAccepted===0" class="fa fa-close"></i>
                Modes of Payment Accepted
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.modesOfInteractionsWithCustomers===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.modesOfInteractionsWithCustomers===0" class="fa fa-close"></i>
                Modes of Interaction with customers
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.listOfAmmenties===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.listOfAmmenties===0" class="fa fa-close"></i>
                List Other Amenities available with business
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.accessBusinessDashboardToManageProfile===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.accessBusinessDashboardToManageProfile===0" class="fa fa-close"></i>
                Access Business Dashboard to manage profile
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.allTimeEditProfile===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.allTimeEditProfile===0" class="fa fa-close"></i>
                24/7 Edit profile
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.subCategoryListing>=1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.subCategoryListing===0" class="fa fa-close"></i>
                {{data.subscription_list.subCategoryListing}} Sub-category Listing
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.addTags>0" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.addTags===0" class="fa fa-close"></i>
                {{data.subscription_list.addTags}} Add most relevant Tags / Keywords
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.addBusinessImages>0" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.addBusinessImages===0" class="fa fa-close"></i>
                {{data.subscription_list.addBusinessImages>0 ? data.subscription_list.addBusinessImages:''}} Add
                Business Images
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.addBusinessVideos>0" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.addBusinessVideos===0" class="fa fa-close"></i>
                {{data.subscription_list.addBusinessVideos > 0 ? data.subscription_list.addBusinessVideos:'' }} Add
                Business Videos
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.addBusinessAudios>=1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.addBusinessAudios===0" class="fa fa-close"></i>
                {{data.subscription_list.addBusinessAudios>0 ? data.subscription_list.addBusinessAudios:''}} Add
                Business Audio
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.listOfServices>=1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.listOfServices===0" class="fa fa-close"></i>
                {{data.subscription_list.listOfServices>0 ? data.subscription_list.listOfServices:''}} List Products or
                Services
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.listOfOffers>=1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.listOfOffers===0" class="fa fa-close"></i>
                {{data.subscription_list.listOfOffers>0 ? data.subscription_list.listOfOffers:''}} List Offers or
                Discounts (Business or Product Specific)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.emailDisplay ===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.emailDisplay===0" class="fa fa-close"></i>
                Email Display
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.websiteLinkDisplay===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.websiteLinkDisplay===0" class="fa fa-close"></i>
                Website Link Display
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.socialMediaLinkDisplay===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.socialMediaLinkDisplay===0" class="fa fa-close"></i>
                Social Media Links Display
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.awardsDisplay===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.awardsDisplay===0" class="fa fa-close"></i>
                Display Awards (upload certificates or pictures)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.verifiedBusinessSeal===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.verifiedBusinessSeal===0" class="fa fa-close"></i>
                Verified Business Seal
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.digitalCatalogue===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.digitalCatalogue===0" class="fa fa-close"></i>
                Digital Catalogue (Share/Download)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.sharableDigitalVisitingCard===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.sharableDigitalVisitingCard===0" class="fa fa-close"></i>
                Sharable Digital Visiting Card (eCard)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.finnditDigitalPartnerCertificate===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.finnditDigitalPartnerCertificate===0" class="fa fa-close"></i>
                Finndit Partner Digital Certificate
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.inboxAccess===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.inboxAccess===0" class="fa fa-close"></i>
                Inbox Access (Text chat and Calls with business leads)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.customUrl===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.customUrl===0" class="fa fa-close"></i>
                Custom URL (e.g. https://YOURNAME.finndit.com)
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.finnditTrustSeal===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.finnditTrustSeal===0" class="fa fa-close"></i>
                Finndit Trust Seal
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.basicPerformanceReports===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.basicPerformanceReports===0" class="fa fa-close"></i>
                Basic Performance Reports
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.detailPerformanceReports===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.detailPerformanceReports===0" class="fa fa-close"></i>
                Detailed Performance Reports
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.newLeadAlertViaSms===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.newLeadAlertViaSms===0" class="fa fa-close"></i>
                New lead alert via SMS
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.listMultipleBusinessUnderSameLogin===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.listMultipleBusinessUnderSameLogin===0" class="fa fa-close"></i>
                List Multiple Business under same login and plan
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.userReviewManagement===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.userReviewManagement===0" class="fa fa-close"></i>
                User Review Management
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.unlimitiedOrganicLeads===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.unlimitiedOrganicLeads===0" class="fa fa-close"></i>
                Unlimited Organic Leads
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.buyPaidLeads===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.buyPaidLeads===0" class="fa fa-close"></i>
                Buy Paid Leads
              </li>
              <li class="mb-3">
                <i *ngIf="data.subscription_list.runBannerCampaigns===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.runBannerCampaigns===0" class="fa fa-close"></i>
                Run Banner Campaigns
              </li>
              <!-- <li class="mb-3">
                <i *ngIf="data.subscription_list.bookTopBusinessSlots===1" class="fa fa-check"></i>
                <i *ngIf="data.subscription_list.bookTopBusinessSlots===0" class="fa fa-close"></i>
                Book Top Business Slots
              </li> -->
            </ul>
            <a *ngIf="activePlan !==data.id" class="btn-main d-block text-center pointer"
              (click)="paymentMethod(data.id, data?.autoRenewal)">Get
              Started</a>

            <a *ngIf="activePlan ===data.id" class="btn-main d-block text-center">Current Plan</a>
            <div></div>

          </div>
        </div>
      </div>
    </div>

    <div class="No_found" *ngIf="planList.length == 0">
      No plans available
    </div>
  </div>
</div>
<!--   -->
<div class="qrcode" *ngIf="showQRcode" [ngClass]="planPage ? 'planPage':''">
  <div class="paymentWrapper">
    <a (click)="backToPlan()" *ngIf="!qrMethod" class="pointer backBtn">
      < Back </a>
        <ul class="paymentMethodList" *ngIf="!qrMethod">
          <li *ngIf="!showUPIForm">
            <div class="qrButton">
              <img style="max-width: 200px;" src="../../../assets/images/qr_code.jpg">
            </div>
            <a class="pointer btn-main" (click)="payWithQR()">Generator QR Code</a>
          </li>
          <li class="upiPayment" [ngClass]="showUPIForm ? 'active':''"><a class="pointer" (click)="payWithUPI()">Pay
              through UPI ID</a></li>
          <li *ngIf="showUPIForm">
            <div class="form-group group-cstm" style="margin-bottom: 0px;">
              <form [formGroup]="upiForm" (ngSubmit)="submitForm()">
                <label>Enter UPI ID</label>
                <input formControlName="upiId" class="form-control titleCase" type="text"
                  placeholder="UPI ID. eg - 89xxxxxx50@ybl">
                <div *ngIf="upiIdName" class="upiName">UPI ID associated with: <span class="upiName"> <i
                      class="fa fa-check-circle" aria-hidden="true"></i>{{upiIdName}}</span> </div>
                <div class="invlid_error" *ngIf="upiForm.controls['upiId'].hasError('required') && showError">
                  *Enter valid UPI ID
                </div>

                <div class="invlid_error" *ngIf="upiForm.controls['upiId'].hasError('cannotContainSpace') && showError">
                  *Enter valid UPI ID </div>

                <div class="invlid_error" *ngIf="showError&&upiForm.controls['upiId'].errors?.minlength">
                  *UPI ID should be minimum 3 characters. </div>
                <button *ngIf="!upiIdName" class="btn-main" [ngClass]="upiForm.value.upiId.length > 2 ? (''):'disable'"
                  style="font-size: 14px; margin: 10px 0 0 0;">Verify UPI ID</button>

                  <div (click)="send()"  *ngIf="upiIdName" class="btn-main pointer" style="font-size: 14px; margin: 10px 0 0 0;">Proceed to pay </div>

              </form>
            </div>
          </li>
        </ul>
        <div *ngIf="qrMethod" style="text-align: center; padding: 20px 0 10px 0;" >
          <div *ngIf="payWithScanner">
            <h2 style="font-size: 20px;padding-bottom: 15px;">Scan QR to Pay </h2>
            <qrcode [qrdata]="qrData" [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
              [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'" [imageHeight]="75"
              [imageWidth]="75" [margin]="4" [scale]="1" [title]="'Finndit'" [width]="250"></qrcode>
          </div>
          <div *ngIf="payWithUpi">
            <h2 style="font-size: 20px;padding-bottom: 15px;">Please check your Mobile app </h2>
          </div>
          <p class="timerBtn">{{ displayTime ? displayTime:"05:00" }}</p>
          <p class="noteMsg">Please do not press back or close window</p>
        </div>
  </div>
</div>
<!-- <div class="preloader-backdrop" *ngIf="showLoader">
  <div class="page-preloader">Verify Payment</div>
</div> -->
<div>
  <app-phone-pe #phonePe [parentToChild]="parentToChild"
  (childEvent)="childMessage($event)"></app-phone-pe>
</div>