import { Component, OnInit, ElementRef, ViewChild, TemplateRef, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiUrl } from "../../global/global";
import { AdminService } from "../../services/admin.service";
import { phonePeUrl } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
import { Router } from "@angular/router";
@Component({
  selector: 'app-phone-pe',
  templateUrl: './phone-pe.component.html',
  styleUrls: ['./phone-pe.component.css']
})
export class PhonePeComponent implements OnInit {
  paymentUrl = phonePeUrl.url;
  @Output() childEvent = new EventEmitter();
  @Input() parentToChild: Boolean;
  urlSafe: SafeResourceUrl;
  modalRef: BsModalRef;
  businessId: any;
  merchantTransactionId: any;
  basePrice: any;
  finalPrice: any;
  payMentType: any;
  count = 0;
  showOverLayer=false;
  private timerSubscription: Subscription;
  @ViewChild('paymentModel') private paymentModel: TemplateRef<any>
  // @ViewChild('myIframe', { read: ElementRef }) myIframe: ElementRef;
  @ViewChild('myIframe') myIframe: ElementRef;
  constructor(private modalService: BsModalService,private router: Router, public sanitizer: DomSanitizer, private api: AdminService,) { }

  ngOnInit(): void {
    if(this.router.url == "/become-merchant"){
      this.showOverLayer=true;
    }
  }
  ngOnDestroy() {
    this.showOverLayer=false;
    window.removeEventListener('message', this.handleIframeMessage.bind(this));
  }
  ngOnChanges() {
    if (this.parentToChild) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.parentToChild.toString());
    }
  }

  openModel(finalPrice, basePrice, businessId, merchantTransactionId, type) {
    this.payMentType = type.toString();
    this.businessId = businessId;
    this.merchantTransactionId = merchantTransactionId;
    this.basePrice = basePrice;
    this.finalPrice = finalPrice
    this.count = 0;
    this.modalRef = this.modalService.show(this.paymentModel, {
      animated: true,
      backdrop: 'static',

    });
    setTimeout(() => {
      if (this.parentToChild) {
        window.addEventListener('message', this.handleIframeMessage.bind(this));
      } else {

      }
    }, 200);
  }
  onIframeLoad() {
    try {
      const iframeWindow = this.myIframe.nativeElement.contentWindow;
      // Use postMessage to send a message to the iframe
      iframeWindow.postMessage({ type: 'initiateCommunication' }, this.urlSafe);
    } catch (error) {
      console.error('Error accessing iframe content:', error);
    }
  }

  handleIframeMessage(event: MessageEvent) {
    if (event.origin === this.paymentUrl) {
      const newContent = event.data;
      this.count++
      if (this.count == 1) {
        if (this.payMentType === "3") {
          setTimeout(() => {
            this.checkPaymentStatus();
          },6500);
        }else{
          setTimeout(() => {
            this.checkPaymentStatus();
          },6000);
        }        
        window.removeEventListener('message', this.handleIframeMessage.bind(this));
        return
      }
    }
  }
  checkPaymentStatus() {
    let data = {
      businessId: this.businessId,
      transactionId: this.merchantTransactionId,
    }
    this.api.getData(apiUrl._checkPaymentStatus, data).subscribe(async (res) => {
      if (res.data?.lastTransactionStatus == 1) {
        if (this.payMentType === "3") {
          this.modalRef.hide()
          this.childEvent.emit('updatePayment');
        } else {
          this.upDatePayMent()
        }
      } else {
        this.childEvent.emit('cancelPayment');
        this.modalRef.hide()
      }
    },
      err => {
        this.childEvent.emit('cancelPayment');
        this.modalRef.hide()

      })
  }


  upDatePayMent() {
    if (this.payMentType === "1") {
      let data = {
        businessId: this.businessId,
        transactionId: this.merchantTransactionId,
        finalPrice: this.finalPrice.toString(),
        basePrice: this.basePrice.toString()
      }
      this.api.putData(apiUrl._updateLeadsPayment, data).subscribe(async (res) => {
        this.childEvent.emit('updatePayment');
        this.modalRef.hide();
      },
        err => {
          this.childEvent.emit('cancelPayment');
          this.modalRef.hide()


        });

    }
    else if (this.payMentType === "2") {
      const apiData = {
        "transactionId": this.merchantTransactionId,
        "campaignId": this.basePrice,
        "businessId": this.businessId,
        "price": this.finalPrice.toString(),
      }
      this.api.putData(apiUrl._singlePayment, apiData)
        .subscribe(async (res) => {
          this.childEvent.emit('updatePayment');
          this.modalRef.hide()

        },
          err => {
            this.childEvent.emit('cancelPayment');
            this.modalRef.hide()

          });
    }

    //   else if (this.payMentType === "3") {
    //     const apiData = {
    //       "merchantTransactionId": this.merchantTransactionId,
    //       "businessId": this.businessId,
    //     }
    //     this.api.putData(apiUrl._singlePayment, apiData)
    //       .subscribe(async (res) => {
    //         this.childEvent.emit('updatePayment');
    //         
    //       },
    //         err => {
    //           this.childEvent.emit('cancelPayment');
    //           this.modalRef.hide()
    //         });
    //   }
  }
  remove() {
    window.removeEventListener('message', this.handleIframeMessage.bind(this));
  }
}
