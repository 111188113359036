import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateago",
  pure: true
})
export class DateagoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 10) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'Day': 86400,
        'Hour': 3600,
        'Min': 59,
        'Sec': 1
      };
      let counter;
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      if (seconds > 86400) {
        value = new Date(value);
        if (value.getFullYear() == yesterday.getFullYear() && value.getMonth() == yesterday.getMonth() && value.getDate() == yesterday.getDate())
          return "Yesterday";
        else {
          return (new DatePipe("en-US")).transform(value, 'dd MMM,yyyy');
        }
      }
      else {
        for (const i in intervals) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0)
            if (counter === 1) {
              return counter + ' ' + i + ' ago'; // singular (1 day ago)
            } else {
              return counter + ' ' + i + 's ago'; // plural (2 days ago)
            }
        }
      }

    }
    return value;
  }
}
