import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.css']
})
export class FeedbackPopupComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @ViewChild('feedBackModal') private feedBackModal: TemplateRef<any>
  modalRef: BsModalRef;
  feedbackForm: FormGroup;
  showButton = true;
  allData;
  disableForm= false
  showError = false;
  constructor(private api: AdminService
    , private modalService: BsModalService,
    private toasterService: ToastrService,
    private formBuilder: FormBuilder,) { }


  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      type: ["", Validators.compose([Validators.required])],
      description: [""]
    });

  }
  openModal(data) {
    this.showButton = true;
    console.log("data.businessName", data.businessName)
    this.allData = data;
    this.modalRef = this.modalService.show(this.feedBackModal, {
      animated: true,
      backdrop: 'static',
      class: "siderBarPopup right modal-log-sign"
    });
    if (data.description != '' && !!data.type) {
      this.showButton = false;
      this.feedbackForm.patchValue({ type: data.type, description: data.description });
      this.feedbackForm.disable();
      this.disableForm= true;
    }
    else {
      this.disableForm= false;
      this.feedbackForm.reset();
      this.feedbackForm.enable();
    }
  }
  close() {
    this.modalRef.hide();
  }
  submitFeedback() {
    if (this.feedbackForm.valid) {
      let param: any = {
        description: this.feedbackForm.value.description ? this.feedbackForm.value.description:'',
        leadId: this.allData.leadId,
        feedbackFormType: this.allData.from == 'customer' ? 1 : 2,
        type: this.feedbackForm.value.type
      }
      
      this.api.postData(apiUrl._addFeedback, param).subscribe(res => {
        this.feedbackForm.patchValue({ type: '', description: '' });
        this.valueChange.emit('');
        this.close();
      });
    }
    else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000)
    }

  }
  expClick(type) {
    this.feedbackForm.patchValue({ type: type });
    if (this.allData.from != 'customer') {
      let param: any = {
        description: this.feedbackForm.value.description,
        leadId: this.allData.leadId,
        feedbackFormType: this.allData.from == 'customer' ? 1 : 2,
        type: this.feedbackForm.value.type
      }
      if(this.allData.businessId)
      {
        param.businessId=this.allData.businessId
      }
      this.api.postData(apiUrl._addFeedback, param).subscribe(res => {
        this.feedbackForm.patchValue({ type: '', description: '' });
        if (this.allData.from !== 'customer') {
          this.toasterService.success('Feedback updated successfully.')
        }
        this.close();
        this.valueChange.emit('');
      });
    }
  }
}
