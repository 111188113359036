<div class="container">
    <div class="termsWrap">
        <h1>Merchant Terms and Conditions</h1>

        <p>Welcome to Finndit, a search engine platform operated by [Trycyfer Technologies Private Limited] ("we" or
            "us"). By using our platform and services, you agree to comply with and be bound by the following Terms and
            Conditions ("Terms").</p>
        <p>Please read these Terms carefully before using our platform and services.</p>
        <span class="sub-heading">Use of the Platform and Services</span>
        <p><strong>(a) Eligibility:</strong> You must be at least 18 years old and of sound mind to use our platform and
            services. By using our platform and services, you represent and warrant that you are at least 18 years old
            and a person of sound mind.</p>
        <p><strong>(b) License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license
            to use our platform and services in accordance with these Terms.</p>
        <p><strong>(c) Prohibited Uses:</strong> You agree not to use our platform and services for any illegal or
            unauthorized purpose, or in a manner that violates these Terms or any applicable laws or regulations. You
            also agree not to:</p>
        <ul>
            <li>Use our platform and services to harass, intimidate, or threaten any person or entity, or to impersonate
                any person or entity;</li>
            <li>Use our platform and services to upload, post, or transmit any content that is unlawful, defamatory,
                libelous, or infringes on the intellectual property rights of others;</li>
            <li>Use our platform and services to transmit any viruses, worms, or other harmful software;</li>
            <li>Use our platform and services to collect or store the personal data of other users without their
                consent;</li>
            <li>Interfere with or disrupt our platform and services or servers or networks connected to our platform and
                services;</li>
            <li>Use any automated system, including without limitation "robots," "spiders," or "offline readers," to
                access our platform and services in a manner that sends more request messages to our servers than a
                human can reasonably produce in the same period of time by using a conventional web browser; or</li>
            <li>Engage in any other activity that we determine, in our sole discretion, may be harmful to our platform
                and services, our users, or any third parties.</li>
        </ul>
        <span class="sub-heading">Content on the Platform</span>
        <p><strong>(a) Ownership:</strong> We own all intellectual property rights in our platform and services,
            including without limitation any text, graphics, images, software, and other content that we make available
            to you through our platform and services ("Finndit Content"). You agree not to copy, modify, distribute,
            sell, or lease any Finndit Content, or to create derivative works based on Finndit Content, without our
            prior written consent.</p>
        <p><strong>(b) User Content:</strong> You retain all ownership rights in any content that you upload, post, or
            transmit to our platform and services ("User Content"). By uploading, posting, or transmitting User Content
            to our platform and services, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and
            transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform User
            Content in connection with our platform and services.</p>
        <p><strong>(c) Prohibited Content:</strong> You agree not to upload, post, or transmit any content that is
            unlawful, defamatory, libelous, or infringes on the intellectual property rights of others. We reserve the
            right to remove/reject any User Content that we determine, in our sole discretion, violates these Terms or
            may be harmful to our platform and services, our users, or any third parties.</p>
        <span class="sub-heading">Payment and Fees</span>
        <p><strong>(a) Fees:</strong> We may charge fees for certain features of our platform and services, such as
            premium listings, Top Categories, Banners, Subscription plans, or advertising. We will notify you of any
            applicable fees before you use these features, and you agree to pay all fees that you incur in connection
            with our platform and services.</p>
        <p><strong>(b) Payment:</strong> You may be required to provide payment information, such as credit card
            details, in order to pay for any fees that you incur. By providing such information, you represent and
            warrant that you are authorized to use the payment method and that the information you provide is true,
            accurate, and complete. We reserve the right to refuse or cancel any transaction at any time for any reason.
        </p>
        <p><strong>(c) Taxes:</strong> You are responsible for all applicable taxes and fees that may be associated with
            your use of our platform and services, including without limitation any sales or use taxes.</p>
        <span class="sub-heading">Infringement Policy</span>
        <p>At Finndit, we take intellectual property rights seriously and expect all users of our Website and services
            to do the same. We have implemented the following policies to protect the intellectual property rights of
            others and to address any instances of infringement that may occur.</p>
        <span class="sub-heading">Notification of Infringement:</span>
        <p>If you believe that your intellectual property rights have been violated on our Website or through our
            services, please provide us with the following information:</p>
        <ul>
            <li>Identification of the copyrighted work or other intellectual property that you claim has been infringed
            </li>
            <li>A description of the material that you claim is infringing, including the location of the material on
                our Website or services</li>
            <li>Your contact information, including name, address, telephone number, and email address</li>
            <li>A statement that you have a good-faith belief that the use of the material is not authorized by the
                intellectual property owner, its agent, or the law</li>
            <li>A statement that the information you have provided is accurate and that you are the owner of the
                intellectual property or are authorized to act on behalf of the owner.</li>

        </ul>
        <p>Please send this information to our designated agent for receiving notifications of infringement at <strong>C
                196,
                Industrial Area Phase 8 B, Mohali Punjab 160055.<br />
                Email <a href="mailto:support@finndit.com">support@finndit.com</a> Phone Number +91-7341123700.</strong>
        </p>
        <p>We will investigate your claim and take appropriate action, which may include removing the infringing
            material or disabling access to it.</p>
        <span class="sub-heading">Counter-Notification:</span>
        <p>If you believe that material you have posted on our Website or through our services has been removed in error
            or due to misidentification, you may submit a counter-notification.</p>
        <p>Your counter-notification should include the following information:</p>
        <ul>
            <li>Identification of the material that has been removed or disabled and its location on our Website or
                services.</li>
            <li>Your contact information, including name, address, telephone number, and email address.</li>
            <li>A statement under penalty of perjury that you have a good-faith belief that the material was removed or
                disabled as a result of a mistake or misidentification.</li>
            <li>A statement that you consent to the jurisdiction of the courts in the location where Finndit is located.
            </li>
            <li>A statement that you will accept service of process from the party who submitted the notification of
                infringement or their agent.</li>

        </ul>
        <p>Please send your counter-notification to our designated agent for receiving counter-notifications at
            <strong>C
                196, Industrial Area Phase 8 B, Mohali Punjab 160055.<br />
                Email <a href="mailto:support@finndit.com">support@finndit.com</a> Phone Number +91-7341123700.</strong>
        </p>
        <p>We will forward your counter-notification to the party who submitted the original notification of the
            infringement and will take appropriate action in accordance with applicable laws.</p>
        <span class="sub-heading">Repeat Infringers:</span>
        <p>We will terminate the accounts of repeat infringers in appropriate circumstances, as determined in our sole
            discretion.</p>
        <span class="sub-heading">-Contact:</span>
        <p>If you have any questions or concerns about our Infringement Policy or our services, please contact us at
            <strong>C
                196, Industrial Area Phase 8 B, Mohali Punjab 160055.<br />
                Email <a href="mailto:support@finndit.com">support@finndit.com</a> Phone Number +91-7341123700.</strong>
        </p>
        <span class="sub-heading">Point of contact</span>
        <p>Our end user upon visiting our platform and making a query about a particular merchant either by calling or
            by dropping an enquiry email, the call or the query of the user shall be routed through our platform to our
            merchant. The lead so routed through us shall be recorded and shall be used in the event of any dispute.
            Furthermore, we reserve our right to forward/reject the lead in the event of usage of unparliamentary
            language. It shall be the responsibility and discretion of the merchant whether to mature the lead provided
            by us or not. We shall have no responsibility whether the lead provided by us is matured by the merchant or
            not. </p>
        <span class="sub-heading">Right to approach</span>
        <p>It is clarified that, irrespective of whether the merchant has registered or not registered their entity/
            firm's contact numbers in the "Do Not Call" registry of Telecom Regulatory Authority of India (TRAI), or any
            other statutory body, upon acceptance of the Terms Of Service, the merchant would be deemed to have given
            their consent and authorized us to share the merchant's details and contact numbers for business and
            promotional purpose with Users of the Service and for our other business purposes. Further the merchant, by
            accepting the Terms of Service, has given his consent to us to contact him for any business promotion of
            ourselves. The merchant confirms that the merchant shall carry out all the obligations as specified in the
            Terms of Service. The merchant also undertakes to take all such measures as may be necessary to ensure that
            the Users do not encounter any difficulty in contacting the merchant. In the event the Users are unable to
            contact the merchant it would not be deemed to any deficiency in the Services rendered by us. The merchant
            undertakes to comply with all the present and future TRAI regulations or regulations of any other regulatory
            bodies on subject matter covered under this clause and would ensure that us or the user will not face any
            difficulty in contacting the merchant.</p>
        <span class="sub-heading">NO GUARANTEE OF BUSINESS</span>
        <p>We do not guarantee that enquiries/leads will be generated at our platform for the merchant or that any of
            such enquiries/leads will be converted into business for the merchant. It shall be the sole responsibility
            and discretion of the merchant whether to mature the lead provided by us or not. We shall have no
            responsibility whether the lead provided by us is matured by the merchant or not as our role is restricted
            to the point of forwarding the demands of the end user to the merchant, if any. Furthermore, it shall be the
            responsibility of the data he is providing it to us for his business listing. We shall be not responsible
            for any false advertisement made by the merchant.</p>
        <span class="sub-heading">Visibility of business</span>
        <p>The listing of the merchant shall be dependent upon the subscription packages he/it is opting for. The
            details of the subscription plans can be viewed in the ____ column of our website. The subscription plans
            are subject to change without notice.</p>
        <span class="sub-heading">Disclaimer of Warranties</span>
        <p>Our platform and services are provided "as is" and without warranty of any kind, whether express, implied, or
            statutory. We disclaim all warranties, including without limitation any implied warranties of
            merchantability, fitness for a particular purpose, or non-infringement.</p>
        <span class="sub-heading">Limitation of Liability</span>
        <p>To the fullest extent permitted by applicable law, we will not be liable to you for any indirect, incidental,
            special, consequential, or punitive damages, or any loss of profits, revenue, data, or other intangible
            losses, arising out of or in connection with your use of our platform and services. Our liability to you
            will not exceed the amount you have paid to us in the twelve months preceding the event giving rise to the
            liability.</p>
        <span class="sub-heading">Indemnification</span>
        <p>You agree to indemnify and hold us harmless from and against any claims, damages, liabilities, costs, and
            expenses (including without limitation reasonable attorneys' fees) arising out of or in connection with your
            use of our platform and services, your User Content, or your violation of these Terms.</p>
        <span class="sub-heading">Termination</span>
        <p>We may terminate these Terms and your access to our platform and services at any time for any reason, without
            notice or liability to you. Upon termination, you must immediately cease all use of our platform and
            services.</p>
        <span class="sub-heading">Governing Law and Dispute Resolution</span>
        <ul>
            <li>In case of any dispute every endeavor to settle the disputes amicably shall be made and upon mutual
                understanding between finndit and the customer, the terms and conditions of the agreements can be
                altered,
                amended and modified as per business requirements however if mutual agreement fails all disputes
                inter-see
                the parties shall be referred to the Arbitration and governed by the Arbitration and Conciliation Act,
                1996
                as amended.</li>
            <li>The place of Arbitration shall be Mohali.</li>
            <li>Arbitrator to be chosen by finndit and the customer from the Panel of Arbitrators or appointed by the
                Court as per procedure and law.</li>
            <li>Finndit and the customer shall pay the Arbitrator equally.</li>
            <li>The arbitrator has the right to award or include in his award any relief that he deems proper, including
                money damages (with interest on unpaid amounts from the date due), specific performance, injunctive
                relief,
                and attorneys’ fees and costs, provided that the arbitrator may not declare any taxes, penalties,
                revenue
                charges, Proprietary Mark generic rights copyrights etc for which court of law established for the
                purpose
                will decide.</li>

        </ul>
        <span class="sub-heading">Changes to the Terms</span>
        <p>We may modify these Terms at any time by posting the modified Terms on our platform or by notifying you via email or other means. Your continued use of our platform and services after the effective date of any modifications to these Terms constitutes your acceptance of the modified Terms.</p>
        <span class="sub-heading">Intellectual Property</span>
        <p>The content on our platform, including but not limited to text, graphics, images, logos, and software, is our property or the property of our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use or reproduce any content on our platform without our prior written consent.</p>
        <span class="sub-heading">Third-Party Links</span>
        <p>Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for, the content, privacy policies, comments made by users upon the quality of the services offered by the merchant on our website/application or practices of any third-party websites or services. You acknowledge and agree that we are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>
        <span class="sub-heading">Modification or Discontinuation of Platform</span>
        <p>We reserve the right to modify, suspend, or discontinue our platform and services at any time without notice or liability to you. We will not be liable to you or any third party for any modification, suspension, or discontinuation of our platform or services.</p>
        <span class="sub-heading">Assignment</span>
        <p>You may not assign or transfer these Terms or any rights or obligations hereunder, by operation of law or otherwise, without our prior written consent. Any attempted assignment or transfer in violation of this provision will be null and void. We may assign or transfer these Terms or any rights or obligations hereunder without restriction.</p>      
    </div>
</div>