<section class="pt-4">
    <div class="container">
        <div class="accordionWrap">
            <h5 class="mb-4">Frequently Asked Questions</h5>
            <accordion class="accordion" [isAnimated]="true" [closeOthers]="true"  *ngIf="faqList?.data?.length>0">
                <accordion-group *ngFor="let faq of faqList?.data" class="card" heading={{faq?.question}}>
                    {{faq?.answer}}
                </accordion-group>
            </accordion>

            <div class="new-pagination-wrapper" *ngIf="total>9" style="margin-top: 15px;">
          <div class="pagination-wrapper">
            <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage" [boundaryLinks]="true"
              [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prev" nextText="Next"
              [totalItems]="total" firstText="First" lastText="Last" [rotate]="true"></pagination>
          </div>
          </div>
           
            <div class="No_found" *ngIf="faqList?.data?.length==0">
                No Data Found!
            </div>
        </div>
    </div>
</section>