import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/services/admin.service';
import { DataSharingService } from 'src/app/services/data.share.service';
import { apiUrl } from "../../../app/global/global";
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})
export class AllCategoriesComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  allCategory: any = [];
  subscription: Subscription;
  @ViewChild('category') private category: TemplateRef<any>
  constructor(private modalService: BsModalService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private admin: AdminService,
    @Inject(PLATFORM_ID) private platformId: any) { }
  ngOnInit(): void {
    this.getAllCategories();
    this.router.events.subscribe((value: any) => {
      const route = value;
      if (route.url == "/") {
        this.allCategory.forEach(element => {
          element.checked = false;
        });
      }
    });
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
  close() {
    this.modalRef.hide();
  }
  openModal() {
    this.modalRef = this.modalService.show(this.category, {
      animated: false,
      // backdrop: 'static',
      class: "left in"
    });
  }

  getAllCategories() {
    this.dataSharingService.allCategory.subscribe(data => {
      if (data) {
        data.data.forEach(element => {
          element.checked = false;
        });
        this.allCategory = data?.data;
        this.selectCategory();
      }
    });
  }
  categoryClick(category) {
    this.dataSharingService.clearSearch.next(true);
    this.allCategory.forEach(x => {
      if (x.id == category.id) {
        x.checked = true;
      }
      else {
        x.checked = false;
      }
    });
    //sessionStorage.setItem('selectedCategory',btoa(JSON.stringify({id:category.id,name:category.name})));
    this.dataSharingService.setSelectedCategory(category);
    this.close();
    this.router.navigate(['/category/sub-category'], { replaceUrl: false });
  }
  selectCategory() {
    this.subscription = this.dataSharingService.selectedCategory.subscribe(value => {
      if (value) {
        this.allCategory.forEach(element => {
          element.checked = false;
        });
        this.allCategory.forEach(x => {
          if (x.id == value.id) {
            x.checked = true;
          }
        });
      }
    })
  }
}
