<div class="container">
    <div class="termsWrap">
        <h1>Refund Policy (Applicable for paid subscribers)</h1>
        <p>Thank you for choosing Finndit as your business listing platform. We strive to provide high-quality services
            to all of our customers. However, if you are not satisfied with our services, we have a refund policy in
            place to protect your investment.
        </p>
        <p>Refunds will be provided in the following circumstances:
        </p>
        <ul>
            <li><strong>Duplicate payment:</strong> If you accidentally make a duplicate payment for the same listing,
                we will refund the additional payment.</li>

            <li><strong>Failure to list:</strong> If we are unable to list your business due to technical difficulties
                or other reasons, we will provide a full refund.</li>

            <li><strong>Unsatisfactory service:</strong> If you are not satisfied with the service provided, you may
                request a refund within 07 days of purchase. Refunds will be prorated based on the remaining time left
                in the subscription period. However, please note that no refunds will be processed if you have already
                used our services. We strongly encourage all customers to try our free plan before making a purchase to
                ensure that our services meet their needs.</li>
            <li><strong>Right to refuse refund:</strong> We reserve to refuse to initiate any refund to the merchant in
                the event where the merchant unilaterally refuses to use our services. Further in the event where the
                merchant has purchased a payment plan and has failed to provide details of his business to list his
                business within a period of 03 days from the date of payment. In the event the merchant removes or
                alters any services offered by him, in that event also we shall not be liable to refund the subscription
                fee.</li>
        </ul>
        <p>To request a refund, please contact our customer support team via email or phone. Refunds will be processed
            within 7 business days of the request being received.</p>

        <p>Please note that refunds will only be issued to the original payment method used. If payment was made by
            credit card, the refund will be issued to the same credit card. If payment was made by check, the refund
            will be issued by check.</p>

        <p>Thank you for choosing Finndit. We are committed to providing excellent service to our customers, and we
            appreciate your business.</p>

    </div>
</div>