<div class="container">
    <div class="termsWrap">
        <h1>Privacy Policy</h1>
        <p class="update-date">Last updated: August 07, 2023</p>

        <span class="sub-heading"> Introduction </span>
        <p>Finndit Digitech Private Limited (“we,” “our,” “Finndit” or “us”) is committed to protecting your privacy. We
            respect your privacy and recognise the importance of secure transactions and information privacy. </p>
        <p>This Privacy Policy describes how we and our affiliates collect, use, share or otherwise process your
            personal information through Finndit website www.finndit.com and Mobile Application “Finndit”.
        </p>
        <p>This Privacy Policy is an electronic agreement formed under the Information Technology Act, 2000 and the
            rules and regulations made there under (as amended till date) including the Information Technology
            (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 & the
            Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021. It does not
            require any physical or digital signatures to make the terms of this policy binding. This privacy policy is
            a legally binding document. The terms of this privacy policy will be effective upon your use of our Platform
            /service. Please read this policy carefully, together with our Terms of Services
        </p>
        <p>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”)
            alongside our application, Finndit. By accessing or using our Service, you signify that you have read,
            understood, and agree to our collection, storage, use, and disclosure of your personal information as
            described in this Privacy Policy and our Terms of Service.
        </p>
        <span class="sub-heading">Definitions and key terms</span>
        <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are
            referenced, are strictly defined as:
        </p>
        <ul>
            <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to
                identify your browser, provide analytics, remember information about you such as your language
                preference or login information. </li>
            <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Finndit Digitech
                Private Limited, (C-196A, 6TH FLOOR, TIMES SQUARE, PHASE 8B INDUSTRIAL AREA,SECTOR 74, MOHALI ), that is
                responsible for your information under this Privacy Policy.
            </li>
            <li>Country: where Finndit or the owners/founders of Finndit are based, in this case is India
            </li>
            <li>Customer: refers to the company, organization or person that signs up to use the Finndit Service to
                manage the relationships with your consumers or service users, and includes individual entities using
                our services, and corporate entities extending our services to their employees or class of such
                employees therein. </li>
            <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be
                used to visit Finndit and use the services.</li>
            <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol
                (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used
                to identify the location from which a device is connecting to the Internet.
            </li>
            <li>Personnel: refers to those individuals who are employed by Finndit or are under contract to perform a
                service on behalf of one of the parties.
            </li>
            <li>Personal Data: any information that directly, indirectly, or in connection with other information —
                including a personal identification number — allows for the identification or identifiability of a
                natural person.
            </li>
            <li>Service: refers to the service provided by Finndit as described in the relative terms (if available) and
                on this platform.
            </li>
            <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
                others who provide our content or whose products or services we think may interest you.</li>
            <li>Website: Finndit’s site, which can be accessed via this URL: <a href="https://finndit.com/"
                    target="_blank">https://finndit.com/</a>
            </li>
            <li>You: a person or entity that is registered with Finndit to use the Services.
            </li>
            <li>Vendor: Any entity which offers services or products on our platform
            </li>
        </ul>
        <span class="sub-heading">Applicable Laws</span>
        <p>Although there is no exclusive data protection law in India, the privacy and data protection is mandated
            under Section 43A of Information Technology Act, 2000 read with Information Technology (reasonable security
            practices and procedures and sensitive personal data or information) Rules, 2011.</p>
        <span class="sub-heading">Application of this Policy </span>
        <p>This policy shall apply equally to all of our vendors, service providers, subcontractors, partners, agents,
            representatives, employees, and any other third parties. Finndit undertakes to only collect and use personal
            data voluntarily provided by you. You are able to browse our platform without disclosing any personal
            information about them. However you have to provide limited personal data to us, as may be required, in
            order to register or to avail certain services from us.</p>
        <p>This policy applies to all contractors, suppliers, customers, users, vendors, sellers, partners, and other
            third party working on behalf of Finndit or accessing or using our platforms/service. It also applies to the
            head office, branches office and other offices of Finndit and all employees/officials of Finndit. It applies
            to all data that Finndit holds relating to identifiable individuals, even if that information technically
            falls outside of the Privacy & Data Protection law. The said information includes, Names, address,
            mobile/telephone numbers, email Id of users and any other information collected or received by users while
            accessing or using our platforms/service.
        </p>
        <p>This Privacy Policy is part of Finndit’s Terms of Service and covers the treatment of user information,
            including personally identifying information, obtained by Finndit, including information obtained when you
            access the Finndit platform, use the Finndit Service or any other software provided by Finndit. Unless
            otherwise stated explicitly, this Policy applies to Personal Information as disclosed on any of our
            Platform. This policy does not apply to the practices of organizations that we do not own or to people that
            we do not employ or manage.
        </p>
        <span class="sub-heading"> Objectives of the Policy</span>
        <p>Finndit is required to collect & use certain information about individuals, i.e., customers,
            suppliers/vendors, business contacts, employees, and the third parties with whom Finndit has a business
            relationship or may need to contact. This policy describes, how Finndit collects, receives, possesses,
            stores, deals or handle personal information including sensitive personal information about you and ensure
            that the same are available for review by you. The personal information must be collected, handled and
            stored to meet the data protection standards as well as legislative requirements. This policy ensures to
            comply with data protection law and follows the good practice and protects the rights of employees,
            customers, suppliers/vendors, business contacts, employees, and the third parties and how to stores and
            processes data and protects from the risks of data breach.</p>
        <span class="sub-heading">The Information we collect</span>
        <p>We collect information from you when you visit our website, register on our site, place an order, subscribe
            to our newsletter, respond to a survey or fill out a form.
            We also collect information from mobile devices for a better user experience, although these features are
            completely optional:
        </p>
        <ul>
            <li>Location (GPS): Location data helps to create an accurate representation of your interests, and this can
                be used to bring more targeted and relevant ads to potential customers.</li>
            <li> Camera (Pictures): Granting camera permission allows the user to upload any picture straight from the
                platform, you can safely deny camera permissions for this platform.</li>
            <li> Photo Gallery (Pictures): Granting photo gallery access allows the user to upload any picture from
                their photo gallery, you can safely deny photo gallery access for this platform.
            </li>
        </ul>
        <p>You may be required to give your name, residence address, workplace address, email address, date of birth,
            educational qualifications, Marital status, and similar Personal Information ("Personal Information") &
            credit card/debit card, and other payment instrument details. We, either through third party service
            provider or by our own, collect the Know-Your-Customer (KYC) related document from you. KYC related
            documents include but not limited to, driving licence, proof of possession of Aadhaar number, the Voter's
            Identity Card, Pan Card, GST, Passport, job card issued by NREGA (duly signed by an officer of the State
            Government and letter issued by the National Population Register containing details of name and address). If
            OVD (officially valid documents) furnished by you don’t have an updated address, then in addition to OVD, we
            may also collect the utility bill (electricity, telephone, post-paid mobile phone, piped gas, water bill)
            not more than two months old, property or Municipal tax receipt; pension or family pension payment orders
            (PPOs) issued to retired employees by Government Departments or Public Sector Undertakings, containing the
            address; letter issued by the Foreign Embassy or Mission in India (in case of foreigner), as prescribed by
            RBI (Reserve Bank of India) from time to time. If you are Sole Proprietary firms, we may collect the
            documents which include, (a) Registration certificate; (b) Certificate/licence issued by the municipal
            authorities under Shop and Establishment Act. (c) Sales and income tax returns. (d) GST certificate
            (provisional/final). (e) IEC (Importer Exporter Code) issued to the proprietary concern by the office of
            DGFT or Licence/certificate of practice issued in your firm’s name (in case of Importer /Exporter). (f)
            Income Tax Return (just the acknowledgement) in your firm’s name, duly authenticated/acknowledged by the
            Income Tax authorities. (g) Utility bills such as electricity, water, landline telephone bills, etc., or,
            the documents relating to proof of business/activity. We may also conduct verification and collect such
            other information and clarification as would be required to establish the existence of your firm, as per our
            satisfaction. If you are Legal Entities, we may collect the documents which includes, (a) Certificate of
            incorporation; (b) Memorandum and Articles of Association; (c) Permanent Account Number of the company; (d)
            Board resolution & power of attorney granted to its managers, officers or employees to transact on its
            behalf or Documents relating to beneficial owner. If you are partnership firm, we may collect the documents
            which include, (a) the certified copies Registration certificate; Partnership deed; Permanent Account Number
            of the partnership firm and Documents relating to beneficial owner, managers, officers or employees. If you
            are Trust, we may collect the documents which include, (a) the certified copies Registration certificate;
            Trust deed; Permanent Account Number or Form No.60 of the trust and Documents relating to beneficial owner,
            managers, officers or employees. If you are unincorporated association or a body of individuals, we may
            collect the documents which include, (a) the certified copies Resolution of the managing body; Permanent
            Account Number or Form No.60 of the unincorporated association or a body of individuals and Documents
            relating to beneficial owner, managers, officers or employees. We, either through third party service
            provider or by our own, may also undertake Video based Customer Identification Process (V-CIP) in Compliance
            of KYC policy of RBI. The Personal Information is used for three general purposes: to customize the content
            you see, to fulfil your requests for certain services, and to contact you about our services via including
            but not limited to email's, SMS’s and other means of communication. Where possible, we indicate which fields
            are required and which fields are optional. You always have the option to not provide information by
            choosing not to use a particular service, product or feature on our Platform. Finndit collects the personal
            information voluntarily provided by you while accessing or using our platforms/service. Finndit also
            collects data (on regular basis) from various sources such as newspaper, visiting cards, pamphlets,
            magazines and journals (including both free and pay), by appointment of data collectors, join free option
            made available on Finndit platforms. </p>
        <p>
            For a detailed list of data that may be recorded by us in the scope of our services, please see below
        </p>
        <div class="table-wrap">
            <table style="width: 100%;">
                <tr>
                    <td> S. No. </td>
                    <td> Source of Information </td>
                    <td> Nature of Information Collected </td>
                </tr>
                <tr>
                    <td>1.</td>
                    <td>Users/Customers</td>
                    <td>
                        <ol>
                            <li>Personal Identifying information such as name, address and phone numbers; email Id, Age,
                                personal description, profile photograph etc., & delivery address,
                            </li>
                            <li>payment information.</li>
                            <li>location information.</li>
                            <li>Device information (if you provided).</li>
                            <li>IP address.
                            </li>
                            <li>Name, addresses & phone numbers, e-mail IDs of friends and other people listed in
                                Addresses;
                            </li>
                            <li>Content of reviews and e-mails to us.</li>
                            <li>voice recordings when you call to us.
                            </li>
                            <li>credit usage, login detail, device log files etc., while using our platform.</li>
                            <li>Contacts – address book for app users
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Vendors/Sellers</td>
                    <td>
                        <ol>
                            <li>Personal Identifying information such as name, address and phone numbers; email Id, Age,
                                personal description, profile photograph ,Marital status etc.,.</li>
                            <li>payment information.
                            </li>
                            <li>location information.
                            </li>
                            <li>Device information (if you provided)
                            </li>
                            <li>IP address.</li>
                            <li>Name, addresses & phone numbers, e-mail IDs of friends and other people listed in
                                Addresses.</li>
                            <li>content of reviews and e-mails to us.
                            </li>
                            <li>voice recordings when you call to us.</li>
                            <li>images, videos and other content collected or stored in connection with our Services.
                            </li>
                            <li>information and officially valid documents (KYC regarding identity and address
                                information, including mobile & landline number, place of business, valid Email id,
                                vendor’s photo, id & address proof (such as Aadhar card, Pan Card, GST Voter Id Card,
                                Passport, Shop and Establishment Certificate, etc.,.</li>
                            <li>credit usage
                            </li>
                            <li>corporate and financial information, and</li>
                            <li>device log files and configurations etc.,.</li>

                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td>Automatic Information</td>
                    <td>
                        <ol>
                            <li>IP address of your device connected to our platform.</li>
                            <li>Login details, e-mail address, and password, device log files etc.,.
                            </li>
                            <li>ocation of device/computer.
                            </li>
                            <li>content interaction information, downloads, streaming of video, network details etc.,.
                            </li>
                            <li>device metrics, application usage, connectivity data, and any errors or event failures.
                            </li>
                            <li>our Services metrics, any technical errors, interactions with our service features and
                                content, settings preferences and backup information, location of device, file name,
                                dates, times etc while using our service.</li>
                            <li>content use history.
                            </li>
                            <li>URLs including date & time; products & contents viewed or searched for; page response
                                times, download errors, length of visits to certain pages, and page interaction
                                information etc.,.
                            </li>
                            <li>phone numbers used to call to us.</li>
                            <li>Images/videos while visiting our platforms.
                            </li>
                            <li>device identifiers, cookies, browsing history, usage history, and/or other technical
                                information.
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td>Information from Other Sources

                    </td>
                    <td>
                        <ol>
                            <li>updated delivery and address information from our carriers or other third parties.
                            </li>
                            <li>account information, purchase or redemption information and page-view information from
                                some merchants/partners for which we provide technical, advertising or other services.
                            </li>
                            <li>information about interactions with vendors while interacting via our channel.
                            </li>
                            <li>search results and links, including paid/free listings.
                            </li>
                            <li>internet-connected devices details.
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>5.</td>
                    <td>Officials/Employees/Resellers etc...</td>
                    <td>
                        <ol>
                            <li>Personal Identifying information such as name, address and phone numbers; email Id, Age,
                                personal description, profile photograph etc.,.</li>
                            <li>Educational Information.
                            </li>
                            <li>information and officially valid documents (KYC) regarding identity and address
                                information.
                            </li>
                            <li>payment information.</li>
                            <li>location information.
                            </li>
                            <li>Device information (if you provided)
                            </li>
                            <li>IP address.
                            </li>
                            <li>content of reviews and e-mails to us.
                            </li>
                            <li>voice recordings when you call to us.</li>
                            <li>login detail, device log files etc., while using our platforms.</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Third Party Information</td>
                    <td>
                        <ol>
                            <li>Corporate & financial information about our co-branded partners, delivery partners, and
                                other third party associated with us.
                            </li>
                            <li>CIN Number, PAN Number, GSTN Number etc.,.</li>
                            <li>Location information.</li>
                            <li>Device information (if you provided)</li>
                            <li>IP address.
                            </li>
                            <li>Internet-connected devices details.
                            </li>
                            <li>Identity and address information etc.,.</li>
                        </ol>
                    </td>

                </tr>
            </table>
        </div>
        <span class="sub-heading">What are the terms of the collection of data?</span>
        <p>Finndit does not collect sensitive personal data or information. It only collects the sensitive personal data
            or information which is considered necessary to provide the service or to fulfil the purpose and for a
            lawful purpose connected with a function or activity of Finndit. User can continue to browse / search on
            Finndit’s platform without giving any information; however, Personal Information is required for availing
            the certain services on Finndit’s platform.
        </p>
        <p>Finndit does not collect emails, addresses or other contact information from your mobile address book or
            contact lists other than name and mobile phone numbers. Finndit does not store historical location
            information in any form or manner at its end, except for last known location from which Finndit software was
            accessed in case of non-availability of current location data. Finndit App integrates with face-book SDK and
            may send aggregate usage statistics to face-book from time to time. Usage of information sent to face-book
            is governed by Facebook's data usage policies which can be found at <a
                href="https://www.facebook.com/about/privacy" target="_blank"></a> www.facebook.com/about/privacy.
        </p>
        <p>Finndit reserves the right to use other third party service providers for provision of similar or same
            services and aforementioned third parties shall be governed by this privacy policy in an identical manner.
        </p>
        <p>While collecting information, Finndit ensures that you must be informed about
        </p>
        <p>the fact that the information is being collected;
        </p>
        <ul>
            <li>the purpose for which the information is being collected;</li>
            <li> the intended recipients of the information; and</li>
            <li> the name and address of (i) the agency that is collecting the information; and (ii) the agency that
                will retain the information.
            </li>
        </ul>
        <p>The data/information shall not be collected unless-
        </p>
        <ul>
            <li>you have provided a valid, informed & free consent;
            </li>
            <li>processing is necessary for the performance of a contract to which you are party;
            </li>
            <li>processing is necessary for compliance with the company legal obligation;</li>
            <li>processing is necessary in order to protect your interests; or
            </li>
            <li>processing is necessary for the performance of a task carried out in the public interest.
            </li>
        </ul>
        <span class="sub-heading">How do we use your information?</span>
        <p>The data/information collected shall be used for the purpose for which it has been collected. In addition,
            the Finndit uses the data for the following purposes:
        </p>
        <ul>
            <li>To provide services.</li>
            <li>To fulfill your requests for products and services and communicate with you about those requests. If you
                search the products or service from our platform without logged in or sought the information by calling
                our hotline, we collect mobile number and generate lead with our vendors/service providers, as per your
                choice/request. If you search the products or service from our platform with logged in, we share your
                information (i.e., name, mobile, email etc.,) as available with us with our service providers to fulfil
                your choice/request.
            </li>
            <li>To administer contests, sweepstakes, promotions, and surveys.</li>
            <li>To respond to reviews, comments, or other feedback you provide us.
            </li>
            <li>To help us personalize our service offerings, websites, mobile services, and advertising.
            </li>
            <li>To provide functionality, analyze performance, fix errors, and improve the usability and effectiveness
                of the Services.</li>
            <li>To recommend features, products, and services that might be of interest to you, identify your
                preferences, and personalize your experience with Finndit Services.
            </li>
            <li>To operate, provide, develop, and improve the products and services that we offer our customers.
            </li>
            <li>To provide our services and to support our core business functions. These include order or service
                fulfilment, internal business processes, marketing, authentication, loss and fraud prevention, public
                safety and legal functions.
            </li>
            <li>To display interest-based ads for features, products, and services that might be of interest to you.
            </li>
            <li>To prevent and detect fraud and abuse in order to protect the security of our customers, Finndit, and
                others.
            </li>
            <li>Communicate with you.
            </li>
            <li>Contacting with you for promoting services.
            </li>
            <li>The User's Personal Data may be used for legal purposes by Finndit in legal proceeding at the stages
                leading to possible legal action arising from improper use of Finndit services.
            </li>
            <li>For operation and maintenance purposes.
            </li>
            <li>We use your voice input, images, videos, and other personal information to respond to your requests,
                provide the requested service to you, and improve our services.</li>
            <li>It helps us in operating, providing, improving, understanding, customizing, supporting, and marketing
                our Services.</li>
            <li>Comply with legal obligations. In certain cases, we collect and use your personal information to comply
                with laws. For instance, we collect from seller’s information regarding place of establishment and bank
                account information for identity verification and other purposes.
            </li>

        </ul>
        <p>Personal Information will be kept confidential and will be used for our research, marketing, and strategic
            client analysis objectives and other internal business purposes only. We do not sell Personal Information.
            If you are a customer of our services, your Information shall be shared with our subscribers/advertisers and
            you shall be given consent to the same. Further, the subscribers / advertisers who are listed with us, may
            call you, based on the query or enquiry that you make with us, enquiring about any Product / Service or
            Product / Service of any subscriber / advertiser. If you are a vendor/seller/business entity availing our
            services, your information shall be shared with our users, customers, callers, subscribers/advertisers who
            are seeking our services. You are hereby giving us the consent to share the details.
        </p>
        <p>We use your personal information to assist vendors, service providers, and business partners in handling and
            fulfilling orders; enhancing customer experience; resolve disputes; troubleshoot problems; help promote a
            safe service; collect money; measure consumer interest in our products/services; inform you about offers,
            products, services, and updates; customize and enhance your experience; detect and protect us against error,
            fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at
            the time of collection of information.
        </p>
        <p>With your consent, we will have access to your SMS, contacts in your directory, location and device
            information. We may also request you to provide your PAN, GST Number, Government issued ID cards/number and
            Know-Your-Customer (KYC) details to: (i) check your eligibility for certain products and services including
            but not limited to credit and payment products; (ii) issue GST invoice for the products and services
            purchased for your business requirements; (iii) enhance your experience on the Platform and provide you
            access to the products and services being offered by us, vendors, sellers, service providers, affiliates or
            lending partners. You understand that your access to these products/services may be affected in the event
            consent is not provided to us.</p>
        <p>In our efforts to continually improve our product and service offerings, we and our affiliates collect and
            analyse demographic and profile data about our users' activity on our Platform. We identify and use your IP
            address to help diagnose problems with our server, and to administer our Platform. Your IP address is also
            used to help identify you and to gather broad demographic information.
        </p>
        <p>We will occasionally ask you to participate in optional surveys conducted either by us or through a third
            party market research agency. These surveys may ask you for personal information, contact information, date
            of birth, demographic information (like zip code, age, or income level), attributes such as your interests,
            household or lifestyle information, your purchasing behaviour or history, preferences, and other such
            information that you may choose to provide. The surveys may involve collection of voice data or video
            recordings, the participation of which would purely be voluntary in nature. We use this data to tailor your
            experience at our Platform, providing you with content that we think you might be interested in and to
            display content according to your preferences.</p>
        <p>We have arrangement with other companies and individuals to perform functions for examples include analysing
            data, providing marketing assistance, providing search results and links (including paid listings and
            links), processing credit card payments and providing customer service etc. They have access to personal
            information needed to perform their functions, but may not use it for other purposes. Further, they must
            process the personal information in accordance with this Privacy Policy and as permitted by applicable law.
            In certain cases, we collect and use your personal information to comply with laws. For instance, we collect
            from vendors/seller’s information regarding place of establishment and bank account information for identity
            verification and other purposes.</p>
        <p>If you submit Personally Identifiable Information to us through the Finndit platform, or Finndit Service,
            then we use your personal information to operate, maintain, and provide to you the features and
            functionality of the Finndit platform and Finndit Service. In particular, your mobile phone number is
            essential to your use of the Finndit Service and will be retained. Your name (as it is saved in other user's
            mobile phone address book or contact list) may be displayed to other users if they tag your mobile number
            and access your ratings in the App.
        </p>
        <p>Finndit "tags" the contacts in your address book as your friends, but at any-time, you can review the list of
            your tagged friends and choose to untag any of them at any time. Untagging your contacts will hide your
            ratings from them and will also result in their ratings not being shown to you. Ratings would not indicate
            your relationship with the reviewer however ratings will be always shown on the platform.
        </p>
        <p>Only users of Finndit will be displayed in your tagged friends list. If a contact in your address book has
            rated an establishment/s on Finndit platforms, the ratings associated with the contact will be displayed as
            part of your friends' ratings. If a contact in your address book has used Finndit's service across any
            platform and has not rated an establishment, although their name is displayed in your friends list, no
            ratings will be shown associated with the said contact. If any of your tagged friends registers with our
            Service across Finndit's platforms, ratings of such a friend will be visible to you only if Your mobile
            number is present in his contacts list and You have not been untagged by your friend. It will be indicated
            as friend however rating will be always visible to everyone as a common user of Finndit.</p>
        <p>We do not use your mobile phone number or other Personally Identifiable Information to send commercial or
            marketing messages without your consent or except as part of a specific program or feature for which you
            will have the ability to opt-in or opt-out. We may use cookies and log file information to (a) remember
            information so that you will not have to re-enter it during your re-visit on our platform; (b) provide
            custom, personalized content and information; (c) monitoring or pages viewed, etc.; and (d) track your
            entries, submissions, views etc.,.
        </p>
        <span class="sub-heading">When does Finndit use customer information from third parties?
        </span>
        <p>We receive some information from the third parties when you contact us. For example, when you submit your
            email address to us to show interest in becoming a Finndit customer, we receive information from a third
            party that provides automated fraud detection services to Finndit. We also occasionally collect information
            that is made publicly available on social media websites. You can control how much of your information
            social media websites make public by visiting these websites and changing your privacy settings
        </p>
        <span class="sub-heading">Do we share the information we collect with third parties?
        </span>
        <p>We may share the information that we collect, both personal and non-personal, with third parties such as
            advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or
            whose products or services we think may interest you. We may also share it with our current and future
            affiliated companies and business partners, and if we are involved in a merger, asset sale or other business
            reorganization, we may also share or transfer your personal and non-personal information to our
            successors-in-interest.
        </p>
        <p>We may engage trusted third party service providers to perform functions and provide services to us, such as
            hosting and maintaining our servers and the website, database storage and management, e-mail management,
            storage marketing, credit card processing, customer service and fulfilling orders for products and services
            you may purchase through the website. We will likely share your personal information, and possibly some
            non-personal information, with these third parties to enable them to perform these services for us and for
            you. We may share portions of our log file data, including IP addresses, for analytics purposes with third
            parties such as web analytics partners, application developers, and ad networks.
        </p>
        <p>If your IP address is shared, it may be used to estimate general location and other technographics such as
            connection speed, whether you have visited the website in a shared location, and type of the device used to
            visit the website. They may aggregate information about our advertising and what you see on the website and
            then provide auditing, research and reporting for us and our advertisers. We may also disclose personal and
            non-personal information about you to government or law enforcement officials or private parties as we, in
            our sole discretion, believe necessary or appropriate in order to respond to claims, legal process
            (including subpoenas), to protect our rights and interests or those of a third party, the safety of the
            public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to
            otherwise comply with applicable court orders, laws, rules and regulations.</p>
        <p>Where and when is information collected from customers and end users?
            Finndit will collect personal information that you submit to us. We may also receive personal information
            about you from third parties as described above.
        </p>
        <span class="sub-heading">How Do We Use Your Email Address?
        </span>
        <p>By submitting your email address on this website, you agree to receive emails from us. You can cancel your
            participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe
            option that is included in the respective email. We only send emails to people who have authorized us to
            contact them, either directly, or through a third party. We do not send unsolicited commercial emails,
            because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use
            your email address for customer audience targeting on sites like Facebook, where we display custom
            advertising to specific people who have opted-in to receive communications from us. Email addresses
            submitted only through the order processing page will be used for the sole purpose of sending you
            information and updates pertaining to your order. If, however, you have provided the same email to us
            through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time
            you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at
            the bottom of each email which may be classified as marketing materials. Emails containing essential
            notifications relevant to the user/vendor/service providers cannot be unsubscribed from. </p>
        <span class="sub-heading">How Long Do We Keep Your Information?
        </span>
        <p>We keep your information only so long as we need it to provide Finndit to you and fulfill the purposes
            described in this policy. This is also the case for anyone that we share your information with and who
            carries out services on our behalf. When we no longer need to use your information and there is no need for
            us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or
            depersonalize it so that we can't identify you.
        </p>
        <span class="sub-heading">How do we process your information?</span>
        <ul>
            <li>Personal /Sensitive personal data may be processed if such processing is necessary, for any function of
                Parliament or any State Legislature and/or for the exercise of any function of the State authorised by
                law for the provision of any service or benefit to the data principal from the State; or the issuance of
                any certification, license or permit for any action or activity of the data principal by the State.</li>
            <li>Personal data/Sensitive personal data may be processed if such processing is (a) explicitly mandated
                under any law made by Parliament or any State Legislature; or (b) for compliance with any order or
                judgment of any Court or Tribunal in India.</li>
            <li>Personal data/Sensitive personal data may be processed if such processing is necessary— (a) to respond
                to any medical emergency involving a threat to the life or a severe threat to the health of the data
                principal or any other individual; (b) to undertake any measure to provide medical treatment or health
                services to any individual during an epidemic, outbreak of disease or any other threat to public health;
                or (c) to undertake any measure to ensure safety of, or provide assistance or services to, any
                individual during any disaster or any breakdown of public order.
            </li>
            <li>Personal data may be processed if such processing is necessary for— (a) recruitment or termination of
                employment of a data principal; (b) provision of any service to, or benefit sought by, the data
                principal; (c) verifying the attendance of the data principal; or (d) any other activity relating to the
                assessment of the performance of the data principal.</li>
            <li>In addition to the grounds for processing contained aforesaid, personal data may be processed if such
                processing is necessary for such reasonable purposes as may be specified after taking into
                consideration— (a) the interest of us in processing for that purpose; (b) whether us can reasonably be
                expected to obtain the consent of the data principal; (c) any public interest in processing for that
                purpose; (d) the effect of the processing activity on the rights of the data principal; and (e) the
                reasonable expectations of the data principal having regard to the context of the processing.</li>
            <li>The reasonable purposes means and includes, prevention and detection of any unlawful activity including
                fraud; whistle blowing; mergers and acquisitions; network and information security; credit scoring;
                recovery of debt; processing of publicly available personal data. However Finndit ensures the protection
                of the rights of data principals.
            </li>
            <li>Sensitive personal data may be processed on the basis of explicit consent. [Consent shall be considered
                explicit only if it is valid if: (a) informed, having regard to whether the attention of the data
                principal has been drawn to purposes of or operations in processing that may have significant
                consequences for the data principal; (b) clear, having regard to whether it is meaningful without
                recourse to inference from conduct in a context; and (c) specific, having regard to whether the data
                principal is given the choice of separately consenting to the purposes of, operations in, and the use of
                different categories of sensitive personal data relevant to processing].
            </li>
            <li><strong>Processing of personal data of children</strong>: Finndit doesn’t allow children below the age
                of 13 years to visit our platform without parental guidance or control. Based on your date of birth
                disclosed by you, we verify your age. When the children visit our platform or use our service, we
                believe that they access our platform or service under the parental guidance, control and consent and we
                can process the information accordingly. Finndit shall process personal data of children in a manner
                that protects the rights and best interests of the child.
            </li>
        </ul>
        <span class="sub-heading">How Do We Protect Your Information?</span>
        <p>We implement a variety of security measures to maintain the safety of your personal information when you
            place an order or enter, submit, or access your personal information. We offer the use of a secure server.
            All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then
            encrypted into our Payment gateway providers database only to be accessible by those authorised with special
            access rights to such systems, and are required to keep the information confidential. After a transaction,
            your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We
            cannot, however, ensure or warrant the absolute security of any information you transmit to Finndit or
            guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a
            breach of any of our physical, technical, or managerial safeguards. </p>

        <span class="sub-heading">Could my information be transferred to other countries?</span>
        <p>Finndit is incorporated in India. Information collected via our website, through direct interactions with
            you, or from use of our help services may be transferred from time to time to our offices or personnel, or
            to third parties, located throughout the world, and may be viewed and hosted anywhere in the world,
            including countries that may not have laws of general applicability regulating the use and transfer of such
            data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to
            the trans-border transfer and hosting of such information.
        </p>

        <span class="sub-heading">Is the information collected through the Finndit Service secure?</span>
        <ul>
            <li>The security of your Personal Information is important to us. You are responsible for keeping your
                device and your account safe and secure, and must notify us promptly of any unauthorised use or security
                breach of your account or our Services. Your account information is protected by the password you use to
                access your online account. Please keep this password confidential. Finndit uses commercially reasonable
                physical, managerial, technical, electronic and procedural safeguards to preserve the integrity and
                security of your personal information. Finndit shall adopt reasonable security practices and procedures
                as mandated under applicable laws for the protection of your Information. For this purpose, Finndit
                adopts internal reviews of the data collection, storage and processing practices and security measures,
                including appropriate encryption and physical security measures to guard against unauthorised access to
                systems where Finndit stores your Personal Information. We cannot, however, ensure or warrant the
                security of any information you transmit to Finndit and you do so at your own risk. You hereby waive and
                release Finndit from any claim of damages under the contract.</li>
            <li>Finndit may share your Information with third parties under a confidentiality agreement which,
                inter-alia, provides for that such third parties not disclosing the Information further unless such
                disclosure is for the purpose. However, Finndit is not responsible for any breach of security or for any
                actions of any third parties that receive your Personal Information. Finndit is not liable for any loss
                or injury caused to you as a result of you providing your Personal Information to third party (including
                any third-party websites, even if links to such third-party websites are provided on the Website). </li>
            <li>Notwithstanding anything contained in this Policy or elsewhere, Finndit shall not be held responsible
                for any loss, damage or misuse of your Personal Information, if such loss, damage or misuse is
                attributable to a Force Majeure Event.<strong> A "Force Majeure Event" shall mean any event that is
                    beyond the reasonable control of Finndit and shall include, without limitation, sabotage, fire,
                    flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots,
                    insurrection, war, acts of government, computer hacking, unauthorised access to computer, computer
                    system or computer network, computer crashes, breach of security and encryption (provided beyond
                    reasonable control of Finndit), power or electricity failure or unavailability of adequate power or
                    electricity.</strong> While we will endeavour to take all reasonable and appropriate steps to keep
                secure any Personal Information which we hold about you and prevent unauthorised access, you acknowledge
                that the internet or computer networks are not fully secure and that we cannot provide any absolute
                assurance regarding the security of your Personal Information. We maintain physical, electronic, and
                procedural safeguards in connection with the collection, storage, processing, and disclosure of personal
                information. Our security procedures mean that we may occasionally request proof of identity before we
                disclose personal information to you.</li>
            <li>We maintain reasonable physical, electronic and procedural safeguards to protect your information.
                Whenever you access your account information, we offer the use of a secure server. Once your information
                is in our possession we adhere to our security guidelines to protect it against unauthorised access.
                However, by using our platform, you accept the inherent security implications of data transmission over
                the internet which cannot always be guaranteed as completely secure, and therefore, there would always
                remain certain inherent risks regarding use of the Platform. You are responsible for ensuring the
                protection of login and password records for their account. You hereby waive and release Finndit from
                any claim of damages under contract.</li>
            <li>We work to protect the security of your data/information including sensitive personal data during
                transmission by using encryption protocols and software. We follow the Payment Card Industry Data
                Security Standard (PCI DSS) when handling payment card data. If you choose a payment gateway to complete
                any transaction on Finndit Platform, then your credit/debit card data/financial details may be stored in
                compliance with industry standards/recommended data security standard for security of financial
                information, i.e., the Payment Card Industry Data Security Standard (PCI-DSS) and as per applicable law.
            </li>
            <li>Finndit shall review the policies and collection methods of Third-Parties before accepting personal
                information from Third-Party data sources. The audit of reasonable security practices and procedures
                shall be carried out by an auditor annually to undertake significant upgradation of its process and
                computer resource.
            </li>
        </ul>
        <span class="sub-heading">Can I update or correct my information?</span>
        <p>The rights you have to request updates or corrections to the information Finndit collects depend on your
            relationship with Finndit. Personnel may update or correct their information as detailed in our internal
            company employment policies. Customers have the right to request the restriction of certain uses and
            disclosures of personally identifiable information as follows. You can contact us in order to – </p>
        <ul>
            <li>Update or correct your personally identifiable information,</li>
            <li>Change your preferences with respect to communications and other information you receive from us, or
            </li>
            <li>Delete the personally identifiable information maintained about you on our systems (subject to the
                following paragraph), by cancelling your account.
            </li>
        </ul>
        <p>Such updates, corrections, changes and deletions will have no effect on other information that we maintain,
            or information that we have provided to third parties in accordance with this Privacy Policy prior to such
            update, correction, change or deletion.
        </p>
        <p>To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to
            verify your identity before granting you profile access or making corrections. You are responsible for
            maintaining the secrecy of your unique password and account information at all times. You should be aware
            that it is not technologically possible to remove each and every record of the information you have provided
            to us from our system.
        </p>
        <p>The need to back up our systems to protect information from inadvertent loss means that a copy of your
            information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly
            after receiving your request, all personal information stored in databases we actively use, and other
            readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as, and to
            the extent reasonably and technically practicable. If you are an end user and wish to update, delete, or
            receive any information we have about you, you may do so by contacting our Data Grievance Officer as shown
            in this Privacy Policy
        </p>
        <span class="sub-heading">When do we disclose your information?</span>
        <ul>
            <li>Other users of Finndit Service may see your ratings submissions in a way that is consistent with the use
                of the Finndit Service. For example, a rating of "ABC Restaurant" on Finndit will be available to every
                user of the Finndit Service who has that mobile phone number in their mobile phone's address book or
                contact list and has tagged the mobile phone number as a friend in the Finndit App friends ratings
                feature. For more information on this feature, please refer paragraphs above.
            </li>
            <li>We do not sell or share your Personally Identifiable Information (such as mobile or phone number) with
                other third-party companies for their commercial or marketing use without your consent, or except as
                part of a specific program or feature for which you will have the ability to opt-in or opt-out. We may
                share your Personally Identifiable Information with third-party service providers to the extent that it
                is reasonably necessary to perform, improve or maintain the Finndit Service.</li>
            <li>We may disclose your personal information to third parties, such as vendors, sellers, service providers,
                business partners and other stakeholders. This disclosure may be required for us to provide you access
                to our products and services; for fulfilment of your orders; for enhancing your experience; for
                providing feedback on products; to collect payments from you; to comply with our legal obligations; to
                conduct market research or surveys; to enforce our Terms of Use; to facilitate our marketing and
                advertising activities; to analyse data; for customer service assistance; to prevent, detect, mitigate,
                and investigate fraudulent or illegal activities related to our product and services, sending postal
                mail and e-mail, removing repetitive information from customer lists, analyzing data and links
                (including paid listings and links), processing payments, transmitting content, scoring, assessing and
                managing credit risk, and providing customer service. We do not disclose your personal information to
                third parties without your explicit consent. These third-party service providers may have access to
                personal information needed to perform their functions, but may not use it for other purposes. Further,
                they must process the personal information in accordance with applicable law.</li>
            <li>We share information with our affiliated companies, business partners, associates etc with whom we are
                having an arrangement to provide/receive services. We may use the information we receive from them, and
                they may use the information we share with them, to help operate, provide, improve, understand,
                customise, support, and market our Services and their offerings. This includes helping improve
                infrastructure and delivery systems, understanding how our Services or theirs are used, securing
                systems, and fighting spam, abuse, or infringement activities and promoting, marketing of business.
            </li>
            <li>As we continue to develop our business, we might sell or buy other businesses or services. In such
                cases, all of our rights and obligations under our Privacy Policy are freely assignable by us to any of
                our affiliates, in connection with a merger, acquisition, restructuring, sale of assets, or by operation
                of law or otherwise, and we may transfer your information to any of our affiliates, successor entities,
                or third parties while providing the services subject to the pre-existing Privacy policy.</li>
            <li>We may disclose personal information if required to do so by law or in the good faith belief that such
                disclosure is reasonably necessary to: (a) respond to subpoenas, court orders, or other legal process or
                to government/law enforcement agencies requests; (b) enforce our Terms and any other applicable terms
                and policies, including for investigations of potential violations; (c) detect, investigate, prevent,
                and address fraud and other illegal activity, security, or technical issues; or (d) protect the rights,
                property, and safety of our users, companies, or others. (e) for purpose of verification of identity, or
                for prevention, detection, investigation including cyber incidents, prosecution, and punishment of
                offences.
            </li>
            <li>We disclose the account and other personal information when we believe such disclosure is appropriate to
                comply with the law; enforce or apply our Terms of Use and other agreements; or protect the rights,
                property, or safety of Finndit, our users, or others. This includes exchanging information with other
                companies and organizations for fraud protection.
            </li>
            <li>Finndit shall share/disclose the data/information, without obtaining prior consent from you, with
                Government agencies mandated under the law to obtain information, including sensitive personal data or
                information for the purpose of verification of identity, or for prevention, detection, investigation,
                including cyber incidents, prosecution, and punishment of offences.</li>
        </ul>

        <span class="sub-heading"> Personnel</span>
        <p>If you are a Finndit worker or applicant, we collect information you voluntarily provide to us. We use the
            information collected for Human Resources purposes in order to administer benefits to workers and screen
            applicants. You may contact us in order to </p>
        <ul>
            <li>Update or correct your information,</li>
            <li>Change your preferences with respect to communications and other information you receive from us, or
            </li>
            <li>Receive a record of the information we have relating to you. Such updates, corrections, changes and
                deletions will have no effect on other information that we maintain, or information that we have
                provided to third parties in accordance with this Privacy Policy prior to such update, correction,
                change or deletion.
            </li>
        </ul>
        <span class="sub-heading"> Transfer of Information </span>
        <p>Finndit shall not transfer sensitive personal data or information including any information, to third parties
            in India, or outside India, except under the following circumstances:
        </p>
        <ul>
            <li>To fulfill your requests for products and services,</li>
            <li>To fix errors, and improve the usability and effectiveness of the Services,</li>
            <li>To fraud prevention, public safety and legal functions,</li>
            <li>To prevent and detect fraud and abuse in order to protect the security of our customers, Finndit, and
                others,</li>
            <li>It is necessary for the performance of the lawful contract between Finndit and data principal; or
            <li>where data principal has consented to data transfer;</li>
        </ul>
        <p>However, Finndit ensures the same level of data protection that is adhered to by them, as that of Finndit as
            provided for.
        </p>
        <p>When using vendors to collect personal information on the behalf of Finndit, it shall ensure that the vendors
            comply with the data protection requirements of Finndit as defined in this Policy.</p>

        <span class="sub-heading">Accountability Measures</span>
        <p>Privacy by Design:</p>
        <ul>
            <li>Finndit shall implement policies and measures to ensure that managerial, organisational and business
                practices, and technical systems are designed in a manner to anticipate, identify and avoid harm to you;
            </li>
            <li>Finndit shall implement policies and measures to ensure that the technology used in the processing of
                personal data is in accordance with commercially accepted or certified standards; </li>
            <li>Finndit shall implement policies and measures to ensure that the legitimate interest of businesses
                including any innovation is achieved without compromising privacy interests;
            </li>
            <li>Finndit shall implement policies and measures to ensure that privacy is protected throughout processing
                from the point of collection to deletion of personal data, and processing of personal data is carried
                out in a transparent manner and the interest of the data provider is accounted for at every stage of
                processing of personal Data.
            </li>

        </ul>
        <p>Transparency: Finndit shall take reasonable steps to maintain transparency regarding its general practices
            related to processing personal data and shall make the following information available in an easily
            accessible form as may be specified:
        </p>
        <ul>
            <li>the categories of personal data generally collected and the manner of such collection;
            </li>
            <li>the purposes for which personal data is generally processed;
            </li>
            <li>any categories of personal data processed in exceptional situations or any exceptional purposes of
                processing that create a risk of significant harm;
            </li>
            <li>the existence of and procedure for the exercise of data principal rights;
            </li>
            <li>the existence of a right to file complaints to the Authority;
            </li>
            <li>Information regarding cross-border transfers of personal data, if any.
            </li>
        </ul>
        <p>Security Safeguards: Having regard to the nature, scope and purpose of processing personal data undertaken,
            the risks associated with such processing, and the likelihood and severity of the harm that may result from
            such processing, Finndit shall implement appropriate security safeguards including (a) use of methods such
            as de-identification and encryption; (b) steps necessary to protect the integrity of personal data; and (c)
            steps necessary to prevent misuse, unauthorised access to, modification, disclosure or destruction of
            personal data. Finndit shall undertake a review of its security safeguards periodically as may be specified
            and may take appropriate measures accordingly.
        </p>
        <p>Personal Data Breach: Finndit shall notify the Authority of any personal data breach relating to any personal
            data processed by Finndit where such breach is likely to cause harm to any data principal. The said
            notification includes, (a) nature of personal data which is the subject matter of the breach; (b) number of
            data principals affected by the breach; (c) possible consequences of the breach; and (d) measures being
            taken by the data fiduciary to remedy the breach.</p>
        <p>Record-Keeping: Finndit shall maintain accurate and up-to-date records of the following: (a) important
            operations in the data life-cycle including collection, transfers, and erasure of personal data to
            demonstrate compliance; (b) periodic review of security safeguards; and (c) data protection impact
            assessments.
        </p>
        <p>Data Audits: Finndit shall have its policies and the conduct of its processing of personal data audited
            annually by an independent data auditor. The data auditor will evaluate the compliance of Finndit with the
            provisions of law including clarity and effectiveness of notices, transparency, security safeguards,
            instances of personal data breach and response thereto.
        </p>
        <span class="sub-heading">Sale of Business</span>
        <p>We reserve the right to transfer information to a third party in the event of a sale, merger or other
            transfer of all or substantially all of the assets of Finndit or any of its Corporate Affiliates (as defined
            herein), or that portion of Finndit or any of its Corporate Affiliates to which the Service relates, or in
            the event that we discontinue our business or file a petition or have filed against us a petition in
            bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the
            terms of this Privacy Policy. Affiliates We may disclose information (including personal information) about
            you to our Corporate
        </p>
        <span class="sub-heading">Affiliates</span>
        <p>For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or
            indirectly controls, is controlled by or is under common control with Finndit, whether by ownership or
            otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by
            those Corporate Affiliates in accordance with the terms of this Privacy Policy.
        </p>
        <span class="sub-heading">Governing Law </span>
        <p>This Privacy Policy is governed by the laws of India without regard to its conflict of laws provision. You
            consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between
            the parties under or in connection with this Privacy Policy, except for those individuals who may have
            rights to make claims under Privacy Shield, or the Swiss-US framework. The laws of India, excluding its
            conflicts of law rules, shall govern this Agreement and your use of the website. Your use of the website may
            also be subject to other local, state, national, or international laws. By using Finndit or contacting us
            directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy,
            you should not engage with our website, or use our services. Continued use of the website, direct engagement
            with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use
            or disclosure of your personal information will mean that you accept those changes. </p>
        <span class="sub-heading">Your Consent</span>
        <p>We have updated our Privacy Policy to provide you with complete transparency into what is being set when you
            visit our site, and how it is being used. By using our Finndit, registering an account, or making a
            purchase, you hereby consent to our Privacy Policy and agree to its terms.</p>
        <span class="sub-heading">Links to Other Websites</span>
        <p>This Privacy Policy applies only to the Services. The Services may contain links to other websites not
            operated or controlled by Finndit. We are not responsible for the content, accuracy or opinions expressed in
            such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by
            us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy
            is no longer in effect. Your browsing and interaction on any other website, including those that have a link
            on our platform, is subject to that website’s own rules and policies. Such third parties may use their own
            cookies or other methods to collect information about you.
        </p>
        <span class="sub-heading">Advertising</span>
        <p>This website may contain third party advertisements and links to third party sites. Finndit does not make any
            representation as to the accuracy or suitability of any of the information contained in those advertisements
            or sites and does not accept any responsibility or liability for the conduct or content of those
            advertisements and sites and the offerings made by the third parties. Advertising keeps Finndit and many of
            the websites and services you use free of charge. We work hard to make sure that advertisements are safe,
            unobtrusive, and as relevant as possible. Third-party advertisements and links to other sites where goods or
            services are advertised are not endorsements or recommendations by Finndit of the third-party sites, goods
            or services. Finndit takes no responsibility for the content of any of the ads, promises made, or the
            quality/reliability of the products or services offered in all advertisements.</p>
        <span class="sub-heading">Cookies for Advertising</span>

        <p>These cookies collect information over time about your online activity on the website and other online
            services to make online advertisements more relevant and effective to you. This is known as interest-based
            advertising. They also perform functions like preventing the same advertisement from continuously
            reappearing and ensuring that advertisements are properly displayed for advertisers. Without cookies, it is
            really hard for an advertiser to reach its audience, or to know how many ads were shown and how many clicks
            they received.
        </p>
        <span class="sub-heading">Cookies
        </span>
        <p>To optimise our web presence, we use cookies. These are small text files stored on your computer's main
            memory. These cookies are deleted after you close the browser. Other cookies remain on your computer
            (long-term cookies) and permit its recognition on your next visit. This allows us to improve your access to
            our site. This helps us to learn more about your interests, and provides you with essential features and
            services, including:
        </p>
        <ul>
            <li>Keeping track of items stored in your shopping basket.</li>
            <li>Conducting research and diagnostics to improve the content, products, and services.
            </li>
            <li>Preventing fraudulent activity.</li>
            <li>Improving security.
            </li>
        </ul>
        <p>Our cookies allow you to take advantage of some of our essential features. For instance, if you block or
            otherwise reject our cookies, you will not be able to add items to your shopping basket, proceed to
            checkout, or use any products or services that require you to sign in.

            Approved third parties also may set cookies when you interact with our services.
        </p>
        <p>Third parties include search engines, providers of measurement and analytics services, social media networks,
            and advertising companies.</p>
        <p>Third parties use cookies in the process of delivering content, including advertisements relevant to your
            interests, to measure the effectiveness of their advertisements, and to perform services on behalf of us.
        </p>
        <p>You can prevent the storage of cookies by choosing a "disable cookies" option in your browser settings.
            However, this can limit the functionality of our services.
        </p>
        <span class="sub-heading">Blocking and disabling cookies and similar technologies</span>
        <p>Wherever you are located you may also set your browser to block cookies and similar technologies, but this
            action may block our essential cookies and prevent our website from functioning properly, and you may not be
            able to fully utilise all of its features and services. You should also be aware that you may also lose some
            saved information (e.g., saved login details, site preferences) if you block cookies on your browser.
            Different browsers make different controls available to you. Disabling a cookie or category of cookie does
            not delete the cookie from your browser, you will need to do this yourself from within your browser, you
            should visit your browser's help menu for more information.
        </p>
        <span class="sub-heading">Remarketing Services
        </span>
        <p>We use remarketing services. In digital marketing, remarketing (or retargeting) is the practice of serving
            advertisements across the internet to people who have already visited your website. It allows your company
            to seem like they are “following” people around the internet by serving advertisements on the websites and
            platforms they use most.
        </p>
        <span class="sub-heading">Children’s Privacy
        </span>
        <p>We collect information from kids under the age of 13 just to better our services. If you are a parent or
            guardian and are aware that your child has provided us with Personal Data without your permission, please
            contact us at <a href="mailto:support@finndit.com">support@finndit.com</a>
            . If we become aware that we have collected Personal Data from anyone under
            the age of 13 without verification of parental consent, we shall take steps to remove that information from
            our servers.
        </p>
        <span class="sub-heading">Changes To Our Privacy Policy
        </span>
        <p>We may change our Service and policies. We may need to make changes to this Privacy Policy so that it
            accurately reflects our Service and policies. Unless otherwise required by law, we will notify you (for
            example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to
            review them before they go into effect. Then, if you continue to use the Service, you will be bound by the
            updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete
            your account.
        </p>
        <span class="sub-heading">Third-Party Services
        </span>
        <p>We may display, include or make available third-party content (including data, information, applications and
            other products services) or provide links to third-party websites or services ("Third- Party Services"). You
            acknowledge and agree that Finndit shall not be responsible for any Third-Party Services, including their
            accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other
            aspect thereof. Finndit does not assume and shall not have any liability or responsibility to you or any
            other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided
            solely as a convenience to you and you access and use them entirely at your own risk and subject to such
            third parties' terms and conditions.

        </p>
        <span class="sub-heading">Facebook Pixel
        </span>
        <p>Facebook pixel is an analytics tool that allows you to measure the effectiveness of your advertising by
            understanding the actions people take on your website/app. You can use the pixel to make sure your
            advertisements are shown to the right people. Facebook pixel may collect information from your device when
            you use the service. Facebook pixel collects information that is held in accordance with its Privacy Policy.
        </p>
        <span class="sub-heading">Tracking Technologies
        </span>
        <p>Google Maps API</p>
        <ul>
            <li>Google Maps API is a robust tool that can be used to create a custom map, a searchable map, check-in
                functions, display live data synching with location, plan routes, or create a mashup just to name a few.
                Google Maps API may collect information from You and from Your Device for security purposes.
                Google Maps API collects information that is held in accordance with its Privacy Policy
            </li>
        </ul>
        <p>Cookies</p>
        <ul>
            <li>We use Cookies to enhance the performance and functionality of our platform but are non-essential to
                their use. However, without these cookies, certain functionality like videos may become unavailable or
                you would be required to enter your login details every time you visit the platform as we would not be
                able to remember that you had logged in previously.
            </li>
        </ul>
        <p>Local Storage </p>
        <ul>
            <li>Local Storage sometimes known as DOM storage provides web apps with methods and protocols for storing
                client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly
                enhanced capacity and no information stored in the HTTP request header.
            </li>
        </ul>
        <p>Merchant Data Sharing </p>
        <ul>
            <li>We reserve the right to share personal information for marketing materials with merchants.
            </li>
        </ul>
        <span class="sub-heading">Monitoring and Enforcement
        </span>
        <ul>
            <li>Dispute Resolution & Recourse: Finndit shall define and document a cyber-incident management program
                which addresses the data protection & privacy related incidents. The incident management program
                includes a clear escalation path up to the executive management, legal counsel, and the board based on
                type and/or severity of the data breach. It shall define a process to register all the
                incidents/complaints and queries related to data breach. Finndit shall perform a periodic review of all
                the complaints related to data breach to ensure that all the complaints are resolved in a timely manner
                and resolutions are documented and communicated to the data principals. An escalation process for
                unresolved complaints and disputes which shall be designed and documented and the Communication of
                privacy incident/breach reporting channels and the escalation matrix shall be provided to all data
                providers.</li>
            <li>Dispute Resolution & Escalation Process for Employees: Employees with enquiries or complaints about the
                processing of their personal information shall first discuss the matter with their immediate reporting
                manager. If the employee does not wish to raise an inquiry or complaint with their immediate reporting
                manager, or if the manager and employee are unable to reach a satisfactory resolution of the issues
                raised, the employee shall bring the issue to the attention of the Grievance Officer.
            </li>
            <li>Dispute Resolution & Escalation Process for Customer/Third Party: Customers/Third Party with inquiries
                or complaints about the processing of their personal information shall bring the matter to the attention
                of the Grievance Officer in writing. Any disputes concerning the processing of the personal information
                of non-employees shall be resolved through arbitration.</li>

        </ul>
        <p>Grievance Officer
        </p>
        <p> In accordance with Information Technology Act, 2000 and rules & regulations made thereunder, the name and
            contact details of the Grievance Officer are provided below:
        </p>
        <p>Name: Yogesh Kumar </p>
        <p>Address: (C-196A, 6TH FLOOR, TIMES SQUARE, PHASE 8B INDUSTRIAL AREA,SECTOR 74, MOHALI ), </p>
        <p>Contact No. 9115567558 </p>
        <p> Email: datagrievance@finndit.com </p>
        
        <p>If you have a query, issue, concern, or complaint in relation to collection or usage of your personal
            information under this Privacy Policy, please contact us at the contact information provided above or write
            to us at usersupport@finndit.com
        </p>
    </div>
</div>