import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  HostListener,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UsernameValidator } from "../../services/NoWhiteSpace.validator";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "src/app/global/global";
//import { HomePageComponent } from '../home-page/home-page.component';
import { DataSharingService } from "../../services/data.share.service";

@Component({
  selector: "app-location-popup",
  templateUrl: "./location-popup.component.html",
  styleUrls: ["./location-popup.component.css"],
})
export class LocationPopupComponent {
  @ViewChild("locationTemplate") private locationTemplate: TemplateRef<any>;
  @ViewChild("blockTemplate") private blockTemplate: TemplateRef<any>;
  @ViewChild("gotoManuallyTemplate")
  private gotoManuallyTemplate: TemplateRef<any>;
  @Input() intervalId: any;
  modalRef: BsModalRef;
  blockModalRef: BsModalRef;
  gotoManuallyRef: BsModalRef;
  formData: any = [];
  isOpen = false;
  modelcontent="";
  manually=false;
  cross=true;
  isBlocked = false;
  flickerClass = "";
  disableForm = false;
  showDropdwonCity = false;
  cityData: any = [];
  manualLocationForm: FormGroup;
  latitude: number = 30.713329;
  longitude: number = 76.695566;
  mapCity: any;

  @Output() childEvent = new EventEmitter();
  showError = false;
  //private HomePage: HomePageComponent,
  constructor(
    private dataSharingService: DataSharingService,
    private formBuilder: FormBuilder,
    private api: AdminService,
    private modalService: BsModalService,
    private elementRef: ElementRef
  ) {
    this.manualLocationForm = this.formBuilder.group({
      pincode: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          UsernameValidator.cannotContainSpace,
        ]),
      ],
      city: [
        "",
        Validators.compose([
          Validators.required,
          UsernameValidator.cannotContainSpace,
        ]),
      ],
    });
  }
  ngOnInit(): void {
    this.manualLocationForm.reset();
    //this.loadGeoMaps();
    this.dataSharingService.intervalIdshare.subscribe((data) => {
      this.intervalId = data;
    });
  }

  @Output() locationAccessGranted = new EventEmitter<boolean>();
  @HostListener("window:click") click() {
    if (this.showDropdwonCity) {
      this.showDropdwonCity = false;
    }
  }
  @HostListener("window:click", ["$event"])
  onWindowClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.modalRef) {
        this.flickerClass = "flicker";
        setTimeout(() => {
          this.flickerClass = "";
        }, 500);
      }
    }
  }

  allowLocationAccess() {
    this.locationAccessGranted.emit(true);
  }

  denyLocationAccess() {
    this.locationAccessGranted.emit(false);
  }
  opencurrentlocation(){
    this.closeGotoManuallyTemplate();
    this.openModal(true);
    
  }

  isblock(){
    this.isBlocked = true;
    // setTimeout(() => {
    //   this.isBlocked = false;
    // }, 5000);
  }

  openModal(isAccess = true) {
    if (isAccess) {
      this.isOpen = true;
      if (localStorage.getItem("city")) {
      this.cross=true;
        this.modelcontent = "Please share the location where you want to get the service. This will help us to show you relevant nearby options and their distance from your chosen location."
      }else{
        this.modelcontent = "We need to know your location in order to suggest nearby services.";
    this.cross=false;
      }
      this.modalRef = this.modalService.show(this.locationTemplate, {
        class: "modal-lg modal-class freeze-resolve",
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
      });
      setTimeout(() => {
        const modalElement = document.querySelector('.modal') as HTMLElement;
        if (modalElement) {
          modalElement.setAttribute('aria-label', 'location popup');
        }
      }, 0);
    } else if (!isAccess) {
      // this.isBlocked = true;
      // setTimeout(() => {
      //   this.isBlocked = false;
      // }, 5000);
      // this.blockModalRef = this.modalService.show(this.blockTemplate, {
      //   class: "modal-lg modal-class freeze-resolve",
      //   animated: true,
      //   keyboard: true,
      //   backdrop: true,
      //   ignoreBackdropClick: true,
      // });
    }
    document.body.style.overflow = "auto";

    setTimeout(() => {
      this.flickerClass = "flicker";
      setTimeout(() => {
        this.flickerClass = "";
      }, 500);
    }, 400);
  }

  gotoManually() {
    this.isOpen = false;
    this.manually = true;
    this.modalRef.hide();
    clearInterval(this.intervalId);   
    this.manualLocationForm.reset();
    this.gotoManuallyRef = this.modalService.show(this.gotoManuallyTemplate, {
      class: "modal-lg modal-class freeze-resolve",
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }
  close(isLocationPopup = true) {
    if (isLocationPopup) {
      this.modalRef.hide();
      this.isOpen = false;
    } else if (!isLocationPopup) {
      this.blockModalRef.hide();
      this.isBlocked = false;
    }
  }

  closeGotoManuallyTemplate() {
    if (!localStorage.getItem("city")) {
      this.openModal(true);
      this.isOpen = true;
      this.isBlocked = false;
    }
    this.gotoManuallyRef.hide();
    this.isOpen = false;
    this.manually =false;
  }
  submitManualLocation() {
    if (this.manualLocationForm.valid) {
      this.childEvent.emit(this.formData);

    
      this.setAddressLocal(this.formData);
      this.submitAddress(this.formData);
      const cityNameElement = document.querySelector(".cityName");
      if (cityNameElement) {
        cityNameElement.textContent = this.formData.city;
      }
      /* ------------------- */

      this.closeGotoManuallyTemplate();
      this.close(true);
      window.location.reload();
    } else {
      this.showError = true;
    }
  }


  submitAddress(data) { 
    const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    this.dataSharingService.locationChange.next({ setLocation: false, address: data });
    if (userData) {    
        this.api.postData(apiUrl._location, data).subscribe(async (res) => {        
          let userinfo = JSON.stringify(res.data.user);
          localStorage.setItem("customerDetail", userinfo);
          this.setAddressLocal(data);
          this.dataSharingService.isLoginChanged.next(true);
          localStorage.removeItem("setLocation");
        }, err => {
          this.api.showErrorMsg(err.error.message);
        })  
    }
    
  }

  onModalClick(event: MouseEvent) {
    event.stopPropagation();
  }
  onlyNumberKey(event) {
    return this.api.allowOnlyNumbers(event);
  }

  getCity(e, type) {
    let data: any = {};

    if (type == 3) {
      if (this.manualLocationForm.value.pincode.length === 6) {
        data = {
          type: 3,
          title: this.manualLocationForm.value.pincode,
        };
        this.getDataFromApi(data, type);
      }
    }
    if (type == 2) {
      if (this.manualLocationForm.value.city.length > 3) {
        data = {
          type: 2,
          title: this.manualLocationForm.value.city?.trim(),
        };
        this.getDataFromApi(data, type);
      }
      if (this.manualLocationForm.value.city.length <= 3) {
        setTimeout(() => {
        this.showDropdwonCity = false;
        this.cityData ="";
        },2000);
      }
    }
  }

  getDataFromApi(data, type) {    
    this.api
      .getDataWithoutLoader(apiUrl._getCityState, data)
      .subscribe(async (res) => {
       if(res.data.rows.length>0){
        if (type == 3) {
          this.showDropdwonCity = true;
          this.cityData = res.data?.rows;
          //this.addPinCodeAddress(res.data.rows);
        }
        if (type == 2) {
          this.showDropdwonCity = true;
          this.cityData = res.data?.rows;
        }
      }else{
        this.showDropdwonCity=false;
        this.cityData=[];
      }
      });
  }
  selectRelevantCity(data) {
    this.showDropdwonCity = false;
    this.formData = [];
    this.formData = {
      latitude: data.latitude ? data.latitude.toString() : "30.713329",
      longitude: data.longitude ? data.longitude.toString() : "76.695566",
      address: data.place,
      state: data.state,
      city: data.district,
      country: "",
      pincode: data.pincode,
    };
    let locationData =
      (data.place ? data.place + ", " : "") +
      (data?.district ? data?.district : "") +
      (data?.state ? " (" + data?.state + ")" : "");
    const address = data.district;

    this.manualLocationForm.patchValue({ city: locationData });
    this.manualLocationForm.patchValue({ pincode: data?.pincode });

   // this.dataSharingService.locationChange.next({ setLocation: false, address: data });
  }
  setAddressLocal(data) {
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.mapCity = data.city;
    localStorage.setItem("state", data.state);
    localStorage.setItem("city", data.city);
    localStorage.setItem("latitude", this.latitude.toString());
    localStorage.setItem("longitude", this.longitude.toString());
  }

  addPinCodeAddress(address) {
    if (address.length > 0) {
      this.formData = [];
      this.formData = {
        latitude: address[0]?.latitude
          ? address[0]?.latitude.toString()
          : "30.713329",
        longitude: address[0]?.longitude
          ? address[0]?.longitude.toString()
          : "76.695566",
        address: address[0]?.place,
        state: address[0]?.state,
        city: address[0]?.district,
        country: "",
        pincode: address[0]?.pincode,
      };
      let locationData =
        (address[0].place ? address[0].place + ", " : "") +
        (address[0]?.district ? address[0]?.district : "") +
        (address[0]?.state ? " (" + address[0]?.state + ")" : "");
      const city = address[0]?.district;   
      this.manualLocationForm.patchValue({ city: locationData });
      this.manualLocationForm.patchValue({ pincode: address[0]?.pincode });
    }
  }
}