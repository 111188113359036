import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-merchant-terms-conditions',
  templateUrl: './merchant-terms-conditions.component.html',
  styleUrls: ['./merchant-terms-conditions.component.css']
})
export class MerchantTermsConditionsComponent implements OnInit {

  constructor( private admin: AdminService,) { }

  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Merchant Terms & Conditions", pageImg, "Merchant Terms & Conditions", "", "");
  }

}
