<section>
    <div class="container">
        <div class="notifiWrap">
            <div class="head_ttl">
                <h4> Settings </h4>
            </div>
            <!-- Pinned -->
            <h5> Notification Settings </h5>
            <div class="notifiList">
                <ul class="">
                    <li>
                        <div class="comt-sect">
                            <h6> Do not Disturb (9PM to 9AM) </h6>
                            <p>Note: Some system generated account notifications may still be sent </p>

                            <div class="switchbtnWrap">
                                <input type="checkbox" (change)="notDisturb($event)" hidden="hidden" id="username"
                                    [checked]="doNotDisturb"> <label class="switch" for="username"></label>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- New -->
            <div class="notifiList">
                <!-- <h5> New </h5> -->
                <ul class="">
                    <li>
                        <div class="comt-sect">
                            <h6> All Notifications </h6>
                            <div class="switchbtnWrap">
                                <input type="checkbox" (change)="allNotificationSetting($event,disablePop)"
                                    hidden="hidden" id="allNotifications" [checked]="allNotifications"> <label
                                    class="switch" for="allNotifications"></label>
                            </div>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>Offers uploaded by favourite business uses</p>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>Offers on service</p>
                        </div>
                    </li>
                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>New rewards available </p>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>Reward points are expiring </p>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>Successfully purchased the rewards </p>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>New tier assigned </p>
                        </div>
                    </li>

                    <li *ngIf="merchant !=='1'">
                        <div class="comt-sect ">
                            <p>New rewards points earned </p>
                        </div>
                    </li>
                    <li *ngIf="merchant==='1'">
                        <div class="comt-sect ">
                            <p>Express interest</p>
                        </div>
                    </li>

                    <li *ngIf="merchant==='1'">
                        <div class="comt-sect ">
                            <p>New message from customers</p>
                        </div>
                    </li>
                    <li *ngIf="merchant==='1'">
                        <div class="comt-sect ">
                            <p>New rating and review received </p>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 3 -->
            <div class="notifiList" *ngIf="merchant==='1'">
                <ul class="">
                    <li>
                        <h5>Chat Settings</h5>
                        <div class="comt-sect">
                            <h6> Send greeting message </h6>
                            <p>Note: Greet customer when they message you for the first time </p>

                            <div class="switchbtnWrap">
                                <a class="pointer" (click)="greetingMessage(sendGreetingMessage)"
                                    [ngClass]="sendGreetingMessage===1 ? 'activeBtn':''">
                                    <label class="switch" for="greetingMessage"></label>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="comt-sect">
                            <h6> Message </h6>
                            <p style="text-transform: capitalize;">{{message ? message:'&nbsp;'}}</p>

                            <div class="switchbtnWrap">
                                <a class="btn_brdr delete pointer" (click)="openModel(setMessage)"> Add/Edit </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="notifiList">
                <ul>
                    <!-- pt-0 -->
                    <li [ngClass]="merchant==='1' ? '':''">
                        <h5> Account Settings </h5>
                        <div class="comt-sect" style="min-height: 35px;">
                            <div *ngIf="merchant !=='1'">
                                <p class="change_password">Change Password </p>
                                <div class="switchbtnWrap">
                                    <a class="btn_brdr delete pointer" (click)="openModel(updatePwd)"> Change Password
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="merchant==='1'">
                                <p>Delete my Finndit business profile </p>
                                <div class="switchbtnWrap">
                                    <a class="btn_brdr delete pointer" (click)="openModel(deleteAccount)"> Delete </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<ng-template #updatePwd>
    <div class="modal-header">
        <div>
            <img class="popupLogo" src="../../../assets/images/img_logo.png" alt="">
        </div>
        <button type="button" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="pwdForm" class="d-flex flex-column h-100" (ngSubmit)="setPassword()">
            <h4 class="mb-4"> Change Password</h4>
            <div class="middle">
                <div class="form-group">
                    <span>
                        <label>Old Password</label>
                    </span>
                    <input [type]="oldPassword ? 'text' : 'password'" class="form-control" id="password" placeholder=""
                        formControlName="oldPassword">
                    <a class="eye_close pointer" [ngClass]="oldPassword ? 'show' : ''"
                        (click)="oldPassword =! oldPassword"></a>
                    <div class="invlid_error" *ngIf="pwdForm.controls['oldPassword'].hasError('required') && showError">
                        *Please enter old password.
                    </div>
                </div>
                <div class="form-group">
                    <span>
                        <label>New Password</label>
                    </span>
                    <input [type]="show ? 'text' : 'password'" class="form-control" id="password" placeholder=""
                        formControlName="newPassword">
                    <a class="eye_close pointer" [ngClass]="show ? 'show' : ''" (click)="show =! show"></a>
                    <div class="invlid_error" *ngIf="pwdForm.controls['newPassword'].hasError('required') && showError">
                        *Please enter new password.
                    </div>
                    <div class="invlid_error" *ngIf="pwdForm.controls['newPassword'].hasError('pattern') && showError">
                        *Password must be at least 6 characters with 1 uppercase, 1 special character & 1 numeric
                        character.
                    </div>
                </div>
                <div class="form-group">
                    <span>
                        <label>Confirm Password </label>
                    </span>
                    <a class="eye_close pointer" [ngClass]="confrmPassword ? 'show' : ''"
                        (click)="confrmPassword =! confrmPassword"></a>
                    <input [type]="confrmPassword ? 'text' : 'password'" class="form-control" id="confirm-password"
                        placeholder="" formControlName="confirmPassword">
                    <div class="invlid_error"
                        *ngIf="pwdForm.controls['confirmPassword'].hasError('required') && showError">
                        *Please enter confirm password.
                    </div>
                    <div class="invlid_error"
                        *ngIf="pwdForm.controls['confirmPassword'].hasError('misMatch') && showError">
                        *Confirm password must be same as password.
                    </div>
                </div>
            </div>
            <button class="btn-main bottom">Update Password</button>
        </form>
    </div>
</ng-template>

<ng-template #deleteAccount>
    <div class="modal-header">
        <div>
            <a class="pointer">
                <img class="ic_back_popup" (click)="close()" src="../../../assets/images/ic_back.svg">
            </a>
            <div class="delete-popup-wrap mt-3">
                <h5> Delete Your Account </h5>
                <p class="mb-4"> Are you sure you want to delete your account? <br> Your merchant profile related
                    information will be deleted </p>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="deletAccount" class="d-flex flex-column h-100" (ngSubmit)="delectAccount()">
            <div class="middle1">
                <div class="form-group">
                    <span>
                        <label>Password</label>
                    </span>
                    <input [type]="deleteAccountPwd ? 'text' : 'password'" class="form-control" id="password"
                        placeholder="" formControlName="password">
                    <a class="eye_close pointer" [ngClass]="deleteAccountPwd ? 'show' : ''"
                        (click)="deleteAccountPwd =! deleteAccountPwd"></a>
                    <p class="error-massage"> Please note: If you delete your account, your profile and related account
                        information will be deleted. </p>
                    <div class="invlid_error"
                        *ngIf="deletAccount.controls['password'].hasError('required') && showError">
                        *Please enter password.
                    </div>
                </div>
            </div>
            <button class="btn-main bottom" style="margin-top: auto;">Delect Account</button>
        </form>
    </div>
</ng-template>
<ng-template #setMessage>
    <div class="modal-header">
        <div>
            <a class="pointer">
                <img class="ic_back_popup" (click)="close()" src="../../../assets/images/ic_back.svg">
            </a>
            <div class="delete-popup-wrap mt-3">
                <h5>Message </h5>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="messageForm" class="d-flex flex-column h-100" (ngSubmit)="upDateMessage()">

            <div class="middle1">
                <div class="form-group">
                    <span>
                        <label>Message</label>
                    </span>
                    <textarea appAutoFocusDirective rows="5" maxlength="150" class="form-control"
                        formControlName="messageTxt" style="text-transform: capitalize;"></textarea>
                    <div class="charCount" [ngClass]="messageForm.value.messageTxt?.length == 150 ? ('txtRed'):''">
                        {{messageForm.value.messageTxt?.length || 0 }}/150</div>

                    <div class="invlid_error"
                        *ngIf="messageForm.controls['messageTxt'].hasError('required') && showError">
                        *Please enter message.
                    </div>
                </div>
            </div>
            <button class="btn-main bottom pointer" style="margin-top: auto; text-align: center;">Save</button>
        </form>
    </div>
</ng-template>
<ng-template #disablePop>
    <div class="modal-header text-center hdr-dsply">
        <h5>Disable Notifications </h5>
    </div>
    <div class="modal-body">
        <div class="text-center">
            Attention! You will not get FREE {{points}} POINTS EVERY MONTH if you disable notifications.
        </div>
        <div class="bottom text-center mt-5">
            <button class="btn-main " (click)="disable(true)">Disable</button>

        </div>
        <div class="bottom text-center mt-1">
            <button class="cancl-btn" (click)="disable(false)">Cancel</button>
        </div>
    </div>
</ng-template>