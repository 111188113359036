<ng-template #paymentModel>
     <!-- <div class="modal-header">
        <div>
        </div>
       <button type="button" class="closePopup" (click)="close()"></button>
    </div> -->
    <div class="overlayDiv"></div>
    <div class="modal-body">
        <iframe #myIframe [src]="urlSafe" style="width: 100%; height: 800px; border: none;">
            <p>Your browser does not support iframes.</p>
        </iframe>
    </div>
</ng-template>