<div class="main merchantpanel" style="padding-bottom: 0px;" [ngClass]="userChatDetails ? 'hideList':''">
    <div class="body-container chat-inbox"  [ngClass]="activeTabs==='2' ?  'calls':''" [ngStyle]="{height:'calc('+screenHight+'px - 90px)'}">
        <div class="heading">
            <h2 class="page-title">Inbox</h2>
        </div>
        <div class="chat-wrapper">
            <div class="people-list" [ngClass]="userChatDetails ? 'hideList':''">
                <div class="tabs">
                    <ul>
                        <li [ngClass]="activeTabs==='1' ? 'active':''"   (click)="chatTabs('1')">Chats</li>
                        <li  [ngClass]="activeTabs==='2' ? 'active':''"  (click)="chatTabs('2')" > Calls</li>
                    </ul>
                </div>
                <div *ngIf="activeTabs==='1'"  class="searchbox">
                    <div class="form-element">
                        <form (ngSubmit)="searchFun()" name="searchForm">
                            <input type="text" minlength="3" placeholder="Search by name..." name="search"
                                class="search" [(ngModel)]="search" (keyup)="chnageSearch($event)">
                            <span class="input-icon"><i class="fa fa-search"></i></span>
                        </form>
                        <button (click)="filterUser()" [ngClass]="filterIcon ? 'activeFilter':''" class="search-btn">
                            <svg id="ic_filter_inactive" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                viewBox="0 0 32 32">
                                <g id="Rectangle_20014" data-name="Rectangle 20014" fill="#fff" stroke="#707070"
                                    stroke-width="1" opacity="0">
                                    <rect width="32" height="32" stroke="none" />
                                    <rect x="0.5" y="0.5" width="31" height="31" fill="none" />
                                </g>
                                <g id="Group_43163" data-name="Group 43163" transform="translate(20713.871 -9277.134)">
                                    <path id="Path_49170" data-name="Path 49170"
                                        d="M178.181,157.667h20.951a1.109,1.109,0,1,0,0-2.219H178.181a1.109,1.109,0,1,0,0,2.219Z"
                                        transform="translate(-20886.109 9129.552)" fill="#a0a0a1" />
                                    <path id="Path_49171" data-name="Path 49171"
                                        d="M236.99,261.047h13.176a1.11,1.11,0,1,0,0-2.219H236.99a1.11,1.11,0,1,0,0,2.219Z"
                                        transform="translate(-20941.027 9033.125)" fill="#a0a0a1" />
                                    <path id="Path_49172" data-name="Path 49172"
                                        d="M285.013,373.157h6.515a1.109,1.109,0,1,0,0-2.219h-6.515a1.109,1.109,0,0,0,0,2.219Z"
                                        transform="translate(-20985.717 8928.557)" fill="#a0a0a1" />
                                </g>
                                <g id="Ellipse_7707" data-name="Ellipse 7707" transform="translate(21.775 4)"
                                    fill="#a0a0a1" stroke="#fff" stroke-width="1">
                                    <circle cx="3.443" class="redColor" cy="3.443" r="3.443" stroke="none" />
                                    <circle cx="3.443" cy="3.443" r="2.943" fill="none" />
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div *ngIf="activeTabs==='1'"  class="comment-wrap">
                    <ul>
                        <!-- [ngClass]="dialog?.unread_count ? dialog?.unread_count[dialog.service_id  ? dialog.service_id:dialog.job_id]>0 ? 'unread':'':''" -->
                        <li class="bounceInDown user-comt"
                            [ngClass]="dialog.chat_dialog_id===chat_dialog_id ? 'activeUser':'' "
                            *ngFor="let dialog of dialogListing; let i = index">
                            <a style="cursor: pointer;"
                                (click)="getChatHistory(dialog.chat_dialog_id, dialog.job_id, dialog.user_id,dialog.name[dialog.user_id], dialog.pic[dialog.job_id], dialog.lead_id, dialog.service_name, dialog.service_id ); selectChatDetails(dialog)">
                                <div class="friend-name comt-sect" style="padding-left: 0px;">
                                    <div class="chatHead">
                                        <h5>{{dialog.name[dialog.user_id] | titlecase}}</h5>
                                        <small class="date-time">{{dateagoPipe.transform(dialog.last_message_time)}}
                                        </small>
                                    </div>
                                    <!--  -->
                                    <i class="message"
                                        *ngIf="dialog.unread_count && dialog?.unread_count[dialog.service_id  ? dialog.service_id : dialog.job_id]>0">{{dialog?.unread_count[dialog.service_id
                                        ? dialog.service_id : dialog.job_id]}}</i>
                                    <p *ngIf="dialog.last_message_type===1 &&  dialog.last_message[dialog.user_id] !='123@App@Default@Message@finndit@321'"
                                        class="firstlCaptial">{{dialog.last_message ?
                                        dialog.last_message[dialog.user_id] :'' }}</p>
                                    <p *ngIf="dialog.last_message_type===2">Image</p>
                                    <p *ngIf="dialog.last_message_type===3">Video</p>
                                    <p class="leadChatInfo">Lead ID: {{dialog.lead_id}} <span class="firstlCaptial"
                                            *ngIf="dialog.service_name">({{dialog.service_name}})</span> </p>

                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="No_found" *ngIf="dialogListing.length == 0">
                        No conversation yet!
                    </div>

                </div>
                <div class="callWrap" *ngIf="activeTabs==='2'">
                    <app-calls [fromData]="callData"  (chatId)="selectChat($event)"></app-calls> 
                </div>
            </div>
        
            <div *ngIf="activeTabs==='1'"  class="chat-wrap" style="height: auto;" [ngClass]="user_name ? 'showDetails':''">
                <div class="head-bar dflex" *ngIf="chatDisable==='0' || chatDisable==='1'">
                    <a class="bkbtn pointer" (click)="backToList()"> &lt;</a>
                    <div class="leftPenal">
                        <h3>
                            <!-- <i class="userImage"><img src="../../../assets/images/user.svg"></i> -->
                            {{user_name | titlecase}}
                        </h3>
                        <p class="leadChatInfo">Lead ID: {{lead_id}} <span class="firstlCaptial"
                                *ngIf="service_name">({{service_name}})</span> </p>
                    </div>
                    <div class="clear-chat">
                        <ul>
                            <li>
                                <a (click)="noteModel(takeNote)" class="takeNote btn-main"><img
                                        src="../../../assets/images/ic_note.svg"> <span>Take Note</span></a>
                            </li>
                            <li *ngIf="chatDisable==='1'">
                                <svg (click)="onCallClick()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <g id="ic_call" transform="translate(-0.209 -0.21)">
                                        <g id="Component_697_2" data-name="Component 697 – 2"
                                            transform="translate(0.209 0.21)">
                                            <rect id="Rectangle_19077" data-name="Rectangle 19077" width="32"
                                                height="32" fill="#100e0e" opacity="0.001" />
                                            <path id="Path_49144" data-name="Path 49144"
                                                d="M18.136,15.394a4.8,4.8,0,0,1-4.267,2.925c-.233,0-3.871.193-9.1-4.766A14.5,14.5,0,0,1,0,4.454C-.039,3.66.188,1.667,2.929.187,3.269,0,3.942-.078,4.116.1c.077.077,2.378,3.863,2.438,3.988a.846.846,0,0,1,.091.37,1.076,1.076,0,0,1-.37.648,6.309,6.309,0,0,1-.8.713,7.807,7.807,0,0,0-.8.687.987.987,0,0,0-.37.6c.017.4.348,1.85,2.868,4.064a19.377,19.377,0,0,0,3.852,2.882.877.877,0,0,0,.292.064.987.987,0,0,0,.6-.369c.242-.247,1.056-1.362,1.315-1.608a1.077,1.077,0,0,1,.649-.369.845.845,0,0,1,.369.091c.125.06,3.916,2.278,3.988,2.353.2.2.058.831-.091,1.187"
                                                transform="translate(9.27 5.054) rotate(9)" fill="#318337"
                                                stroke="#707070" stroke-width="0.3" />
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <div class="dropdownWrap" dropdown>
                                    <span dropdownToggle class="toggleIcon"></span>
                                    <ul *dropdownMenu class="dropdown-menu lg" role="menu" placement="right">
                                        <li *ngIf="chatDisable==='1'"><a class="dropdown-item "
                                                (click)="closeChat(clearchat)">Block/Close Chat</a></li>
                                        <li><a class="dropdown-item redTxt" (click)="clearChat()">Clear Chat</a></li>

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="chat-history" #scrollMe>
                    <ul class="list">
                        <li *ngFor="let message of messageData; let i = index"
                            class='{{message.sender_id == (service_id ? service_id : job_id) ? "align-right" : "received_msg" }}'>
                            <div class="leadID">
                                <div class="leadIdHeading " *ngIf="checkLeadID(message.leadId, i) && message.leadId">{{message.leadId}}</div>
                            </div>
                            <!-- <div class="leadID">
                                <div class="" *ngIf="message.leadId !== messageData[i].leadId">
                                    {{message.leadId}} --- </div>
                            </div> -->
                            <div class="chat-message-content">
                                <p *ngIf="message?.message_type==1">{{message.message}}</p>
                                <img (click)="showBigsize(message.attachment_url,1)" *ngIf="message?.message_type==2"
                                    [src]="(baseUrl +'assets/'+message.attachment_url)">
                                <div class="play-icon pointer" *ngIf="message?.message_type==3"
                                    (click)="showBigsize(message.attachment_url,2)">
                                    <video class="cropped_img">
                                        <source [src]="(baseUrl +'assets/'+message.attachment_url)" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                            <span class="chat-time">
                                {{dateagoPipe.transform(message.message_time)}}</span>
                        </li>
                    </ul>
                </div>
                <div class="bottom-box" *ngIf="chatDisable==='0' || chatDisable==='1'">
                    <div *ngIf="chatDisable==='0'" class="disbaleChat">Chat Disabled</div>
                    <form *ngIf="chatDisable==='1'" [formGroup]="chatForm" (ngSubmit)="sendMessage()"
                        (keydown.enter)="sendMessage()">
                        <fieldset>
                            <div class="add-icons">
                                <a class=""><input id="fileUpload" type="file" id="uploadFile" class="upload-pic"
                                        #userPhoto (change)="onFileSelected($event)">
                                    <svg id="ic_add" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                        viewBox="0 0 30 30">
                                        <rect id="Rectangle_20034" data-name="Rectangle 20034" width="30" height="30"
                                            fill="#fff" opacity="0.003" />
                                        <path id="noun-add-4699483"
                                            d="M204.535,122.5a1.337,1.337,0,0,1,1.337,1.337V133.2h9.361a1.337,1.337,0,1,1,0,2.674h-9.361v9.361a1.337,1.337,0,1,1-2.674,0v-9.361h-9.361a1.337,1.337,0,0,1,0-2.674H203.2v-9.361A1.337,1.337,0,0,1,204.535,122.5Z"
                                            transform="translate(-189.535 -119.535)" fill="#318337"
                                            fill-rule="evenodd" />
                                    </svg>

                                </a>
                                <!--  -->
                            </div>
                            <div class="input_msg_write">
                                <input autocomplete="off" type="text" class="messageInput" formControlName="message" name="userMessage"
                                    placeholder="Type a message" (keypress)="updateVale($event)">
                                <button class="msg_send_btn"><img src="assets/images/sendimg.svg"></button>

                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="preloader-backdrop" *ngIf="showLoader1">
    <div class="page-preloader">Loading</div>
</div>

<ng-template #takeNote>
    <div class="modal-header">
        <h4>Take Note</h4>
        <button type="button" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="takeForm" (ngSubmit)="submitNote()" style="margin-top: 0px;">
            <div class="">
                <!-- <p class="mb-5 mt-0">Lead Id: {{leadId}}  {{serviceName? ( '(' +serviceName + ')'):''}}</p> -->
                <div class="form-group group-cstm">
                    <span>
                        <label>Lead Status*</label>
                    </span>
                    <div class="icon_div">
                        <select class="form-control dropdown" formControlName="status">
                            <option value="1">New</option>
                            <option value="2">Inprogress (Chat Initiated)</option>
                            <option value="3">Closed</option>
                        </select>
                    </div>
                    <div class="invlid_error" *ngIf="takeForm.controls['status'].hasError('required') && showError">
                        *Please select status.
                    </div>
                </div>
                <div class="form-group group-cstm  mt-5">
                    <span>
                        <label>Tag*</label>
                    </span>
                    <div class="icon_div">
                        <select class="form-control dropdown" formControlName="tag">
                            <option value="3">Cold</option>
                            <option value="1">Hot</option>
                            <option value="2">Warm</option>
                        </select>
                    </div>
                    <div class="invlid_error" *ngIf="takeForm.controls['tag'].hasError('required') && showError">
                        *Please select tag.
                    </div>
                </div>
                <div class="form-group group-cstm  mt-5">
                    <span>
                        <label>Details*</label>
                    </span>
                    <textarea uppercase rows="5" maxlength="500" formControlName="note" class="form-control"
                        placeholder=""></textarea>
                    <div class="invlid_error" *ngIf="takeForm.controls['note'].hasError('required') && showError">
                        *Please enter details.
                    </div>
                    <div class="invlid_error"
                        *ngIf="takeForm.controls['note'].hasError('cannotContainSpace') && showError"> *Please enter
                        details.</div>
                </div>
                <div class="form-group pr-input mt-4">
                    <button class="btn-main full">Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #clearchat>
    <div class="modal-body">
        <h4>Close Chat</h4>
        <p>Please mark the lead Won or lost before closing it</p>
        <a class="btn-main pointer margin5" (click)="updateLead(1)">Mark Won</a>
        <a class="btn_brdr pointer margin5" (click)="updateLead(2)">Mark Lost</a>
    </div>
</ng-template>
<app-gallery-slider #imgGallery></app-gallery-slider>
<app-feedback-popup #feedbackModal></app-feedback-popup>