import { Component, OnInit, ElementRef, HostListener, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UsernameValidator } from "../../../services/NoWhiteSpace.validator";
import { AdminService } from "../../../services/admin.service";
import { apiUrl } from 'src/app/global/global';

@Component({
  selector: 'app-address-location',
  templateUrl: './address-location.component.html',
  styleUrls: ['./address-location.component.css']
})
export class AddressLocationComponent implements OnInit {
  manualLocationForm: FormGroup;
  @Output() childEvent = new EventEmitter();
  showError = false;
  zoom: number = 8;
  private geoCoder;
  gMaps: any;
  disableForm = false;
  show = false;
  formData: any = [];
  showDropdwonCity = false;
  cityData: any = []
  @ViewChild('search') public searchElementRef: ElementRef;
  @HostListener('window:click') click() {
    if (this.showDropdwonCity) {
      this.showDropdwonCity = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  constructor(private api: AdminService, private ngZone: NgZone, private formBuilder: FormBuilder,) {
    this.manualLocationForm = this.formBuilder.group({
      pincode: ['', Validators.compose([Validators.required, Validators.minLength(6), UsernameValidator.cannotContainSpace])],
      city: ['', Validators.compose([Validators.required, UsernameValidator.cannotContainSpace])],
    });
  }
  ngOnInit(): void {
    this.manualLocationForm.reset();
    //this.loadGeoMaps();
  }

  getCity(e, type) {
    let data: any = {}

    if (type == 3) {
      if (this.manualLocationForm.value.pincode.length === 6) {
        data = {
          'type': 3,
          'title': this.manualLocationForm.value.pincode
        }
        this.getDataFromApi(data, type)
      }
    }
    if (type == 2) {
      if (this.manualLocationForm.value.city.length > 1) {
        data = {
          'type': 2,
          'title': this.manualLocationForm.value.city?.trim()
        }
        this.getDataFromApi(data, type)
      }
    }
  }
  getDataFromApi(data, type) {
    this.api.getDataWithoutLoader(apiUrl._getCityState, data).subscribe(async (res) => {
      if (type == 3) {
        this.addPinCodeAddress(res.data.rows)
      } if (type == 2) {
        this.showDropdwonCity = true
        this.cityData = res.data?.rows;;
      }
    });
  }
  selectRelevantCity(data) {
 
    this.showDropdwonCity = false
    this.formData = []
    this.formData = {
      "latitude": data.latitude ? data.latitude.toString() : "30.713329",
      "longitude": data.longitude ? data.longitude.toString() : "76.695566",
      "address": data.place,
      "state": data.state,
      "city": data.district,
      "country": '',
      "pincode": data.pincode,
    }
    let locationData = (data.place? (data.place+", "):'') +(data?.district ? data?.district:'') +(data?.state ? (' ('+data?.state+")"):"");
    this.manualLocationForm.patchValue({ "city": locationData })
    this.manualLocationForm.patchValue({ "pincode": data?.pincode })
  }

  addPinCodeAddress(address) {
    if (address.length > 0) {
      this.formData = []
      this.formData = {
        "latitude": address[0]?.latitude ? address[0]?.latitude.toString() : "30.713329",
        "longitude": address[0]?.longitude ? address[0]?.longitude.toString() : "76.695566",
        "address": address[0]?.place,
        "state": address[0]?.state,
        "city": address[0]?.district,
        "country": '',
        "pincode": address[0]?.pincode,
      }
      let locationData = (address[0].place? (address[0].place+", "):'') +(address[0]?.district ? address[0]?.district:'') +(address[0]?.state ? (' ('+address[0]?.state+")"):"");;
      this.manualLocationForm.patchValue({ "city": locationData })
      this.manualLocationForm.patchValue({ "pincode": address[0]?.pincode })
    }

  }

  searchCity(event) {
    let search = event.target.value.trim();
    if (search.length > 1) {
      let data = {
        'type': 2,
        'title': search
      }
      this.api.getDataWithoutLoader(apiUrl._getCityState, data).subscribe(async (res) => {
        this.formData = []
        this.formData = {
          "latitude": res.data.rows[0]?.latitude ? res.data.rows[0]?.latitude.toString() : "30.713329",
          "longitude": res.data.rows[0]?.longitude ? res.data.rows[0]?.longitude.toString() : "76.695566",
          "address": res.data.rows[0]?.place,
          "state": res.data.rows[0]?.state,
          "city": res.data.rows[0]?.district,
          "country": '',
          "pincode": res.data.rows[0]?.pincode,
        }
        this.manualLocationForm.patchValue({ "city": res.data.rows[0]?.district })
        this.manualLocationForm.patchValue({ "pincode": res.data.rows[0]?.pincode })
      });
    }
  }
  submitManualLocation() {
    if (this.manualLocationForm.valid) {
      this.childEvent.emit(this.formData);
    } else {
      this.showError = true
    }
  }
  onlyNumberKey(event) {
    return this.api.allowOnlyNumbers(event);
  }

}
