import { Component, OnInit, Input, Output, Inject, EventEmitter, PLATFORM_ID } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import * as CONSTANT from "../../services/constants";
import { Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
const DATA_KEY = makeStateKey<any>('data');
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],

})
export class FaqComponent implements OnInit {
  @Input() faqType: any;
  @Output() allFaqData = new EventEmitter()
  faqList: any = [];
  searchFAQ: any;
  type: any;
  total: any;
  getAllFaq: any;
  pagination = { limit: 5, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  currentPage: number;
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private admin: AdminService,
    private router: Router,
  ) { }
  ngOnChanges(faqType: any): void {
    this.type = faqType?.faqType?.currentValue.type ? faqType?.faqType?.currentValue.type : '';
    this.searchFAQ = faqType?.faqType?.currentValue.search ? faqType?.faqType?.currentValue.search : '';
    this.getAllFaq = faqType?.faqType?.currentValue.allData ? faqType?.faqType?.currentValue.allData : '';
    if (this.searchFAQ || this.getAllFaq) {
      this.getFaqs();
    }
  }
  ngOnInit(): void {
   
    // if (isPlatformServer(this.platformId)) {
    //   this.getFaqs();    
    // } else {
    //   this.faqList = this.transferState.get(DATA_KEY, null);
    // }  
    this.getFaqs(); 
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Faq's", pageImg, "Faq's page", "", "");
  }
  getFaqs() {
    let data: any = {
      "type": this.type ? this.type : 3,
      "limit": this.pagination.limit.toString(),
      "skip": this.pagination.skip.toString(),
    }
    if (this.searchFAQ) {
      data.search = this.searchFAQ
    }
    this.admin.getData(apiUrl._faqs, data).subscribe(res => {
      this.faqList = res.data;
      //this.transferState.set(DATA_KEY, res.data);
      this.total = res.data.count;
      this.pagination.totalItems = res.data.count;
      this.allFaqData.emit(this.faqList)

    });
  }
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 0;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.getFaqs()
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) * 1;
    this.getFaqs()
  }


}
