import { Component, OnInit, Input, Output,ViewChild, EventEmitter, Inject, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AdminService } from "../../services/admin.service";
import { PlanService } from "../../extrnal/subscription-plan/plan.service";
import { Router } from '@angular/router';
import { DataSharingService } from "../../services/data.share.service";
import { apiUrl } from "../../global/global";
import { interval, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UsernameValidator } from 'src/app/services/NoWhiteSpace.validator';
import { PhonePeComponent } from 'src/app/common/phone-pe/phone-pe.component';
import { log } from 'console';
@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.css']
})
export class SubscriptionPlanComponent implements OnInit {
  @Input() paymentData: any;
  showError = false;
  @Output() paymentValue = new EventEmitter()
  allPlan: any = [];
  upiId: any;
  plan = "1 Month";
  newPlan: any = [];
  planList: any = [];
  businessID: any;
  qrMethod = false;
  allPanPage: boolean;
  planLevel: any;
  customerType: any;
  activePlan: any;
  showQRcode = false;
  qrData: any;
  omSubscriptionId: any;
  private subscription: Subscription;
  count = 0;
  subscriptionPlanListId: any;
  private timerSubscription: Subscription;
  private readonly durationInSeconds = 300; // 5 minutes
  private timerCount = this.durationInSeconds;
  displayTime: string;
  timerCompleted = false;
  payWithUpi = false;
  payWithScanner = false;
  upiForm: FormGroup;
  upiIdName: any;
  showUPIForm = false;
  planPage = false;
  showLoader=false;
  parentToChild: any;
  @ViewChild('phonePe') private phonePeModel: PhonePeComponent;
  constructor(
    @Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
    private api: AdminService, private dataSharingService: DataSharingService, private planService: PlanService, private router: Router, private formBuilder: FormBuilder,) {
    this.upiForm = this.formBuilder.group({
      'upiId': ["", Validators.compose([Validators.required, Validators.minLength(3), UsernameValidator.cannotContainSpace])],
    })
  }

  ngOnInit(): void {
    let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
    if (token) {
      this.getFirstBusinessDetails();
    }
    let curremtUrl = this.router.url;
    if (curremtUrl === "/subscription-plan") {
      this.planPage = true;
      this.allPanPage = true;
      let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
      this.api.addMetaTag("Subscription Plan", pageImg, "Subscription Plan", "", "");
    } else {
      this.planPage = false
    }
    if (this.planLevel === "upgrade" || this.planLevel === "downgrade") {
      this.getCurrentPlan(this.plan);
    } else {
      this.getPlanLists(this.plan);
    }
  }
  ngOnChanges(paymentData: any): void {
    this.planLevel = paymentData?.paymentData?.currentValue.planType ? paymentData.paymentData.currentValue.planType : '';
    this.customerType = paymentData?.paymentData?.currentValue.customerType ? paymentData.paymentData.currentValue.customerType : '';
  }

  planType(type) {
    this.plan = type
    // this.getPlanLists(this.plan);
    if (this.planLevel === "upgrade" || this.planLevel === "downgrade") {
      this.getCurrentPlan(this.plan);
    } else {
      this.getPlanLists(this.plan);
    }

  }
  getPlanLists(type) {
    const data = {
      validityLevel: type
    };
    this.api.getData(apiUrl._getPlan, data).subscribe(async (res) => {
      this.planList = res.data.rows;
    },
      err => {
        console.log(err.error.message, "err");
      })
  }

  getCurrentPlan(type) {
    let businessId = this.api.getBusinessId();
    let data = {
      "validity": type,
      "businessId": businessId
    }
    this.api.getData(apiUrl._getActivePlan, data).subscribe(async (res) => {
      if (this.planLevel === "upgrade") {
        this.planList = res.data.nextPlanV2;
      } else if (this.planLevel === "downgrade") {
        this.planList = res.data.downgradePlanV2;
      } else {
        this.planList = res.data.activePlan;
      }
    },
      err => {
        console.log(err, "Plan");
      });
  }
  close() {
    // this.onCancel.emit();
  }
  paymentMethod(id, autoRenewal) {
    let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
    if (token) {
      let data = {
        "businessId": this.businessID,
        "subscriptionPlanListId": id
      }
      this.api.getData(apiUrl._checkPlanMigration, data).subscribe(async (res) => {
        this.subscriptionPlanListId = id;
        if (autoRenewal === 1) {
          this.showQRcode = true;
          this.renderer.addClass(this.document.body, 'paymentMethodModel');
        } else {
          this.payment(this.subscriptionPlanListId, '', "2")
        }

      })
    } else {
      this.api.guestUser();
    }

  }
  payWithUPI() {
    this.showUPIForm = true
  }
  payWithQR() {
    this.renderer.addClass(this.document.body, 'hideCloseBtn');
    this.payment(this.subscriptionPlanListId, '', "")
  }
  submitForm() {
    if (this.upiForm.valid) {
      this.api.loader(false);
      this.api.getData(apiUrl._verifyUpiId, this.upiForm.value).subscribe(async (res) => {
        this.upiIdName = res.data?.data.name ? res.data?.data.name : '';

      })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 10000);
    }
  }
  send() {
    if (this.upiIdName) {
      this.renderer.addClass(this.document.body, 'hideCloseBtn');
      this.payment(this.subscriptionPlanListId, this.upiForm.value.upiId, "")
    }
  }

  async payment(id, upiId, autoRenewal) {
    let data = {
      subscriptionPlanListId: id,
      businessId: this.businessID,
      // :
    }
    if (upiId) {
      data['isCollectView'] = 1;
      data['upiId'] = upiId;
    }
    if (this.planLevel == "downgrade") {
      data['isDowngrade'] = 1;
    }

    this.api.postData(apiUrl._createPayment, data).subscribe(async (res) => {
      if (autoRenewal === "2") {
        let url = res.data.order.data?.instrumentResponse?.redirectInfo?.url;
        console.log("url", url)
        this.parentToChild = url;
        console.log("11", res.data.data.amount, res.data.data.amount,this.businessID,  res.data.order.merchantTransactionId)
        this.phonePeModel.openModel(res.data.data.amount, res.data.data.amount,this.businessID,  res.data.order.merchantTransactionId, "3");

      } else {
        this.startTimer();
        this.qrMethod = true;
        if (upiId) {
          this.payWithUpi = true;
        } else {
          this.payWithScanner = true;
          this.qrData = res.data?.authRequest.data.redirectUrl;
        }
        this.omSubscriptionId = res.data?.subscription.data.subscriptionId
        this.startFetchingData();
        if (this.allPanPage) {
          let planData;
          planData = await this.api.getSubscriptionPlan("2");
          if (planData) {
            let currentPlanSetting = planData?.subscription_plan_list?.subscription_list;
            localStorage.setItem("userPer", JSON.stringify(currentPlanSetting))
          }
          this.dataSharingService.isPlanChanged.next(true);
          this.getActivePlan(this.businessID);
          this.getPlanLists(this.plan);
        }
      }
    },
      err => {
        this.resetAll()
        console.log(err, "err");

      });

  }

  childMessage(txt) {
    if (txt == "updatePayment") {
      this.getPlanLists(this.plan);     
      this.api.successMsg(); 
      this.paymentValue.emit({ planID: "planID" }); 

    }if(txt == "cancelPayment"){
      this.api.errorMsg() 
      this.paymentValue.emit({ planID: "" }); 
    }
    this.parentToChild = "";    
  }

  backToPlan() {
    if (this.qrMethod) {
      this.qrMethod = false;
    } else if (this.showUPIForm) {
      this.showUPIForm = false;
      this.upiForm.patchValue({ "upiId": "" })
      this.upiIdName = "";
    } else {
      this.renderer.removeClass(this.document.body, 'paymentMethodModel');
      this.showQRcode = false;
    }
  }

  ngOnDestroy(): void {
    this.payWithUpi = false;
    this.payWithScanner = false;
    this.renderer.removeClass(this.document.body, 'paymentMethodModel');
    this.renderer.removeClass(this.document.body, 'hideCloseBtn');
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleQrCodeGeneration() {
    this.count++;
    let data = {
      "businessId": this.businessID,
      "phonePaySubscriptionId": this.omSubscriptionId
    }
    this.api.getDataWithoutLoader(apiUrl._checkSubscriptionStatusPhonePe, data).subscribe(async (res) => {
      if (res.data?.isPaymentStatus?.toString() === "2") {
        this.api.successMsg();
        this.paymentValue.emit({ planID: "planID" });
        this.payWithUpi = false;
        this.payWithScanner = false;
        this.timerSubscription.unsubscribe(); // Stop the timer when completed
        this.subscription.unsubscribe();
        this.resetAll()
      } else if (res.data?.isPaymentStatus?.toString() === "3") {
        this.api.errorMsg();
        this.payWithUpi = false;
        this.payWithScanner = false;
        this.paymentValue.emit({ planID: "" })
        this.timerSubscription.unsubscribe(); // Stop the timer when completed
        this.subscription.unsubscribe();
        this.resetAll()
      } else {


      }
    },
      err => {
        console.log(err, "Plan");
      });
  }

  getActivePlan(businessId) {
    let data = {
      "businessId": businessId
    }
    this.api.loader(false);
    this.api.getDataWithoutLoader(apiUrl._getActivePlan, data).subscribe(async (res) => {
      this.activePlan = res.data.activePlan.subscription_plan_list.id;
    })
  }

  getFirstBusinessDetails() {
    this.api.loader(false);
    let businessId = this.api.getBusinessId();
    let data = {}
    this.api.loader(false);
    this.api.getData(apiUrl._getFirstBusinessDetails, data).subscribe(async (res) => {
      this.businessID = businessId ? businessId : res.data.id
      this.getActivePlan(this.businessID)
    })
  }

  private startTimer(): void {
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timerCount--;
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      this.displayTime = `0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      if (this.timerCount <= 0) {
        this.timerCompleted = true;
        this.paymentValue.emit({ planID: "" })
        this.count = 30
        this.timerSubscription.unsubscribe(); // Stop the timer when completed
        this.subscription.unsubscribe();
        this.timerCount = this.durationInSeconds;
        this.resetAll()
      }
    });
  }
  resetAll() {
    this.renderer.removeClass(this.document.body, 'paymentMethodModel');
    this.renderer.removeClass(this.document.body, 'hideCloseBtn');
    this.showQRcode = false;
    this.payWithScanner = false;
    this.showUPIForm = false;
    this.payWithUpi = false;
    this.qrMethod = false;
    this.upiForm.patchValue({ "upiId": "" })
    this.upiIdName = "";
  }
  private startFetchingData(): void {
    this.subscription = interval(10000).subscribe(() => {
      this.handleQrCodeGeneration();
      if (this.timerCount <= 0) {
        this.paymentValue.emit({ planID: "" })
        this.subscription.unsubscribe();
        this.api.paymentTimeOut();
      }
    })
  }
}
