import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { DataSharingService } from "../services/data.share.service";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { apiUrl } from "../global/global";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { TransferState, makeStateKey } from '@angular/platform-browser';
const DATA_KEY = makeStateKey<any>('data');
@Injectable({
  providedIn: "root",
})
export class AdminService {
  private subscriptionPlanData: any = undefined;
  private totalCount: any = '';
  apiEndpoint: string = "";
  apiS3url: string = "";
  showLoader: boolean = true;
  access_token: any;
  private showSignUpModel = new Subject<any>();
  public _albums = [];
  userProfileData: any;
  flag = false;
  allCategories: any[];
  constructor(private http: HttpClient, private meta: Meta, private title: Title, private location: Location, private router: Router, private dataSharingService: DataSharingService, public afAuth: AngularFireAuth, private toastrService: ToastrService, private transferState: TransferState) {
    this.apiEndpoint = environment.apiUrl;
    this.access_token = localStorage.getItem("adminToken");
  }

  sendClickEvent(data) {
    this.showSignUpModel.next(data);
  }
  getClickEvent(): Observable<any>{ 
    return this.showSignUpModel.asObservable();
  }
  setPlanData(data: any) {
    this.subscriptionPlanData = data;
  }
  getPlanData(): any {
    return this.subscriptionPlanData;
  }
  setTotalCount(data: any) {
    this.totalCount = data;
  }
  getTotalCount(): any {
    return this.totalCount;
  }
  loaderStop() {
    this.loader(false)
    this.showLoader = false
  }
  getSubscriptionPlan(type) {
    var data = new Promise((resolve, reject) => {
      let businessId = this.getBusinessId() ? this.getBusinessId() : '';
      let data = {}
      if (type === "1") {
        data["businessId"] = ""
      } else {
        data["businessId"] = businessId
      }
      this.getData(apiUrl._getSubscriptionPlanDetails, data).subscribe(async (res) => {
        this.setPlanData(res.data)
        resolve(res.data);
      },
        err => {
          reject("err")
        });
    });
    return data;
  }

  checkPlanStatus(businessId, planId) {
    var data = new Promise((resolve, reject) => {
      let data = {
        "planId": planId,
        "businessId": businessId,
      }
      this.getData(apiUrl._currentPlanStatus, data).subscribe(async (res) => {
        resolve(res);
      },
        err => {
          reject("err")
        });
    });
    return data;
  }
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
      "responseType": 'text',
      "Access-Control-Allow-Headers": "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
    }),
  };
  maximumError(count, name) {
    let itemName = name ? name : 'items';
    this.toastrService.error('Please Buy / Upgrade Subscription Plan to add more categories.')
    // this.toastrService.error(`You can select maximum ${count} ${itemName}`, "");
  }
  planDowngradeError() {
    this.toastrService.error(`Your have a change of plans`, "");
  }

  successMsg() {
    this.toastrService.success("Payment successfully done", "");
  }
  errorMsg() {
    this.toastrService.error("Payment failed", "");
  }
  paymentTimeOut() {
    this.toastrService.error("Time out please try again", "");
  }
 
  guestUser() {
    this.toastrService.error(" Your are unauthorized. Please login or create account to get access.", "");
  }

 
  showErrorMsg(msg) {
    this.toastrService.error(`${msg}`, "");
  }


  allowOnlyNumbers(event) {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    let result = maskSeperator.test(event.key);
    return result;
  }


  firebaseSignIn() {
    let checkUserToken = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
    if (checkUserToken) {
      const checkToken = localStorage.getItem("utTime") ? localStorage.getItem("utTime") : "2";
      if (checkToken === "2") {
        let customToken: any
        this.showLoader = false;
        this.getData(apiUrl._fireToken, {}).subscribe(res => {
          localStorage.setItem("utTime", "1");
          this.removeUtTime();
          // console.log(res.data.customToken)
          customToken = res.data.customToken;
          return this.afAuth.signInWithCustomToken(customToken)
            .then((userCredential) => {

            })
            .catch((error) => {
              // Handle any errors that occurred during sign-in.
              console.error('Error signing in:', error);
            });

        }, err => {
          console.log(err, "err");
        });
      }
    }
  }
  removeUtTime() {
    let myInterval = 1 * 60 * 1000; // 15 min interval
    setInterval(function () {
      localStorage.removeItem("utTime");
    }, myInterval);
  }
  /* Sign out */
  firebaseSignOut() {
    this.afAuth.signOut();
  }

  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDataWithoutLoader(url, param) {
    this.showLoader = false;
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDatas(url) {
    return this.http.get<any>(this.apiEndpoint + url);
  }

  getDetailByidParams(data, url, param = {}) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {
      params: param,
    });
  }
  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }


  getDetailByid(data, url) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {});
  }
  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, param);
  }
  putDataWithoutLoader(url, param) {
    this.showLoader = false;
    return this.http.put<any>(this.apiEndpoint + url, param);
  }
  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postDataWithoutLoader(url, value) {
    this.showLoader = false;
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  getUserSaveData() {
    const userSaveData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    let userInfo;
    if (userSaveData) {
      userInfo = JSON.parse(userSaveData);
    }
    return userInfo;
  }

  httpOptions1 = {
    headers: new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    ),
  };
  postDataNew(url, value) {
    return this.http.post<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }
  putDataNew(url, value) {
    return this.http.put<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }


  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }
  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }
  getBusinessId(): any {
    let businessId;
    let dId = localStorage.getItem("defultBusiness") ? localStorage.getItem("defultBusiness") : '';
    if (dId) {
      businessId = atob(dId);
    }
    return businessId;
  }

  deleteData(url, param) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: param,
    };
    return this.http.delete<any>(this.apiEndpoint + url, options);
  }

  swalfire(title, text) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#2FC863",
      timer: 2000,
    })
  }
  showMsg(title: string, text: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#2FC863",
      timer: 2000,
    })
  }

  showError(title: string, text: string, confirmButtonText: string) {
    swal.fire({
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Okay",
      confirmButtonColor: "#ff0000",
      timer: 1500,
    });
  }

  errorAlert(text, status) {
    if (!this.flag) {
      this.flag = true;
      swal
        .fire({
          title: status ? "Opps!" : "",
          text: text,
          showConfirmButton: status ? true : false,
          allowOutsideClick: true,
          allowEscapeKey: status ? true : false,
          confirmButtonText: "Got it",
          confirmButtonColor: "#18225A",
          timer: status ? 1000000 : 10000,
        })
        .then((result) => {
          if (status) {
            this.flag = false;
            this.firebaseSignOut();
            localStorage.clear();
            sessionStorage.removeItem('claimCounter');
            sessionStorage.removeItem('timerCLaimPopUp');
            localStorage.removeItem("Marchant");
            this.dataSharingService.isLoginChanged.next(true);
            this.router.navigate(["/"]);
          }
        });
    }

  }

  errorAlert1(text, time, status) {
    swal
      .fire({
        title: "Wrong OTP entered multiple times",
        text: "Please try after " + time + " minutes",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Got it",
        showConfirmButton: true,
        confirmButtonColor: "#18225A",
      })
      .then((result) => { });
  }


  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();
  loader(value) {
    this.loaderSubject.next(value);
  }
  async getUserLevelDetails() {
    if (this.userProfileData) {
      return this.userProfileData;
    }
    else {
      this.showLoader = false;
      const earnPoints = await new Promise((resolve) => {
        this.getData(apiUrl._getEarnedPoints, {}).toPromise().then(res => {
          resolve(res.data);
        }, err => {
          console.log(err, "err");
        });
      });
      const stageData = await new Promise((resolve) => {
        this.showLoader = false;
        this.getData(apiUrl._getStagesData, {}).toPromise().then(res => {
          resolve(res.data);
        }, err => {
          console.log(err, "err");
        });
      })
      this.showLoader = true;
      this.userProfileData = {
        earnPoints: earnPoints,
        stageData: stageData
      };
      return this.userProfileData;
    }
  }
  async getUserLevelInfo() {
    const earnPoints = await new Promise((resolve) => {
      this.getDataWithoutLoader(apiUrl._getEarnedPoints, {}).toPromise().then(res => {
        resolve(res.data);
      }, err => {
        console.log(err, "err");
      });
    });
    const stageData = await new Promise((resolve) => {
      this.getDataWithoutLoader(apiUrl._getStagesData, {}).toPromise().then(res => {
        resolve(res.data);
      }, err => {
        console.log(err, "err");
      });
    })
    this.userProfileData = {
      earnPoints: earnPoints,
      stageData: stageData
    };
    return this.userProfileData;

  }
  submitProfile() {
    let merchant = localStorage.getItem("Marchant") ? (localStorage.getItem("Marchant")) : "0";
    if (merchant === "1") {
      const userData = this.getUserSaveData()
      if (userData) {
        let userInfo = userData;
        let businessID = userInfo?.usersBusiness?.id;
        localStorage.setItem("defultBusiness", btoa(businessID));
      }
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/"]);
    }

    swal
      .fire({
        title: "Thank you",
        //icon: "warning",
        text: "Your profile is under verification and you will only be able to access your business profile once we will send confirmation email.",
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonColor: "#14141f",
        cancelButtonColor: "#939393",
        allowOutsideClick: true,
        timer: 3000
      })
      .then((result) => {
        if (result.value) {
          //this.router.navigate(["/"]);
        }
      });
  }
  checkDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      return 'mobile';
    }
    else {
      return 'desktop';
    }
  }
  async getCategories() {
    if (this.allCategories) {
      return this.allCategories;
    }
    else {
      const data: any = await new Promise((resolve) => {
        this.http.get<any>(environment.jsonURL, this.httpOptions).toPromise().then(res => {
          resolve(res);
        },
          err => {
            console.log(err, "err");
          });
      });
      this.allCategories = data;
      return this.allCategories;
    }
  }
  pushStateUrl(pageUrl) {
    let url = pageUrl ? pageUrl : "/home"
    console.log("this.router.url", this.router.url)
    window.history.pushState(null, "Title", url);
  }

  updateLoadingUrl(pageName) {
    //this.location.go(pageName)
    this.location.go(pageName);
  }
  updateUrl(pageName, subPageNeme, business) {
    let level1 = pageName?.trim().toLowerCase().replace(/  +/g, ' ').replace(/ /g, '-')?.trim();
    let level2 = subPageNeme ? subPageNeme?.trim().toLowerCase().replace(/  +/g, ' ').replace(/ /g, '-') : "";
    if (business) {
      let level3 = business?.trim().toLowerCase().replace(/  +/g, ' ').replace(/ /g, '-')
      let pageUrl = level1 + level2 + level3;
      //this.location.go(pageUrl);
      this.location.replaceState(pageUrl);
    } else if (subPageNeme) {
      let pageUrl = level1 + level2
      //this.location.go(pageUrl);
      this.location.replaceState(pageUrl);
    } else {
      //this.location.go(level1);
      this.location.replaceState(level1);
    }
  }
  addMetaTag(pageName, pageImage, description, keywords, url) {
    this.title.setTitle(pageName);    
    let ogUrl = url ? url : "https://www.finndit.com"
    this.meta.updateTag({ name: 'description', content: description ? description : pageName });
    this.meta.updateTag({ name: 'author', content: pageName });
    if(keywords){
      let checkKeywords = keywords.replaceAll("#" , ",");
      this.meta.updateTag({ name: 'keywords', content: checkKeywords ? checkKeywords : '' });
    }else{
      this.meta.removeTag("name= 'keywords'");
    }
    
    this.meta.updateTag({ property: 'og:title', content: pageName });
    this.meta.updateTag({ property: 'og:description', content: description ? description : pageName });
    this.meta.updateTag({ property: 'og:type', content: pageName });
    this.meta.updateTag({ property: 'og:url', content: ogUrl });
    if (pageImage) {
      this.meta.updateTag({ property: 'og:image', content: pageImage });
    } else {
      let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
      this.meta.updateTag({ property: 'og:image', content: pageImg });
    }
  };

}
