<main class="main merchantpanel">
    <div class="heading">
        <h2 class="page-title">Buy leads</h2>
        <div class="ttl-pont"><b>Total Lead Point:{{configurationData?.businessData?.leadPoints}}</b></div>
    </div>
    <div class="lead-merchant">
        <h5 class="sub-label"> Select Location </h5>
        <ul class="tabs-wrap">
            <li class="">
                <label class="radio"> <input type="radio" name="radio" checked value="0"
                        (change)="onLocation($event)" /><i class="input-helper"></i> Pan India </label>
            </li>
            <li>
                <label class="radio"> <input type="radio" name="radio" value="2" (change)="onLocation($event)" /><i
                        class="input-helper"></i> States </label>
            </li>
            <li>
                <label class="radio"> <input type="radio" name="radio" value="1" (change)="onLocation($event)" /><i
                        class="input-helper"></i> Cities </label>
            </li>
        </ul>
        <div class="w-100 mt-2 mb-3 " *ngIf="location=='2'">
            <h5 class="sub-label">State</h5> 
            <div class="tag_field  form-control" style="position: relative; cursor:text"
                (click)="focusInput('chip-control')" [ngClass]="stateData.length>0 ?  'icon_div':''">
                <div class="chipsWrap">
                    <small class="tag_input" *ngFor="let tags of selectState; let i = index"
                        [ngClass]="i < 2  ?'':'Hide'">
                        <span *ngIf="i < 2"> {{tags.state}} <img (click)="remove(i,2)"
                                src="../../../assets/images/ic_crossimg.svg" alt=""></span>
                    </small>
                    <small class="tag_input" *ngIf="selectState.length>2" style="font-size:12px;">
                        <span class="plusMore">+</span>{{selectState.length-2}} More
                    </small>
                    <div class="searchTags" style='display: inline-block'> 
                        <input type="text" autocomplete="off" placeholder="" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="searchStates" (input)="searchLocation($event,'1')" id='chip-control'>
                    </div>
                </div>

                <div class="grlistDropDwon" *ngIf="showTgsDropdwon">
                    <ul class="autoComplete">
                        <li *ngFor="let tags of stateData; let i = index">
                            <a href="javascript:;"
                                (click)="selectRelevantStage(tags.state)">{{tags.state
                                ?
                                (tags.state) :''}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="invlid_error" *ngIf="selectState.length==0  && showError">
                *Please enter your state.
            </div>
        </div>
        <div class="w-100 mt-2 mb-3" *ngIf="location=='1'">
            <h5 class="sub-label">City</h5>   
            <div class="tag_field  form-control" style="position: relative; cursor:text"
                (click)="focusInput('chip-controls')" [ngClass]="cityData.length>0 ?  'icon_div':''">
                <div class="chipsWrap">
                    <small class="tag_input" *ngFor="let tags of selectCity; let i = index"
                        [ngClass]="i < 2  ?'':'Hide'">
                        <span *ngIf="i < 2"> {{tags.city}} ({{tags.state || ''}})  <img (click)="remove(i,1)" src="../../../assets/images/ic_crossimg.svg"
                                alt="">
                        </span>
                    </small>
                    <small class="tag_input" *ngIf="selectCity.length>2" style="font-size:12px;">
                        <span class="plusMore">+</span>{{selectCity.length-2}} More
                    </small>

                    <div class="searchTags" style='display: inline-block'>
                        <input type="text" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchCity"
                            autocomplete="off" (input)="searchLocation($event,'2')" id='chip-controls'>
                    </div>
                </div>

                <div class="grlistDropDwon" *ngIf="showTgsDropdwonCity">
                    <ul class="autoComplete">
                        <li *ngFor="let tags of cityData; let i = index">
                            <a class="pointer" (click)="selectRelevantCity(tags.district, tags?.state)">{{tags.district
                                ? (tags.district) :''}} {{tags?.state ? ('('+tags?.state+')'):''}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="invlid_error" *ngIf="selectCity.length==0  && showError">
                *Please enter your city.
            </div>
        </div>

        <div class="lead-search-bar">
            <h5 class="sub-label"> Select Sub-category </h5>
            <div class="tag_field  form-control" style="position: relative; cursor:text"
                (click)="focusInput('chip-controls-category')" [ngClass]="categoryList?.length>0 ?  'icon_div':''">
                <div class="chipsWrap">
                    <small class="tag_input" *ngFor="let tags of selectedCategory; let i = index"
                        [ngClass]="i < 2  ?'':'Hide'">
                        <span *ngIf="i < 2">
                            {{tags.name}} <img (click)="remove(i,3)" src="../../../assets/images/ic_crossimg.svg"
                                alt="">
                        </span>
                    </small>
                    <small class="tag_input" *ngIf="selectedCategory.length>2" style="font-size:12px;">
                        <span class="plusMore">+</span>{{selectedCategory.length-2}} More
                    </small>

                    <div class="searchTags" style='display: inline-block'>
                        <input type="text" placeholder="" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="searchCategory" (input)="searchCategoryEvent($event)"
                            id='chip-controls-category'>
                    </div>
                </div>

                <div class="grlistDropDwon" *ngIf="showTgsDropdwonCategory">
                    <ul class="autoComplete">
                        <li *ngFor="let tags of categoryList; let i = index">
                            <a class="pointer" (click)="selectCategory(tags)">{{tags.name
                                ?
                                (tags.name) :''}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="invlid_error" *ngIf="selectedCategory.length==0  && showError">
                *Please enter sub category name.
            </div>
        </div>
        <div class="d-flex downloadHeading">
            <ul class="tabs-wrap">
                <li class="">
                    <label class="radio"> <input value="0" (change)="onMatchChange($event)" type="radio"
                            name="match-type" checked /><i class="input-helper"></i>
                        Exact
                        Match </label>
                </li>
                <li>
                    <label class="radio"> <input value="1" (change)="onMatchChange($event)" type="radio"
                            name="match-type" /><i class="input-helper"></i> Broad
                        Match
                    </label>
                </li>
            </ul>
            <div class="download-btn">
                <a class="pointer" (click)="getPrices(false)"> Download Rate CSV </a>
            </div>
        </div>

        <div class="amount-wrap">
            <div class="form-group">
                <div class="row">
                    <!-- <div class="col-12 col-md-6">
                        <input type="email" [(ngModel)]="price" class="form-control" placeholder="Amount" />
                    </div>
                    -->
                    <div class="col-12 col-md-12">
                        <ul>
                            <li (click)="priceClick(data)" *ngFor="let data of priceList">
                                <a [ngClass]="data?.id==price?.id?'active':''" href="javascript:;" class="price">
                                    <b> ₹{{data?.finalPrice}} </b>
                                    <p> Base Price <span class="discount-price"> ₹{{data?.basePrice}} </span> </p>
                                    <p> Save <span class="offer-price"> ₹{{data?.basePrice - data?.finalPrice}}
                                            ({{data?.discountPercentage}}%) </span></p>
                                </a>
                            </li>
                            <!-- <li (click)="priceClick(price)" > <a class="price pointer"> {{price.basePrice}} </a> </li> -->
                        </ul>
                    </div>
                </div>

                <div class="invlid_error" *ngIf="showError && !price">
                    Please select the price.
                </div>
            </div>
            <a (click)="getPrices(true)" class="btn-main pointer" style="margin: 0;"> Pay Now </a>
        </div>
    </div>
</main>

<div>
    <app-phone-pe #phonePe [parentToChild]="parentToChild"
    (childEvent)="childMessage($event)"></app-phone-pe>
</div>

