<section class="business_pro pt-0">
    <div class="container">
        <ul class="inner_tabs pt-3">
            <li>
                <a href="/">Home </a>&gt;
            </li>
            <li>
                <a>Merchant Feedback </a>
            </li>
        </ul>

        <div class="notifiWrap shadowBox">
            <h2 class="subHeading">Merchant Feedback</h2>
                <!-- Pinned -->
                <div class="notifiList" *ngIf="feedbackList.count !== 0 ">
                    <ul class="">
                        <li *ngFor="let data of feedbackList.rows">
                            <div class="imgbox"><img [src]="BaseUrl+data.businessDetailsFeedback.logo" /></div>
                            <div class="comt-sect" data-toggle="modal" (click)="openModal(data)">
                                <h6 class="capitalize  crsr">{{data.businessDetailsFeedback.businessName}}</h6>
                                <p *ngIf="!data.description" class="crsr">Please provide your feedback to help us
                                    improve</p>
                                <p *ngIf="data.description" class="crsr">{{data.description}}</p>
                                <div class="date-time-details"><span class="date-time"> {{data.createdAt | dateDay}}
                                        | {{data.createdAt | date:'hh:mm a'}}</span></div>
                            </div>
                        </li>
                    </ul>
                    <div class="new-pagination-wrapper" *ngIf="feedbackList.count>5">
                        <div class="pagination-wrapper">
                            <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"
                                [boundaryLinks]="true" [maxSize]="pagination.maxSize"
                                [itemsPerPage]="pagination.limit" previousText=" Prev" nextText="Next"
                                [totalItems]="feedbackList.count" firstText="First" lastText="Last" [rotate]="true">
                            </pagination>
                        </div>
                    </div>
                </div>

                <div class="No_found" style="border: none;" *ngIf="feedbackList.count == 0 ">

                    No Data Found!
                </div>
        </div>
    </div>
</section>
<app-feedback-popup (valueChange)="getFeedbackList()" #feedbackModal></app-feedback-popup>