import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService,ToastrConfig } from 'ngx-toastr';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import { DataSharingService } from 'src/app/services/data.share.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChatService } from 'src/app/services/chat.service';


@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {
  allData: any;
  count: any;
  showCancel = false;
  businessId: any;
  showCheckBox = false;
  showtext = false;
  i: number = 0;
  data: any = {
    businessIds: []
  }
  showError;
  showCrousel = false;
  BaseUrl = environment.BaseUrl + 'assets/';
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "arrows": false };
  modalRef: BsModalRef;
  slides = [
    { img: "../../../assets/images/b-banner.png" },
    { img: " ../../../assets/images/b-banner.png" },
    { img: " ../../../assets/images/b-banner.png" },
  ];
  businessChatInfo: any;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
  @ViewChild('chatModel') private chatModel: TemplateRef<any>; 
  constructor(private dataSharingService: DataSharingService,
    private router: Router, private admin: AdminService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private chatService: ChatService,) { }

  ngOnInit(): void {
    this.getFavoritesData();
    setTimeout(() => {
      this.showCrousel = true;
    }, 500)

    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Favourite page", pageImg, "Favourite page", "","");
  }

  getFavoritesData() {
    this.admin.getDatas(apiUrl._getfavourite).subscribe(async (res) => {
      this.count = res.data.count;
      res.data.data.forEach(x => {
        let latitude = Number(x.businessDetails.latitude ? x.businessDetails.latitude : '');
        let longitude = Number(x.businessDetails.longitude ? x.businessDetails.longitude : '');
        const userLat = Number(localStorage.getItem("latitude"))
        let userLng = Number(localStorage.getItem("longitude"))
        let destination = { lat: userLat, lng: userLng };
        x.distance = parseFloat(this.getDistanceFromLatLonInKm(latitude, longitude, userLat, userLng).toFixed(1));
      });
      this.allData = res.data.data;
    })
  }

  DeleteMultiple() {
    if (this.showCancel && this.data.businessIds.length == 0) {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000)
      return
    }
    this.showCancel = true;
    this.showCheckBox = true;
    this.showtext = true;
    if (this.data.businessIds.length > 0) {
      this.modalRef = this.modalService.show(this.confirmModal, {
        animated: true,
        backdrop: 'static',
        class: "modal-dialog-centered rewardPopup"
      });
    }

  }
  confirmDelete() {
    this.admin.deleteData(apiUrl._updatefavourite, this.data).subscribe(async (res) => {
      this.toastrService.success("Removed Successfully")
      this.modalRef.hide();
      await this.getFavoritesData()
      this.data.businessIds = []
    },
      err => {
        console.log(err, "err");
      });
  }
  cancelButton() {
    this.showCancel = false;
    this.showCheckBox = false;
    this.showtext = false;
    this.data.businessIds = []
  }

  removeFavourite(value: any) {
    this.data.businessIds.push(value)
    this.modalRef = this.modalService.show(this.confirmModal, {
      animated: true,
      backdrop: 'static',
      class: "modal-dialog-centered rewardPopup"
    });
  }
  cancelDelete() {
    this.modalRef.hide();
    this.cancelButton();
  }
  selectMultiple(event: any, id: any) {
    if (event.target.checked) {
      this.showtext = false;
      this.data.businessIds.push(id)
    } else {
      const index = this.data.businessIds.indexOf(id);
      if (index !== -1) {
        this.data.businessIds.splice(index, 1);
      }
      this.data.businessIds.length > 0 ? this.showtext = false : this.showtext = true;
    }
  }

  navigateToBusinessDetail(value: any) {
    sessionStorage.removeItem('businessID');
    sessionStorage.setItem('businessID', btoa(value));
    this.dataSharingService.isBusinessChanged.next(true);
    this.router.navigate(['category/sub-category/business/business-detail'], { replaceUrl: false, skipLocationChange: true })
  }
  onChatClick(allData, template: TemplateRef<any>) {
    this.admin.firebaseSignIn();
    let userId;
    let userInfo;
    let userName;
    const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    if (userData) {
      let userInfo = userData;
      userName = JSON.parse(userInfo).fullName
      userId = JSON.parse(userInfo).id
    }
    if (allData?.businessDetails?.unlimitiedOrganicLeads === 1) {
      if (Number(allData?.businessDetails?.isExpressInterest) === 1) {
        this.openChat(template);
        this.businessChatInfo = { 'id': allData.businessId, "Name": allData.businessDetails.displayName, "businessName": allData.businessDetails.businessName, "isExpressInterest": allData?.businessDetails.isExpressInterest, "defultMsg": 0 }
      } else {
        let data = {
          "businessId": allData.businessId
        }
        this.admin.postData(apiUrl._postLead, data).subscribe(res => {
          this.chatService.updateJob(allData.businessId, userId, allData.businessDetails.displayName, userName, allData.businessDetails.displayName, res.data?.leadDetails?.leadId, allData.businessDetails.phoneNumber, "1", "serviceName", res.data?.leadDetails?.id, "serviceID")
          this.openChat(template);
          this.businessChatInfo = { 'id': allData.businessId, "Name": allData.businessDetails.displayName, "businessName": allData.businessDetails.businessName, "isExpressInterest": allData?.businessDetails.isExpressInterest, "defultMsg": 1 }
          allData.businessDetails.isExpressInterest = 1;
        });
      }
    }
    else {
      this.admin.showErrorMsg("Merchant is not available for communication");
    }

  }
  openChat(template) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      class: "siderBarPopup right chating"
    });
  }
  close() {
    this.modalRef.hide();
  }
  closeChat(id) {
    this.modalRef.hide();
    this.allData.forEach(x => {
      if (x.businessId == id) {
        x.businessDetails.isExpressInterest = 0;
      }
    });
  }
  onCallClick(data) {
    let userInfo;
    const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    if (userData) {
      userInfo = JSON.parse(userData);
    }
    let params = {
      callerNumber: userInfo.phoneNumber,
      businessId: data.businessId
    }
    this.admin.getData(apiUrl._getAvailableDID, params).subscribe(res => {
      if (this.admin.checkDevice() == 'mobile') {
        if(res.data.result.DID){
         // window.open('tel:' + res.data.result.DID);
          window.location.href = 'tel:'  + res.data.result.DID;
        }else{
          if(userData){
            Swal.fire({
              // title: "Contact Details",
              // icon: "info",
              text: "Merchant not available on call try calling some other business.",
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonColor: "#14141f",
              cancelButtonColor: "#939393",
              allowOutsideClick: true,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.onChatClick(data,this.chatModel);
              }
            });    
          }else{  
          const toastConfig: Partial<ToastrConfig> = {
            toastClass: 'error-custom-toast', // Apply custom CSS class
            progressBar: true, // Add progress bar
            timeOut: 10000 ,// Set timeout for auto-dismissal
            enableHtml: true,
            };
      
            var message = data?.businessName+" is busy on another call , please try calling some other business.";
            this.toastrService.success('<span class="error-toast-icon"><i class="fa fa-close"></i></span> ' + message, '',
            toastConfig);
          }
          
        }
      }
      else {
        Swal
          .fire({
            // title: "Contact Details",
            // icon: "info",
            text: "Call is only possible through our mobile application or mobile browser. Please download the app or open the link in the mobile browser.",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#14141f",
            cancelButtonColor: "#939393",
            allowOutsideClick: true,
            confirmButtonText: "Ok",
          });

      }
    })
  }
  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
}
