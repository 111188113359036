import { AbstractControl, ValidationErrors } from '@angular/forms';
export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string)?.indexOf(' ') >= 0) {
      const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { cannotContainSpace: true };
    }

    return null;
  }
} 
