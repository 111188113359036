<div class="container">
    <div class="siteMap">
        <div class="head_ttl">
            <h1>Site Map </h1>
        </div>
        <ul class="category">
            <li class="categoryList" *ngFor="let category of  allCategory; let indexCategory = index">
                <a class="pointer" (click)="selectCategory(category)">
                    {{category?.name}}
                </a>
                <ul class="subCategory">
                    <li class="subCategoryList" *ngFor="let subCategory of category.subCategories; let i = index">
                        <a class="pointer"  (click)="selectSubCategory(subCategory, category)">
                            {{subCategory?.name}}
                        </a>
                        <ul class="subCategoriesLevel2">
                            <li class="subCategoriesLevel2List" *ngFor="let subCategoryLvl of subCategory?.subCategoriesLevel2">
                                <a class="pointer" (click)="selectCategoryLevel(subCategoryLvl)">{{subCategoryLvl.name}}</a>
                                
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>