<ng-template #locationTemplate class="location-popup-position">
  <div
    class="location__shake-container-v1 animated"
    [ngClass]="flickerClass"
    (click)="onModalClick($event)"
  >
    <div class="location-v1">
      <div class="rectangle">
        <div class="location-body__top-container">
          <div class="close-icon">
            <div class="welcome-to-grofers weight--semibold">
              <img
              srcset="../../../assets/images/img_logo.png 480w,
                      ../../../assets/images/img_logo.png 800w,
                      ../../../assets/images/img_logo.png 1200w"
              sizes="(max-width: 600px) 980px,
                     (max-width: 900px) 800px,
                     1200px"
              src="../../../assets/images/img_logo.png"
              alt="Company Logo"
              width="100%"
              height="auto"
            />
              <!-- Welcome to <span
                                class="text-style-1">finndit</span> -->
            </div>
            <div *ngIf="cross==true">
              <button
                type="button"
                class="closePopup"
                (click)="close()"
              ></button>
            </div>
          </div>

          <div class="flex-text">
            <div class="icon">
              <i class="fa fa-map-marker location-icon"></i>
            </div>          
            <div class="please-provide-your">{{ modelcontent }}</div>
          </div>        
          <div class="row hideinmobile">
            <div class="col-md-5 col-sm-12 currentlocationdiv">
              <button
                class="btn location-box mask-button currentlocation"
                (click)="allowLocationAccess()"
              >
                Fetch Current Location
              </button>
            </div>

            <div class="col-md-1 col-sm-12">
              <div class="oval-container">
                <div class="oval">
                  <span class="separator-text"><div class="or">OR</div></span>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 manuallydiv">
              <button
                class="btn location-box manually"
                (click)="gotoManually()"
              >
                Enter Your Location Manually
              </button>
            </div>
          </div>    
          <div class="row showinmobile">
            <div class="col-md-5 col-sm-12 currentlocationdiv">
              <button
                class="btn location-box mask-button currentlocation"
                (click)="allowLocationAccess()"
              >
                Fetch Location
              </button>
           
              <div class="oval-container">
                <div class="oval">
                  <span class="separator-text"><div class="or">OR</div></span>
                </div>
                <div class="manuallydiv">
                  <button
                    class="btn location-box manually"
                    (click)="gotoManually()"
                  >
                    Enter Manually
                  </button>
                </div>
              </div>
            

           
          </div>
          </div>      
          <!-------------------->
        </div>
      </div>
      <div class="boldred" *ngIf="isBlocked==true">
        Oops! We do not have permission to determine your location. Please enter your location manually or allow permissions from browser settings.
      </div>
    </div>   
  </div>  
</ng-template>

<ng-template #blockTemplate>
  <div class="block-model">
    <div class="close-icon">
      <span class="p-text">Browser does not have location access</span>
      <div>
        <button
          type="button"
          class="closePopup"
          (click)="close(false)"
        ></button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #gotoManuallyTemplate>
  <div
    class="location__shake-container-v1"
  >
    <div class="location-v1">
      <div class="rectangle">
        <div class="location-body__top-container">
          <div class="close-icon">
            <div class="welcome-to-grofers weight--semibold">
              <img src="../../../assets/images/img_logo.png" alt=""/>
              <!-- Welcome to <span
                                class="text-style-1">finndit</span> -->
            </div>
            <div *ngIf="cross==true">
              <button
                type="button"
                class="closePopup"
                (click)="closeGotoManuallyTemplate()"
              ></button>
            </div>
          </div>

          <div>
            <h4 class="mt-5">Select your location</h4>
            <p>
              To avail location related services within app please provide your
              location.
            </p>
          </div>

          <form
            class="d-flex h-100 row"
            [formGroup]="manualLocationForm"
            (ngSubmit)="submitManualLocation()"
          >
            <div class="form-group col-md-6" style="margin-bottom: 5px">
              <input
                type="text"
                class="form-control"
                id="pin code"
                autocomplete="off"
                placeholder="Enter Pin Code"
                formControlName="pincode"
                (keypress)="onlyNumberKey($event)"
                (input)="getCity($event, 3)"
                maxlength="6"
              />
              <div
                class="invlid_error"
                *ngIf="
                  manualLocationForm.controls['pincode'].hasError('required') &&
                  showError
                "
              >
                *Please enter pincode.
              </div>
              <div
                class="invlid_error"
                *ngIf="
                  !showError &&
                  manualLocationForm.controls['pincode'].errors?.minlength
                "
              >
                *Please enter valid pincode.
              </div>

              <div
                class="invlid_error"
                *ngIf="
                  manualLocationForm.controls['pincode'].hasError(
                    'cannotContainSpace'
                  ) && showError
                "
              >
                *Please enter pincode.
              </div>
            </div>
            <div
              class="form-group col-md-6 group-cstm pr-input"
              style="margin-bottom: 5px"
            >
              <!-- *ngIf="!disableForm" -->

              <input
                style="padding-right: 40px"
                (input)="getCity($event, 2)"
                type="text"
                formControlName="city"
                class="form-control"
                placeholder="Select Your Location"
              />
              <img
                class="eye_close"
                src="../../../assets/images/location.svg"
                alt=""
              />
              <input
                (paste)="(false)"
                *ngIf="disableForm"
                maxlength="500"
                uppercase
                class="form-control"
                formControlName="city"
                type="text"
                readonly
              />
              <div class="grlistDropDwon" *ngIf="showDropdwonCity">
                <ul class="autoComplete">
                  <li *ngFor="let tags of cityData; let i = index">
                    <a class="pointer" (click)="selectRelevantCity(tags)"
                      >{{ tags.place ? tags.place + "," : "" }}
                      {{ tags.district ? tags.district : "" }}
                      {{ tags?.state ? "(" + tags?.state + ")" : "" }}
                    </a>
                  </li>
                </ul>
              </div>

              <div
                class="invlid_error"
                *ngIf="
                  manualLocationForm.controls['city'].hasError('required') &&
                  showError
                "
              >
                *Please enter your location.
              </div>

              <div
                class="invlid_error"
                *ngIf="
                  manualLocationForm.controls['city'].hasError(
                    'cannotContainSpace'
                  ) && showError
                "
              >
                *Please enter location.
              </div>
            </div>
            <div class="form-group" style="margin-bottom: 0px">
              <div class="col-md-12">
                <p style="font-size: 14px; margin-bottom: 0px">
                  "If you are unable to select your location, please use <a  type="button" (click)="opencurrentlocation()" style="color: #308136;"><b>auto fetch</b></a> option".
                </p>
              </div>
              <div class="col-md-12 bottom text-center">
                <button class="btn-main verified_pop">Continue</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
