<div class="chat-wrap">
    <div class="head-bar">
        <h3>{{bussionName}} {{serviceName ? '('+serviceName+')':''}}</h3>
        <div class="clear-chat">
            <ul>
                <li>
                    <div class="dropdownWrap" dropdown>
                        <span dropdownToggle class="toggleIcon"></span>
                        <ul *dropdownMenu class="dropdown-menu lg" role="menu" placement="right">
                            <li><a class="dropdown-item" (click)="closeChat()">Block/Close Chat</a></li>
                            <li><a class="dropdown-item redTxt" (click)="clearChat(messageData)">Clear Chat</a></li>

                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="chat-history" #scrollMe>
        <ul class="list">
            <li *ngFor="let message of messageData; let i = index"
                class='{{message.sender_id ==  (serviceId ? serviceId : job_id) ? "received_msg" : "align-right " }}'>
                <div class="chat-message-content">
                    <p *ngIf="message?.message_type==1">{{message.message
                        }}</p>
                    <img (click)="showBigsize(message.attachment_url,1)" *ngIf="message?.message_type==2"
                        [src]="(baseUrl +'assets/'+message.attachment_url)">
                    <div class="play-icon pointer" *ngIf="message?.message_type==3" (click)="showBigsize(message.attachment_url,2)">
                        <video  class="cropped_img"
                            *ngIf="message?.message_type==3">
                            <source [src]="(baseUrl +'assets/'+message.attachment_url)" type="video/mp4">
                        </video>
                    </div>
                </div>

                <span class="chat-time">{{message.message_time | dateago}}</span>
            </li>
        </ul>

        <div class="No_found" *ngIf="messageData.length == 0 && (chatDisable==='0' || chatDisable==='1' ) "
            style="border: none;">
            No conversation yet!
        </div>
    </div>
    <div class="bottom-box">
        <div *ngIf="chatDisable==='0'" class="disbaleChat">Chat Disabled</div>
        <form *ngIf="chatDisable==='1'" [formGroup]="chatForm" (ngSubmit)="sendMessage()"
            (keydown.enter)="sendMessage()">
            <fieldset>
                <div class="add-icons">
                    <a class=""><input id="fileUpload" type="file" id="uploadFile" class="upload-pic" #userPhoto
                            (change)="onFileSelected($event)">
                        <svg id="ic_add" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <rect id="Rectangle_20034" data-name="Rectangle 20034" width="30" height="30" fill="#fff"
                                opacity="0.003" />
                            <path id="noun-add-4699483"
                                d="M204.535,122.5a1.337,1.337,0,0,1,1.337,1.337V133.2h9.361a1.337,1.337,0,1,1,0,2.674h-9.361v9.361a1.337,1.337,0,1,1-2.674,0v-9.361h-9.361a1.337,1.337,0,0,1,0-2.674H203.2v-9.361A1.337,1.337,0,0,1,204.535,122.5Z"
                                transform="translate(-189.535 -119.535)" fill="#318337" fill-rule="evenodd" />
                        </svg>
                    </a>
                    <!--  -->
                </div>
                <div class="input_msg_write">
                    <input type="text" class="messageInput" formControlName="message" name="userMessage"
                        placeholder="Type a message" (keypress)="updateVale($event)">
                    <button class="msg_send_btn"><img src="assets/images/sendimg.svg"></button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
<app-gallery-slider #imgGallery></app-gallery-slider>

<div class="preloader-backdrop" *ngIf="showLoader1">
    <div class="page-preloader">Loading</div>
</div>
<app-feedback-popup #feedbackModal></app-feedback-popup>