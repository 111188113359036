import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
@Pipe({
  name: 'dateDay'
})
export class DateDayPipe implements PipeTransform {

  transform(value) {
    value=new Date(value)
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

       if (value.getFullYear() == today.getFullYear() && value.getMonth() == today.getMonth() && value.getDate() == today.getDate())
       return "Today";
     else if (value.getFullYear() == yesterday.getFullYear() && value.getMonth() == yesterday.getMonth() && value.getDate() == yesterday.getDate())
       return "Yesterday";
    else{
      return (new DatePipe("en-US")).transform(value, 'dd MMM,yyyy');
    }
 }
}
