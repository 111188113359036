import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {Router } from "@angular/router";
import { DataSharingService } from 'src/app/services/data.share.service';
import { chatEnvironment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menu_items: any[] = [];
  FbChats = chatEnvironment.chats;
  sub_menu: any = [];
  unReadCount: any;
  constructor(private admin: AdminService,    private dataSharingService: DataSharingService, private db: AngularFireDatabase,    public router: Router) {
    this.dataSharingService.isPlanChanged.subscribe(value => {
      if (value) {
        this.updateSideBar();
      }
    });
    //this.admin.firebaseSignIn();
  }

  ngOnInit(): void {
   this.updateSideBar();
  } 

  updateSideBar(){
    let jobID = this.admin.getBusinessId();
    this.getJobDialogs(jobID);
    this.getFormSetting();
  }

  getFormSetting() {
    let planData = this.admin.getPlanData();
    let currentPlanSetting = planData ? planData?.subscription_plan_list?.subscription_list : '';
    if (!currentPlanSetting) {
      this.checkSubscriptionPlan("2");
    }else{
      this.menu(currentPlanSetting); 
    }
  }
  async checkSubscriptionPlan(type) {
    let planData;
    planData = await this.admin.getSubscriptionPlan(type);
    let currentPlanSetting = planData ? planData?.subscription_plan_list?.subscription_list : '';
    if (currentPlanSetting) {
      this.menu(currentPlanSetting);
    }
  }

  getJobDialogs(job_id: string) {
    this.db.list(`${this.FbChats}`,
      query => query
        .orderByChild("job_id")
        .equalTo(job_id)
      //.limitToLast(20)
    ).valueChanges().subscribe(listNew => {
      let dialogListing: any = [];
      dialogListing = Object.assign([], listNew);
      if (dialogListing.length > 0) {
        let i = 0;
        let filterData = dialogListing.filter(res => {
          let id = res?.service_id ? res.service_id : job_id;
          if (res.unread_count) {
            if (res.unread_count[id] > 0) {
              i++
            }
          }
        });
        this.unReadCount = i;
      }
    });
  }

  menu(currentPlanSetting) {
    let meun: any = [];
    meun.push({
      name: "Dashboard",
      link: "/dashboard",
      count: "",
      image: "/assets/images/ic_dashboard_inactive.svg",
      image2: "/assets/images/dashboard.svg",
      show:1
    },
      {
        name: "Leads",
        link: "/leads",
        count: "",
        image: "/assets/images/ic_leads_active.svg",
        image2: "/assets/images/leads_active.svg",
        show:1
      },
      {
        name: "Inbox",
        link: "/merchant-inbox",
        count: "",
        image: "/assets/images/ic_inbox.svg",
        image2: "/assets/images/ic_inbox_active.svg",
        show:currentPlanSetting?.inboxAccess
      },
      {
        name: "Services",
        link: "/service",
        count: "",
        image: "/assets/images/service.svg",
        image2: "/assets/images/ic_service_active.svg",
        show:currentPlanSetting?.listOfServices===0 ? currentPlanSetting?.listOfServices :1
      },
      {
        name: "Offers and Discounts",
        link: "/offer-discount",
        count: "",
        image: "/assets/images/offers.svg",
        image2: "/assets/images/ic_offers_active.svg",
        show:currentPlanSetting?.listOfOffers===0 ? currentPlanSetting?.listOfOffers :1
      },
      {
        name: "Advertising & Campaigns",
        link: "/advertisement",
        count: "",
        image: "/assets/images/advertising.svg",
        image2: "/assets/images/advertising_active.svg",
        show:currentPlanSetting?.runBannerCampaigns===0 ? currentPlanSetting?.runBannerCampaigns : 1
      },
      {
        name: "Ratings & Reviews",
        link: '/rate-review',
        count: "",
        image: "/assets/images/ic_star.svg",
        image2: "/assets/images/ic_star_active.svg",
        show:currentPlanSetting?.userReviewManagement
      },
      {
        name: "Feedback",
        link: '/feedback-forms',
        count: "",
        image: "/assets/images/ic_feedback.svg",
        image2: "/assets/images/ic_feedback_active.svg",
        show:1
      },
      {
        name: "Analytics",
        link: '/analytics',
        count: "",
        image: "/assets/images/analytics_icon.svg",
        image2: "/assets/images/analytics_icon_active.svg",
        show:1
      },      
      {
        name: "Buy Leads",
        link: '/buy-leads',
        count: "",
        image: "/assets/images/ic_leads_active.svg",
        image2: "/assets/images/leads_active.svg",
        show:currentPlanSetting.buyPaidLeads
      },
      {
        name: "Lead Report",
        link: '/subscription-management',
        count: "",
        image: "/assets/images/ic_leads_active.svg",
        image2: "/assets/images/leads_active.svg",
        show:currentPlanSetting.buyPaidLeads
      }
    );
    this.menu_items = meun;
  }
  closeMenuBar(){ 
    document.querySelector('body')?.classList.remove('sidenav-toggled');
  }
  goTopage(url){ 
    // this.router.navigate([url]);
    this.closeMenuBar();
  }

}
