import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionPlanComponent } from './../extrnal/subscription-plan/subscription-plan.component';
import { PhonePeComponent } from './../common/phone-pe/phone-pe.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({ 
  imports: [
    CommonModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  declarations: [
    SubscriptionPlanComponent,
    PhonePeComponent
  ],
  exports: [
    SubscriptionPlanComponent,
    PhonePeComponent

  ],
  providers: []
})
export class SharePlanModule { }