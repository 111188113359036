import { NgModule, Query } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { HomePageComponent } from "./extrnal/home-page/home-page.component";
import { AuthGuard } from "./services/auth.guard";
import { MerchantPermissionGuard } from "./services/merchant-permission.guard";
import { NotFoundComponent } from "./extrnal/not-found/not-found.component";
import { PageRedirectionComponent } from "./extrnal/page-redirection/page-redirection.component";
import { AllSearchListComponent } from "./extrnal/all-search-list/all-search-list.component";
import { InboxComponent } from "./extrnal/inbox/inbox.component";
import { TemplateComponent } from 'src/app/extrnal/template/template.component';
import { ChatsComponent } from "./merchantPenal/chats/chats.component";
import { ReviewHistoryComponent } from './extrnal/review-history/review-history.component';
import { FavouriteComponent } from './extrnal/favourite/favourite.component';
import { QueryListComponent } from "./extrnal/query-list/query-list.component";
import { QueryManagementComponent } from "./merchantPenal/query-management/query-management.component";
import { FeedbackComponent } from "./extrnal/feedback/feedback.component";
import { FeedbackFormsComponent } from "./merchantPenal/feedback-forms/feedback-forms.component";
import { RatingReviewsComponent } from "./merchantPenal/rating-reviews/rating-reviews.component";
import { FaqComponent } from "./extrnal/faq/faq.component";
import { PrivacyPolicyComponent } from "./extrnal/privacy-policy/privacy-policy.component";
import { TermsUseComponent } from "./extrnal/terms-use/terms-use.component";
import { AboutusComponent } from "./extrnal/aboutus/aboutus.component";
import { SettingComponent } from "./extrnal/setting/setting.component";
import { SubscriptionPlanComponent } from "./extrnal/subscription-plan/subscription-plan.component";
import { BuyLeadsComponent } from "./merchantPenal/buy-leads/buy-leads.component";
import { TermsServiceComponent } from "./extrnal/terms-service/terms-service.component";
import { MerchantTermsConditionsComponent } from "./extrnal/merchant-terms-conditions/merchant-terms-conditions.component";
import { RefundPolicyComponent } from "./extrnal/refund-policy/refund-policy.component";
import { GrievanceComponent } from "./extrnal/grievance/grievance.component";
import { SupportComponent } from "./extrnal/support/support.component";
import { SitemapComponent } from "./extrnal/sitemap/sitemap.component";

const routes: Routes = [
  { path: "", component: HomePageComponent },
  { path: "category", loadChildren: () => import("./extrnal/categories/categories.module").then((m) => m.CategoriesModule), },
  { path: "notification", loadChildren: () => import("./extrnal/notification/notification.module").then((m) => m.NotificationModule), },
  { path: "become-merchant", canActivate: [AuthGuard], loadChildren: () => import("./becomeMerchant/merchant.module").then((m) => m.MerchantModule), },
  // { path: "switch-businesses", canActivate: [AuthGuard], loadChildren: () => import("./becomeMerchant/merchant.module").then((m) => m.MerchantModule), },
  { path: "home", component: PageRedirectionComponent },
  { path: "home/:id", component: PageRedirectionComponent },
  { path: "chats", canActivate: [AuthGuard], loadChildren: () => import("./extrnal/inbox/inbox.module").then((m) => m.InboxModule) },
  { path: "search-list", canActivate: [AuthGuard], loadChildren: () => import("./extrnal/all-search-list/all-search-list.module").then((m) => m.AllSearchListModule) },
  { path: "review-history", canActivate: [AuthGuard], loadChildren: () => import("./extrnal/review-history/review-history.module").then((m) => m.ReviewHistoryModule) },
  { path: "favourite", canActivate: [AuthGuard], component: FavouriteComponent },
  { path: "myfinndit", canActivate: [AuthGuard], loadChildren: () => import("./extrnal/my-finndit/my-findit.module").then((m) => m.MyFinditModule) },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'merchant-terms-conditions', component: MerchantTermsConditionsComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'terms-use', component: TermsUseComponent },
  { path: 'terms-service', component: TermsServiceComponent },
  { path: 'about', component: AboutusComponent },
  { path: 'grievance', component: GrievanceComponent },
  { path: 'support', component: SupportComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'signup', component: HomePageComponent },
  { path: 'user_login', component: HomePageComponent },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'feedback', loadChildren: () => import("./extrnal/feedback/feedback.module").then((m) => m.FeedbackModule), canActivate: [AuthGuard] },
  { path: "query", canActivate: [AuthGuard], loadChildren: () => import("./extrnal/query-list/query-list.module").then((m) => m.QueryListModule) },
  { path: 'rewards', loadChildren: () => import("./extrnal/rewards/rewards.module").then((m) => m.RewardsModule) },
  //{ path: 'rewards', canActivate: [AuthGuard], loadChildren: () => import("./extrnal/rewards/rewards.module").then((m) => m.RewardsModule) },
  { path: 'redeem-rewards',loadChildren: () => import("./extrnal/redeem-points/redeem-points.module").then((m) => m.RedeemPointsModule) },
  { path: 'offers-discount', loadChildren: () => import("./extrnal/offers-discount/offers-discount.module").then((m) => m.OffersDiscountModule) },
  {
    path: "", component: TemplateComponent,
    children: [
      { path: "subscription-plan", component: SubscriptionPlanComponent },
    ]
  },
  // merchant router
  { path: "dashboard", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/dashboard/dashboard.module").then((m) => m.DashboardModule) },
  { path: "service", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/service/service.module").then((m) => m.ServiceModule), },
  { path: "offer-discount", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/offer-discount/offer.module").then((m) => m.OfferDiscountModule), },
  { path: "merchant-profile", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./becomeMerchant/merchant.module").then((m) => m.MerchantModule), },
  { path: "leads", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/leads/leads.module").then((m) => m.LeadsModule) },
  { path: 'advertisement', canActivate: [MerchantPermissionGuard], loadChildren: () => import('./merchantPenal/advertisement/advertisement.module').then(m => m.AdvertisementModule) },
  {
    path: 'merchant-inbox', canActivate: [MerchantPermissionGuard], component: TemplateComponent,
    children: [{ path: '', component: ChatsComponent },]
  },

  {
    path: "", component: TemplateComponent,
    children: [
      { path: "query-management", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/query-management/query-management.module").then((m) => m.QueryManagementModule) },
      { path: "rate-review", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/rating-reviews/rating-reviews.module").then((m) => m.RatingReviewsModule) },
      { path: "feedback-forms", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/feedback-forms/feedback-forms.module").then((m) => m.FeedbackFormsModule) },
      { path: "analytics", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/analytics/analytics.module").then((m) => m.AnalyticsModule) },
      { path: "buy-leads", component: BuyLeadsComponent, canActivate: [MerchantPermissionGuard] },
      { path: "subscription-management", canActivate: [MerchantPermissionGuard], loadChildren: () => import("./merchantPenal/subscription-management/subscription-management.module").then((m) => m.SubscriptionManagementModule), }
    ]
  },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'feedback-forms', component: FeedbackFormsComponent },

  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
