<div class="callsPage">
  <div class="searchBar">
    <div class="searchbox">
      <div class="form-element">
        <input name="" [(ngModel)]="search" (keyup)="getCallLogs()" placeholder="Search" type="text">
        <span class="input-icon"><i class="fa fa-search"></i></span>

      </div>
      <div *ngIf="!missedCallFilter" class="search-btn" (click)="applyFilter()"><i class="icon icon-search-2"></i></div>
      <div *ngIf="missedCallFilter" class="filter-icon">
        <i (click)="applyFilter()" class="missedCallIcon filter-red-icon"></i>
      </div>
    </div>
    <div class="table-wrapper" style="min-height: 1px;">
      <table class="table">
        <tbody *ngIf="callList.count>0">
          <tr *ngFor="let data of callList.rows">
            <td>
              <div class="d-flex" style="align-items: center">
                <div class="userBx">
                  <img [src]="baseUrl+data?.user?.profilePic "
                    *ngIf="data?.user?.profilePic && data?.user?.profilePic!=='null'">
                  <img src="../../../../assets/images/user.svg"
                    *ngIf="!data?.user?.profilePic || data?.user?.profilePic=='null'">
                </div>
                <label class="userName firstlCaptial">
                  {{fromData.fromMerchant? data?.user.fullName :data?.business?.displayName}}
                </label>
              </div>
            </td>
            <td *ngIf="fromData.businessId" class="noWrap">
              Lead ID: {{data?.lead?.leadId}} (<span class="businessName">{{data.business_service?.serviceName ? data.business_service?.serviceName : data.business?.displayName}}</span>)
            </td>
            <td>

              <span class="firstlCaptial setAlign" *ngIf="data?.status==4 && !fromData.fromMerchant">
                <img *ngIf="data?.isFromMerchant!==0 " src="./assets/images/ic_incoming.svg">
                <i *ngIf="data?.isFromMerchant==0 " class="outgoingIcon"></i>{{data?.isFromMerchant==0
                ?'Outgoing':'Incoming'}}
              </span>
              <span class="firstlCaptial setAlign" *ngIf="data?.status==4 && fromData.fromMerchant">
                <img *ngIf="data?.isFromMerchant===0 " src="./assets/images/ic_incoming.svg">
                <i *ngIf="data?.isFromMerchant!==0 " class="outgoingIcon"></i>{{data?.isFromMerchant!==0
                ?'Outgoing':'Incoming'}}
              </span>
              <span class="firstlCaptial setAlign" *ngIf="data?.status==3 ">
                <i class="missedCallIcon"></i>Missed call
              </span>
            </td>
            <td><span class="firstlCaptial" style="text-transform: none;">{{data.callDuration}} </span></td>
            <td><span class="firstlCaptial" style="text-transform: none;">{{data.createdAt | dateago}}</span></td>
            <td><a href="javascript:;" (click)="onCall(data)" class="blue tag_bt">Call</a></td>
            <td><a (click)="goToChat(data)" href="javascript:;"><img
                  src="../../../../assets/images/ic_comment1.svg"></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="No_found mb-5" *ngIf="callList.count==0">
      No Data Found!
    </div>
  </div>