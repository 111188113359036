import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private admin: AdminService) { }

  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Support", pageImg, "Support", "", "");
  }

}
