<ng-template #feedBackModal>
    <div class="modal-header">
        <h4> Send Us Feedback!</h4>
        <button type="button" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="feedbackForm" class="" (ngSubmit)="submitFeedback()" style="margin: 0px;" [ngClass]="disableForm?('form-disable'):''">
            <div class="delete-popup-wrap">
                <p>Do you have a suggestion regarding your experience with {{allData.businessName ? allData.businessName:'Finndit'}}.</p>
            </div>

            <div class="your-experience">
                <h6>How was your experience ?</h6>
                <ul class="" *ngIf="allData.type && allData.description && allData.from=='customer' ">
                    <li class="mr-3">
                        <img [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==1?'':'active'"
                            src="assets/images/ic_like.svg">
                    </li>
                    <li>
                        <img [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==2?'':'active'"
                            src="assets/images/ic_dislike.svg">
                    </li>

                </ul>
                <ul class="" *ngIf="(allData.from=='customer') && (allData.type==null && allData.description==null)">
                    <li class="mr-3">
                        <img class="crsr" (click)="expClick(1)"
                            [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==1?'':'active'"
                            src="assets/images/ic_like.svg">
                    </li>
                    <li>
                        <img class="crsr" (click)="expClick(2)"
                            [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==2?'':'active'"
                            src="assets/images/ic_dislike.svg">
                    </li>

                </ul>
                <ul class="" *ngIf="allData.from!=='customer'">
                    <li class="mr-3">
                        <img class="crsr" (click)="expClick(1)"
                            [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==1?'':'active'"
                            src="assets/images/ic_like.svg">
                    </li>
                    <li>
                        <img class="crsr" (click)="expClick(2)"
                            [ngClass]="feedbackForm.value.type!=='' && feedbackForm.value.type==2?'':'active'"
                            src="assets/images/ic_dislike.svg">
                    </li>

                </ul>
                <div class="invlid_error" *ngIf="feedbackForm.controls['type'].hasError('required') && showError">
                    *Please like or dislike.
                </div>
            </div>
            <div class="form-group group-cstm pr-input">
                <span>
                    <label css-select-moz> Description</label>
                </span>
                <textarea maxlength="150" class="form-control" formControlName="description" name="description"
                    rows="5"></textarea>
                <div class="charCount" [ngClass]="feedbackForm.value.description?.length == 150 ? ('txtRed'):''">{{feedbackForm.value?.description?.length ? feedbackForm.value?.description?.length:0}}/150
                </div>
                <div class="invlid_error"
                    *ngIf="feedbackForm.controls['description'].hasError('required') && showError">
                    *Please enter description.
                </div>
            </div>
            <button *ngIf="showButton" class="btn-main form-control" type="submit">Send Feedback</button>
        </form>
    </div>
</ng-template>