<footer>
    <div class="footer-top">
        <div class="container">
            <div class="row">
               <!-- <div class="col-lg-4 col-md-4">
                     <div class="footerBox address">
                        <h4>Address:</h4>
                        <p>C-196, Industrial Area Phase 8 B, Mohali Punjab 160055 </p>
                    </div>
                   
                </div> -->
                <div class="col-md-8">
                    <ul class="address1">
                        <li>
                            <a routerLinkActive="active" class="pointer" (click)="showPlan(allPlan)" href="#">Subscription Plans</a>
                        </li>
                        <li >
                            <a routerLinkActive="active" routerLink="/about" (click)="clearSearch()">About Us</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" target="_blank"  routerLink="/privacy-policy" (click)="clearSearch()">Privacy Policy</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" target="_blank" routerLink="/terms-use" (click)="clearSearch()">Terms & Conditions</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" target="_blank" routerLink="/merchant-terms-conditions" (click)="clearSearch()">Merchant Terms and Conditions</a>
                        </li>                        
                        <li>
                            <a routerLinkActive="active" routerLink="/refund-policy" (click)="clearSearch()">Refund and Cancellation Policy</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/faq"  (click)="clearSearch()">FAQ's</a>
                        </li> 

                        <li>
                            <a routerLinkActive="active" routerLink="/support"  (click)="clearSearch()">Support</a>
                        </li> 

                        <li>
                            <a routerLinkActive="active" routerLink="/grievance"  (click)="clearSearch()">Data Grievance</a>
                        </li> 

                        <li>
                            <a routerLinkActive="active" target="_blank" href="https://www.finndit.com/blog/">Blog</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/sitemap"  (click)="clearSearch()">Sitemap</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                  

                    <div class="footerBox">
                        <h2>Contact:</h2>
                        <p>+91-9478787880 </p>
                    </div>
                    <div class="footerBox">
                        <h2>Email:</h2>
                        <p><a href="mailto:support@finndit.com">support@finndit.com</a></p>
                    </div>
                    <h2>Follow Us On</h2>
                    <ul class="social-icon">
                        <li>
                            <a href="https://www.facebook.com/thefinndit?mibextid=LQQJ4d" target="_blank" aria-label="Follow us on Facebook">
                                <img src="../../../assets/images/social1.svg" alt="Follow us on Facebook">
                              </a>
                        </li>
                        <li> 
                            <a href="https://www.linkedin.com/company/99146480" target="_blank" aria-label="Follow us on linkedin">
                                <img src="../../../assets/images/social2.svg" alt="Follow us on linkedin" width="100%"
                                height="auto">
                              </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/finnditcom" target="_blank" aria-label="Follow us on twitter">
                                <img src="../../../assets/images/social3.svg" alt="Follow us on twitter" width="100%"
                                height="auto">
                              </a>
                        </li>
                        <li>
                            <a href="https://www.pinterest.com/thefinndit/?invite_code=2479efbd702b464bb9c340b724158d39&sender=699958104493659511" target="_blank" aria-label="Follow us on pinterest">
                                <img src="../../../assets/images/social4.svg" alt="Follow us on pinterest" width="100%"
                                height="auto"> 
                              </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@TheFinndit" target="_blank" aria-label="Follow us on youtube">
                                <img src="../../../assets/images/social6.svg" alt="Follow us on youtube" width="100%"
                                height="auto">
                              </a>
                            
                        </li>
                        <li>
                            <a href="https://www.instagram.com/thefinndit_/" target="_blank" alt="Follow us on instagram">
                                <img src="../../../assets/images/social5.svg" alt="Follow us on instagram" width="100%"
                                height="auto">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <p class="copyright">© 2024.finndit Inc. All Rights Reserved. </p>
        </div>
    </div>
</footer>


<ng-template #allPlan>
    <div class="modal-header">
        <button style="margin-left: auto;" type="button" class="closePopup" (click)="close()"></button>
    </div>
    <app-subscription-plan [paymentData]="paymentData"
        (paymentValue)='getPaymentStatus($event)'></app-subscription-plan>
</ng-template>
