import { Injectable } from '@angular/core';
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from "@angular/fire/compat/database";
// import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs'
import swal from 'sweetalert2';
import "firebase/messaging"
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { DataSharingService } from '../services/data.share.service';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  firbasNotification = new BehaviorSubject(null);
  currentMessage = new BehaviorSubject(null);
  fireBase = new BehaviorSubject(null)
  checkNotification = new BehaviorSubject(null);
  checkMessageNotification = new BehaviorSubject(null);
  constructor(private dataSharingService: DataSharingService, private angularFireMessaging: AngularFireMessaging, private router: Router, private db: AngularFireDatabase) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
        this.firbasNotification.next(_messaging);
      }, (error) => {
        console.log("_messaging", error)
      }
    )
  }
  requestPermission() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            localStorage.setItem('fireBaseToken', token ? token:'' )
            // return token;
            resolve(token);
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );
      }, 1000);
    });
    return promise;
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        let data = payload.data;
        this.currentMessage.next(payload);       
      })
  }

  redirect(data) {
    if (data.type == 7 || data.type == 23 || data.type == 26 || data.type == 46 || data.type == 47) {
      // post
     // this.router.navigateByUrl('/feed/post/' + data.id)
    } else if (data.type == 2) {

     // this.router.navigateByUrl('/clubs/' + data.id)
      // 5 days Inactivity:
    } else if (data.type == 61 || data.type == 63) {

    } else {

    }


  }
}