import { Component, OnInit } from "@angular/core";
import { AdminService } from "../../services/admin.service";
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent implements OnInit {
  show:any;
  constructor(private changeDetector: ChangeDetectorRef,  private admin: AdminService) {
    this.admin.loaderState.subscribe((data) => {  
       if (data) {
        this.changeDetector.detectChanges();
         this.show=data;
       } else {
        this.changeDetector.detectChanges();
         this.show=data;
       }
     });
  }
  ngOnInit(): void {}
  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
 }
}
