import { Component, OnInit,Renderer2,Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DataSharingService } from "src/app/services/data.share.service";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
})
export class NotFoundComponent implements OnInit {
  prevURL: any = [];
  notFound: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private dataSharingService: DataSharingService
  ) {
    try {
      let rootVar = window["rootId"];
      if (rootVar) {
        this.notFound = "";
      }  else {
        this.notFound = "Page Not Found";
      }
    } catch (ex) {
      this.notFound = "";
      console.log(ex);
    } 
  }

  ngOnInit(): void {}
  backHomePage() {
    localStorage.removeItem("defultBusiness");
    this.renderer.removeClass(this.document.body, 'marchantPenal');
    localStorage.removeItem("Marchant");
    this.router.navigate(["/"]);
  }
}
