import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import * as CONSTANT from '../../services/constants';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { DataSharingService } from 'src/app/services/data.share.service';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService,ToastrModule, ToastrConfig } from "ngx-toastr";

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.css']
})
export class CallsComponent implements OnInit, OnDestroy {
  constructor(private toastrService: ToastrService,private api: AdminService, private dataService: DataSharingService, @Inject(PLATFORM_ID) private platformId: any) { }
  pagination = { limit: 5, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  currentPage: number;
  callList: any = [];
  baseUrl = environment.BaseUrl + 'assets/'
  @Input() fromData: any;
  missedCallFilter = false;
  search = '';
  @Output() chatId = new EventEmitter();
  subscription: Subscription;
  currentBusiness;
  ngOnInit(): void {
    this.getCallLogs();
    this.getCurrentBusiness();
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
  getCallLogs() {
    let params = {
      limit: this.pagination.limit.toString(),
      skip: this.pagination.skip.toString(),
    }
    if (this.fromData.businessId) {
      params['businessId'] = this.fromData.businessId
    }
    if (this.fromData.userId) {
      params['userId'] = this.fromData.userId
      //params['userId'] ="7edb4e03-7ed0-40e1-90ae-a34e821d7281"
    }
    if (this.missedCallFilter) {
      params["isMissedCall"] = 1
    }
    if (this.search.length > 0) {
      params['search'] = this.search
    }
    this.api.getData(apiUrl._getCallHistory, params).subscribe(res => {
      res.data.rows.forEach(x => {
        let time;
        if (x.callDuration == 0 || x.callDuration == null) {    
          x.callDuration = "0 second" ;
        } else {
          if (x.callDuration > 60) {
            time = (x.callDuration / 60);
            let min = time.toString().split('.')[0]
            let sec = time.toString().split('.')[1].substring(0, 2) + ' seconds';
            min = min > 60 ? min / 60 + ' hours ' : min = 1 ? min + ' min ' : min + ' mins ';
            x.callDuration = min + sec
          }
          else {
            x.callDuration = x.callDuration + ' seconds';
          }
        }
      })
      this.callList = res.data;      
    })
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = event.page - 1;
    this.getCallLogs();
  }
  onCall(data) {   
    let userId;
    let userInfo;
    let userName;
    const userData = this.api.getUserSaveData();
    if (userData) {
      userInfo = userData;
      userId = userInfo.id;
    }
    let params: any = {};
    if (this.fromData.fromMerchant) {
      params.callerNumber = this.currentBusiness.phoneNumber,
      params.userId = data.userId,
      params.businessServiceId=data.business_service?.id ? data.business_service?.id:'' 
    }
    else {
      params.businessId = data.businessId;
      params.callerNumber = userInfo.phoneNumber;
      params.businessServiceId=data.business_service?.id ? data.business_service?.id:''
    }
    this.api.getData(apiUrl._getAvailableDID, params).subscribe(res => {
      if (this.api.checkDevice() == 'mobile') {
        if(res.data.result.DID){
       // window.open('tel:' + res.data.result.DID);
          window.location.href = 'tel:'  + res.data.result.DID;
      }else{
        if(userData){
          Swal.fire({
            // title: "Contact Details",
            // icon: "info",
            text: "Merchant not available on call try calling some other business.",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#14141f",
            cancelButtonColor: "#939393",
            allowOutsideClick: true,
            confirmButtonText: "Ok",
          });         

        }else{
        const toastConfig: Partial<ToastrConfig> = {
          toastClass: 'error-custom-toast', // Apply custom CSS class
          progressBar: true, // Add progress bar
          timeOut: 10000 ,// Set timeout for auto-dismissal
          enableHtml: true,
          };
    
          var message = data?.displayName+" is busy on another call , please try calling some other business.";
          this.toastrService.success('<span class="error-toast-icon"><i class="fa fa-close"></i></span> ' + message, '',
          toastConfig);
        }
    
        
      }
        
      }
      else {
        Swal
          .fire({
            //  title: "Contact Details",
            //  icon: "info",
            text: "Call is only possible through our mobile application or mobile browser. Please download the app or open the link in the mobile browser.",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#14141f",
            cancelButtonColor: "#939393",
            allowOutsideClick: true,
            confirmButtonText: "Ok",
          });
      }
    });
  }
  applyFilter() {
    this.missedCallFilter = this.missedCallFilter ? false : true;
    this.getCallLogs();
  }
  goToChat(data) {
    let chatId = data.businessId + ',' + data.userId;
    this.chatId.emit(chatId)
  }
  getCurrentBusiness() {
    this.subscription = this.dataService.currentBusiness.subscribe(value => {
      if (value) {
        this.currentBusiness = value
      }
    })
  }
}
