<div class="bannerSlider homePagebannner" [style]="bannerData?.length>0 ? '' : 'padding-bottom:56%'">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (afterChange)="afterChange($event)">
        <div ngxSlickItem *ngFor="let slide of bannerData" class="slide"> 
            <!-- -->
            <div class="banner-sect"
            [ngClass]="slide?.businessId || slide?.businessServiceId || slide?.link ? 'pointer':''"
            (click)="slide?.businessId || slide?.businessServiceId || slide?.link ? goTodetails(slide, slide?.businessServiceId, slide?.link) : ''"
            [ngStyle]="{'background': 'url(' + BaseUrl + 'advertisements/' + (slide?.backgroundImageWeb ? slide?.backgroundImageWeb : slide?.fullImage) + ') no-repeat center center / cover'}">
         <div class="slider">
           <div class="container">
             <div class="row align-items-center">
               <div class="col-12 col-sm-6 bannerPenal content_left">
                 <h2>{{slide?.title}}</h2>
                 <p>{{slide?.message}}</p>
                 <span class="bg_text" *ngIf="slide?.ctaText">{{slide?.ctaText}}</span>
               </div>
               <div class="col-12 col-sm-6 bannerPenal">
                 <div class="right_img" *ngIf="slide?.webImage">
                   <img [srcset]="BaseUrl + 'advertisements/' + slide?.webImage" alt="Banner Image" title="Banner" loading="lazy">
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
        </div>
    </ngx-slick-carousel>
</div>

<!-- featured category  -->
<section class="feature_cate" *ngIf="showCategories">
    <div class="container">
        <div class="head_ttl">
            <h2>Top Categories <a class="view_all" *ngIf="homeDataV2?.topCategories !==[]" routerLink="/category">View all</a>
            </h2>           
        </div>
        <ul class="list_cate">
            <li class="" *ngFor="let category of homeDataV2?.topCategories">
                <a class="pointer"  href="#" (click)="categoryClick($event, category)">
                    <div class="categoryImg">
                        <img width="100%"
     height="auto"
     [src]="BaseUrl + 'assets/' + category?.categoryData.image"
     alt="Image representing {{category?.categoryData.name}} category"
     title="{{category?.categoryData.name}}">
                    </div>
                    <span>{{category?.categoryData.name}}</span>
                </a>
            </li>
        </ul>
    </div>
</section>

<!-- top business  -->
<section *ngIf="showBussiness" class="feature_serv upto_off brdr_site">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="head_ttl">
                    <h2>Top Businesses <a class="view_all" href="javascript:;" routerLink="category/top-business">View all</a> </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul class="list_cate col-set-4">
                    <li class="" *ngFor="let category of topbussinessList">
                        <a href="javascript:;" (click)="topBusinessClick(category,'business')">
                            <figure class="image_box">
                                <div class="aspect-ratio-box">
                                    <img [src]="(BaseUrl+'assets/' + category?.logo)" alt="{{category?.displayName}}"
                                         decoding="async"
                                         fetchpriority="high" 
                                         title="{{category?.displayName}}" loading="lazy" >
                                </div>
                            </figure>
                            <span>{{category?.title}}</span>
                        </a>
                        
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>


<!-- join become  -->
<section class="join_become">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12 hideAfterLogin">
                <section class="bnr_sml d-flex" style="background-image: linear-gradient(to right, #5E6BB9, #9AA5DE);padding:0px">
                    <div class="left">
                        <h3>Join Finndit</h3>
                        <p>Find painstakingly verified and trusted businesses near you.</p>
                        <a class="btn_main pointer" (click)="signUpShow()"  href="#">Register Now</a>
                    </div>
                    <div class="imgbox">
                        <img src="../../../assets/images/men1.png" alt="Person using a digital device"  loading="lazy">
                    </div>
                </section>
            </div>
            <div [ngClass]="loginUser ? 'col-12' : 'col-md-6'">
                <div class="bnr_sml d-flex" style="background-image: linear-gradient(to right, #659669 , #D9DE61);">
                    <div class="left">
                        <h4>Become a merchant</h4>
                        <p>Reach millions of potential customers quickly, effectively and economically</p>
                        <a *ngIf="!loginUser" (click)="signUpShow()" class="btn_main btn_white clr_grn"
                            href="javascript:;">List your business now</a>
                        <a *ngIf="loginUser" (click)="switchMerchant()" class="btn_main btn_white clr_grn"
                            href="javascript:;">List your business now</a>
                    </div>
                    <div class="imgbox"> <img src="../../../assets/images/men2.png" alt=""  loading="lazy"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- featured  -->
<section class="feature_serv" *ngIf="showFeature">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="head_ttl">
                    <h2> Featured Section<a *ngIf="homeData?.featuredCategories.length>4" class="view_all" href="javascript:;" routerLink="/category/featured-category">View all</a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul class="list_cate col-set-4">
                    <li class=""*ngFor="let category of recomendedList">
                        <a href="javascript:;" (click)="featuredCategoryClick(category)">
                            <figure  class="image_box padding-0">
                                <img *ngIf="category.image" [src]="(BaseUrl+'assets/' + category?.image)" alt=" {{category.logoText}}" title=" {{category.logoText}}">
                                <span *ngIf="!category.image" class="transparent">
                                    {{category.logoText}}
                                </span>
                            </figure>
                           
                            <span class="category-name">{{category.featuredCategoryData.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section *ngIf="!loginUser && showContests" class="contests brdr_site">
    <div class="container">
        <div class="head_ttl">
            <h2>Contests <a class="view_all" href="javascript;">View all</a>
            </h2>
        </div>
        <ul class="d-flex">
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg1.jpg" alt="Bologna">
                    </div>

                    <div class="card-body">
                        <h4 class="card-title">Super car quiz</h4>
                        <p class="card-text">Play super quiz and win Sponsored by: <strong>Joshi Autozone</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg2.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">PP jewellers</h4>
                        <p class="card-text">Play super quiz and win <strong>18 karat gold coin</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg3.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Wordscapes</h4>
                        <p class="card-text">Next level is available. resume now to earn amazing rewards</p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg4.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Hdfc home loan</h4>
                        <p class="card-text">Starting at <br>
                            <strong>8.70% pa onwards</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
<section *ngIf="loginUser && showContests" class="contests brdr_site">
    <div class="container">
        <div class="head_ttl">
            <h2>Active contests <a class="view_all" href="#_">View all</a>
            </h2>
        </div>
        <ul class="d-flex">
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg1.jpg" alt="Bologna">
                    </div>

                    <div class="card-body">
                        <h4 class="card-title">Super car quiz</h4>
                        <p class="card-text">Play super quiz and win Sponsored by: <strong>Joshi Autozone</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg2.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">PP jewellers</h4>
                        <p class="card-text">Play super quiz and win <strong>18 karat gold coin</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg3.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Wordscapes</h4>
                        <p class="card-text">Next level is available. resume now to earn amazing rewards</p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg4.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Hdfc home loan</h4>
                        <p class="card-text">Starting at <br>
                            <strong>8.70% pa onwards</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>


</section>
<section *ngIf="loginUser && showContests" class="contests brdr_site">
    <div class="container">
        <div class="head_ttl">
            <h2>New contests <a class="view_all" href="#_">View all</a>
            </h2>
        </div>
        <ul class="d-flex">
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg1.jpg" alt="Bologna">
                    </div>

                    <div class="card-body">
                        <h4 class="card-title">Super car quiz</h4>
                        <p class="card-text">Play super quiz and win Sponsored by: <strong>Joshi Autozone</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg2.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">PP jewellers</h4>
                        <p class="card-text">Play super quiz and win <strong>18 karat gold coin</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg3.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Wordscapes</h4>
                        <p class="card-text">Next level is available. resume now to earn amazing rewards</p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/images/blg4.jpg" alt="Bologna">
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Hdfc home loan</h4>
                        <p class="card-text">Starting at <br>
                            <strong>8.70% pa onwards</strong>
                        </p>
                        <p>Start Date: <strong>22 Oct, 2022</strong>
                        </p>
                    </div>
                    <div class="cardFooter">
                        <small>Earn upto: <strong>100 points <img src="../../../assets/images/coin.svg">
                            </strong>
                        </small>
                        <a href="#" class="btn btn_brdr">Notify</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
<!-- Offers & Discounts  -->
<section *ngIf="showOffers && homeData?.offerAndDiscounts.length>0" class="feature_serv upto_off brdr_site">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="head_ttl">
                    <h2>Offers & Discounts <a *ngIf="homeData?.offerAndDiscounts.length>4" class="view_all" href="javascript:;" routerLink="offers-discount">View all</a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul class="list_cate col-set-4">
                    <li class="" *ngFor="let offer of offersDiscount">
                        <a href="javascript:;" (click)="offerClick(offer)">
                            <figure class="image_box padding-0">
                                <img [src]="(BaseUrl+'assets/' + offer?.image)" alt="{{offer.discount}}" title="{{offer.discount}}">
                            </figure>
                            <span><strong *ngIf="offer.discount!=0 && offer.discount">{{offer.discount}}% Off</strong> {{offer.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<ng-template #business_claim>
    <div class="modal-body claim-pop">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (afterChange)="afterChange($event)">
        <div ngxSlickItem *ngFor="let claimData of homeData?.claimBusinesses" class="slide">
            <div class="claimWrap">
                <h4> Claim Your Business</h4>
                <p> <span class="bus-name" *ngIf="claimData.ownerName">{{claimData.ownerName}}, </span>A business named <span
                        class="bus-name">{{claimData.businessName}}</span> is already listed with us with
                    your mobile number +91{{userInfo?.phoneNumber}}
                </p>
                <div class="claim_tag">
                    <div class="img-box">
                        <img *ngIf="claimData.logo" [src]="(BaseUrl+ 'assets/' + claimData.logo)" alt="logo">
                        <span *ngIf="!claimData.logo" class="defaultImg">{{claimData?.businessName?.charAt(0)}}</span>
                    </div>
                    <div class="content-box">
                        <h6 class="bus-name">{{claimData.businessName}}</h6>
                        <p>{{claimData.addressLineOne}} {{claimData.addressLineTwo}}</p>
                        <p> <strong>{{claimData.averageRating}}</strong>
                            ({{claimData.reviewCount==1?claimData.reviewCount +'
                            Review':claimData.reviewCount + ' Reviews'}} )</p>
                    </div>
                </div>
                <p class="big_txt">Claim This Business And Grow<br> Your Revenue</p>
                <!-- claim -->
                <a class="btn-main pointer" (click)="viewBusiness(claimData.id, 'claim')">Claim Now</a>
                <a class="btn_brdr pointer" (click)="viewBusiness(claimData.id, '')">View Business Details</a>                
            </div>
        </div>
    </ngx-slick-carousel>
        <a class="clr_blue pointer" (click)="close()">Skip</a>
    </div>
</ng-template>
<app-signup #signUp></app-signup>
<app-claim-business #claimBusinessModel></app-claim-business>
