<div class="container">
    <div class="termsWrap">
        <h1 style="padding-bottom: 20px;">Data Grievance</h1>
        <p>Kindly refer to our privacy policy(<a href="https://www.finndit.com/privacy-policy"
                target="_blank">https://www.finndit.com/privacy-policy</a>) to know about our data management
            practices and how we handle user data.</p>
        <p>You can always delete your account and all its related data from the settings section of your account on
            Finndit</p>
        <p>If you have any queries related to your data after account deletion, Kindly reach out to
            <a href="mailti:datagrievance@finndit.com">datagrievance@finndit.com</a>
        </p>
    </div>
</div>