
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms"

export function passwordValidator(controlName: string, matchingControlName: string) {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[controlName]
		const matchingControl = formGroup.controls[matchingControlName]

		if (matchingControl.errors && !matchingControl.errors.misMatch) {
			// return if another validator has already found an error on the matchingControl
			return
		}

		// set error on matchingControl if validation fails
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ misMatch: true })
		} else {
			matchingControl.setErrors(null)
		}
	}
}
