import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeedbackPopupComponent } from 'src/app/common/feedback-popup/feedback-popup.component';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import * as CONSTANT from "../../services/constants";

@Component({
  selector: 'app-feedback-forms',
  templateUrl: './feedback-forms.component.html',
  styleUrls: ['./feedback-forms.component.css']
})
export class FeedbackFormsComponent implements OnInit {
  businessId;
  @ViewChild('feedbackModal') private feedbackModal: FeedbackPopupComponent;
  modalRef: BsModalRef;
  feedbackList:any=[];
  BaseUrl='';
  pagination = { limit: 5, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  currentPage: number;
  constructor(private api:AdminService
    , private modalService: BsModalService)  { }

  ngOnInit(): void {
    this.BaseUrl = environment.BaseUrl + 'assets/';
    this.businessId = this.api.getBusinessId();
    this.getFeedbackList();
  }
  openModal(value){
    let data={
      businessId:this.businessId,
      from:'merchant',
      leadId:value.leadId,
      description:value.description,
      type:value.type
    }
    this.feedbackModal.openModal(data);

  }
  close()
  {
    this.modalRef.hide();
  }
  getFeedbackList()
  {
    let param={
      businessId:this.businessId,
      limit: this.pagination.limit.toString(),
      skip: this.pagination.skip.toString(),
    }
    this.api.getData(apiUrl._getChatFeedbacks,param).subscribe(res=>{
      this.feedbackList=res.data;
    },
    err => {
      console.log(err, "err");
    })
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = event.page - 1;
    this.getFeedbackList();
  }
}
