import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
  Renderer2,
  ElementRef,
  HostListener,
  NgZone,
  AfterViewInit,
  PLATFORM_ID,
} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd,NavigationStart ,  } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { DOCUMENT, isPlatformBrowser, DatePipe } from "@angular/common";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { ToastrService } from "ngx-toastr";
import { AllCategoriesComponent } from "../all-categories/all-categories.component";
import { SignupComponent } from "../../extrnal/signup/signup.component";
import { MapsAPILoader } from "@agm/core";
import { MessagingService } from "./../../services/messaging.service";
import { environment } from "src/environments/environment";
import { FeedbackPopupComponent } from "../feedback-popup/feedback-popup.component";
import { Subscription } from "rxjs";
import { chatEnvironment } from "src/environments/environment";
import { LocationPopupComponent } from "../../extrnal/location-popup/location-popup.component";
import { SignupPopupComponent } from "../../extrnal/signup-popup/signup-popup.component";
import { Location } from '@angular/common';
declare var google: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit,AfterViewInit {
  private previousUrl: string | null = null;
  locationdata: any;
  title: string;
  public focusElement: number = -1;
  FbChats = chatEnvironment.chats;
  msgUnreadCount: any;
  profileImg: any;
  unreadCount: any;
  notificationData: any;
  description: string;
  pastBelief: string;
  pastMeanings: string;
  pastBehaviors: string;
  searchData: any;
  seoData: any;
  activityPoints = 0;
  clickEventsubscription: Subscription;
  latitude: number = 30.713329;
  longitude: number = 76.695566;
  userInactivityTimeout : number =15000; // 15 seconds
  userExtendedInactivityTimeout : number =120000; // 2 minutes
  private intervalId: any;
  private signuppopupintervalId: any;  
  isLocationBlocked = false;
  sendLocationData: any = {};
  zoom: number = 8;
  private geoCoder;
  address: string;
  mapCity: any;
  alldata: any;
  referralCode: any;
  selectedCategoryId = "";
  BaseUrl: string;
  notifyFlag: number = 0;
  openpopuptime:number =0;
  ExitIntent:boolean=false;
  currentPlanSetting: any;
  @ViewChild("locationTemplate")
  private locationTemplate: LocationPopupComponent;
  @ViewChild("signuppopup")
  private signuppopupTemplate: SignupPopupComponent;
  @ViewChild("feedbackModal") private feedbackModal: FeedbackPopupComponent;
  allCategory: any = [];
  lastScrollTop = 0;
  earnPoints: any;
  typedText = "";
  isSignupLoaded = false;
  private isBackButtonProcessed = false;
  @ViewChild("search_box") searchBox: ElementRef;
  @HostListener("document:click", ["$event"])
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {   
    if (event.type == "popstate") {
      this.dataSharingService.regpopupVariable.subscribe(value => {
      if (!this.isBackButtonProcessed && this.previousUrl && this.previousUrl !== this.location.path() && !this.locationTemplate.isOpen && !this.locationTemplate.manually && !this.ExitIntent && !value && !this.signuppopupTemplate.isOpen && !this.isSignupLoaded && !localStorage.getItem("customerDetail")  && this.mapCity) {
        this.ExitIntent=true; 
        this.signuppopupTemplate.ExitIntentPopupopen(2);
        this.isBackButtonProcessed = true;
      }
    })
      let merchantType = localStorage.getItem("Marchant")
        ? localStorage.getItem("Marchant")
        : "0";
      window["rootId"] = undefined;

      if (
        location.pathname == "/" ||
        location.pathname == "/category" ||
        location.pathname == "/merchant-terms-conditions" ||
        location.pathname == "/refund-policy" ||
        location.pathname == "/home" ||
        location.pathname == "/faq" ||
        location.pathname == "/signup" ||
        location.pathname == "/user_login" ||
        location.pathname == "/about" ||
        location.pathname == "/sitemap" ||
        location.pathname == "/privacy-policy" ||
        location.pathname == "/terms-use" ||
        location.pathname == "/blog" ||
        location.pathname == "/rewards" ||
        location.pathname == "/chats" ||
        location.pathname == "/favourite" ||
        location.pathname == "/notification" ||
        location.pathname == "/redeem-rewards" ||
        location.pathname == "myfinndit" ||
        location.pathname == "/myfinndit/view-profile" ||
        location.pathname == "/myfinndit/update-profile" ||
        location.pathname == "/search-list" ||
        location.pathname == "/feedback" ||
        location.pathname == "/setting" ||
        location.pathname == "/myfinndit/findit-circle" ||
        location.pathname == "/myfinndit/activity-list" ||
        location.pathname == "/become-merchant" ||
        location.pathname == "/grievance" ||
        location.pathname == "/support" ||
        location.pathname == "/category/top-business" ||
        location.pathname == "/category/featured-category" ||
        location.pathname == "/home" ||
        location.pathname == "/error" ||
        merchantType == "1"
      ) {
      } else {
        let data = {
          url: location.pathname,
        };
        this.admin
          .getDataWithoutLoader(apiUrl._getSeoData, data)
          .subscribe(async (res) => {
            let apiData = res.data;
            if (apiData?.type) {
              this.seoData = {
                id: apiData.typeId,
                type: apiData.type,
                title: apiData.title,
              };
              this.setPageRedirection();
            } else {
              let searchText = sessionStorage.getItem("searchText")
                ? sessionStorage.getItem("searchText")
                : "";
              if (searchText) {
                this.seoData = { id: "110", type: "110", title: searchText };
                this.setPageRedirection();
              }
            }
          });
      }
    }
  }
  clickout(event) {
    if (
      event.target.id == "search_box" ||
      event.target.id == "search-outer" ||
      event.target.id === "search-outer-list" ||
      event.target.id == "cross-btn"
    ) {
      this.showDrop = true;
    } else {
      if (this.router.url === "/category/sub-category/business") {
        let text =
          sessionStorage.getItem("searchText") != null
            ? sessionStorage.getItem("searchText")
            : "";
        this.searchText = text ? text : "";
        //this.searchText = this.searchData!==undefined&&this.searchData!=null?this.searchData.title:"";
      }
      this.showDrop = false;
    }
  }
  modalRef: BsModalRef;
  showDrop = false;
  merchant: any;
  switchToMerchant: any;
  loginshow: any = localStorage.getItem("webToken") ? true : false;
  userName: any;
  showMobMenu = false;
  searchText = "";
  categoryCriteria = {
    category: 60,
    subCategory: 50,
    subCategoryLevelTwo: 40,
    business: 30,
    service: 20,
    tag: 10,
  };
  showMicro: boolean = true;
  speechType: string = "";
  enableBtn: boolean = true;
  @ViewChild("categories") private categoriesModel: AllCategoriesComponent;
  @ViewChild("signUp") private signupModel: SignupComponent;
  @ViewChild(LocationPopupComponent) locationPopup: LocationPopupComponent;

  pastSearchData: any = [];
  searchDataList: any = [];
  pastSearchDataList: any = [];
  userInfo: any;
  pastData = false;
  showDescMicro: boolean = true;
  showpastBeliefMicro: boolean = true;
  showpastMeaningsMicro: boolean = true;
  showpastBehaviorsMicro: boolean = true;
  filterPastData: any = [];
  subscription: Subscription;
  selectedcateId = "";
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location,
    @Inject(DOCUMENT) private document: Document,
    private toastrService: ToastrService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
   
    private admin: AdminService,
    private fcmService: MessagingService,
    private dataSharingService: DataSharingService,
    private eRef: ElementRef,
    private db: AngularFireDatabase,
  ) {
    this.merchant = localStorage.getItem("Marchant")
      ? localStorage.getItem("Marchant")
      : "0";
    if (!this.merchant || this.merchant != "1") {
      this.setPageRedirection();
    }
    this.dataSharingService.isLoginChanged.subscribe((value) => {
      if (value) {
        this.merchant = localStorage.getItem("Marchant")
          ? localStorage.getItem("Marchant")
          : "0";
        this.loginUser();
      }
    });
    this.dataSharingService.clearSearch.subscribe((value) => {
      if (value) {
        this.clearSearch();
      }
    });
    this.clickEventsubscription = this.admin
      .getClickEvent()
      .subscribe((value) => {
        if (value) {
          // setTimeout(() => {
          //   this.signupModel.openModal(value.type,value.phn,value.from,value.index);
          // }, 200);

          this.signUpShow(value.type, value.phn, value.from, value.index);
        }
      });

    this.dataSharingService.isPointUpdate.subscribe((value) => {
      if (value) {
        this.getUpdatePoints();
      }
    });

    this.dataSharingService.isHeaderChanged.subscribe((value) => {
      if (value) {
        this.merchant = localStorage.getItem("Marchant")
          ? localStorage.getItem("Marchant")
          : "0";
        this.headerUpdate();
        this.getList(false);
      }
    });
    this.fcmService.currentMessage.subscribe((value) => {
      if (value) {
        this.admin.showLoader = false;
        let token = localStorage.getItem("webToken")
          ? localStorage.getItem("webToken")
          : "";
        if (token) {
          this.notifyFlag = 0;
          this.getList(false);
        }
      }
    });
    this.dataSharingService.clearSearchField.subscribe((value) => {
      if (value) {
        this.clearSearch();
      }
    });
    this.dataSharingService.isHomePage.subscribe((value) => {
      if (value) {
        this.clearSearch();
      }
    });
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
    if (this.clickEventsubscription) {
      this.clickEventsubscription.unsubscribe();
    }
  }
  ngOnInit(): void {
   window.addEventListener('popstate', () => {
    if (this.previousUrl && this.previousUrl !== this.location.path() && !this.isBackButtonProcessed) {
      this.previousUrl = this.location.path();
    }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Save the previous URL
        this.previousUrl = event.url;
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      const id = sessionStorage.getItem("selectedCategory")
        ? sessionStorage.getItem("selectedCategory")
        : "";
      if (id !== "" && id) this.selectedCategoryId = JSON.parse(atob(id)).id;
    }
    this.BaseUrl = environment.BaseUrl + "notifications/";
    this.mapCity = localStorage.getItem("city")
      ? localStorage.getItem("city")
      : "";
    if (!this.mapCity) {
      this.mapsAPILoader.load().then(() => {
        // this.setCurrentLocation("1");
        this.geoCoder = new google.maps.Geocoder();
      });
    }
    this.loginUser();
    this.headerUpdate();
    this.onRefreshClick();
    this.setBreadCrumbs();
    this.dataSharingService.locationChange.subscribe((data) => {
      if (!!data.address) {
        this.setAddressLocal(data?.address);
        this.dataSharingService.locationChange.next({
          setLocation: false,
          address: "",
        });
      } else if (data.setLocation) {
        this.setCurrentLocation("1");
      }
    });
    this.getAllCategories();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Reload the header component
        this.reloadHeader();
      }
    });
  }
  reloadHeader() {
    this.ExitIntent=false;
  }
  setAddressLocal(data) {
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.mapCity = data.city;
    localStorage.setItem("state", data.state);
    localStorage.setItem("city", data.city);
    localStorage.setItem("latitude", this.latitude.toString());
    localStorage.setItem("longitude", this.longitude.toString());
    this.locationTemplate.close();
    this.stopInterval();
  }

  submitAddress(data) {
    const userData = localStorage.getItem("customerDetail")
      ? localStorage.getItem("customerDetail")
      : "";
    this.dataSharingService.locationChange.next({
      setLocation: false,
      address: data,
    });
    if (userData) {
      this.admin.postData(apiUrl._location, data).subscribe(
        async (res) => {
          let userinfo = JSON.stringify(res.data.user);
          localStorage.setItem("customerDetail", userinfo);
          this.setAddressLocal(data);
          this.dataSharingService.isLoginChanged.next(true);
          localStorage.removeItem("setLocation");
        },
        (err) => {
          this.admin.showErrorMsg(err.error.message);
        }
      );
    }
  }

  setPageRedirectionAfterLocation() {
    this.stopInterval();
   // try {
    //  let rootVar = window["rootId"];
     // if (rootVar) {
       // let data = rootVar;
       // if (data && data.type == "40") {
        //  this.dataSharingService.isLocation.next(true);
        //  this.dataSharingService.search.next(data.title);
        //  this.dataSharingService.setSearch(data.title);
         // sessionStorage.setItem(
         //   "selectedSubCategoryLvl",
        //    btoa(JSON.stringify({ id: data.id, name: data.title }))
       //   );
        //  this.router.navigate(["/category/sub-category/business"], {
         //   replaceUrl: false,
        //    skipLocationChange: true,
         // });
      //    window["rootId"] = undefined;
	//	   window.location.reload();
    //    }
		// }else{

        window.location.reload();
     // }
   // } catch (ex) {
     // console.log(ex);
    //}
  }
  setPageRedirection() {
    try {
      let rootVar = this.seoData?.type ? this.seoData : window["rootId"];
      if (rootVar) {
        let data = rootVar;
        if (data.type == "120") {
          window["rootId"] = undefined;
          sessionStorage.removeItem("searchText");
          this.router.navigate(["/"]);
        } else if (data && data.type != "40") {
          sessionStorage.removeItem("selectedSubCategoryLvl");
          sessionStorage.removeItem("selectedSubCategory");
          sessionStorage.removeItem("selectedCategory");
          let category = {
            id: data.id,
            name: data.title,
            title: data.title,
          };
          if (data.type == "10") {
            // Business
            sessionStorage.removeItem("businessID");
            sessionStorage.removeItem("searchText");
            sessionStorage.setItem("businessID", btoa(data.id));
            this.dataSharingService.isBusinessChanged.next(true);
            this.router.navigate(
              ["category/sub-category/business/business-detail"],
              {
                replaceUrl: false,
                skipLocationChange: true,
              }
            );
          } else if (data.type == "20") {
            // Category
            sessionStorage.removeItem("searchText");
            sessionStorage.setItem(
              "selectedCategory",
              btoa(JSON.stringify({ id: category.id, name: category.name }))
            );
            // window.location.reload();

            this.router.navigate(["/category/sub-category"], {
              replaceUrl: false,
              skipLocationChange: true,
            });
          } else if (data.type == "30") {
            // Sub Category

            sessionStorage.removeItem("searchText");
            sessionStorage.setItem(
              "selectedSubCategory",
              btoa(JSON.stringify({ id: category.id, name: category.name }))
            );
            this.dataSharingService.setSelectedSubCategory(category);
            // window.location.reload();

            this.router.navigate(["/category/sub-category-lvl"], {
              replaceUrl: false,
              skipLocationChange: true,
            });
          } else if (data.type == "50") {
            // Services
            sessionStorage.setItem("serviceID", btoa(data.id));
            sessionStorage.removeItem("searchText");

            this.router.navigate(
              ["/category/sub-category/business/services/services-details"],
              {
                replaceUrl: false,
                skipLocationChange: true,
              }
            );
            window["rootId"] = undefined;
          } else if (data.type == "100") {
            let searchTxt = data?.url?.trim();
            this.searchText = searchTxt?.replace(/\//g, "").replace(/-/g, " ");
            sessionStorage.setItem("searchText", this.searchText);
            this.dataSharingService.search.next(this.searchText);
            this.searchDataList = [];
            // window.location.reload();
            this.router.navigate(["/category/sub-category/business"], {
              replaceUrl: false,
              skipLocationChange: true,
            });
          }
          //window["rootId"] = undefined;
          else if (data.type == "110") {
            this.searchText = data.title;
            sessionStorage.setItem("searchText", data.title);
            this.dataSharingService.search.next(data.title);
            this.searchDataList = [];
            this.router.navigate(["/category/sub-category/business"], {
              replaceUrl: false,
              skipLocationChange: true,
            });
            window["rootId"] = undefined;
          }
        } else if (data && data.type == "40") {
          this.dataSharingService.search.next(data.title);
          this.dataSharingService.setSearch(data.title);
          sessionStorage.removeItem("selectedSubCategoryLvl");
          sessionStorage.removeItem("selectedSubCategory");
          sessionStorage.removeItem("selectedCategory");
          sessionStorage.removeItem("businessID");
          sessionStorage.setItem(
            "selectedSubCategoryLvl",
            btoa(JSON.stringify({ id: data.id, name: data.title }))
          );
          this.router.navigate(["/category/sub-category/business"], {
            replaceUrl: false,
            skipLocationChange: true,
          });
          window["rootId"] = undefined;
        }
      } else {
        this.searchText = "";
        sessionStorage.removeItem("searchText");
      }
    } catch (ex) {
      console.log("ex==========", ex);
    }
  }
  setPageRedirection_1() {
    try {
      let rootVar = this.seoData?.type
        ? this.seoData
        : isPlatformBrowser(this.platformId) ? window["rootId"] : undefined;

      if (rootVar) {
        let data = rootVar;

        if (data.type == "120") {
          if (isPlatformBrowser(this.platformId)) {
            window["rootId"] = undefined;
            sessionStorage.removeItem("searchText");
          }
          this.router.navigate(["/"]);
        } else if (data && data.type != "40") {
          if (isPlatformBrowser(this.platformId)) {
            sessionStorage.removeItem("selectedSubCategoryLvl");
            sessionStorage.removeItem("selectedSubCategory");
            sessionStorage.removeItem("selectedCategory");
          }

          let category = { id: data.id, name: data.title, title: data.title };

          if (data.type == "10") {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.removeItem("businessID");
              sessionStorage.removeItem("searchText");
              sessionStorage.setItem("businessID", btoa(data.id));
            }
            this.dataSharingService.isBusinessChanged.next(true);
            this.router.navigate(["category/sub-category/business/business-detail"], { replaceUrl: false, skipLocationChange: true });
          } else if (data.type == "20") {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.removeItem("searchText");
              sessionStorage.setItem("selectedCategory", btoa(JSON.stringify({ id: category.id, name: category.name })));
            }
            this.router.navigate(["/category/sub-category"], { replaceUrl: false, skipLocationChange: true });
          } else if (data.type == "30") {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.removeItem("searchText");
              sessionStorage.setItem("selectedSubCategory", btoa(JSON.stringify({ id: category.id, name: category.name })));
            }
            this.dataSharingService.setSelectedSubCategory(category);
            this.router.navigate(["/category/sub-category-lvl"], { replaceUrl: false, skipLocationChange: true });
          } else if (data.type == "50") {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.setItem("serviceID", btoa(data.id));
              sessionStorage.removeItem("searchText");
              window["rootId"] = undefined;
            }
            this.router.navigate(["/category/sub-category/business/services/services-details"], { replaceUrl: false, skipLocationChange: true });
          } else if (data.type == "100") {
            let searchTxt = data?.url?.trim();
            this.searchText = searchTxt?.replace(/\//g, "").replace(/-/g, " ");
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.setItem("searchText", this.searchText);
            }
            this.dataSharingService.search.next(this.searchText);
            this.searchDataList = [];
            this.router.navigate(["/category/sub-category/business"], { replaceUrl: false, skipLocationChange: true });
          } else if (data.type == "110") {
            this.searchText = data.title;
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.setItem("searchText", data.title);
            }
            this.dataSharingService.search.next(data.title);
            this.searchDataList = [];
            this.router.navigate(["/category/sub-category/business"], { replaceUrl: false, skipLocationChange: true });
            if (isPlatformBrowser(this.platformId)) {
              window["rootId"] = undefined;
            }
          }
        } else if (data && data.type == "40") {
          this.dataSharingService.search.next(data.title);
          this.dataSharingService.setSearch(data.title);
          if (isPlatformBrowser(this.platformId)) {
            sessionStorage.removeItem("selectedSubCategoryLvl");
            sessionStorage.removeItem("selectedSubCategory");
            sessionStorage.removeItem("selectedCategory");
            sessionStorage.removeItem("businessID");
            sessionStorage.setItem("selectedSubCategoryLvl", btoa(JSON.stringify({ id: data.id, name: data.title })));
          }
          this.router.navigate(["/category/sub-category/business"], { replaceUrl: false, skipLocationChange: true });
          if (isPlatformBrowser(this.platformId)) {
            window["rootId"] = undefined;
          }
        }
      } else {
        this.searchText = "";
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.removeItem("searchText");
        }
      }
    } catch (ex) {
      console.log("ex==========", ex);
    }
  }
  setCurrentLocation(type) {
    if ("geolocation" in navigator) {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "denied") {
              this.isLocationBlocked = true;
              this.locationTemplate.isblock();
            }

            if (permissionStatus.state === "granted") {
              this.isLocationBlocked = false;
              navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude, type);
              });
            }
            if (permissionStatus.state === "prompt") {
              this.isLocationBlocked = false;
              navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude, type);
              });
            }
          });
      }
    }
  }
  getAddress(latitude, longitude, type) {
    let googleCity = "";
    let googleState = "";
    let googleCountry = "";
    let googlePincode = "";
    localStorage.setItem("latitude", latitude.toString());
    localStorage.setItem("longitude", longitude.toString());
    this.geoCoder = new google.maps.Geocoder();
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          let userAddress = {
            latitude: latitude,
            longitude: longitude,
            gAddress: results[0].address_components,
            formatted_address: results[0].formatted_address,
          };
          sessionStorage.setItem("currentAddress", JSON.stringify(userAddress));
          for (let i = 0; i < results[0].address_components.length; i++) {
            let types = results[0].address_components[i].types;
            for (var j = 0; j < types.length; j++) {
              if (types.indexOf("administrative_area_level_3") != -1) {
                googleCity = results[0].address_components[i].long_name
                  ? results[0].address_components[i].long_name
                  : "";
              }
              if (types.indexOf("administrative_area_level_1") != -1) {
                googleState = results[0].address_components[i].long_name
                  ? results[0].address_components[i].long_name
                  : "";
              }
              if (types.indexOf("country") != -1) {
                googleCountry = results[0].address_components[i].long_name
                  ? results[0].address_components[i].long_name
                  : "";
              }
              if (types.indexOf("postal_code") != -1) {
                googlePincode = results[0].address_components[i].long_name
                  ? results[0].address_components[i].long_name
                  : "";
              }
              if (types[0] == "postal_code") {
                googlePincode = results[0].address_components[i].long_name
                  ? results[0].address_components[i].long_name
                  : "";
              }
            }
          }
          this.ngZone.run(() => {
            this.mapCity = googleCity;
            localStorage.setItem("state", googleState);
            localStorage.setItem("city", googleCity);
            const cityNameElement = document.querySelector(".cityName");
            if (cityNameElement) {
              cityNameElement.textContent = this.mapCity;
            }
            let data = {
              latitude: latitude.toString(),
              longitude: longitude.toString(),
              address: results[0].formatted_address,
              state: googleState,
              city: googleCity,
              country: googleCountry,
              pincode: googlePincode,
            };
            this.setAddressLocal(data);
            this.submitAddress(data);
            if (type === "1") {
              this.dataSharingService.getLocation.next(true);
            }
          });
          this.locationTemplate.close();

          this.setPageRedirectionAfterLocation();
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }
    );
  }

  headerUpdate() {
    this.merchant = localStorage.getItem("Marchant")
      ? localStorage.getItem("Marchant")
      : "0";
    if (this.merchant === "1") {
      this.getSubscriptionPlan();
      this.renderer.addClass(this.document.body, "marchantPenal");
    }
  }
  loginUser() {
    let token = localStorage.getItem("webToken")
      ? localStorage.getItem("webToken")
      : "";
    if (token) {
      this.checkMerchant("1");
      const userData = localStorage.getItem("customerDetail")
        ? localStorage.getItem("customerDetail")
        : "";
      if (userData) {
        this.loginshow = true;
        let userInfo = userData;
        let usernName = JSON.parse(userInfo).fullName || '';
        this.latitude = JSON.parse(userInfo).user_location?.latitude
          ? JSON.parse(userInfo).user_location?.latitude
          : "30.713329";
        this.longitude = JSON.parse(userInfo).user_location?.longitude
          ? JSON.parse(userInfo).user_location?.longitude
          : "76.695566";
        this.mapCity = JSON.parse(userInfo).user_location?.city
          ? JSON.parse(userInfo).user_location?.city
          : localStorage.getItem("city");
        if (usernName.indexOf(" ") > -1) {
          this.userName = usernName.split(" ")[0];
        } else {
          this.userName = usernName;
        }
        let img = JSON.parse(userInfo)?.profilePic;
        if (img) {
          this.profileImg = environment.BaseUrl + "assets/" + img;
        }
        localStorage.setItem("latitude", this.latitude.toString());
        localStorage.setItem("longitude", this.longitude.toString());
        let userID = JSON.parse(userInfo).id;
        this.getJobDialogs(userID);
      }
      this.admin.firebaseSignIn();
      this.renderer.addClass(this.document.body, "main_home");
      let setLocation = localStorage.getItem("setLocation")
        ? localStorage.getItem("setLocation")
        : "";
      if (setLocation) {
        this.isSignupLoaded = true;
        setTimeout(() => {
          this.signupModel.openModal("3", "null", "", "");
        }, 500);
      }
      this.getPointsData();
      this.getList(false);
    } else {
      this.loginshow = false;
      this.merchant = "0";
      //localStorage.clear();
      localStorage.removeItem("defultBusiness");
      localStorage.removeItem("currentBusiness");
      localStorage.removeItem("Marchant");
      localStorage.removeItem("forgotToken");
      localStorage.removeItem("userPer");
      localStorage.removeItem("merchantPrfoleType");
      localStorage.removeItem("customerDetail");
      localStorage.removeItem("businessData");
      localStorage.removeItem("business");
      this.renderer.removeClass(this.document.body, "marchantPenal");
      this.renderer.removeClass(this.document.body, "main_home");
    }
  }
  logout() {
    swal
      .fire({
        icon: "warning",
        text: "Are you sure you want to logout from the Finndit account ?",
        showCancelButton: true,
        confirmButtonColor: "#14141f",
        cancelButtonColor: "#939393",
        confirmButtonText: "Logout",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.value) {
          let data = {
            deviceToken: this.getDeviceToken(),
          };
          this.admin.putData(apiUrl._logout, data).subscribe(
            async (res) => {
              this.admin.userProfileData = null;
              this.admin.firebaseSignOut();
              //this.setCurrentLocation("0");
              this.loginshow = false;
              this.merchant = "0";
              localStorage.removeItem("Marchant");
              this.renderer.removeClass(this.document.body, "marchantPenal");
              this.renderer.removeClass(this.document.body, "main_home");
              sessionStorage.removeItem("claimCounter");
              sessionStorage.removeItem("timerCLaimPopUp");
              sessionStorage.removeItem("customerDetail");
              this.profileImg = "";
              localStorage.clear();
              localStorage.setItem("city", this.mapCity);
              this.dataSharingService.isLoginChanged.next(true);
              this.router.navigate(["/"]);
            },
            (err) => {
              console.log(err, "err");
            }
          );
        }
      });
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if (deviceToken && deviceToken != "") {
      return deviceToken;
    } else {
      this.fcmService.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken");
      if (deviceToken) return deviceToken.toString();
      else return "NOTOKEN";
    }
  }

  toggleCategories() {
    // isAllCategoryLoaded
    this.categoriesModel.openModal();
  }
  switchAccount() {
    if (this.alldata?.isBlocked == 1) {
      swal.fire({
        icon: "warning",
        text: "You are blocked by the admin.",
        showCancelButton: false,
        confirmButtonColor: "#14141f",
        cancelButtonColor: "#939393",
        confirmButtonText: "Ok",
      });
      return;
    }
    this.checkMerchant("2");
  }
  backHomePage() {
    this.selectedcateId = "";
    this.searchText = "";
    if (this.merchant === "1") {
      let data = {};
      const userData = localStorage.getItem("customerDetail")
        ? localStorage.getItem("customerDetail")
        : "";
      if (userData) {
        let userInfo = userData;
        let businessInfo = JSON.parse(userInfo);
        if (businessInfo?.usersBusiness?.length > 0) {
          businessInfo?.usersBusiness.forEach((res) => {
            if (res.data?.status === 1 && res.data.isClaimed === 1) {
              localStorage.setItem("defultBusiness", btoa(res?.id));
            }
          });
        } else {
          localStorage.setItem(
            "defultBusiness",
            btoa(businessInfo.usersBusiness?.id)
          );
        }
        let bID = localStorage.getItem("currentBusiness")
          ? localStorage.getItem("currentBusiness")
          : "";
        let businessID = bID ? atob(bID) : this.admin.getBusinessId();
        this.dataSharingService.businessUpdate.next(true);
        if (businessID) {
          this.getSubscriptionPlan();
          this.router.navigate(["/dashboard"]);
        } else {
          this.renderer.removeClass(this.document.body, "marchantPenal");
          localStorage.removeItem("Marchant");
          localStorage.removeItem("defultBusiness");
          this.router.navigate(["/"]);
        }
      }
    } else {
      this.router.navigate(["/"]);
    }
  }

  signUpShow(type, phn, from, index) {
    this.isSignupLoaded = true;
    clearInterval(this.signuppopupintervalId); // Clear the interval
    this.dataSharingService.setsignuppopupVariable(this.signuppopupintervalId);
    setTimeout(() => {
      this.signupModel.openModal(type, phn, from, index);
    }, 150);
  }

  async checkMerchant(type) {
    let data = {};
    this.admin.getData(apiUrl._checkStep, data).subscribe(
      async (res) => {
        this.alldata = res.data;
        this.switchToMerchant = res.data?.status;
        if (type === "2") {
          if (this.merchant === "1") {
            this.merchant = "0";
            localStorage.removeItem("defultBusiness");
            localStorage.removeItem("currentBusiness");
            this.renderer.removeClass(this.document.body, "marchantPenal");
            localStorage.removeItem("Marchant");
            this.dataSharingService.isHeaderChanged.next(true);
            this.router.navigate(["/"]);
            this.searchText = "";
          } else {
            if (this.switchToMerchant === 0) {
              this.admin.submitProfile();
              return false;
            }
            if (this.switchToMerchant === 2) {
              localStorage.setItem("defultBusiness", btoa(res.data?.id));
              this.router.navigate(["become-merchant"]);
              return false;
            }
            if (
              (this.switchToMerchant === 1 || this.switchToMerchant === 4) &&
              this.alldata.isClaimed === 1
            ) {
              // this.admin.firebaseSignIn();
              localStorage.setItem("defultBusiness", btoa(res.data?.id));
              localStorage.setItem("currentBusiness", btoa(res.data?.id));
              let planData;
              planData = await this.admin.getSubscriptionPlan("2");
              if (planData) {
                this.currentPlanSetting =
                  planData?.subscription_plan_list?.subscription_list;
                localStorage.setItem(
                  "userPer",
                  JSON.stringify(this.currentPlanSetting)
                );
              }
              this.renderer.addClass(this.document.body, "marchantPenal");
              localStorage.setItem("Marchant", "1");
              this.merchant = "1";
              this.router.navigate(["/dashboard"]);
              this.dataSharingService.isHeaderChanged.next(true);
            } else {
              if (res.data?.id) {
                localStorage.setItem("defultBusiness", btoa(res.data?.id));
              } else {
                localStorage.removeItem("defultBusiness");
              }
              this.router.navigate(["become-merchant"]);
            }
          }
        }
        if (this.switchToMerchant === 1 && this.alldata.isClaimed === 1) {
          const userData = localStorage.getItem("customerDetail")
            ? localStorage.getItem("customerDetail")
            : "";
          if (userData) {
            let userInfo = userData;
            let businessInfo = JSON.parse(userInfo);
            if (businessInfo && !businessInfo.usersBusiness) {
              businessInfo.usersBusiness = res.data;
              localStorage.setItem(
                "customerDetail",
                JSON.stringify(businessInfo)
              );
            }
          }
        }
      },
      (err) => {
        console.log(err, "err");
      }
    );
  }
  pastSearches() {
    this.admin.showLoader = false;
    this.admin.loader(false);
    this.showDrop = true;
    this.focusElement = -1;
    if (this.loginshow && this.searchText == "") {
      const userData = localStorage.getItem("customerDetail")
        ? localStorage.getItem("customerDetail")
        : "";
      let userInfo = userData ? userData : "";
      this.userInfo = JSON.parse(userInfo);
      let data = {
        userId: this.userInfo !== undefined ? this.userInfo.id : "",
      };
      this.admin.getData(apiUrl._searchRecent, data).subscribe(
        async (res) => {
          this.pastSearchDataList = res.data;
          this.searchDataList = res.data;
          this.searchDataList.forEach((x) => {
            switch (x.type) {
              case 60:
                x.id = x.category;
                break;
              case 50:
                x.id = x.subCategory;
                break;
              case 40:
                x.id = x.subCategoryLevel2;
                break;
              case 30:
                x.id = x.business;
                break;
              case 20:
                x.id = x.service;
                break;
              case 10:
                x.id = x.tag;
                break;
            }
          });
          if (this.searchDataList?.length > 10) {
            this.filterPastData = res.data.slice(0, 10);
          } else {
            this.filterPastData = res.data;
          }
          this.pastData = true;
          this.checkCategory();
        },
        (err) => {
          console.log(err, "err");
        }
      );
    }
  }

  onSearchChangeAfterType(event, value) {
    if (event && event.keyCode && event.keyCode > 36 && event.keyCode < 41) {
    } else {
      this.focusElement == -1;
      this.onSearchChange(value);
    }
  }

  onSearchChange(value) {
    this.admin.showLoader = false;
    this.admin.loader(false);
    this.longitude = Number(localStorage.getItem("longitude"));
    this.latitude = Number(localStorage.getItem("latitude"));
    let data = {
      latitude: this.latitude,
      longitude: this.longitude,
      title: value,
      userId: this.userInfo !== undefined ? this.userInfo.id : "",
    };
    if (data.userId == "") {
      delete data.userId;
    }
    this.admin.getData(apiUrl._search, data).subscribe(
      async (res) => {
        this.searchDataList = res.data.rows;
        if (this.searchDataList?.length > 0) {
          this.showDrop = true;
        }
        if (this.searchDataList?.length > 10) {
          this.filterPastData = res.data.rows.slice(0, 10);
        } else {
          this.filterPastData = res.data.rows;
        }
        this.checkCategory();
      },
      (err) => {
        console.log(err, "err");
      }
    );
  }
  searchClicked(searchValue) {
    if (searchValue.type == 40 && this.userInfo?.id) {
      searchValue.fromSearch = 1;
    }
    if (searchValue.type == 30) {
      sessionStorage.removeItem("businessID");
      sessionStorage.setItem("businessID", btoa(searchValue.id));
      sessionStorage.setItem("leadFromAction", "1");
      sessionStorage.removeItem("prevURLS");
      sessionStorage.setItem(
        "prevURLS",
        JSON.stringify([
          { url: "/", name: "Home" },
          { url: "/category/sub-category/business/business-detail" },
        ])
      );
      sessionStorage.setItem("searchText", this.searchText);
      this.dataSharingService.isBusinessChanged.next(true);
      this.searchText = searchValue.title;
      setTimeout(() => {
        this.router.navigate([
          "/category/sub-category/business/business-detail",
        ]);
      }, 600);

      this.searchDataList = [];
    } else if (searchValue.type==60) {
      this.searchData = null;
      this.clearSearch();
      this.selectedCategoryId = searchValue.id;
      this.dataSharingService.setSelectedCategory(searchValue);
      this.router.navigate(["/category/sub-category"]);
    } else if (searchValue.type==50) {
      this.dataSharingService.clearSearch.next(true);
      this.dataSharingService.search.next(searchValue);
      //sessionStorage.setItem('selectedCategory', btoa(JSON.stringify({ id: searchValue.id, name: searchValue.name })));
      sessionStorage.setItem('selectedSubCategory', btoa(JSON.stringify({ id: searchValue.id, name: searchValue.name })));
      this.dataSharingService.setSelectedSubCategory(searchValue);
      this.router.navigate(['/category/sub-category-lvl']);
    }  else if (!!searchValue.type) {
      this.searchData = searchValue;
      this.searchText = searchValue.title;
      sessionStorage.setItem("searchText", this.searchText);
      this.dataSharingService.search.next(searchValue);
      this.searchDataList = [];
      this.router.navigate(["/category/sub-category/business"]);
    } else {
      this.searchText = searchValue.title;
      this.onEnterClick(searchValue.title);
    }
  }
  checkCategory() {
    if (!!this.searchDataList)
      this.searchDataList.forEach((x) => {
        switch (x.type) {
          case 60:
            x.category = "Category";
            break;
          case 50:
            x.category = "Category";
            break;
          case 40:
            x.category = "Category";
            break;
          case 30:
            x.category = "Business";
            break;
          case 20:
            x.category = "Category";
            break;
          case 10:
            x.category = "Category";
            break;
        }
      });
  }
  onEnterClick(searchValue) {
    this.focusElement = -1;
    this.longitude = Number(localStorage.getItem("longitude"));
    this.latitude = Number(localStorage.getItem("latitude"));
    let data = {
      latitude: this.latitude,
      longitude: this.longitude,
      title: searchValue,
      userId: this.userInfo !== undefined ? this.userInfo.id : "",
    };
    if (data.userId == "") {
      delete data.userId;
    }
    this.admin.showLoader = false;
    this.admin.loader(false);
    if (
      this.focusElement >= 0 &&
      this.searchDataList &&
      this.focusElement < this.searchDataList.length
    ) {
      let selectedItem = this.searchDataList[this.focusElement];
      this.searchClicked(selectedItem);
    } else {
      this.admin.getData(apiUrl._search, data).subscribe(async (res) => {
        if (res.data?.rows?.length > 0) {
          this.searchData = res.data.rows[0];
          if (
            res.data.rows[0].type != 30 &&
            this.searchData.title.toLowerCase() == searchValue.toLowerCase()
          ) {
            if (this.searchData.type==60) {             
              this.clearSearch();
              this.selectedCategoryId = this.searchData.id;
              this.dataSharingService.setSelectedCategory(this.searchData);
              this.router.navigate(["/category/sub-category"]);
            } else if (this.searchData.type==50) {
              this.dataSharingService.clearSearch.next(true);
              this.dataSharingService.search.next(this.searchData);
              //sessionStorage.setItem('selectedCategory', btoa(JSON.stringify({ id: searchValue.id, name: searchValue.name })));
              sessionStorage.setItem('selectedSubCategory', btoa(JSON.stringify({ id: this.searchData.id, name: this.searchData.name })));
              this.dataSharingService.setSelectedSubCategory(this.searchData);
              this.router.navigate(['/category/sub-category-lvl']);
            } else{
              this.searchText = this.searchData.title;
              sessionStorage.setItem("searchText", this.searchText);
              this.dataSharingService.search.next(this.searchData);
              this.searchDataList = [];
              this.router.navigate(["/category/sub-category/business"]);
            }
          } else {
            sessionStorage.setItem("searchText", searchValue);
            this.dataSharingService.search.next(searchValue);
            this.searchDataList = [];
            this.router.navigate(["/category/sub-category/business"]);
          }
        } else {
          sessionStorage.setItem("searchText", searchValue);
          this.dataSharingService.search.next(searchValue);
          this.searchDataList = [];
          this.router.navigate(["/category/sub-category/business"]);
        }
      });
    }
  }
  openSpeechModal(template: TemplateRef<any>, title) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      class: "filterPopup modal-dialog-centered",
    });
  }

  catchSpeechText(e) {
    if (e.speech) {
      this.onSearchChange(e.speech);
      setTimeout(() => {
        this.searchText = e.speech;
        this.showDrop = true;
      }, 100);
      //this.onEnterClick(this.searchText)
    }
    this.modalRef.hide();
  }
  clearSearchText() {
    this.searchText = "";
    this.searchData = null;
    this.searchBox.nativeElement.focus();
    if (this.router.url === "/category/sub-category/business") {
      this.searchDataList = this.pastSearchDataList;
    }
  }
  async onRefreshClick() {
    let text =
      sessionStorage.getItem("searchText") != null
        ? sessionStorage.getItem("searchText")
        : "";
    const searchText = text ? text : "";
    if (searchText !== "") {
      this.searchText = searchText;
      const data: any = await new Promise((resolve) => {
        this.router.events.toPromise().then((res) => {
          resolve(res);
        });
      });
      if (data.url === "/category/sub-category/business") {
        this.onEnterClick(searchText);
      }
    }
  }
  setBreadCrumbs() {
    this.router.events.subscribe((value: any) => {
      const route = value;
      let data = sessionStorage.getItem("prevURLS");
      if (data) {
        let prevURLs = JSON.parse(data ? data : "");
        if (
          prevURLs.filter((x) => x.url == route.url).length == 0 &&
          !!route.url
        ) {
          prevURLs.push({ url: route.url });
          sessionStorage.setItem("prevURLS", JSON.stringify(prevURLs));
        } else if (
          prevURLs.filter((x) => x.url == route.url).length > 0 &&
          !!route.url
        ) {
          prevURLs.splice(
            prevURLs.map((e) => e.url).indexOf(route.url),
            prevURLs.length
          );
          prevURLs.push({ url: route.url });
          sessionStorage.setItem("prevURLS", JSON.stringify(prevURLs));
        } else if (route.url == "/") {
          sessionStorage.setItem("prevURLS", "");
          sessionStorage.setItem("prevURLS", JSON.stringify([{ url: "/" }]));
          this.selectedCategoryId = "";
        }
      } else {
        sessionStorage.setItem("prevURLS", JSON.stringify([{ url: "/" }]));
        this.selectedCategoryId = "";
      }
      if (route.url === "/") {
        sessionStorage.setItem("prevURLS", "");
        sessionStorage.setItem("searchText", "");
        sessionStorage.setItem("prevURLS", JSON.stringify([{ url: "/" }]));
        this.searchText = "";
        this.selectedCategoryId = "";
      }
    });
  }

  getList(isRead) {
    this.admin.loader(false);
    let data: any = {
      limit: 4,
      skip: 0,
    };
    if (!isRead) {
      data.doNotMarkRead = 1;
    }
    if (this.merchant === "1") {
      let businessId = this.admin.getBusinessId();
      data["businessId"] = businessId;
    }
    this.admin.getData(apiUrl._getNotification, data).subscribe(
      async (res) => {
        this.notificationData = res.data.data;
        this.unreadCount = res.data?.unreadNotificationsCount;
        this.notifyFlag = this.unreadCount;
        this.notificationData.rows.forEach((x) => {
          x.date = this.calculateDiff(x.createdAt);
        });
      },
      (err) => {
        console.log(err, "err");
      }
    );
  }
  pinNotification(id, pin, index) {
    let data = {
      isPinned: pin === 1 ? 0 : 1,
    };
    this.admin.putData(apiUrl._getNotification + "/" + id, data).subscribe(
      async (res) => {
        this.notificationData.rows[index].isPinned = pin === 1 ? 0 : 1;
      },
      (err) => {
        console.log(err, "err");
      }
    );
  }

  handleLocationAccess() {
    this.setCurrentLocation("0");
    if (localStorage.getItem("city")) {
      setTimeout(() => {
        this.locationTemplate.close();
      }, 7000);     
    }
  }
  private addEvent(obj: any, evt: string, fn: EventListener): void {
    if (obj.addEventListener) {
      obj.addEventListener(evt, fn, false);
    } else if (obj.attachEvent) {
      obj.attachEvent("on" + evt, fn);
    }
  }

  private onMouseOut(evt: Event): void {
    const mouseEvent = evt as MouseEvent;
    if (!mouseEvent.relatedTarget) {
      this.showLightbox();
    }
  }

  private showLightbox(): void {
    this.dataSharingService.regpopupVariable.subscribe(value => {
   // if (!this.ExitIntent && !value && !this.signuppopupTemplate.isOpen && !this.isSignupLoaded && !localStorage.getItem("customerDetail")  && this.mapCity) {  
  if (!this.locationTemplate.isOpen && !this.locationTemplate.manually && !this.ExitIntent && !value && !this.signuppopupTemplate.isOpen && !this.isSignupLoaded && !localStorage.getItem("customerDetail")  && this.mapCity) { 
      this.ExitIntent=true;  
      this.signuppopupTemplate.ExitIntentPopupopen(2);
    }
  })
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
       document.getElementById('headerElement');
    this.addEvent(document, 'mouseout', (evt: Event) => this.onMouseOut(evt));
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url == "/signup" || this.router.url == "/user_login") {

        } else {
          if(!localStorage.getItem("customerDetail")){
           //window.addEventListener('scroll', this.handleScroll.bind(this));
           this.startIntervalsignuppopup(this.userInactivityTimeout); 
           window.addEventListener('mousemove', this.resetIntervalsignuppopup.bind(this));
           //window.addEventListener('scroll', this.resetIntervalsignuppopup.bind(this));
           window.addEventListener('click', this.resetIntervalsignuppopup.bind(this));
          
          }
          if (!this.mapCity) {
            this.locationTemplate.openModal();
            this.startInterval();
          }
        }
      }
    });
 }
  }
    //   ---------------------
  handleScroll() {    
    const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;    
    if (scrollTop > this.lastScrollTop) {
    if (scrollPercentage >= 80 && scrollPercentage <= 81) {
      if (this.openpopuptime==0 && !this.signuppopupTemplate.isOpen && !this.isSignupLoaded && !localStorage.getItem("customerDetail")  && this.mapCity) {      
       this.openpopuptime++
        this.signuppopupTemplate.openModal(1);
      }   
    }
  }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
   
  }
  startIntervalsignuppopup(timeout: number) {
    // Clear the previous timer
    clearTimeout(this.signuppopupintervalId);

    // Start a new timer for 2 mints
    this.signuppopupintervalId = setTimeout(() => {
      this.dataSharingService.regpopupVariable.subscribe(value => {
       if (!this.locationTemplate.isOpen && !this.locationTemplate.manually && !value && !this.isSignupLoaded && !this.signuppopupTemplate.isOpen && !localStorage.getItem("customerDetail") && this.mapCity) {  
     // if (!this.locationTemplate.isOpen && !value && !this.isSignupLoaded && !this.signuppopupTemplate.isOpen && !localStorage.getItem("customerDetail") && this.mapCity) {               
        if (this.router.url == "/signup" || this.router.url == "/user_login") {

        } else {
          this.openpopuptime++
           this.signuppopupTemplate.ExitIntentPopupopen(1);
        }
      }
    });
    }, timeout);

    this.dataSharingService.setsignuppopupVariable(this.signuppopupintervalId);
  }
  resetIntervalsignuppopup() {
    this.startIntervalsignuppopup(this.userExtendedInactivityTimeout);
  }
  startInterval() {
    this.intervalId = setInterval(() => {
      if (!localStorage.getItem("city")) {
        if (this.locationTemplate.isOpen) {
          this.locationTemplate.close();
        }
        
        if (!this.locationTemplate.isOpen) {
          this.locationTemplate.openModal();
        }
      }
    }, 12000);
    this.dataSharingService.setHeaderVariable(this.intervalId);
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  calculateDiff(value) {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 10)
        // less than 30 seconds ago will show as 'Just now'
        return "Just now";
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        Day: 86400,
        Hour: 3600,
        Min: 59,
        Sec: 1,
      };
      let counter;
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      if (seconds > 86400) {
        value = new Date(value);
        if (
          value.getFullYear() == yesterday.getFullYear() &&
          value.getMonth() == yesterday.getMonth() &&
          value.getDate() == yesterday.getDate()
        )
          return "Yesterday";
        else {
          return new DatePipe("en-US").transform(value, "dd MMM,yyyy");
        }
      } else {
        for (const i in intervals) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0)
            if (counter === 1) {
              return counter + " " + i + " ago"; // singular (1 day ago)
            } else {
              return counter + " " + i + "s ago"; // plural (2 days ago)
            }
        }
      }
    }
    return value;
  }

  openLocation() {
    this.locationPopup.openModal();
    // this.isSignupLoaded = true;
    // sessionStorage.setItem("setLocation", "1");
    // setTimeout(() => {
    //     this.signupModel.openModal("3", null, "maps", "");
    // }, 100);
  }
  delect(id) {
    swal
      .fire({
        icon: "warning",
        text: "Are you sure you want to delete ?",
        showCancelButton: false,
        confirmButtonColor: "#14141f",
        cancelButtonColor: "#939393",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          let data = {};
          this.admin
            .deleteData(apiUrl._getNotification + "/" + id, data)
            .subscribe(
              async (res) => {
                this.getList(false);
              },
              (err) => {
                console.log(err, "err");
              }
            );
        }
      });
  }

  goTochat() {
    this.clearSearch();
    this.admin.firebaseSignIn();
    this.router.navigate(["/chats"]);
  }
  goToNotification() {
    this.clearSearch();
    this.getList(true);
    this.router.navigate(["notification"]);
  }
  goToEditProfile(data) {
    this.clearSearch();
    switch (data.type) {
      case 7: {
        this.checkMerchant("2");
        // this.goToEdit();
        break;
      }
      case 14: {
        sessionStorage.removeItem("businessID");
        sessionStorage.setItem("businessID", btoa(data.objectId));
        this.dataSharingService.isBusinessChanged.next(true);
        this.router.navigate(
          ["category/sub-category/business/business-detail"],
          {
            replaceUrl: false,
            skipLocationChange: true,
          }
        );
        break;
      }
      case 13: {
        sessionStorage.removeItem("businessID");
        sessionStorage.setItem("businessID", btoa(data.objectId));
        this.dataSharingService.isBusinessChanged.next(true);
        this.router.navigate(
          ["category/sub-category/business/business-detail"],
          {
            replaceUrl: false,
            skipLocationChange: true,
          }
        );
        break;
      }
      case 15: {
        sessionStorage.removeItem("businessID");
        sessionStorage.setItem("businessID", btoa(data.objectId));
        this.dataSharingService.isBusinessChanged.next(true);
        this.router.navigate(
          ["category/sub-category/business/business-detail"],
          {
            replaceUrl: false,
            skipLocationChange: true,
          }
        );
        break;
      }
      case 16: {
        sessionStorage.setItem("offerID", btoa(data.objectId));
        this.router.navigate(
          ["/category/sub-category/business/offers/offers-details"],
          {
            replaceUrl: false,
            skipLocationChange: true,
          }
        );
        break;
      }
      case 12: {
        sessionStorage.removeItem("businessID");
        sessionStorage.setItem("businessID", btoa(data.objectId));
        this.dataSharingService.isBusinessChanged.next(true);
        this.router.navigate(
          ["category/sub-category/business/business-detail"],
          {
            replaceUrl: false,
            skipLocationChange: true,
          }
        );
        break;
      }
      case 22: {
        sessionStorage.setItem("plansBilling", "1");
        this.router.navigate(["merchant-profile"]);
        break;
      }
      case 21: {
        let userInfo;
        const userData = localStorage.getItem("customerDetail")
          ? localStorage.getItem("customerDetail")
          : "";
        if (userData) {
          userInfo = JSON.parse(userData);
        }
        if (userInfo.usersBusiness?.id) {
          if (
            userInfo.usersBusiness?.status === 1 &&
            userInfo.usersBusiness?.isClaimed === 1
          ) {
            this.renderer.addClass(document.body, "marchantPenal");
            localStorage.setItem("Marchant", "1");
            localStorage.setItem(
              "defultBusiness",
              btoa(userInfo.usersBusiness?.id)
            );
            this.dataSharingService.isLoginChanged.next(true);
            sessionStorage.setItem("advID", data.objectId);
            this.router.navigate(["/advertisement/edit"]);
          }
        }
        break;
      }
      // case 17:{
      //   let dataModal={
      //     businessId:'',
      //     from:data.notificationFor==1?'customer':'merchant',
      //     leadId:'data'
      //   }
      //   this.feedbackModal.openModal(dataModal);
      // }
    }
  }
  getClass(status, isPinned, isNew, earlier, notificationFor) {
    let className: string = "";
    if (notificationFor == 2) {
      className = "highlit ";
    }
    if (status === 0) {
      if (isPinned === 1) {
        className = className + "unread isPinned";
        return className;
      } else if (isNew === 1) {
        className = className + "unread isNew";
        return className;
      } else if (earlier === 1) {
        className = className + "unread earlier";
        return className;
      } else {
        className = className + "unread";
        return className;
      }
    } else {
      if (isPinned === 1) {
        className = className + "isPinned";
        return className;
      } else if (isNew === 1) {
        className = className + "isNew";
        return className;
      } else if (earlier === 1) {
        className = className + "earlier";
        return className;
      } else {
        className = className + "read";
        return className;
      }
    }
  }
  notificationClick() {
    this.notifyFlag = +1;
    if (this.notifyFlag > 2) {
      this.notifyFlag = 1;
    }
    if ((this.notifyFlag = 2)) {
      this.admin.showLoader = false;
      this.getList(true);
    }
  }

  onBlur() {
    this.admin.showLoader = false;
    this.getList(true);
  }
  clearSearch() {
    sessionStorage.setItem("searchText", "");
    this.searchText = "";
    this.selectedcateId = "";
    this.searchText = "";
  }

  contactUsModel(template: TemplateRef<any>, type) {
    let addClasses;
    if (type === 1) {
      addClasses = "right modal-log-sign";
    } else {
      this.getReferralCode();
      addClasses = "right modal-log-sign refer-friend";
    }
    this.modalRef = this.modalService.show(template, {
      animated: false,
      backdrop: "static",
      class: addClasses,
    });
  }
  close() {
    this.modalRef.setClass("right modal-log-sign out");
    setTimeout(() => {
      this.modalRef.hide();
    }, 100);
  }

  getReferralCode() {
    let data = {};
    this.admin.getData(apiUrl._getReferralCode, data).subscribe(async (res) => {
      this.referralCode = res.data?.referralCode;
      this.activityPoints = res.data?.activityPoints;
    });
  }
  copyInputMessage(inputElement) {
    if (this.referralCode) {
      inputElement.select();
      this.document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.toastrService.success("Referral Code Copied", "");
      this.close();
    }
  }

  getJobDialogs(user_id: string) {
    this.db
      .list(`${this.FbChats}`, (query) =>
        query.orderByChild("user_id").equalTo(user_id).limitToLast(1000)
      )
      .valueChanges()
      .subscribe((listNew) => {
        let dialogListing: any = [];
        dialogListing = Object.assign([], listNew);
        if (dialogListing.length > 0) {
          let i = 0;
          let filterData = dialogListing.filter((res) => {
            let id = res.user_id;
            if (res.unread_count) {
              if (res.unread_count[id] > 0) {
                i++;
              }
            }
          });
          this.msgUnreadCount = i;
        }
      });
  }
  async switchBusiness() {
    let data = await this.admin.getSubscriptionPlan("1");
    localStorage.removeItem("defultBusiness");
    this.router.navigate(["become-merchant"]);
  }
  async getAllCategories() {
    const data: any = await this.admin.getCategories();
    this.dataSharingService.allCategory.next(data);
    if (data.data.length > 11) {
      this.allCategory = data.data.slice(0, 10);
    } else {
      this.allCategory = data.data;
    }
    this.selectCategory();
  }
  categoryClick(category) {
    this.searchData = null;
    this.clearSearch();
    this.selectedCategoryId = category.id;
    this.dataSharingService.setSelectedCategory(category);
    this.router.navigate(["/category/sub-category"]);
  }
  async getPointsData() {
    this.admin.loader(false);
    let data = await this.admin.getUserLevelDetails();
    this.earnPoints = data.earnPoints;
  }
  async getUpdatePoints() {
    this.admin.loader(false);
    let data = await this.admin.getUserLevelInfo();
    this.earnPoints = data.earnPoints;
  }
  async getSubscriptionPlan() {
    let planData;
    planData = await this.admin.getSubscriptionPlan("2");
    if (planData) {
      this.currentPlanSetting =
        planData?.subscription_plan_list?.subscription_list;
      localStorage.setItem("userPer", JSON.stringify(this.currentPlanSetting));
    }
  }
  redirectToSubs() {
    this.dataSharingService.profileTab.next("subscription-plans");
    this.router.navigate(["/merchant-profile"]);
  }
  showSideBar() {
    document.querySelector("body")?.classList.toggle("sidenav-toggled");
  }
  selectCategory() {
    this.subscription = this.dataSharingService.selectedCategory.subscribe(
      (value) => {
        if (value) {
          this.selectedCategoryId = value.id;
          this.selectedcateId = value.id;
          if (this.searchText != value.name) {
            // this.selectedcateId="";
            // this.searchText = '';
            // sessionStorage.setItem('searchText', '');
          }
        }
      }
    );
  }

  onLocationFocus() {
    //this.focusElement = 0;
  }
  onLocationBlur() {
    setTimeout(() => {
      this.showDrop = false;
    }, 1000);

    this.focusElement = -1;
  }

  onArrowUp(e) {
    if (this.focusElement > 0) {
      this.focusElement--;
      this.searchText = this.searchDataList[this.focusElement].title;
    } else {
      this.focusElement = -1;
      this.searchText = this.typedText;
    }
  }

  onArrowDown(e) {
    if (this.focusElement == -1) {
      this.typedText = this.searchText;
    }
    if (this.focusElement <= this.filterPastData.length - 2) {
      this.focusElement++;
      this.searchText = this.searchDataList[this.focusElement].title;
    }
  }
  goToSettingPage(pageUrl: string) {
    let bID = localStorage.getItem("currentBusiness")
      ? localStorage.getItem("currentBusiness")
      : "";
    let businessID = bID ? atob(bID) : this.admin.getBusinessId();
    localStorage.setItem("defultBusiness", btoa(businessID));
    if (pageUrl === "setting") {
      this.router.navigate(["/setting"]);
    } else if (pageUrl === "profile") {
      this.router.navigate(["/merchant-profile"]);
    } else if (pageUrl === "plan") {
      this.router.navigate(["/subscription-plan"]);
    } else if (pageUrl === "help") {
      this.router.navigate(["/query-management"]);
    }
    this.clearSearch();
  }

  sendLocation() {
    this.sendLocationData = {
      latitude: this.latitude,
      longitude: this.longitude,
      draggable: true,
      zoom: 10,
    };
  }
  pinChange(data) {
    if (data) {
      this.setAddressLocal(data);
      this.dataSharingService.locationChange.next({
        setLocation: false,
        address: data,
      });
    }
  }
}
