<ng-template #signup>
    <div class="modal-header">
        <div *ngIf="showPopup !=='setPwd'">
            <img *ngIf="showPopup !== 'manualLocation'" class="popupLogo" src="../../../assets/images/img_logo.png"
                alt="">
        </div>
        <button [ngClass]="showPopup==='setPwd' ? 'setPwd':''" *ngIf="showPopup !== 'manualLocation'" type="button"
            class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="h-100" *ngIf="showPopup==='signup'">
            <form [formGroup]="signupForm" class="d-flex flex-column h-100" (ngSubmit)="signupFun()">
                <h4>Create Account</h4>
                <p>Already have an account? <a href="javascript:;" (click)="loginPopup()">Log in</a>
                </p>
                <div class="form-group">
                    <span>
                        <label>Enter Full Name</label>
                    </span>
                    <input (paste)="(false)" autocomplete="off" appAutoFocusDirective type="text" id="demo"
                        name="fullName" class="form-control" placeholder="" formControlName="fullName" maxlength="30"
                        style="text-transform: capitalize;" value="">
                        <!-- *ngIf="signupForm.controls['fullName'].hasError('required') && showError" -->
                    <!-- <div class="invlid_error" >
                        *Please enter full name.
                    </div>

                    <div class="invlid_error"
                        *ngIf="signupForm.controls['fullName'].hasError('cannotContainSpace') && showError">*Please
                        enter full name.</div>

                    <div class="invlid_error" *ngIf="!showError&&signupForm.controls['fullName'].errors?.minlength">
                        *Full name should be minimum 2 and maximum of 30 characters.</div> -->
                </div>
                <div class="form-group">
                    <span>
                        <label>Phone Number<span class="asteriskIcon">*</span></label>
                    </span>
                    <div class="phoneNo">
                        <div class="contryCode">+91</div>
                        <input (paste)="(false)" autocomplete="off" type="tel" class="form-control" id="tel"
                            placeholder="" formControlName="phoneNumber" maxlength="10"
                            (keypress)="onlyNumberKey($event)">
                    </div>
                    <div class="invlid_error"
                        *ngIf="signupForm.controls['phoneNumber'].hasError('required') && showError">
                        *Please enter phone number.</div>

                    <div class="invlid_error"
                        *ngIf="signupForm.controls['phoneNumber'].hasError('cannotContainSpace') && showError">
                        *Please
                        enter phone number.</div>
                    <div class="invlid_error" *ngIf="!showError&&signupForm.controls['phoneNumber'].errors?.minlength">
                        *Please enter valid phone number.</div>

                </div>
                <div class="form-group">
                    <span>
                        <label>Password</label>
                    </span>
                    <input [type]="show ? 'password' : 'text'" class="form-control" id="password" placeholder=""
                        formControlName="password" value="">
                    <a href="javascript:;" class="eye_close" [ngClass]="show ? '' : 'show'" (click)="password()"></a>
                    <!-- <div class="invlid_error" *ngIf="signupForm.controls['password'].hasError('required') && showError">
                        *Please enter password.
                    </div>
                    <div class="invlid_error" *ngIf="signupForm.controls['password'].hasError('pattern') && showError">
                        *Password must be at least 6 characters with 1 uppercase, 1 special character & 1 numeric
                        character.
                    </div> -->
                </div>
                <div class="form-group">
                    <span>
                        <!-- <span class="asteriskIcon">*</span> -->
                        <label>Confirm Password</label>
                    </span>
                    <a href="javascript:;" class="eye_close" [ngClass]="confrmPassword ? '' : 'show'"
                        (click)="confrmPassword =! confrmPassword"></a>
                    <input (paste)="(false)" autocomplete="off" [type]="confrmPassword ? 'password' : 'text'"
                        class="form-control" id="confirm-password" placeholder="" formControlName="confirmPassword">
                    <!-- <div class="invlid_error"
                        *ngIf="signupForm.controls['confirmPassword'].hasError('required') && showError">
                        *Please enter confirm password.
                    </div>
                    <div class="invlid_error"
                        *ngIf="signupForm.controls['confirmPassword'].hasError('misMatch') && showError">
                        *Confirm password must be same as password.
                    </div> -->
                </div>
                <div class="form-group">
                    <span>
                        <label>Referral Code</label>
                    </span>
                    
                    <!-- <img class="eye_close" src="../../../assets/images/verified.png" alt=""> -->
                    <input style="text-transform: uppercase;" type="text" class="form-control"
                        formControlName="referralCode">
                </div>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheckBox1"
                        formControlName="acceptTerms">
                    <label class="custom-control-label" for="customCheckBox1">I agree to the <a class="pointer"
                            (click)="gotoTermsPage('terms')">Terms of
                            Service</a> and <a class="pointer" (click)="gotoTermsPage('Privacy')">Privacy Policy</a>
                    </label>
                    <div class="invlid_error"
                        *ngIf="signupForm.controls['acceptTerms'].hasError('required') && showError">
                        *Please agree terms of service and privacy policy.
                    </div>
                </div>
                <div class="bottom">
                    <button class="btn-main">Continue</button>
                    <a class="need_help" href="javascript:;">Need help?</a>
                </div>
            </form>
        </div>
        <div class="verificationWrap d-flex flex-column" *ngIf="showPopup==='verification'">
            <h4>{{reqType==="4" ? "Verify number to reset password" :"Verification Code"}}</h4>
            <p>We have sent the code of verification to your mobile number
                <a>{{phoneNo}}
                    <img *ngIf="reqType!=='4'" (click)="backTo()" style="cursor: pointer;"
                        src="../../../assets/images/edit.png" alt="">
                </a>
            </p>
            <form [formGroup]="otpForm" class="d-flex flex-column">
                <div class="d-flex flex-row mt-5 otp_flds">
                    <input (paste)="(false)" autocomplete="off" id="otpFocus" appAutoFocusDirective type="tel"
                        class="form-control" formControlName="digit1" #input1 maxlength="1" autofocus=""
                        (input)="onInputEntry($event, input2 )" (keydown)="onKeyDown($event, input1)" />

                    <input (paste)="(false)" autocomplete="off" type="tel" class="form-control" formControlName="digit2"
                        #input2 maxlength="1" (input)="onInputEntry($event, input3)"
                        (keydown)="onKeyDown($event, input2)" />

                    <input (paste)="(false)" autocomplete="off" type="tel" class="form-control" formControlName="digit3"
                        #input3 maxlength="1" (input)="onInputEntry($event, input4)"
                        (keydown)="onKeyDown($event, input3)" />

                    <input (paste)="(false)" autocomplete="off" type="tel" class="form-control" formControlName="digit4"
                        #input4 maxlength="1" (input)="onInputEntry($event, input4)"
                        (keydown)="onKeyDown($event, input4)" />
                </div>
                <div class="invlid_error"
                    *ngIf="otpForm.controls['digit1'].hasError('required') && showError || otpForm.controls['digit2'].hasError('required') && showError || otpForm.controls['digit3'].hasError('required')  && showError || otpForm.controls['digit4'].hasError('required')  && showError">
                    *Please enter vaild OTP.
                </div>

                <div class="middle">
                    <p *ngIf="maxTime >0" class="send_otp clr_blue mt-5">Didn't get an OTP?
                        <span>00:{{maxTime}}</span>
                    </p>

                    <button *ngIf="maxTime < 1" class="resendBtn" (click)="resendOtp('otpFocus')"
                        class=" btn_brdr resend_otp">Resend OTP</button>

                </div>
               
                <button class="btn-main bottom" (click)="verifyOtp(updateProfile, reqType)">Continue</button>
            </form>
        </div>
        <div class="locationWrap d-flex flex-column" *ngIf="showPopup === 'location'">
            <div class="top">
                <div class="" style="background: #fff; margin: 60px 0 40px 0;">
                    <img src="../../../assets/images/location.png">
                </div>
                <h4>Enable Location</h4>
                <p>We need to know your location in order to suggest nearby services.</p>
            </div>
            <div class="middle">

            </div>
            <div class="bottom">
                <button class="btn-main" (click)="location(updateProfile)">Fetch Current Location</button>
                <button class="btn-main borderButton" (click)="gotoManually(updateProfile)">Enter Your Location
                    Manually</button>
            </div>
        </div>
        <div class="manualLocationWrap d-flex flex-column h-100" *ngIf="showPopup === 'manualLocation'">
            <a href="javascript:;" class="backBtn"> <img (click)="showPopup='location'"
                    src="../../../assets/images/ic_back.svg" alt=""></a>
            <app-address-location (childEvent)="manualAddress($event)"></app-address-location>
        </div>

        <div class="setPwdWrap d-flex flex-column h-100" *ngIf="showPopup==='setPwd'">
            <a href="javascript:;" class="backBtn" (click)="backTo()"> <img src="../../../assets/images/ic_back.svg"
                    alt=""></a>
            <h4>Set new password</h4>
            <p>Please reset your new password below.</p>
            <form [formGroup]="setPwdForm" class="d-flex flex-column h-100" (ngSubmit)="setPassword()">
                <div class="form-group">
                    <span>
                        <label>Password</label>
                    </span>
                    <input [type]="show ? 'password' : 'text'" class="form-control" id="password" placeholder=""
                        formControlName="newPassword">
                    <a href="javascript:;" class="eye_close" [ngClass]="show ? '' : 'show'" (click)="password()"></a>
                    <div class="invlid_error"
                        *ngIf="setPwdForm.controls['newPassword'].hasError('required') && showError">
                        *Please enter password.
                    </div>
                    <div class="invlid_error"
                        *ngIf="setPwdForm.controls['newPassword'].hasError('pattern') && showError">
                        *Password must be at least 6 characters with 1 uppercase, 1 special character & 1 numeric
                        character.
                    </div>
                </div>
                <div class="form-group">
                    <span>
                        <label>Confirm password </label>
                    </span>
                    <a href="javascript:;" class="eye_close" [ngClass]="confrmPassword ? '' : 'show'"
                        (click)="confrmPassword =! confrmPassword"></a>
                    <input [type]="confrmPassword ? 'password' : 'text'" class="form-control" id="confirm-password"
                        placeholder="" formControlName="confirmPassword">
                    <div class="invlid_error"
                        *ngIf="setPwdForm.controls['confirmPassword'].hasError('required') && showError">
                        *Please enter confirm password.
                    </div>
                    <div class="invlid_error"
                        *ngIf="setPwdForm.controls['confirmPassword'].hasError('misMatch') && showError">
                        *Confirm password must be same as password.
                    </div>
                </div>

                <button class="btn-main bottom">Done</button>
            </form>
        </div>
    </div>

</ng-template>
<ng-template #updateProfile>
    <div class="modal-body">
        <img class="upgraded_img" src="../../../assets/images/level.png" alt="">
        <h4>Upgrade Your Level</h4>
        <p>Complete your profile and get upgraded to elite level</p>
        <!-- <p class="big_txt">Claim this business and grow<br> your revenue</p> -->
        <a class="btn-main pointer" (click)="editProfile()">Update Profile Now</a>
        <a class="btn_brdr pointer" (click)="closePopup()">Remind me Later</a>
    </div>
</ng-template>
<ng-container>
    <app-login #login></app-login>
</ng-container>