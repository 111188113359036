import { Component, OnInit, Renderer2 } from '@angular/core';
import { apiUrl } from "../../../global/global";
import { AdminService } from "../../../services/admin.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DataSharingService } from "../../../services/data.share.service";

import swal from "sweetalert2";

@Component({
  selector: 'app-user-business-list',
  templateUrl: './user-business-list.component.html',
  styleUrls: ['./user-business-list.component.css']
})
export class UserBusinessListComponent implements OnInit {
  businessList: any = [];
  businessID: any;
  count = 1;
  constructor(private admin: AdminService, public router: Router, private renderer: Renderer2, private dataSharingService: DataSharingService,) {
    this.dataSharingService.businessUpdate.subscribe(value => {
      if (value) {
        this.businessID = ""
        this.getBusinessList("2");
      }
    });
  }
  ngOnInit(): void {
    this.getBusinessList("1");
  }
  getBusinessList(flag) {
    this.admin.loaderStop();
    let data = {}
    this.admin.getData(apiUrl._userBusinessesListing, data).subscribe(async (res) => {
      this.businessList = res.data.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
      this.dataSharingService.currentBusiness.next(this.businessList.find(x => x.id == this.admin.getBusinessId()))
      this.businessID = this.admin.getBusinessId();
      if (flag === "2") {
        let currentUrl = this.router.url;
        if (currentUrl == "/dashboard") {
          this.reloadCurrentRoute();
        }
      }
    })
  }

  setBusiness() {
    this.dataSharingService.currentBusiness.next(this.businessList.find(x => x.id == this.businessID))
    let data = {
      "businessId": this.businessID,
      "withoutModels": 1
    }
    this.admin.getData(apiUrl._getBusiness, data).subscribe(async (res) => {
      if (res.data?.status === 0) {
        this.businessID = this.admin.getBusinessId();
        this.admin.submitProfile();
        return false
      }
      if (res.data?.isBlocked == 1) {
        this.businessID = this.admin.getBusinessId();
        swal
          .fire({
            icon: "warning",
            text: "You are blocked by the admin.",
            showCancelButton: false,
            confirmButtonColor: "#14141f",
            cancelButtonColor: "#939393",
            confirmButtonText: "Ok",
          });
        return;
      }
      if (res.data?.status === 1 && res.data.isClaimed === 1) {
        localStorage.setItem("defultBusiness", btoa(this.businessID));
        localStorage.setItem("currentBusiness", btoa(this.businessID));
        this.renderer.addClass(document.body, 'marchantPenal');
        this.dataSharingService.isHeaderChanged.next(true);
        let businessId = await this.admin.getSubscriptionPlan("2");
        localStorage.setItem("Marchant", "1");
        let currentUrl = this.router.url;
        if (currentUrl === "/dashboard") {
          this.reloadCurrentRoute();
        } else {
          this.router.navigate(['dashboard']);
        }
      } else {
        localStorage.setItem("defultBusiness", btoa(this.businessID));
        this.router.navigate(['become-merchant']);
      }
    });
  }
  getSubscriptionPlan() {
    let businessId = this.admin.getSubscriptionPlan("2");
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  checkMerchant() {
    let data = {}
    this.admin.getData(apiUrl._checkStep, data).subscribe(async (res) => {
    },
      err => {
        console.log(err, "err");
      });
  }

}
