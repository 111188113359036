import { Injectable } from '@angular/core';
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  private geoCoder;
  constructor() { }
  getPlacesList(latitude,longitude): any {
    return new Promise((resolve, reject) => {
      this.geoCoder = new google.maps.Geocoder;
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {         
          resolve(results[0]);       
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
    })
  }
  getFullAddress(placeResult, latitude, longitude){ 
    let userAddress = {
      "latitude": latitude,
      "longitude": longitude,           
      "formatted_address": placeResult.formatted_address,
      "address": placeResult.formatted_address
    }
    console.log("placeResult", userAddress)
    for (let i = 0; i < placeResult.address_components.length; i++) {
      let types = placeResult.address_components[i].types
      for (var j = 0; j < types.length; j++) {
        if (types.indexOf('administrative_area_level_1') != -1) {
          userAddress["state"] = placeResult.address_components[i].long_name ? (placeResult.address_components[i].long_name) : '';
        }
        if (types.indexOf('administrative_area_level_3') != -1) {
          userAddress["city"] = placeResult.address_components[i].long_name ? (placeResult.address_components[i].long_name) : '';
        }
        if (types.indexOf('country') != -1) {
          userAddress["country"] = placeResult.address_components[i].long_name
        }
        if (types.indexOf('postal_code') != -1) {
          userAddress["pincode"] = placeResult.address_components[i].long_name
        }
        if (types[0] == 'postal_code') {
          userAddress["pincode"] = placeResult.address_components[i].long_name
        }
      }
    }
    return userAddress
  }
}
