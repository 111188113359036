import { Component, OnInit, ViewChild, Renderer2,Input, OnDestroy, TemplateRef, NgZone, ElementRef, Inject } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DOCUMENT, isPlatformBrowser, DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { UsernameValidator } from "../../services/NoWhiteSpace.validator";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { passwordValidator } from 'src/app/services/passwordValidator';
import * as CONSTANT from "../../services/constants";
import { LoginComponent } from "../login/login.component";
import { ToastrService,ToastrModule, ToastrConfig } from "ngx-toastr";
import { DataSharingService } from "../../services/data.share.service";
import { MessagingService } from '../../services/messaging.service';
import { ChatService } from '../../services/chat.service';
import { Location } from '@angular/common';
import { LocationPopupComponent } from "../../extrnal/location-popup/location-popup.component";

declare var google: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() signuppopupintervalId: any;
  showPopup = "signup";
  showLogin = false;
  //showPopup = "manualLocation";
  false1 = false;
  signupForm: FormGroup;
  setPwdForm: FormGroup;
  otpForm: FormGroup;
  showError = false;
  show = true;
  confrmPassword = true;
  signupRespose: any;
  maxTime: any = 30;
  timer: any;
  isResend = false;
  phoneNo: any;
  webToken: any;
  reqType: any;
  updateProfileModel: any;
  address: string;
  @ViewChild("locationTemplate")
  private locationTemplate: LocationPopupComponent;
  @ViewChild('login') public loginModel: LoginComponent;
  @ViewChild('signup') private signupModel: TemplateRef<any>;
  @ViewChild('updateProfile') private updateProfile: TemplateRef<any>;  
  hidevalue = true;
  private geoCoder;
  fromPage: string;
  signUpSuccess = false;
  constructor(private setLocation: Location,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private router: Router, private chatService: ChatService, private modalService: BsModalService, private formBuilder: FormBuilder, private api: AdminService, private dataSharingService: DataSharingService,
    private messagingService: MessagingService,
    private toastrService: ToastrService, private ngZone: NgZone) {
    // this.signupForm = this.formBuilder.group({
    //   fullName: ["", Validators.compose([Validators.required, Validators.minLength(2), UsernameValidator.cannotContainSpace])],
    //   phoneNumber: ["", Validators.compose([Validators.required, Validators.minLength(10), UsernameValidator.cannotContainSpace])],
    //   password: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANT.PASSWORD_REGEX1)])],
    //   confirmPassword: ["", Validators.compose([Validators.required])],
    //   acceptTerms: ["", Validators.compose([Validators.required])],
    //   referralCode: [""],
    // }, { validator: passwordValidator("password", "confirmPassword") })

    this.signupForm = this.formBuilder.group({
      fullName: [""],
      phoneNumber: ["", Validators.compose([Validators.required, Validators.minLength(10), UsernameValidator.cannotContainSpace])],
      password: [""],
      confirmPassword: [""],
      acceptTerms: ["", Validators.compose([Validators.required])],
      referralCode: [""],
    })

    this.otpForm = this.formBuilder.group({
      digit1: ['', Validators.compose([Validators.required])],
      digit2: ['', Validators.compose([Validators.required])],
      digit3: ['', Validators.compose([Validators.required])],
      digit4: ['', Validators.compose([Validators.required])],
    });
    this.setPwdForm = this.formBuilder.group({
      forgotPasswordToken: [""],
      newPassword: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANT.PASSWORD_REGEX1)])],
      confirmPassword: ["", Validators.compose([Validators.required])],
    }, { validator: passwordValidator("newPassword", "confirmPassword") })
  }

  ngOnInit(): void {
   
    if (this.router.url == "/signup") {
     
      let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
      if (token) {
        this.router.navigate(["/"]);
        // localStorage.removeItem("defultBusiness");
        // localStorage.removeItem("currentBusiness")
        // localStorage.removeItem("Marchant");
        // localStorage.removeItem("forgotToken");
        // localStorage.removeItem("userPer");
        // localStorage.removeItem("merchantPrfoleType");
        // //localStorage.removeItem("customerDetail");
        // localStorage.removeItem("businessData");
        // localStorage.removeItem("business");
        // this.renderer.removeClass(this.document.body, 'marchantPenal');
        // this.renderer.removeClass(this.document.body, 'main_home');
      } else {
        this.showPopup = "signup";
        this.dataSharingService.signuppopupintervalIdshare.subscribe((data) => {
          this.signuppopupintervalId = data;
          
        });
        clearInterval(this.signuppopupintervalId);
        setTimeout(() => {
          this.openModal("1", "null", '', "");
        }, 100);
      }
    }
  }

  close() {
    if ((this.router.url == "/signup") || (this.router.url == "/user_login")) {
      this.router.navigate(["/"]);
    }    
    if(this.router.url=="/"){
      this.setLocation.replaceState("/")   
    }
    this.modalRef.hide();
    this.showPopup = "signup";
    this.maxTime = 30;
    this.isResend = false;
    sessionStorage.removeItem("setLocation")
  }
  ngOnDestroy() {
    //sessionStorage.removeItem("setLocation")
  }
  openModal(type, phn, from, index) {
    this.fromPage = from ? from : '';
    this.reqType = type;
    if (type === "2" || type === "4") {
      if ( this.router.url == "/user_login" && index==="5"){
        //this.loginModel.closePopup();
        //this.modalRef.hide();
      }
      this.otpForm.reset();
      this.maxTime = 30;
      this.StartTimer();
      this.phoneNo = phn;
      this.showPopup = 'verification';
    }
    if (type === "3") {
      this.showPopup = 'location';
    }
    if (type == "1") {     
      if((this.router.url=="/") || (this.router.url=="/signup" ) || (this.router.url=="/user_login" )){
        this.setLocation.replaceState("/signup")   
      }
      this.showPopup = "signup";
    }
   
    this.signupForm.reset();
    this.modalRef = this.modalService.show(this.signupModel, {
      animated: false,
      backdrop: 'static',
      class: "right modal-log-sign"
    });

  }
  signupFun() {
    if (this.signupForm.valid) {
      if (this.signupForm.value.referralCode === null) {
        this.signupForm.value["referralCode"] = "";
      }
      delete this.signupForm.value.confirmPassword
      delete this.signupForm.value.acceptTerms
      this.signupForm.value["countryCode"] = "+91"
      this.signupForm.value["deviceToken"] = this.getDeviceToken();
      this.signupForm.value["deviceType"] = "web"
      this.signupForm.value["fullName"] = this.signupForm.value["fullName"] ?? ""; //.trim()
      this.signupForm.value["password"] = this.signupForm.value["password"] ?? "";
      this.api.postData(apiUrl._signUp, this.signupForm.value)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.signupRespose = res.data;
            this.phoneNo = this.signupRespose?.user?.phoneNumber;
            // this.signupForm.reset();
            this.maxTime = 30
            this.showPopup = 'verification';
            this.showError = false;
            this.StartTimer();
          }
        }, err => {
          this.api.showErrorMsg(err.error.message);
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  backTo() {
    if (this.reqType === "2") {
      this.modalRef.hide();
      setTimeout(() => {
        this.loginModel.openModal(this.phoneNo);
      }, 100);
    }
    if (this.reqType === "4") {
      this.modalRef.hide();
      setTimeout(() => {
        this.loginModel.openModal("4");
      }, 100);
    }
    else {
      this.showPopup = 'signup';
    }
  }

  password() {
    this.show = !this.show
  }
  // ============ Otp Screen =============
  onInputEntry(event, nextInput) {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    let result = maskSeperator.test(event.data);
    if (result) {
      let input = event.target;
      let length = input.value.length;
      let maxLength = input.attributes.maxlength.value;
      if (length >= maxLength) {
        nextInput.focus();
      }
    }
    else {
      event.target.value = ""
    }
  }

  onKeyDown(event, input) {
    if (event.key === 'Backspace' && input.previousElementSibling) {
      event.target.value = ""
      event.preventDefault();
      input.previousElementSibling.focus();
    }
  }

  onlyNumberKey(event) {
    return this.api.allowOnlyNumbers(event);
  }

  StartTimer() {
    this.timer = setTimeout(x => {
      this.maxTime -= 1;
      if (this.maxTime == 0) {
        this.isResend = false;
      }
      if (this.maxTime < 10) {
        this.maxTime = "0" + this.maxTime
      }
      if (this.maxTime > 0) {
        this.hidevalue = false;
        this.StartTimer();
      }
      else {
        this.hidevalue = true;
      }
    }, 1000);
  }

  focusOtpInput(id) {
    document.getElementById(id)?.focus();
  }
  resendOtp(otpInput) {
    let data = {
      "countryCode": "+91",
      "phoneNumber": this.phoneNo
    }
    this.api.postData(apiUrl._resendOtp, data).subscribe(async (res) => {
      this.maxTime = 30;
      this.otpForm.reset();
      this.StartTimer();
      this.isResend = true;
      this.focusOtpInput(otpInput)
      localStorage.setItem("forgotToken", res.data);
      this.toastrService.success(
        "Resend OTP successfully",
        ""
      );
    },
      err => {
        console.log(err, "err");
        this.otpForm.reset();
      });
  }

  verifyOtp(popup, type) {
    this.signUpSuccess = false;
    if (this.otpForm.valid) {
      const otp = this.otpForm.value.digit1 + this.otpForm.value.digit2 + this.otpForm.value.digit3 + this.otpForm.value.digit4;
      let data;
      if (type === '4') {
        let token = localStorage.getItem("forgotToken");
        var phnNumber = this.phoneNo;
        data = {
          "otp": otp,
          "type": 2,
        }
        if (isNaN(phnNumber) == true) {
          data["forgotPasswordToken"] = token;
        }
        if (isNaN(phnNumber) == false) {
          data["countryCode"] = "+91";
          data["phoneNumber"] = this.phoneNo;
        }
      } else {
        data = {
          "otp": otp,
          "countryCode": "+91",
          "phoneNumber": this.phoneNo,
          "type": 1,
          "deviceType": "web",
          "deviceToken": this.getDeviceToken(),
        }
      }
      this.api.postData(apiUrl._verifyOtp, data).subscribe(async (res) => {
        if (res.statusCode == 200) {
          this.signUpSuccess = true;
          this.otpForm.reset();
          this.api.showMsg("Verified Successfully", "Your number has been verified successfully.")
          if (res.data?.user?.user_location === null) { 
            this.webToken = res.data.accessToken;
            this.location(this.updateProfile);
          } else {
            if (type === '4') {
              localStorage.setItem("otpToken", res.data);
              this.setPwdForm.reset();
              this.showPopup = 'setPwd';
            } else {
              localStorage.setItem("webToken", btoa(res.data.accessToken))
              let userinfo = JSON.stringify(res.data.user)
             
              if (res.data?.user?.user_location != null) {   
                localStorage.setItem("customerDetail", userinfo);
                localStorage.setItem("state",  JSON.parse(userinfo).user_location?.state);
                localStorage.setItem("city", JSON.parse(userinfo).user_location?.city);
                localStorage.setItem("latitude", JSON.parse(userinfo).user_location?.latitude.toString());
                localStorage.setItem("longitude", JSON.parse(userinfo).user_location?.longitude.toString());
              }else{
                this.webToken = res.data.accessToken;
                this.location(this.updateProfile);
              }
              if(localStorage.getItem("city") && localStorage.getItem("city")!=null){
                const toastConfig: Partial<ToastrConfig> = {
                toastClass: 'custom-toast', // Apply custom CSS class
                progressBar: true, // Add progress bar
                timeOut: 10000 ,// Set timeout for auto-dismissal
                enableHtml: true,
              };            
              
              var message = "Welcome Back! We are showing results based on your last used location " + JSON.parse(userinfo).user_location?.city + ". You can always update your location to see customised results.";
              this.toastrService.success('<span class="toast-icon"><i class="fa fa-check-circle"></i></span> ' + message, '', toastConfig);
                
            }
              this.close();            
              this.dataSharingService.isLoginChanged.next(true);
              // this.dataSharingService.editUser(true);
            }
          }
        }
      },
        err => {
          this.api.showErrorMsg(err.error.message);
          this.otpForm.reset();
        });
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 10000);
    }
  }
  // ============== Location =====================

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
      });
    }
  }

  location(popup) {    
    let latitude;
    let longitude;
  if(!localStorage.getItem("city")){
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        this.getLocation(latitude, longitude, popup)        // 
        // this.getAddress(this.latitude, this.longitude);
      });
    }
  }else{
    latitude = Number(localStorage.getItem("latitude"));
    longitude = Number(localStorage.getItem("longitude"));
    this.getLocation(latitude, longitude, popup)  
  }
  }

  getLocation(latitude: any, longitude: any,popup): void {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let locationdata = {
            "latitude": latitude.toString(),
            "longitude": longitude.toString(),
            "city": localStorage.getItem("city") 
        }
        console.log(locationdata);
        //const address = data.address.city;
        this.submitAddress(locationdata, popup)
      })
      .catch((error) => {
        console.error("Error performing reverse geocoding:", error);
      });
  }
  
  // getLocation(latitude, longitude, popup) {
  //   this.geoCoder = new google.maps.Geocoder;
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     if (status === 'OK') {
  //       let data = {
  //         "latitude": latitude.toString(),
  //         "longitude": longitude.toString(),
  //         "address": results[0].formatted_address
  //       }
  //       for (let i = 0; i < results[0].address_components.length; i++) {
  //         let types = results[0].address_components[i].types
  //         for (var j = 0; j < types.length; j++) {
  //           if (types.indexOf('administrative_area_level_1') != -1) {
  //             data["state"] = results[0].address_components[i].long_name ? (results[0].address_components[i].long_name) : '';
  //           }
  //           if (types.indexOf('administrative_area_level_3') != -1) {
  //             data["city"] = results[0].address_components[i].long_name ? (results[0].address_components[i].long_name) : '';
  //           }
  //           if (types.indexOf('country') != -1) {
  //             data["country"] = results[0].address_components[i].long_name
  //           }
  //           if (types.indexOf('postal_code') != -1) {
  //             data["pincode"] = results[0].address_components[i].long_name
  //           }
  //           if (types[0] == 'postal_code') {
  //             data["pincode"] = results[0].address_components[i].long_name
  //           }
  //         }
  //       }
  //       if (data) {
  //         this.submitAddress(data, popup)
  //       } else {
  //         this.toastrService.error('Address not found', '');
  //       }
  //     } else {
  //       console.log('Geocoder failed due to: ' + status);
  //     }
  //   })
  // }
  async submitAddress(data, popup) { 
    const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    this.dataSharingService.locationChange.next({ setLocation: false, address: data });
    if (!userData && !this.signUpSuccess) {
      this.close();
      this.signUpSuccess = false;
      return
    }
    if (this.fromPage == '') {
      localStorage.setItem("webToken", btoa(this.webToken))
    }
    let checkLocationType = sessionStorage.getItem("setLocation") ? sessionStorage.getItem("setLocation") : '';
    if (!checkLocationType) {
      this.api.postData(apiUrl._location, data).subscribe(async (res) => {
        await this.api.firebaseSignIn();
        let userinfo = JSON.stringify(res.data.user);
        localStorage.setItem("customerDetail", userinfo);
        this.dataSharingService.isLoginChanged.next(true);
        // this.dataSharingService.editUser(true);
        this.chatService.addUser(res.data.user.id)
        localStorage.removeItem("setLocation");
        this.close();
        if (this.fromPage == '') {
          this.openConfirmPopup(popup)
        }
      }, err => {
        this.api.showErrorMsg(err.error.message);
      })

    } else {
      this.close();
      sessionStorage.removeItem("setLocation")
    }

  }
  gotoManually(popup) {
    this.updateProfileModel = popup;
    this.showPopup = 'manualLocation';
  }
  manualAddress(address) {
    this.submitAddress(address, this.updateProfileModel)
    this.close();
  }

  loginPopup() {    
    this.modalRef.hide();
    // setTimeout(() => {
    //   this.router.navigate(['/user_login']);
    // }, 200);
    setTimeout(() => {      
      this.loginModel.openModal("1");     
    }, 100);

  }
  setPassword() {
    if (this.setPwdForm.valid) {
      let token = localStorage.getItem("otpToken");
      this.setPwdForm.value["forgotPasswordToken"] = token;
      delete this.setPwdForm.value.confirmPassword
      this.api.postData(apiUrl._setPwd, this.setPwdForm.value)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.api.showMsg("Password Updated", "Your password has been reset successfully")
            localStorage.removeItem("forgotToken");
            localStorage.removeItem("otpToken");
            this.setPwdForm.reset();
            this.modalRef.hide();
            setTimeout(() => {
              this.loginModel.openModal("1");
            }, 100);
          }
        }, err => {
          this.api.showErrorMsg(err.error.message);
        })
    } else {
      this.showError = true;
      setTimeout(() => { 
        this.showError = false;
      }, 5000);
    }
  }
  backToFrd() {
    this.modalRef.hide();
    this.loginModel.openModal("1");
  }
  openConfirmPopup(popup) {
    sessionStorage.setItem('timerCLaimPopUp', '7000');
    this.modalRef = this.modalService.show(popup, {
      animated: false,
      backdrop: 'static',
      class: "modal-dialog-centered borderRadius modal-r"
    });
    // setTimeout(() => {
    //   this.modalRef.hide();
    // }, 5000);
  }
  closePopup() {
    this.modalRef.hide();
  } 
  editProfile() {
    this.router.navigate(["/myfinndit/update-profile"]);
    this.closePopup(); 
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  gotoTermsPage(type: string) {
    this.close();
    if (type == "terms") {
      this.router.navigate([]).then(result => { window.open("/terms-use", '_blank'); });
    } else {
      this.router.navigate([]).then(result => { window.open("/privacy-policy", '_blank'); });
    }
  }
}