<h4>Select your location</h4>
<p>To avail location related services within app' please provide your location.</p>

<form class="d-flex flex-column h-100" [formGroup]="manualLocationForm"
    (ngSubmit)="submitManualLocation()">
    <div class="form-group">
        <span>
            <label>Enter Pin Code</label>
        </span>
        <input type="text" class="form-control" id="pin code" placeholder="" formControlName="pincode"
            (keypress)="onlyNumberKey($event)" (input)="getCity($event, 3)" maxlength="6">
        <div class="invlid_error" *ngIf="manualLocationForm.controls['pincode'].hasError('required')  && showError">
            *Please enter pincode.
        </div>
        <div class="invlid_error" *ngIf="!showError&&manualLocationForm.controls['pincode'].errors?.minlength">
            *Please enter valid pincode.</div>

        <div class="invlid_error"
            *ngIf="manualLocationForm.controls['pincode'].hasError('cannotContainSpace') && showError">
            *Please enter pincode.</div>
    </div>
    <div class="form-group group-cstm pr-input">
        <span>
            <label>Select your location</label>
        </span>       
        <input *ngIf="!disableForm" style="padding-right: 40px;"  (input)="getCity($event, 2)"  type="text" formControlName="city" class="form-control" placeholder="">
        <img *ngIf="!disableForm" class="eye_close" src="../../../assets/images/location.svg" alt="">
        <input (paste)="(false)" *ngIf="disableForm"  maxlength="500" uppercase class="form-control" formControlName="city" type="text" readonly>
        <div class="grlistDropDwon" *ngIf="showDropdwonCity">
            <ul class="autoComplete">
                <li *ngFor="let tags of cityData; let i = index">
                    <a class="pointer" (click)="selectRelevantCity(tags)">{{tags.place
                        ? (tags.place+",") :''}} {{tags.district
                        ? (tags.district) :''}} {{tags?.state ? ('('+tags?.state+')'):''}} </a>
                </li>
            </ul>
        </div>
        <p style="font-size: 14px; padding-top: 5px;">"If you are unable to select your location, please use auto fetch option".</p>
        <div class="invlid_error" *ngIf="manualLocationForm.controls['city'].hasError('required')  && showError">
            *Please enter your location.
        </div>

        <div class="invlid_error"
            *ngIf="manualLocationForm.controls['city'].hasError('cannotContainSpace') && showError"> *Please
            enter location.</div>
    </div>
    <div class="bottom">
        <button class="btn-main verified_pop">Continue</button>
    </div>
</form>