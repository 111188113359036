import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor( private admin: AdminService,) { }

  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Privacy Policy Page", pageImg, "Privacy Policy Page", "", "");
  }

}
