import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataSharingService {
  public isLocation: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isPointUpdate: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public getLocation: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isLoginChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public loadSignPage: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isHomePage: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public clearSearch: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isPlanChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isHeaderChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public enableForm: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isProfileChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isPermissionChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public isBusinessChanged: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public switchBusiness: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public businessUpdate: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public userAvatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public searchParam: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public searchEmpty: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public search: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedCategory: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public SelectedSubCategory: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public allCategory: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public prevUrl: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedTab: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public locationChange: BehaviorSubject<any> = new BehaviorSubject<{setCurrentLocation:boolean,address:any}>({setCurrentLocation:false,address:null});
  public userLevelDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public profileTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public clearSearchField: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public currentBusiness :BehaviorSubject<any>=new BehaviorSubject<any>(null);
  public intervalIdshare = new BehaviorSubject<any>(null);
  public signuppopupintervalIdshare = new BehaviorSubject<any>(null);

  private myregpopupVariable = new BehaviorSubject<any>(false);
  regpopupVariable = this.myregpopupVariable.asObservable();

  setregpopupVariable(value: Boolean) {
    this.myregpopupVariable.next(value);
  }

  getregpopupVariable(): Boolean {
    return this.myregpopupVariable.value;
  }
  
  
  getCurrentUser() {
    return localStorage.getItem("userName");
  }

  setsignuppopupVariable(data: any) {
    this.signuppopupintervalIdshare.next(data);
  }
  
  setHeaderVariable(data: any) {
    this.intervalIdshare.next(data);
  }
  getUserType() {
    return localStorage.getItem("userType");
  }

  setUserAvatar(value) {
    localStorage.setItem("userAvatar", value);
    this.userAvatar.next(value);
  }

  searchFaq(query: string) { 
    this.searchParam.next(query);
  }

  setSearch(value){
    this.search.next(value);
  }
  setSelectedCategory(value){
    this.selectedCategory.next(value);
  }
  setSelectedSubCategory(value) {
    this.SelectedSubCategory.next(value);
  }
  setAllCategory(value){
    this.allCategory.next(value);
  }
  setPrevURL(value){
    this.prevUrl.next(value);
  }
 
}
