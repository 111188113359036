<main class="main merchantpanel">
    <div class="heading">
        <h2 class="page-title mb-4">Customer Feedback</h2>
    </div>

    <div class="table-wrapper noBorderth" *ngIf="feedbackList.count !== 0 ">
        <table class="table  table-responsive" *ngIf="feedbackList.count > 0">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Name </th>
                    <th scope="col">Description</th>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of feedbackList.rows" let i="index">
                    <td>
                        <a (click)="openModal(data)" class="pointer">
                            <div class="imgbox"><img [src]="BaseUrl+data.usersDetailsFeedback.profilePic"
                                    *ngIf="!!data.usersDetailsFeedback.profilePic && data.usersDetailsFeedback.profilePic!='null'" />
                                <img src="/assets/images/user.svg"
                                    *ngIf="!data.usersDetailsFeedback.profilePic || data.usersDetailsFeedback.profilePic=='null'">
                            </div>
                        </a>
                    </td>
                    <td><a class="pointer capitalize" (click)="openModal(data)">{{data.usersDetailsFeedback.fullName}}</a></td>
                    <td> <a class="pointer" (click)="openModal(data)">{{data.description}}</a></td>
                    <td><a class="date-time pointer" (click)="openModal(data)"> {{data.createdAt | dateDay}} | {{data.createdAt | date:'hh:mm a'}}</a></td>

                    <td>
                        <div class="dropdownWrap" dropdown>
                            <span dropdownToggle class="toggleIcon"></span>
                            <ul *dropdownMenu class="dropdown-menu" role="menu" placement="right">
                                <li><a  class="dropdown-item" (click)="openModal(data)">View</a>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>

    <div class="new-pagination-wrapper" *ngIf="feedbackList.count>5">
        <div class="pagination-wrapper">
            <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage" [boundaryLinks]="true"
                [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prev" nextText="Next"
                [totalItems]="feedbackList.count" firstText="First" lastText="Last" [rotate]="true">
            </pagination>
        </div>
    </div>
    <div class="No_found" *ngIf="feedbackList.count == 0 ">
        No Data Found!
    </div>
</main>

<app-feedback-popup (valueChange)="getFeedbackList()" #feedbackModal></app-feedback-popup>