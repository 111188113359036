import { Component, OnInit, Renderer2, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.css']
})
export class GallerySliderComponent implements OnInit {
  slides: any;
  showBg = false
  mediaFile: any
  BaseUrl: any;
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  fromPage="";
  slideConfig = { "slidesToShow": 3, 'centerMode': true, "slidesToScroll": 1, "dots": false, };
  @ViewChild('galleryModel') private imgModel: TemplateRef<any>
  @ViewChild('singleMedia') private singleMedia: TemplateRef<any>
  constructor(
    private renderer: Renderer2,
    private modalService: BsModalService
  ) { }
  ngOnInit(): void {}
  openSliderPopup(popup, sliderImg) {
    if (popup === "1") {
      this.BaseUrl = environment.BaseUrl + 'assets/';
    }
    if (popup === '2') {
      this.BaseUrl = environment.BaseUrl + 'service/';
    }
    if (popup === "3") {
      this.BaseUrl = environment.BaseUrl + 'offers/';
    }
    this.renderer.addClass(document.body, 'modal-dark');
    this.slides = sliderImg
    this.modalRef = this.modalService.show(this.imgModel, {
      animated: false,
      backdrop: 'static',
      class: "modal-dialog-centered sliderPopup"
    });
  }
  showBigSize(img, type, title) {
    this.showBg = true;
    const data = { "title": title, "file": img, "type": type }
    this.openMedia("1", data,'');
  }
  openMedia(popup, sliderImg,fromPage) {
    this.fromPage=fromPage;
    if (popup === '1') {
      this.BaseUrl = environment.BaseUrl + 'assets/';
    }
    if (popup === '2') {
      this.BaseUrl = environment.BaseUrl + 'service/';
    }
    if (popup === '3') {
      this.BaseUrl = environment.BaseUrl + 'offers/';
    }
    if(popup === '4')
    {
      this.BaseUrl = environment.BaseUrl + 'userGuide/';
    }
    this.renderer.addClass(document.body, 'modal-dark');
    this.mediaFile = sliderImg;
    this.modalRef1 = this.modalService.show(this.singleMedia, {
      animated: false,
      backdrop: 'static',
      class: "modal-dialog-centered sliderPopup orgSize"
    });
  }
  close() {
    this.modalRef.hide();
  }
  mediaClose() {
    this.showBg = false;
    this.modalRef1.hide();
  }

}
