import { Injectable, NgModule } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from './admin.service';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { ChatMessage } from '../shared/models/chatMessage';
import { Chat } from '../shared/models/chat';
import { chatEnvironment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class ChatService {
    FbChats = chatEnvironment.chats;
    Jobs = chatEnvironment.Jobs;
    Messages = chatEnvironment.Messages;
    Notifications = chatEnvironment.Notifications;
    Users = chatEnvironment.Users;
    user: any;
    //public chat_dialog_id: any;
    private _ChatMessages = new BehaviorSubject<any[]>([]);
    readonly ChatMessages = this._ChatMessages.asObservable();
    private _Chats = new BehaviorSubject<any[]>([]);
    readonly Chats = this._Chats.asObservable();
    private dataStore: { chats: Chat[], messages: ChatMessage[] } = { chats: [], messages: [] };
    public currentUser: any;
    public unReadMessageCount: any;
    public lastmessageTime: any;
    constructor(private api: AdminService, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private titlecasePipe: TitleCasePipe, private router: Router, private route: ActivatedRoute) { }
    addChat() { }
    addMessage(msg: string) { }
    clearChats() {
        this.dataStore.messages = [];
        this._ChatMessages.next(Object.assign({}, this.dataStore).messages);
    }

    clearMessages() {
        this.dataStore.messages = [];
        this._ChatMessages.next(Object.assign({}, this.dataStore).messages);
    }

    getChats() {
        let userId = 'ID_' + String(this.currentUser);
        this.db.list(this.FbChats,
            query => query
                .orderByChild("seller_id")
                .equalTo(userId)
                .limitToLast(10)
        ).valueChanges().subscribe(list => {
            this.dataStore.chats = list ? <Array<Chat>>list : [];
            this._Chats.next(Object.assign({}, this.dataStore).chats);
            // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        });
    }

    sendMessage(msg: string, chatDiologId: string, jobId: string, userId: string, type: string, unReadCount, serviceName, serviceId) {
        var last_message_time = new Date().getTime()
        var readStatusArr = {};
        readStatusArr[userId] = userId;
        let messageId = this.db.list(`${this.Messages}/${chatDiologId}`).push({}).key;
        var aa = this.db.object(`${this.Messages}/${chatDiologId}/${messageId}/`).update({
            "attachment_url": "",
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,
            "message": msg,
            "message_time": last_message_time,
            "message_type": 1,
            "job_id": jobId,
            "message_id": messageId,
            "user_id": userId,
            "message_read_status": readStatusArr,
            "receiver_id": type === "1" ? userId : serviceId ? serviceId : jobId,
            "sender_id": type === "1" ? serviceId ? serviceId : jobId : userId,
        });
        this.updateNotification(messageId, msg, last_message_time, chatDiologId, type, serviceId, jobId, userId, "1");
        var messageTimeArr = {};
        messageTimeArr[userId] = last_message_time;
        var messageTypeArr = {};
        messageTypeArr[userId] = 1;
        messageTypeArr[serviceId ? serviceId : jobId] = 1;
        var lastMessageArr = {};
        lastMessageArr[userId] = msg;
        lastMessageArr[serviceId ? serviceId : jobId] = msg;
        var deleteTime = {};
        deleteTime[userId] = 0;
        deleteTime[serviceId ? serviceId : jobId] = 0;
        let unreadMessageArr = {};
        let message_count = 1;
        if (type === "1") {
            let count = unReadCount + message_count;
            unreadMessageArr[userId] = count;
            unreadMessageArr[serviceId ? serviceId : jobId] = 0;
        } else {
            let count = unReadCount + message_count;
            unreadMessageArr[serviceId ? serviceId : jobId] = count;
            unreadMessageArr[userId] = 0;
        }
        this.db.object(`${this.FbChats}/${chatDiologId}`).update({
            "last_message_time": last_message_time,
            "last_message_type": 1,
            "last_message_sender_id": userId,
            "last_message": lastMessageArr,
            "last_message_id": messageId,
            "unread_count": unreadMessageArr
        });
        this.removeChat(chatDiologId, messageId);
    }

    removeChat(chatDiologId, messageId) {
        setTimeout(() => {                           //<<<---using ()=> syntax
            var ab = this.db.object(`${this.Messages}/${chatDiologId}/${messageId}/`).remove();
        }, 100000);
    }

    updateNotification(messageId, msg, last_message_time, chatDiologId, type, serviceId, jobId, userId, messageType) {
        this.db.list(this.Notifications).push({
            "message_id": messageId,
            "message": messageType === "1" ? msg : '',
            "attachment_url": messageType === "1" ? '' : msg,
            "message_type": messageType,
            "message_time": last_message_time,
            "firebase_message_time": new Date().getTime(),
            "chat_dialog_id": chatDiologId,
            "sender_id": type === "1" ? serviceId ? serviceId : jobId : userId,
            "receiver_id": type === "1" ? userId : serviceId ? serviceId : jobId,
            "job_id": jobId,
            "user_id": userId,
        });
    }

    removeMessage(chatDiologId, messagePush) { }
    sendMessageDocument(msg: string, chatDiologId: string, jobId: string, userId: string, messageType, type: string, unReadCount, serviceName, serviceId) {
        var last_message_time = new Date().getTime()
        var readStatusArr = {};
        readStatusArr[userId] = userId;
        let messageId = this.db.list(`${this.Messages}/${chatDiologId}`).push({}).key;
        var aa = this.db.object(`${this.Messages}/${chatDiologId}/${messageId}/`).update({
            "message_id": messageId,
            "attachment_url": msg,
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,
            "message": "",
            "message_time": last_message_time,
            "message_type": messageType,
            "job_id": jobId,
            "user_id": userId,
            "receiver_id": type === "1" ? userId : jobId,
            "sender_id": type === "1" ? jobId : userId,
        });
        this.updateNotification(messageId, msg, last_message_time, chatDiologId, type, serviceId, jobId, userId, messageType);
        var messageTimeArr = {};
        messageTimeArr[userId] = last_message_time;
        var messageTypeArr = {};
        messageTypeArr[userId] = 1;
        messageTypeArr[jobId] = 1;
        var lastMessageArr = {};
        lastMessageArr[userId] = msg;
        lastMessageArr[jobId] = msg;
        var deleteTime = {};
        deleteTime[userId] = 0;
        deleteTime[jobId] = 0;
        let unreadMessageArr = {};
        let message_count = 1;
        if (type === "1") {
            let count = unReadCount + message_count;
            unreadMessageArr[userId] = count;
            unreadMessageArr[jobId] = 0;
        } else {
            let count = unReadCount + message_count;
            unreadMessageArr[jobId] = count;
            unreadMessageArr[userId] = 0;
        }

        this.db.object(`${this.FbChats}/${chatDiologId}`).update({
            "last_message_time": last_message_time,
            "last_message_type": messageType,
            "last_message_sender_id": userId,
            "last_message": lastMessageArr,
            "last_message_id": messageId,
            "unread_count": unreadMessageArr
        });
        this.removeChat(chatDiologId, messageId);
    }
    addUser(userId: string) {
        this.db.object(`${this.Users}/${userId}`).update({
            "user_id": userId,
        });
    }

    addJob(jobId: string) {
        this.db.object(`${this.Jobs}/${jobId}`).update({
            "job_id": jobId,
        });
    }

    updateJob(jobId: string, userId: string, businessName: string, user_name: string, businessUserName: string, leadId: string, phone_number: string, chatType: string, serviceName: string, lead_auto_id, serviceID: string,) {
        let businessID = chatType === "2" ? serviceID : jobId;
        let chatDiologId = businessID + ',' + userId;
        let last_message_time = new Date().getTime()
        let messageTimeArr = {};
        messageTimeArr[userId] = last_message_time;
        let messageTypeArr = {};
        messageTypeArr[userId] = 1;
        messageTypeArr[jobId] = 1;
        let lastMessageArr = {};
        lastMessageArr[userId] = "";
        lastMessageArr[jobId] = "";
        let deleteTime = {};
        deleteTime[userId] = 0;
        deleteTime[jobId] = 0;
        let unreadMessageArr = {};
        let message_count = '1';
        unreadMessageArr[jobId] = 0;
        let clear_chat_time = {};
        clear_chat_time[jobId] = 0;
        clear_chat_time[userId] = 0;
        let name = {};
        name[jobId] = businessName;
        name[userId] = user_name;
        let pic = {};
        pic[jobId] = "";
        pic[userId] = "";
        this.db.object(`${this.FbChats}/${chatDiologId}`).update({
            "chat_dialog_id": chatDiologId,
            "clear_chat_time": clear_chat_time,
            "is_chat_enable": 1,
            "job_id": jobId,
            "job_name": businessName,
            "job_pic": "",
            "name": name,
            "service_name": chatType === "2" ? serviceName : '',
            "service_id": chatType === "2" ? serviceID : '',
            "phone_number": phone_number,
            "lead_id": leadId,
            "lead_auto_id": lead_auto_id,
            "pic": pic,
            "user_name": user_name,
            "user_id": userId,
            "user_pic": "",
            "last_message_type": ""
        });

        this.db.object(`${this.Jobs}/${jobId}/chat_dialog_ids`).update({
            [chatDiologId]: chatDiologId
        });

        this.db.object(`${this.Users}/${userId}/chat_dialog_ids`).update({
            [chatDiologId]: chatDiologId
        });
    }

    updateMsgCount(chatDiologId, job_id, user_id, type, service_id, anotherUserCount) {
        var unreadMessageArr = {};
        if (type === "1") {
            let id = service_id ? service_id : job_id
            this.db.object(`${this.FbChats}/${chatDiologId}/unread_count`).update({
                [id]: 0
            })

        } else {
            this.db.object(`${this.FbChats}/${chatDiologId}/unread_count`).update({
                [user_id]: 0
            })
        }
    }

    sendDefultMsg(defultMsg, merchantSettings, chat_dialog_id, job_id, user_id, serviceName, serviceId, bussionName) {

        if (defultMsg === "1") {
            if (merchantSettings?.sendGreetingMessage === 1) {
                let message = merchantSettings.message;
                this.sendMessage(message, chat_dialog_id, job_id, user_id, "1", 0, serviceName, serviceId);
            }
            let text1 = `Hello ${this.titlecasePipe.transform(bussionName)}, I am interested in your service '${this.titlecasePipe.transform(serviceName)}' and would like to discuss further with you`
            let text = `Hello ${this.titlecasePipe.transform(bussionName)}, I am interested in your services and would like to discuss further with you`;
            let msgContent = serviceName ? text1 : text;
            let sendMsg = 1;
            this.sendMessage(msgContent, chat_dialog_id, job_id, user_id, "0", 0, serviceName, serviceId);
        }

     
    }



}