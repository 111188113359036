import { Component, OnInit, TemplateRef, ViewChild, PLATFORM_ID, Inject,ElementRef,NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataSharingService } from "../../services/data.share.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { SignupComponent } from '../signup/signup.component';
import { ClaimBusinessComponent } from '../claim-business/claim-business.component';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from "@angular/common/http";
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Subscription } from "rxjs";
import { LocationPopupComponent } from '../location-popup/location-popup.component';
import { ToastrConfig, ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  slideConfig = {
    "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "infinite": false, "autoplay": false,
    "autoplaySpeed": 5000,
  };

  showLocationPopup = false;
  
  modalRef: BsModalRef;
  loginUser: any;
  homeData: any;
  BaseUrl = environment.BaseUrl;
  showFeature = false;
  showBussiness = false;
  showCategories = false;
  showContests = false;
  showOffers = false;
  userInfo: any;
  claimCounter = 0;
  bannerData: any = [];
  topbussinessList: any = [];
  homeDataV2: any;
  @ViewChild('business_claim') private businessClaimModel: TemplateRef<any>;
  @ViewChild('signUp') private signupModel: SignupComponent;
  private intervalId: any;
  recomendedList: any = [];
  offersDiscount: any = [];
  isLoginChecked: Subscription;
  isLocationChange: Subscription;
  isgetLocation: Subscription;
  @ViewChild('claimBusinessModel') private claimBusinessModel: ClaimBusinessComponent;
  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
  zoom: number = 8;
  latitude: number = 30.713329;
  longitude: number = 76.695566;
  isLocationBlocked = false;
  private geoCoder
  constructor(private admin: AdminService,
    private transferState: TransferState,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: LocationStrategy,
    private router: Router,
    private dataSharingService: DataSharingService,
    private modalService: BsModalService,
    private handler: HttpBackend,
    private toastrService: ToastrService,
    private http: HttpClient,) {

    this.isLoginChecked = this.dataSharingService.isLoginChanged.subscribe(value => {
      this.loginUser = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
      const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
      let userInfo = userData ? (userData) : 'null';
      this.userInfo = JSON.parse(userInfo);
      if (value) {
        console.log("1")
        this.getAllData();
      }
    });
    this.isLocationChange = this.dataSharingService.locationChange.subscribe(value => {
      if (value.address) {
        if (this.router.url == "/") {
          this.getAllData();
          this.dataSharingService.locationChange.next({ setLocation: false, address: '' });
        }
      }
    })
    this.isgetLocation = this.dataSharingService.getLocation.subscribe(value => {
      if (value) {
        this.getAllData();
        console.log("3")
        this.dataSharingService.getLocation.next(false);
      }
    })
  }

  // handleLocationAccess() {
  //   this.getLocation()
  //   if(localStorage.getItem("locationData")){
  //     this.locationTemplate.close();
  //   }
  // }

  // getLocation(): void {
  //   this.setCurrentLocation('0')
  //   if (navigator.geolocation) {
  //     navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
  //       if (permissionStatus.state === 'denied') {
  //         this.isLocationBlocked = true;
  //         console.log(this.locationTemplate);
  //           this.locationTemplate.openModal(false);
  //           this.locationTemplate.close();
  //       }

  //       if(permissionStatus.state === 'granted'){
  //         this.isLocationBlocked = false;
  //         navigator.geolocation.getCurrentPosition((position) => {
  //           const longitude = position.coords.longitude;
  //           const latitude = position.coords.latitude;
  //           this.reverseGeocode(latitude, longitude);
  //           this.dataSharingService.locationChange.next(true)
  //         });
  //       }
  //     });

  //   } else {
  //     console.log("No support for geolocation")
  //   }
  // }

  // reverseGeocode(latitude: any, longitude: any): void {
  //   const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const address = data.address.city;
  //       localStorage.setItem("locationData", JSON.stringify(address));
  //       localStorage.setItem("fulllocationData", JSON.stringify(data));
  //       setTimeout(() => {
  //         this.dataSharingService.locationChange.next(true)
  //       }, 2000);
  //       if (localStorage.getItem("locationData")) {
  //         this.locationTemplate.close();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error performing reverse geocoding:", error);
  //     });
  // }

  // setCurrentLocation(type) {
  //   if ("geolocation" in navigator) {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //       });
  //   }
  // }


  // ngAfterViewInit() {
  //   if(!localStorage.getItem("locationData") && !this.locationTemplate.isBlocked){
  //     this.locationTemplate.openModal();
  //   }
  //   //this.startInterval()
  // }

  // startInterval() {
  //  // this.locationTemplate.close();
  //   this.intervalId = setInterval(() => {
  //     if (!localStorage.getItem("locationData")) {
  //       if (this.locationTemplate.isOpen) {
  //         this.locationTemplate.close();
  //       }
  //       if(!this.locationTemplate.isBlocked){
  //         this.locationTemplate.openModal();
  //       }
  //     }
  //   }, 10000);
  // }

  // stopInterval() {
  //   clearInterval(this.intervalId);
  // }



  ngOnInit(): void {
    
    let merchant = localStorage.getItem("Marchant") ? (localStorage.getItem("Marchant")) : "0";
    if (merchant === "1") {
      this.checkMerchant();
    } else {
      if (isPlatformServer(this.platformId)) { }
      else {
        this.getAllData();
        this.clearCategorySession();
      }
    }
    this.dataSharingService.isHomePage.next(true)
  }
  ngOnDestroy(): void {
   if (this.isLocationChange) {
    this.isLocationChange.unsubscribe()
   }
  }
  getAllData() {
    
    this.getBanner();
    this.getHomeOffer();
    this.getHomeData();
    this.dataConfigurations();
    this.getBusinessList();
    
    
  }
  async getBanner() {
    const currentCity = localStorage.getItem("city") ? localStorage.getItem("city") : '';
    let currentState = localStorage.getItem("state") ? localStorage.getItem("state") : '';
    let data = {
      "type": 1
    }
    if (this.userInfo) {
      data['userId'] = this.userInfo?.id
    } else {
      if (currentCity) {
        data['city'] = currentCity
      }
      if (currentState) {
        data['state'] = currentState
      }
      //
    }
    this.admin.getData(apiUrl._getBanner, data).subscribe(async (res) => {
      this.bannerData = [];
      let totalBanner = res.data?.positionSettings[0].homePagePositions;
      if (res.data?.positionSettings.length > 0) {
        for (let i = 0; i < totalBanner; i++) {
          this.bannerData.push({ "backgroundImageWeb": res.data?.defaultBanner[0]?.webImage })
          if (res.data?.panIndiaBanners.length > 0) {
            const findId = res.data?.panIndiaBanners.filter(obj => {
              if (i == obj.position) {
                let index = obj.position - 1;
                this.bannerData[index] = obj;
              } else {
                //this.bannerData.push({ "backgroundImageWeb": res.data?.defaultBanner[0]?.webImage })
              }
            });
          } else {
            //this.bannerData.push({ "backgroundImageWeb": res.data?.defaultBanner[0]?.webImage })
          }
        };
        const findId = res.data?.banners.filter(obj1 => {
          let index = obj1.position - 1;
          this.bannerData[index] = obj1;
        });
      }
    },
      err => {
        console.log(err, "err");
      });
  }

  afterChange(e) {
    if (this.userInfo) {
      //this.updateCount(e?.currentSlide)
    }
  }
  updateCount(index) {
    let id = this.bannerData[Number(index)]?.id ? this.bannerData[Number(index)]?.id : '';
    if (id) {
      let data = { "campaignId": id }
      // this.admin.loader(false)
      this.admin.putData(apiUrl._viewedCountCampaign, data).subscribe(async (res) => { },
        err => {
          console.log(err, "err");
        });
    }
  }

  checkMerchant() {
    let data = {}
    this.admin.getData(apiUrl._checkStep, data).subscribe(async (res) => {
      let completDoc = res.data.isDocumentCompleted;
      if (completDoc === 1) {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['become-merchant']);
      }
    },
      err => {
        console.log(err, "err");
      });
  }
  close() {
    this.modalRef.hide();
  }
  getHomeData() {
    function addCustomToastStyle() {
      const style = document.createElement('style');
      style.innerHTML = `
        .custom-toast-payment { 
          background-color: #51A351; 
          border-radius: 8px; 
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
          padding: 16px;
          position: fixed; 
          bottom: 5%;  
          max-width: 1100px;  
          width: 100%;  
          left: 3%; 
          color: #fff; 
          z-index: 1050;
        }
        .custom-toast-payment .toast-progress {  
          background-color: #fff; 
          opacity: 1; 
        }
      `;
      document.head.appendChild(style);
    }
    
    const toastConfig: Partial<ToastrConfig> = {
      toastClass: 'custom-toast-payment',
      progressBar: true,
      timeOut: 10000,
      enableHtml: true,
    };
    function showToastOncePerDay(toastrService: ToastrService) {
      const today = new Date().toISOString().split('T')[0];
      const storageKey = 'toast-shown-date';
      const lastShownDate = localStorage.getItem(storageKey);
      if (lastShownDate !== today) {
        const message = `Secure payments made easy! Use the QR code or the official Finndit payment link. <br>
          <strong>Reminder:</strong> Payments are accepted only via official Finndit accounts. Avoid personal account transactions.`;
        addCustomToastStyle();
        toastrService.success(`${message}`, '', toastConfig);
        localStorage.setItem(storageKey, today);
      }
    }
    showToastOncePerDay(this.toastrService);    
    let latitude = localStorage.getItem("latitude") ? localStorage.getItem("latitude") : 30.713329;
    let longitude = localStorage.getItem("longitude") ? localStorage.getItem("longitude") : 76.695566;
    let data = { latitude: latitude, longitude: longitude, type: '1', userId: this.userInfo?.id }
    if (!this.userInfo) {
      delete data.userId;
    }
    this.admin.getData(apiUrl._getHomeDataOfferFeaturedCategories, data).subscribe(async (res) => {
      this.claimCounter = !!sessionStorage.getItem('claimCounter') ? Number(sessionStorage.getItem('claimCounter')) : 0;
      this.homeData = res.statusCode == 200 ? res.data : undefined;
      let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
      this.admin.addMetaTag("Home page", pageImg, "", "", "");
      this.recomendedList = this.homeData.featuredCategories?.slice(0, 4);
      this.offersDiscount = this.homeData.offerAndDiscounts?.slice(0, 4);
      this.recomendedList.forEach(x => {
        let data = x.featuredCategoryData.name.split(' ').slice(0, 2);
        let text = ''
        data.forEach(y => {
          text = text + y[0].toUpperCase();

        })
        x.logoText = text
      })
      if (this.homeData.claimBusinesses.length > 0) {
        if (this.homeData.claimBusinesses[0].id && this.claimCounter == 0) {
          const findId = this.homeData.claimBusinesses.filter(obj => {
            obj.averageRating = parseFloat(obj.averageRating).toFixed(1);
          });
          this.openModel();
        }
      }
    },
      err => {
        console.log(err, "err");
      });
  }
  dataConfigurations() {
    let data = {}
    this.admin.getData(apiUrl._getHomeDataConfigurations, data).subscribe(async (res) => {
      res.data.configuration.forEach(x => {
        if (x.module == "Featured" && x.status == 1) {
          this.showFeature = true;
        }
        else if (x.module == "Top Businesses" && x.status == 1) {
          this.showBussiness = true;
        }
        else if (x.module == "Top Categories" && x.status == 1) {
          this.showCategories = true;
        }
        else if (x.module == "Contests" && x.status == 1) {
          this.showContests = true;
        }
        else if (x.module == "Offers & Discounts" && x.status == 1) {
          this.showOffers = true;
        }
      });
    },
      err => {
        console.log(err, "err");
      });
  }
  async categoryClick(event: Event, category: any): Promise<void> {
    event.preventDefault();
    let allCategory: any = {} = await this.admin.getCategories();
    let selectedCategory: any;
    const findId = allCategory.data.filter(obj1 => {
      if (obj1.id === category.categoryId) {
        selectedCategory = obj1;
        this.dataSharingService.setSelectedCategory(selectedCategory);
        this.router.navigate(['/category/sub-category'], { skipLocationChange: true });
      }
    });
  }
  featuredCategoryClick(category) {
    let obj = category.featuredCategoryData;
    obj.type = 60;
    obj.fromFeaturedCategory = 1;
    this.dataSharingService.search.next(obj);
    this.dataSharingService.setSearch(obj);
    this.router.navigate(['/category/sub-category/business']);
  }
  signUpShow() {
    this.signupModel.openModal("1", "null", '', '');
  }
  switchMerchant() {
    document.getElementById('merchant_button_header')?.click();
  }
  clearCategorySession() {
    sessionStorage.clear();
  }
  openModel() {
    let timer = Number(sessionStorage.getItem('timerCLaimPopUp'));
    let value = !!timer ? timer : 2000;
    setTimeout(() => {
      this.claimCounter = !!sessionStorage.getItem('claimCounter') ? Number(sessionStorage.getItem('claimCounter')) : 0;
      if (document.getElementsByClassName('modal show')?.length > 0 || this.claimCounter > 0) {
        return;
      }
      sessionStorage.setItem('claimCounter', "1");
      this.claimCounter = 1;
      if (this.loginUser) {
        this.modalRef = this.modalService.show(this.businessClaimModel, {
          animated: false,
          backdrop: 'static',
          class: "modal-r borderRradius10 claimModel"
        });

      }

    }, value);
  }
  viewBusiness(id, name) {
    sessionStorage.removeItem('businessID');
    sessionStorage.setItem('businessID', btoa(id));
    this.dataSharingService.isBusinessChanged.next(true);
    this.close();
    if (name != "") {
      sessionStorage.setItem("claim", "1");
      this.goToBusinessDetail()
    }
    else {
      this.goToBusinessDetail()
    }
  }
  goToBusinessDetail() {
    this.router.navigate(["/category/sub-category/business/business-detail"], { skipLocationChange: true });
  }
  goTodetails(business, businessServiceId, link) {
    if (link) {
      window.open(link, "_blank");
    } else {
      if (businessServiceId) {
        sessionStorage.setItem('serviceID', btoa(businessServiceId));
        this.router.navigate(['/category/sub-category/business/services/services-details'], { skipLocationChange: true });

      } else {
        let category = { 'id': business.businessId }
        sessionStorage.setItem('leadFromAction', ('4'));
        sessionStorage.setItem('campaignId', btoa(business.id));
        this.topBusinessClick(category, 'banner');
      }
    }
  }
  topBusinessClick(category, from) {
    if (from == 'banner') {
      sessionStorage.setItem('leadFromAction', ('4'));
    }
    else {
      sessionStorage.setItem('leadFromAction', ('3'));
    }
    sessionStorage.setItem('businessID', btoa(category.id));
    this.router.navigate(['category/sub-category/business/business-detail']);
  }

  getUpdatedLocation() {
    this.dataSharingService.locationChange.subscribe(data => {
      if (data.address) {
        console.log("5")
        this.getAllData();
      }
    })
  }
  offerClick(category) {
    category.type = 60;
    category.id = category.categoryId;
    this.dataSharingService.setSearch(category);
    this.router.navigate(['/category/sub-category/business'], { skipLocationChange: true });

  }
  getHomeOffer() {
    let latitude = localStorage.getItem("latitude") ? localStorage.getItem("latitude") : 30.713329;
    let longitude = localStorage.getItem("longitude") ? localStorage.getItem("longitude") : 76.695566;
    let data = { latitude: latitude, longitude: longitude, type: '1', userId: this.userInfo?.id }
    if (!this.userInfo) {
      delete data.userId;
    }
    this.admin.getData(apiUrl._getHomeDataTopCategories, data).subscribe(async (res) => {
      this.homeDataV2 = res.data
      //this.topbussinessList = res.data.topBusinesses.slice(0, 4);

    })
  }

  getBusinessList() {
    let latitude = localStorage.getItem("latitude") ? localStorage.getItem("latitude") : 30.713329;
    let longitude = localStorage.getItem("longitude") ? localStorage.getItem("longitude") : 76.695566;
    let data = { latitude: latitude, longitude: longitude, type: '1', userId: this.userInfo?.id }
    if (!this.userInfo) {
      delete data.userId;
    }
    this.admin.getData(apiUrl._homeTopBusinesses, data).subscribe(async (res) => {
      this.topbussinessList = res.data.topBusinesses.slice(0, 4);

    })
  }

}


