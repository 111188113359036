import { Injectable } from '@angular/core';
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { razorpayKey } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  paymentKey = "";
  Modeldismiss: any;
  constructor(private api: AdminService, private toastrService: ToastrService, private route: ActivatedRoute, private router: Router) { }
  async buyRazorPay(razorPaData, planID, paymentType, campaignId, price) {
    return new Promise(async (resolve, reject) => {
      const data = {
        "key": this.paymentKey,
        "id":  razorPaData.order.id,
        "entity": razorPaData.order.entity,
        "amount": razorPaData.order.amount,
        "amount_paid": razorPaData.order.amount_paid,
        "amount_due": razorPaData.order.amount_due,
        "currency": razorPaData.order.currency,
        "receipt": razorPaData.order.receipt,
        "status": razorPaData.order.status,
        "attempts": razorPaData.order.attempts,
        "notes": razorPaData.order.notes,
        "created_at": razorPaData.order.created_at,          
        "theme": {
          "color": "#464E79"
        },
        "handler": (response) => {
          if (paymentType === "5") {
            console.log("a=============")
            this.api.loader(true);
            const apiData = {
              "transactionId": response.razorpay_payment_id, 
              "campaignId": campaignId,
              "businessId": planID,
              "price":price
            }
            this.api.putData(apiUrl._advertisementPayment, apiData)
              .subscribe(async (res) => {
                this.api.loader(false);
                resolve("Successfully");
              });
          }
          else if(paymentType==9){
            let data={
              businessId:razorPaData.businessId,
              transactionId:response.razorpay_payment_id,
              finalPrice:price.finalPrice.toString(),
              basePrice:price.basePrice.toString()
            }
            this.api.putData(apiUrl._updateLeadsPayment,data) .subscribe(async (res) => {
              resolve("Successfully");
            });
          } 
          else {
            this.api.loader(true);
            const updatePaymentData = {
              "transactionId": response.razorpay_payment_id,
              "id": razorPaData.data.id,
              "type": paymentType ? Number(paymentType) : 1
            }
            this.api.putData(apiUrl._updatePayment, updatePaymentData)
              .subscribe(async (res) => {
                this.api.loader(false);
                resolve("Successfully");
              });
          }
        },
        "modal": {
          "ondismiss": (response) => {
            let prfoleType = localStorage.getItem("merchantPrfoleType")
            if (prfoleType === "1") {
              resolve("modalDismiss");
            }
          }
        }
      }
      if(razorPaData.subscription_id){
        data["subscription_id"]=razorPaData.subscription_id
      }
      var rzp1 = new Razorpay(data);
      rzp1.on('payment.failed', function (data) {
        console.log(data.error.metadata.order_id);
        console.log(data.error.metadata.payment_id);
        reject("failed");
      });
      var rezp1 = new Razorpay(data)
      rezp1.open()
      let prfoleType = localStorage.getItem("merchantPrfoleType")
    });
  }
}
