import { Component, OnInit } from '@angular/core';
import { AdminService } from "../../services/admin.service";

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(private admin: AdminService,) { 
    let dec =" Welcome to Finndit, the fastest-growing platform for finding local businesses and services in India. Our     mission is to connect customers with the products and services they need, while also promoting local businesses and fostering a strong community."

    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("About us page", pageImg, dec, "", "");
  }

  ngOnInit(): void {
  }

}
