import { Component } from "@angular/core";
import { LocationStrategy } from '@angular/common';
import { MessagingService } from './services/messaging.service';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  message;
  title="FinndIt"
  allCategory = [];
  constructor(
    private location: LocationStrategy,
    private messagingService: MessagingService) {
    if (environment.production) {
      // console.log = function (): void { };
      // console.debug = function (): void { };
      // console.info = function (): void { };
      // console.error = function (): void { };
      // console.warn = function (): void { };
    }
  }
  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage;
  }
}