import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UsernameValidator } from "../../services/NoWhiteSpace.validator";
import { AdminService } from 'src/app/services/admin.service';
import { MessagingService } from '../../services/messaging.service';
import { apiUrl } from "../../global/global";
import { ToastrService,ToastrModule, ToastrConfig } from "ngx-toastr";
import { DataSharingService } from "../../services/data.share.service";
import { ChatService } from '../../services/chat.service';

declare var google: any;
@Component({
  selector: "app-signup-popup",
  templateUrl: "./signup-popup.component.html",
  styleUrls: ["./signup-popup.component.css"],
})
export class SignupPopupComponent implements OnInit{
  imageUrls: string[] = [
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1644403245012_1jzk164kzff8gd0.jpeg",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1593636116976_mp930gkc3tspo0.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1595393897250_62ts2v5kcwwc1lu.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1614239255465_4o9fkdqlklkkdh2h.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1593584496911_mp92eskc2z2bdb.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1669891154334_1canbt2f4olb4y2t26.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1603375662827_i1jl21jkgkwgzcb.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1669006619452_1sahoic5laqbg5ks.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1646826323371_ffc6rxl0jhvfl7.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1648531266103_fe91ubnkl1boy9tj.png",
    "https://cdn.gyftr.com/comm_engine/prod/images/brands/1673431575535_6o5uolcrhybjj.jpeg"
  ];
  randomImageUrls:string[];
  modalRef: BsModalRef;
  happycustomer: boolean = false;
  gradient: string;
  buttontext:string;
  attentiondrawer:string;
  headertext:string;
  @ViewChild("signuppopup") private signuppopup: TemplateRef<any>;
  @ViewChild("ExitIntentPopup") private ExitIntentPopup: TemplateRef<any>;
  signupForm:FormGroup;
  otpForm: FormGroup;
  showPopup = "signup";
  showError = false;
  signupRespose: any;
  phoneNo: any;
  webToken: any;
  maxTime: any = 30;
  timer: any;
  isResend = false;
  hidevalue = true;
  signUpSuccess = false;
  fromPage: string;
  isImage=false;
  private geoCoder;
  @ViewChild('updateProfile') private updateProfile: TemplateRef<any>;  
  ngOnInit(): void {
    
  }
  isOpen = false;
  messagecontent="";
  constructor(
    public router: Router,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private admin: AdminService,
    private messagingService: MessagingService,
    private chatService: ChatService,
    private toastrService: ToastrService,
    private dataSharingService: DataSharingService,
  ) {
    this.signupForm = this.formBuilder.group({
      phoneNumber: ["", Validators.compose([Validators.required, Validators.minLength(10), UsernameValidator.cannotContainSpace])],
      acceptTerms: [true, Validators.compose([Validators.required])],
    })
    this.otpForm = this.formBuilder.group({
      digit1: ['', Validators.compose([Validators.required])],
      digit2: ['', Validators.compose([Validators.required])],
      digit3: ['', Validators.compose([Validators.required])],
      digit4: ['', Validators.compose([Validators.required])],
    });
  }
  gotoTermsPage(type: string) {
    if (type == "terms") {
      this.router.navigate([]).then(result => { window.open("/terms-use", '_blank'); });
    } else {
      this.router.navigate([]).then(result => { window.open("/privacy-policy", '_blank'); });
    }
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  StartTimer() {
    this.timer = setTimeout(x => {
      this.maxTime -= 1;
      if (this.maxTime == 0) {
        this.isResend = false;
      }
      if (this.maxTime < 10) {
        this.maxTime = "0" + this.maxTime
      }
      if (this.maxTime > 0) {
        this.hidevalue = false;
        this.StartTimer();
      }
      else {
        this.hidevalue = true;
      }
    }, 1000);
  }
  backTo(){
    this.showPopup = "signup";
  }
  signupFun(){
    if (this.signupForm.valid && this.signupForm.value.acceptTerms) {
      if (this.signupForm.value.referralCode === null) {
        this.signupForm.value["referralCode"] = "";
      }
      delete this.signupForm.value.confirmPassword
      delete this.signupForm.value.acceptTerms
      this.signupForm.value["countryCode"] = "+91"
      this.signupForm.value["deviceToken"] = this.getDeviceToken();
      this.signupForm.value["deviceType"] = "web"
      this.admin.postData(apiUrl._loginsignup, this.signupForm.value)
      .subscribe((res) => {
        if (res.statusCode == 200) {
          console.log(res.data);
          this.signupRespose = res.data;
          this.phoneNo = this.signupRespose?.user?.phoneNumber;
          this.maxTime = 30
          this.showError = false;
          this.showPopup = 'verification';
          this.StartTimer();
        }
      }, err => {
        this.admin.showErrorMsg(err.error.message);
      })
      
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }

  }
  onlyNumberKey(event) {
    return this.admin.allowOnlyNumbers(event);
  }
  onInputEntry(event, nextInput) {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    let result = maskSeperator.test(event.data);
    if (result) {
      let input = event.target;
      let length = input.value.length;
      let maxLength = input.attributes.maxlength.value;
      if (length >= maxLength) {
        nextInput.focus();
      }
    }
    else {
      event.target.value = ""
    }
  }

  onKeyDown(event, input) {
    if (event.key === 'Backspace' && input.previousElementSibling) {
      event.target.value = ""
      event.preventDefault();
      input.previousElementSibling.focus();
    }
  }
  focusOtpInput(id) {
    document.getElementById(id)?.focus();
  }

  resendOtp(otpInput) {
    let data = {
      "countryCode": "+91",
      "phoneNumber": this.phoneNo
    }
    this.admin.postData(apiUrl._resendOtp, data).subscribe(async (res) => {
      this.maxTime = 30;
      this.otpForm.reset();
      this.StartTimer();
      this.isResend = true;
      this.focusOtpInput(otpInput)
      localStorage.setItem("forgotToken", res.data);
      this.toastrService.success(
        "Resend OTP successfully",
        ""
      );
    },
      err => {
        console.log(err, "err");
        this.otpForm.reset();
      });
  }

  verifyOtp(popup) {
    this.signUpSuccess = false;
    if (this.otpForm.valid) {
      const otp = this.otpForm.value.digit1 + this.otpForm.value.digit2 + this.otpForm.value.digit3 + this.otpForm.value.digit4;
      let data;
     
        data = {
          "otp": otp,
          "countryCode": "+91",
          "phoneNumber": this.phoneNo,
          "type": 1,
          "deviceType": "web",
          "deviceToken": this.getDeviceToken(),
        }
      
      this.admin.postData(apiUrl._verifyOtp, data).subscribe(async (res) => {
        if (res.statusCode == 200) {
          this.signUpSuccess = true;
          this.otpForm.reset();
          this.admin.showMsg("Verified Successfully", "Your number has been verified successfully.")
          this.showPopup = 'signup';
              localStorage.setItem("webToken", btoa(res.data.accessToken))
              let userinfo = JSON.stringify(res.data.user)            
              if (res.data?.user?.user_location != null) {   
                localStorage.setItem("customerDetail", userinfo);
                localStorage.setItem("state",  JSON.parse(userinfo).user_location?.state);
                localStorage.setItem("city", JSON.parse(userinfo).user_location?.city);
                localStorage.setItem("latitude", JSON.parse(userinfo).user_location?.latitude.toString());
                localStorage.setItem("longitude", JSON.parse(userinfo).user_location?.longitude.toString());
              }else{
                this.webToken = res.data.accessToken;
                this.location(this.updateProfile);
              }
              if(JSON.parse(userinfo).user_location?.city){
                const toastConfig: Partial<ToastrConfig> = {
                toastClass: 'custom-toast', // Apply custom CSS class
                progressBar: true, // Add progress bar
                timeOut: 10000 ,// Set timeout for auto-dismissal
                enableHtml: true,
              };            
              
              var message = "Welcome Back! We are showing results based on your last used location " + JSON.parse(userinfo).user_location?.city + ". You can always update your location to see customised results.";
              this.toastrService.success('<span class="toast-icon"><i class="fa fa-check-circle"></i></span> ' + message, '', toastConfig);
                
              this.close();            
              this.dataSharingService.isLoginChanged.next(true);
            
          }
        }
      },
        err => {
          this.admin.showErrorMsg(err.error.message);
          this.otpForm.reset();
        });
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 10000);
    }
  }

  location(popup) {    
    let latitude;
    let longitude;
  if(!localStorage.getItem("city")){
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        this.getLocation(latitude, longitude, popup)        // 
        // this.getAddress(this.latitude, this.longitude);
      });
    }
  }else{
    latitude = Number(localStorage.getItem("latitude"));
    longitude = Number(localStorage.getItem("longitude"));
    this.getLocation(latitude, longitude, popup)  
  }
  }

  getLocation(latitude: any, longitude: any,popup): void {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let locationdata = {
            "latitude": latitude.toString(),
            "longitude": longitude.toString(),
            "city": localStorage.getItem("city") 
        }
        console.log(data);
        //const address = data.address.city;
        this.submitAddress(locationdata, popup)
      })
      .catch((error) => {
        console.error("Error performing reverse geocoding:", error);
      });
  }

  // getLocation(latitude, longitude, popup) {
  //   this.geoCoder = new google.maps.Geocoder;
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     if (status === 'OK') {
  //       let data = {
  //         "latitude": latitude.toString(),
  //         "longitude": longitude.toString(),
  //         "address": results[0].formatted_address
  //       }
  //       for (let i = 0; i < results[0].address_components.length; i++) {
  //         let types = results[0].address_components[i].types
  //         for (var j = 0; j < types.length; j++) {
  //           if (types.indexOf('administrative_area_level_1') != -1) {
  //             data["state"] = results[0].address_components[i].long_name ? (results[0].address_components[i].long_name) : '';
  //           }
  //           if (types.indexOf('administrative_area_level_3') != -1) {
  //             data["city"] = results[0].address_components[i].long_name ? (results[0].address_components[i].long_name) : '';
  //           }
  //           if (types.indexOf('country') != -1) {
  //             data["country"] = results[0].address_components[i].long_name
  //           }
  //           if (types.indexOf('postal_code') != -1) {
  //             data["pincode"] = results[0].address_components[i].long_name
  //           }
  //           if (types[0] == 'postal_code') {
  //             data["pincode"] = results[0].address_components[i].long_name
  //           }
  //         }
  //       }
  //       if (data) {
  //         this.submitAddress(data, popup)
  //       } else {
  //         this.toastrService.error('Address not found', '');
  //       }
  //     } else {
  //       console.log('Geocoder failed due to: ' + status);
  //     }
  //   })
  // }
  async submitAddress(data, popup) { 
    console.log("submitAddress")
    const userData = localStorage.getItem("customerDetail") ? localStorage.getItem("customerDetail") : '';
    console.log(userData);
    this.dataSharingService.locationChange.next({ setLocation: false, address: data });
    if (!userData && !this.signUpSuccess) {
      this.close();
      this.signUpSuccess = false;
      return
    }
    if (this.fromPage == '') {
      localStorage.setItem("webToken", btoa(this.webToken))
    }
    let checkLocationType = sessionStorage.getItem("setLocation") ? sessionStorage.getItem("setLocation") : '';
    if (!checkLocationType) {
      this.admin.postData(apiUrl._location, data).subscribe(async (res) => {
        await this.admin.firebaseSignIn();
        let userinfo = JSON.stringify(res.data.user);
        localStorage.setItem("customerDetail", userinfo);
        this.dataSharingService.isLoginChanged.next(true);
        // this.dataSharingService.editUser(true);
        this.chatService.addUser(res.data.user.id)
        localStorage.removeItem("setLocation");
        this.close();
        if (this.fromPage == '') {
          this.openConfirmPopup(popup)
        }
      }, err => {
        this.admin.showErrorMsg(err.error.message);
      })

    } else {
      this.close();
      sessionStorage.removeItem("setLocation")
    }

  }
  openConfirmPopup(popup) {
    sessionStorage.setItem('timerCLaimPopUp', '7000');
    this.modalRef = this.modalService.show(popup, {
      animated: false,
      backdrop: 'static',
      class: "modal-dialog-centered borderRadius modal-r"
    });
  }
  closePopup() {
    this.modalRef.hide();
  } 
  editProfile() {
    this.router.navigate(["/myfinndit/update-profile"]);
    this.closePopup(); 
  }
  displayedImageUrls: string[] = [];
  currentIndex: number = 0;
  flipImages() {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrls.length;
    this.displayedImageUrls = [this.imageUrls[this.currentIndex]];
  }
  ExitIntentPopupopen(type) {  
    this.isOpen=true;

    if(type==1){
      this.isImage=false;
      this.happycustomer=true;
      this.buttontext="Sign Up Now";
      this.gradient = 'https://cdn2.iconfinder.com/data/icons/banking-astute-vol-2/512/Points_Rewards_Card-256.png' 
      this.headertext="Join the BEST Local Search Engine" ;
      this.attentiondrawer = "attention-drawer"; 
      this.messagecontent="Join India's ONLY local search engine that gives FREE GIFT vouchers for finding the best nearby deals. Enjoy a SPAM-FREE service as your number is never shared. Get BONUS rewards points now by verifying your phone number.";
    }else if(type==2){
      this.isImage=true;
      this.gradient = '../../../assets/images/stophand.png';
      this.happycustomer=false;
      this.headertext="Don't go without taking Free Rewards!";
      this.attentiondrawer = "fade-in";
      this.buttontext="Register Now";     
      this.messagecontent="Register now to explore best deals, rewards, and discounts near you!";
    }    
    
    this.randomImageUrls = this.shuffleArray(this.imageUrls).slice(0, 7);

    this.modalRef = this.modalService.show(this.ExitIntentPopup, {
        class: "modal-lg modal-class freeze-resolve modal-dialog-centered",
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
      });  

      const modalContent = document.querySelector('.modal-content');
      if (modalContent) {
        (modalContent as HTMLElement).style.backgroundColor = 'transparent';        
      }

  }

  openModal(type) {  
    this.isOpen=true;

    if(type==1){
      this.happycustomer=true;
      this.buttontext="Sign Up Now";
      this.gradient = ''  
      this.attentiondrawer = "attention-drawer"; 
      this.messagecontent="Find the best local businesses tailored just for you.";
    }else if(type==2){
      this.gradient = 'linear-gradient(85deg, rgb(246 230 230), #dddddd75) , url(../../../assets/images/stophand.jpg)';
      this.happycustomer=false;
      this.attentiondrawer = "fade-in";
      this.buttontext="Register Now";     
      this.messagecontent="Wait! Don’t miss out on the best local deals and businesses. Sign up now and start exploring!";
    }    
    
    this.randomImageUrls = this.shuffleArray(this.imageUrls).slice(0, 7);

    this.modalRef = this.modalService.show(this.signuppopup, {
        class: "modal-lg modal-class freeze-resolve",
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
      });  

    }

    shuffleArray(array: any[]): any[] {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
    SignUp(){      
      this.close();
      document.getElementById('sign-up')?.click();
      //this.router.navigate(["/signup"]);      
    }

    close() {
        this.isOpen=false;
        this.modalRef.hide();
    }
}


