import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, } from "@angular/core";
import { BrowserModule,BrowserTransferStateModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/////service===
import { InterceptorService } from "./services/interceptor.service";
import { AsyncPipe, TitleCasePipe } from "@angular/common";
import { DataSharingService } from "./services/data.share.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LoaderComponent } from "./common/loader/loader.component";
import { HeaderComponent } from "./common/header/header.component";
import { HomePageComponent } from './extrnal/home-page/home-page.component';
import { FooterComponent } from './common/footer/footer.component';
import { AllCategoriesComponent } from './common/all-categories/all-categories.component';
import { SignupComponent } from './extrnal/signup/signup.component';
import { LoginComponent } from "./extrnal/login/login.component";
import { ShareComponentModule } from "./shared/shareComponent.module"
import { SharePlanModule } from "./shared/sharedPlan.module"
import { AutoFocusDirectiveDirective } from './services/auto-focus-directive.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SidebarComponent } from './extrnal/sidebar/sidebar.component';
import { TemplateComponent } from './extrnal/template/template.component';
import { WebSpeechComponent } from './text-speech/web-speech/web-speech.component';
import { AddressLocationComponent } from './extrnal/signup/address-location/address-location.component';
import { PageRedirectionComponent } from "./extrnal/page-redirection/page-redirection.component";
import { firebaseEnvironment } from "../environments/firebase";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { InboxComponent } from './extrnal/inbox/inbox.component';
import { DatePipe } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AboutusComponent } from './extrnal/aboutus/aboutus.component';
import { TermsUseComponent } from './extrnal/terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './extrnal/privacy-policy/privacy-policy.component';
import { SettingComponent } from './extrnal/setting/setting.component';
import { UserBusinessListComponent } from './common/header/user-business-list/user-business-list.component';
import { BuyLeadsComponent } from "./merchantPenal/buy-leads/buy-leads.component";
import { TermsServiceComponent } from "./extrnal/terms-service/terms-service.component";
import { MerchantTermsConditionsComponent } from './extrnal/merchant-terms-conditions/merchant-terms-conditions.component';
import { RefundPolicyComponent } from './extrnal/refund-policy/refund-policy.component';

import { googleMapsEnvironment } from "./../environments/geokey";
import { GrievanceComponent } from './extrnal/grievance/grievance.component';
import { SupportComponent } from './extrnal/support/support.component';
import { SitemapComponent } from './extrnal/sitemap/sitemap.component'
import { GoogleMapModule } from "./common/google-map/google-map.module";
import { LocationPopupComponent } from './extrnal/location-popup/location-popup.component';
import { SignupPopupComponent } from './extrnal/signup-popup/signup-popup.component';
@NgModule({ 
  declarations: [
    AppComponent,
    SidebarComponent,
    LoaderComponent,
    HeaderComponent,
    HomePageComponent,
    FooterComponent,
    AllCategoriesComponent,
    SignupComponent,
    LoginComponent,
    AutoFocusDirectiveDirective,
    TemplateComponent,
    WebSpeechComponent,
    AddressLocationComponent,
    PageRedirectionComponent,
    AboutusComponent,
    TermsUseComponent,
    PrivacyPolicyComponent,
    SettingComponent,
    UserBusinessListComponent,
    BuyLeadsComponent,
    TermsServiceComponent,
    MerchantTermsConditionsComponent,
    RefundPolicyComponent,
    GrievanceComponent,
    SupportComponent,
    SitemapComponent,
    LocationPopupComponent,
    SignupPopupComponent
  ],
  imports: [
    NgbDropdownModule,
    GoogleMapModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    RouterModule,
    ShareComponentModule,
    SharePlanModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    ServiceWorkerModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SlickCarouselModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),     
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebaseEnvironment.firebase),  
  ],
  exports: [],
  providers: [
    AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    DataSharingService,
    TitleCasePipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
