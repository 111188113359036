<ng-template #loginModel>
    <div class="modal-header">
        <img *ngIf="showPopup==='login'" class="popupLogo" src="../../../assets/images/img_logo.png" alt="">
        <button *ngIf="showPopup==='login'" type="button" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="d-flex flex-column h-100" *ngIf="showPopup==='login'">
            <div class="top">
                <h4>Login for seamless experience</h4>
                <p>Don't have an account? <a href="javascript:;" (click)="signUpModel1()">Create account</a>
                </p>
                <form [formGroup]="loginViaPhone" (ngSubmit)="login('1', updateProfile)">
                    <div class="form-group">
                        <div class="phoneNo" id="focusout">
                            <div class="contryCode">+91</div>
                            <input autocomplete="off" (paste)="(false)" appAutoFocusDirective type="tel" class="form-control" placeholder=""
                                formControlName="phoneNumber" maxlength="10" (keypress)="onlyNumberKey($event)">
                        </div>
                        <div class="invlid_error"
                            *ngIf="loginViaPhone.controls['phoneNumber'].hasError('required') && otpError">
                            *Please enter your registered phone number.
                        </div>

                        <div class="invlid_error"
                            *ngIf="!otpError&&loginViaPhone.controls['phoneNumber'].errors?.minlength">
                            *Please enter valid phone number.</div>
                    </div>
                    <button class="btn-main">Request
                        OTP</button>
                </form>
            </div>
            <div class="middle">
                <span class="or_line"><small>Or</small></span>
            </div>
            <div class="bottom1">
                <form [formGroup]="loginForm" (ngSubmit)="login('2', updateProfile )">
                    <div class="form-group">
                        <span>
                            <label>Enter Email/Phone Number</label>
                        </span>
                        <input autocomplete="off"  type="text" class="form-control" formControlName="email" placeholder="">
                        <div class="invlid_error" *ngIf="loginForm.controls['email'].hasError('required') && showError">
                            *Please enter email or phone number.
                        </div>

                        <div class="invlid_error"
                            *ngIf="loginForm.controls['email'].hasError('pattern') && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
                            *Please enter valid email or phone number</div>
                    </div>

                    <div class="form-group">
                        <span>
                            <label>Password</label>
                        </span>
                        <input autocomplete="off" type="password" [type]="showPwd ? 'password' : 'text'" class="form-control"
                            formControlName="password" placeholder="">
                        <a href="javascript:;" class="eye_close" [ngClass]="showPwd ? '' : 'show'"
                            (click)="password()"></a>
                        <div class="invlid_error"
                            *ngIf="loginForm.controls['password'].hasError('required') && showError">
                            *Please enter Password.
                        </div>
                    </div>
                    <a class="frgt clr_grn" href="javascript:;" (click)="forgotPwd()">Forgot
                        password?</a>
                    <button class="btn-main">Continue</button>
                    <!-- <a class="need_help" href="#_">Need help?</a> -->
                </form>
            </div>
        </div>
        <div class="d-flex flex-column h-100" *ngIf="showPopup==='forgot'">
            <a href="javascript:;" class="backBtn"> <img (click)="showPopup='login'"
                    src="../../../assets/images/ic_back.svg" alt=""></a>
            <h4>Forgot password</h4>
            <p>Please enter your registered email or phone number to receive OTP to reset your password
            </p>
            <form [formGroup]="forgotForm" class="d-flex flex-column h-100" (ngSubmit)="submitForgot()">
                <div class="form-group">
                    <input appAutoFocusDirective type="text" class="form-control" placeholder=""
                        formControlName="phoneNumber">
                    <div class="invlid_error"
                        *ngIf="forgotForm.controls['phoneNumber'].hasError('required') && showError">
                        *Please enter email or Phone number.
                    </div>
                    <div class="invlid_error"
                        *ngIf="forgotForm.controls['phoneNumber'].hasError('pattern') && (forgotForm.controls['phoneNumber'].dirty || forgotForm.controls['phoneNumber'].touched)">
                        Please enter valid email or phone number.</div>
                </div>
                <div class="bottom">
                    <button class="btn-main">Request OTP</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #updateProfile>
    <div class="modal-body">
        <h4>Congratulations!</h4>
        <p> You are on Basic-Signature Level. Complete your profile and get upgraded to Elite Level.</p>
    </div>
</ng-template>
<!-- <app-signup #signUp></app-signup> -->