import { Component, OnInit, ViewChild,Input, ElementRef, TemplateRef, Renderer2, HostListener, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl, } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService,ToastrConfig } from "ngx-toastr";
import { SignupComponent } from "../signup/signup.component"
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataSharingService } from "../../services/data.share.service"
import { MessagingService } from '../../services/messaging.service';
import { Location } from '@angular/common';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @Input() signuppopupintervalId: any;
  modalRef: BsModalRef;
  loginForm: FormGroup;
  showPopup = "login"
  showError = false;
  otpError = false;
  showPwd = true;
  loginViaPhone: FormGroup;
  forgotForm: FormGroup;
  phoneNumber: any;
  showSignup = false;
  @ViewChild('loginModel') private loginModel: TemplateRef<any>
  constructor(private setLocation: Location,   private dataSharingService: DataSharingService, private modalService: BsModalService, private formBuilder: FormBuilder,
    private messagingService: MessagingService,
    private router: Router, private api: AdminService, private toastrService: ToastrService, private elementRef: ElementRef, private renderer: Renderer2,
  ) {
    this.loginViaPhone = this.formBuilder.group({
      phoneNumber: ["", Validators.compose([Validators.required, Validators.minLength(10)])],
      countryCode: ["+91"],
    });

    this.loginForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      password: ["", Validators.compose([Validators.required])],
      countryCode: [""],
    })
    this.forgotForm = this.formBuilder.group({
      phoneNumber: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      countryCode: ["+91"],
    });
  }

  ngOnInit(): void {
    if (this.router.url == "/user_login") {
      setTimeout(() => {
        this.openModal("1")
      }, 100);
      this.dataSharingService.signuppopupintervalIdshare.subscribe((data) => {
        this.signuppopupintervalId = data;
      });
      clearInterval(this.signuppopupintervalId);
    } else {
      //this.closeSignUp()
      this.showPopup = "login"
      this.forgotForm.reset();
    }
  }
  close() {
    if ((this.router.url == "/signup") || (this.router.url == "/user_login")) {
      this.router.navigate(["/"]);
    }
    if(this.router.url=="/"){
      this.setLocation.replaceState("/")   
    }
    this.showPopup = "login"
    this.modalRef.hide();
  }
  openModal(type) {
    this.showPopup = "login";
    if (type === "1") {
      if((this.router.url=="/") || (this.router.url=="/signup" ) || (this.router.url=="/user_login" )){
        this.setLocation.replaceState("/user_login")   
      }
      this.loginViaPhone.reset();
      this.loginForm.reset();
    }
    if (type === "4") {
      this.showPopup = "forgot"
    }
    if (type > "4") {
      this.loginViaPhone.patchValue({ 'phoneNumber': type ? type : '' });
    }
    this.modalRef = this.modalService.show(this.loginModel, {
      animated: false,
      backdrop: 'static',
      class: "right modal-log-sign"
    });

  }
  signUpModel1(){ 
    this.showSignup = true;
    this.modalRef.hide();
    let data={
      "type":"1", "phn":"null", "from":'', "index":""
    }
    this.api.sendClickEvent(data);
    // setTimeout(() => {
    //   this.signupModel.openModal("1", "null", '', "");
    // }, 200);
  }

  signUpModel(type, phn, index) {
    this.showSignup = true;
    this.modalRef.hide();
    let data={
      "type":type, "phn":phn, "from":'', "index":index
    }   
    setTimeout(() => {
      this.api.sendClickEvent(data);
    }, 200);
  }
  onlyNumberKey(event) {
    return this.api.allowOnlyNumbers(event);
  }
  login(type, popup) {
    this.loginForm.value["deviceToken"] = this.getDeviceToken();
    this.loginForm.value["deviceType"] = "web"
    let form = type === "1" ? this.loginViaPhone : this.loginForm;
    this.loginViaPhone.value["countryCode"] = "+91"
    let data;
    if (type !== "1") {
      var email_number = this.loginForm.value.email;
      if (isNaN(email_number) == true) {
        data = {
          "email": this.loginForm.value.email,
          "password": this.loginForm.value.password,
          "deviceToken": this.getDeviceToken(),
          "deviceType": "web"
        }
      }
      if (isNaN(email_number) == false) {
        data = {
          "countryCode": "+91",
          "deviceToken": this.getDeviceToken(),
          "deviceType": "web",
          "phoneNumber": this.loginForm.value.email,
          "password": this.loginForm.value.password
        }
      }
    }
    if (form.valid) {
      this.api.postData(apiUrl._login, type === "1" ? this.loginViaPhone.value : data)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            if (type === "1") {
              this.phoneNumber = res.data?.user?.phoneNumber;
              if (this.router.url == "/signup" || this.router.url == "/user_login") {
                this.signUpModel("2", this.phoneNumber, "5");
              } else {
                this.signUpModel("2", this.phoneNumber, "");
              }
            } else {
              if(res.data.user?.user_location?.city){                             
                     this.close();
                     let currentCity = localStorage.getItem("city")
                     let currentState = localStorage.getItem("state")
                     localStorage.setItem("city", res.data.user?.user_location?.city ? res.data.user?.user_location?.city : currentCity ? currentCity : 'Sahibzada Ajit Singh Nagar');
                     localStorage.setItem("state", res.data.user?.user_location?.state ? res.data.user?.user_location?.state : currentState ? currentState : 'Punjab');
                     localStorage.setItem("webToken", btoa(res.data.accessToken));
                     let userInfo = res.data?.user;
                     let userinfo = JSON.stringify(res.data?.user);
                     if (userinfo) {
                       localStorage.setItem("customerDetail", userinfo);
                     }
                     const toastConfig: Partial<ToastrConfig> = {
                      toastClass: 'custom-toast', // Apply custom CSS class
                      progressBar: true, // Add progress bar
                      timeOut: 10000 ,// Set timeout for auto-dismissal
                      enableHtml: true,
                     };  

                    var message = "Welcome Back! We are showing results based on your last used location " + res.data.user?.user_location?.city  + ". You can always update your location to see customised results.";
                    this.toastrService.success('<span class="toast-icon"><i class="fa fa-check-circle"></i></span> ' + message, '', toastConfig);
               }   
              
                     this.dataSharingService.isLoginChanged.next(true);
                   }
                 }
        }, err => {
          //this.api.showErrorMsg(err.error.message);
        })
    } else {
      if (type === "1") {
        this.otpError = true;
      } else {
        this.showError = true;
      }
      setTimeout(() => {
        this.otpError = false;
        this.showError = false;
      }, 10000);
    }

  }

  removeBoldTags(inputString) {
    // Replace <b> and </b> tags with an empty string
    return inputString.replace(/<\/?b>/g, '');
  }

  password() {
    this.showPwd = !this.showPwd
  }
  submitForgot() {
    if (this.forgotForm.valid) {
      var email_number = this.forgotForm.value.phoneNumber;
      let data;
      let phn
      if (isNaN(email_number) == true) {
        phn = email_number
        data = {
          'email': email_number
        }
      }
      if (isNaN(email_number) == false) {
        phn = email_number
        data = {
          'countryCode': "+91",
          'phoneNumber': email_number,
        }
      }
      this.api.postData(apiUrl._forgot, data)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            localStorage.setItem("forgotToken", res.data);
            this.signUpModel("4", phn, "5");
            this.toastrService.success(
              "Send OTP successfully",
              ""
            );
          }
        }, err => {
          this.api.showErrorMsg(err.error.message);
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 10000);
    }
  }
  forgotPwd() {
    this.showPopup = 'forgot';
    this.forgotForm.reset();
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
}