import { Component, OnInit, ViewChild, ElementRef, TemplateRef, HostListener, AfterViewChecked, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ChatService } from '../../services/chat.service';
import { MessagingService } from './../../services/messaging.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AdminService } from "src/app/services/admin.service";
import { Observable, Subscription } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService,ToastrConfig } from "ngx-toastr";
import { environment, chatEnvironment } from 'src/environments/environment';
import { apiUrl } from "../../global/global";
import { DateagoPipe } from '../../pipe/dateago.pipe'
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { UsernameValidator } from "../../services/NoWhiteSpace.validator";
import { GallerySliderComponent } from 'src/app/extrnal/categories/sub-category/business-listing/gallery-slider/gallery-slider.component';
import { FeedbackPopupComponent } from 'src/app/common/feedback-popup/feedback-popup.component';
import Swal from 'sweetalert2';
import { DataSharingService } from 'src/app/services/data.share.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css'],
  providers: [
    DateagoPipe
  ],
})
export class ChatsComponent implements OnInit, AfterViewChecked, OnDestroy {
  dialogListing: any = [];
  FbChats = chatEnvironment.chats;
  FbJobs = chatEnvironment.Jobs;
  FbMessages = chatEnvironment.Messages;
  FbNotifications = chatEnvironment.Notifications;
  FbUsers = chatEnvironment.Users;  
  activeUser = false;
  showError = false;
  filterIcon: boolean = false;
  modalRef: BsModalRef;
  takeForm: FormGroup;
  service_id: any;
  userChatDetails: boolean;
  messageData: any = [];
  firebaseMsg: any = [];
  showLoader1 = false;
  userMessage1: any;
  baseUrl = environment.BaseUrl;
  user_name;
  profile_pic;
  chat_dialog_id;
  job_id;
  oldLeadId: any;
  sendMsg: any;
  user_id;
  chatDisable = "78";
  defultBusinessId: any;
  lead_id: any;
  service_name: any;
  chatForm: FormGroup;
  Chats: Observable<any[]>;
  Messages: Observable<any[]>;
  public userMessage: string = '';
  chatFileName: any;
  screenHight: any;
  closeSearch = false;
  search: any;
  inboxData: any;
  activeTabs = "1";
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('imgGallery') private imgModel: GallerySliderComponent;
  @ViewChild('feedbackModal') private feedbackModal: FeedbackPopupComponent;
  callData: { businessId: any; fromMerchant: boolean };
  dialog: any;
  currentBusiness;
  subscription:Subscription;
  // @ViewChild('target') private myScrollContainer: ElementRef;
  constructor(private modalService: BsModalService, 
    private toasterService: ToastrService, 
    private route: ActivatedRoute, 
    private chatService: ChatService, 
    public dateagoPipe: DateagoPipe, 
    private router: Router, 
    private db: AngularFireDatabase, 
    private afAuth: AngularFireAuth, 
    private messagingService: MessagingService, 
    private formBuilder: FormBuilder, 
    private storage: AngularFireStorage, 
    private api: AdminService, 
    private http: HttpClient, 
    private handler: HttpBackend, 
    private toastrService: ToastrService,
    private dataService:DataSharingService,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.chatForm = formBuilder.group({
      message: ['',],
    });
    this.takeForm = this.formBuilder.group({
      businessId: ["", Validators.compose([Validators.required])],
      leadId: ["", Validators.compose([Validators.required])],
      note: ["", Validators.compose([Validators.required, UsernameValidator.cannotContainSpace])],
      tag: ["", Validators.compose([Validators.required])],
      status: ["", Validators.compose([Validators.required])],
    })
    this.api.firebaseSignIn();
    // this.Chats = this.chatService.Chats;
    // this.Messages = this.chatService.ChatMessages;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHight = window.innerHeight;
  }
  ngOnInit(): void {
    this.api.firebaseSignIn();
    let chatDat = sessionStorage.getItem("chatData");
    let inboxData = chatDat ? JSON.parse(chatDat) : '';
    sessionStorage.removeItem("chatData");
    this.defultBusinessId = this.api.getBusinessId();
    this.callData = {
      businessId: this.defultBusinessId,
      fromMerchant: true
    }
    this.getJobDialogs(this.defultBusinessId);
    if (inboxData) {
      this.getJobDialogs(inboxData.jobId);
      let merchantId = inboxData.serviceID ? inboxData.serviceID : inboxData.jobId;
      let chat_dialog_id = merchantId + ',' + inboxData.userId
      this.chat_dialog_id = chat_dialog_id;
      this.user_name = inboxData.userName;
      this.profile_pic = "";
      this.job_id = inboxData.jobId;
      this.user_id = inboxData.userId;
      this.service_id = inboxData.serviceID;
      this.service_name = inboxData.service_name;
      this.getchatFromAPI(chat_dialog_id, "1");
      this.readMsg()
    }
    this.scrollToBottom();
    this.screenHight = window.innerHeight;
    this.getFormSetting()
    this.getCurrentBusiness()
  }
  getFormSetting() {
    let planData = this.api.getPlanData();
    let currentPlanSetting = planData ? planData?.subscription_plan_list?.subscription_list : '';
    if (!currentPlanSetting) {
      this.checkSubscriptionPlan("2");
    } else {
      if (currentPlanSetting.inboxAccess !== 1) {
        this.router.navigateByUrl(`/dashboard`);
      }
    }
  }
  async checkSubscriptionPlan(type) {
    let planData;
    planData = await this.api.getSubscriptionPlan(type);
    let currentPlanSetting = planData ? planData?.subscription_plan_list?.subscription_list : '';
    if (currentPlanSetting) {
      if (currentPlanSetting.inboxAccess !== 1) {
        this.router.navigateByUrl(`/dashboard`);
      }
    }
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer?.nativeElement?.scrollHeight;
    } catch (err) {
      console.log(err)
    }
  }

  scrollToElement(el): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }


  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  checkDate(date) {
    setInterval
    let newDate = this.dateagoPipe.transform(date);
    return newDate
  }

  updateVale(event) {
    let message = this.chatForm.value.message ? this.chatForm.value.message : '';
    let text
    if (message) {
      text = message.charAt(0).toUpperCase() + message.slice(1);
      this.chatForm.patchValue({ "message": text })
    }
  }

  public sendMessage() {
    if (this.chatForm.value.message) {
      let unReadCount = 0;
      let serviceName;
      let serviceId;
      if (this.dialogListing.length > 0) {
        let filterData = this.dialogListing.filter(res => {
          if (res.chat_dialog_id === this.chat_dialog_id) {
            unReadCount = res.unread_count[res.user_id] ? res.unread_count[res.user_id] : 0;
            serviceId = res.service_id ? res.service_id : ''
            serviceName = res.service_name ? res.service_name : '';
          }
        })
      }
      let message = this.chatForm.value.message ? this.chatForm.value.message.trim() : '';
      if (message) {
        this.chatForm.reset();
        this.scrollToBottom();
        const text = message.charAt(0).toUpperCase() + message.slice(1);
        this.sendMsg = 1;
        this.chatService.sendMessage(text, this.chat_dialog_id, this.job_id, this.user_id, "1", unReadCount, serviceName, serviceId);
      }
    }
  }
  getJobDialogs(job_id: string) {
    this.db.list(this.FbChats,
      query => query
        .orderByChild("job_id")
        .equalTo(job_id)
      //.limitToLast(20)
    ).valueChanges().subscribe(listNew => {
      this.showLoader1 = false;
      let listWithTime: any = listNew.filter((x: any) => x.last_message_time)
      this.dialogListing = Object.assign([], listWithTime.sort((a, b) => b['last_message_time'] - a['last_message_time']))
      listNew.filter((x: any) => !x.last_message_time).forEach(x => {
        this.dialogListing.push(x);
      });
    });
  }

  readMsg() {
    this.chatService.updateMsgCount(this.chat_dialog_id, this.job_id, this.user_id, "1", this.service_id, 0);
    let filterData = this.dialogListing.filter(res => {
      if (res.chat_dialog_id === this.chat_dialog_id) {
        this.chatDisable = res.is_chat_enable.toString();
      };
    });
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      this.chat_dialog_id = "";
      this.sendMsg = 1;
      this.messageData = [];
      this.firebaseMsg = [];
    }
  }
  backToList() {
    this.userChatDetails = false;
  }
  checkLeadID(currentID, index) {
    if (index == 0) {
      this.oldLeadId = currentID
      return true;
    }
    else if (currentID == this.oldLeadId) {
      return false;
    }
    else {
      this.oldLeadId = currentID;
      return true;
    }
  }

  getChatHistory(chat_dialog_id, job_id, user_id, user_name, profile_pic, lead_id, service_name, service_id) {
    this.userChatDetails = true;
    this.showLoader1 = false;
    this.activeUser = true;
    this.chat_dialog_id = chat_dialog_id;
    this.user_name = user_name;
    this.service_id = service_id;
    this.service_name = service_name;
    this.profile_pic = profile_pic;
    this.job_id = job_id;
    this.user_id = user_id;
    this.lead_id = lead_id;
    if (this.chat_dialog_id != '') {
      this.getchatFromAPI(this.chat_dialog_id, "1");
      if (this.dialogListing.length > 0) {
        this.readMsg();
      }
    }
  }
  getchatFromAPI(chat_dialog_id, type) {
    let data = {
      "chatDialogId": chat_dialog_id
    }
    this.api.getData(apiUrl._chatHistory, data).subscribe(async (res) => {
      this.messageData = res.data;
      let filterData = this.dialogListing.filter(res => {
        if (res.chat_dialog_id === chat_dialog_id) {
          this.chatDisable = res.is_chat_enable.toString();
        };
      });
      this.scrollToBottom();
      this.api.loader(false)
      this.getMessages(this.chat_dialog_id);
    },
      err => {
        console.log(err, "err");
      });
  }
  getMessages(chat_dialog_id) {
    this.db.list(`${this.FbMessages}/${this.chat_dialog_id}`,
      query => query
        .orderByChild("chat_dialog_id")
        .equalTo(chat_dialog_id)
        .limitToLast(2)
    ).valueChanges().subscribe(list => {
      this.firebaseMsg = Object.assign([], list);
      this.firebaseMsg.filter(item => {
        if (item?.message_id) {
          let count = 0;
          this.messageData.filter(data => {
            if (item?.message_id == data?.message_id) {
              count = 1;
            }
          })
          if (count == 0) {
            if (item?.chat_dialog_id === this.chat_dialog_id) {
              this.messageData.push(item);
              if (this.sendMsg !== 1) {
                this.readMsg();
              }
              this.sendMsg = "";
            }
          }
          console.log("this.messageData", this.messageData)
        }      
      })
      setTimeout(() => {
        this.scrollToBottom();
      }, 100)
    });
  }
  onFileSelected(event) {
    if (event.target.files[0].size <= 20000000) {
      const selectedFile = event.target.files[0];
      const idxDot = selectedFile.name.lastIndexOf('.') + 1;
      var extFile = selectedFile.name
        .substr(idxDot, selectedFile.name.length)
        .toLowerCase();
      if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png') {
        //this.spinner.show();
        const file = event.target.files[0];
        this.uploadFileToS3(file, 2);
        this.showLoader1 = true;
        event.target.value = null;
      } else if (extFile == 'video/mp4' || extFile == 'mp4') {
        const file = event.target.files[0];
        this.uploadFileToS3(file, 3);
        this.showLoader1 = true;
        event.target.value = null;
      } else {
        this.showLoader1 = false;
        this.toastrService.error(
          'Only jpg/jpeg, png, and video files are allowed!',
          '',
          {
            timeOut: 5000,
          }
        );
      }
    } else {
      this.showLoader1 = false;
      this.toastrService.error('File size exceeds maximum limit 20MB.', '', {
        timeOut: 5000,
      });
    }
  }

  uploadFileToS3(fileUpload, x) {
    this.api.loader(false)
    const data = {
      "directory": 'assets/',
      "fileName": fileUpload.name,
      "contentType": fileUpload.type
    }
    this.api.postData(apiUrl._upload, data)
      .subscribe(res => {
        this.http = new HttpClient(this.handler);
        this.http.put<any>(res.data.uploadURL, fileUpload).subscribe(response => {
          let unReadCount = 0;
          let serviceId;
          let serviceName;
          let filterData = this.dialogListing.filter(res => {
            if (res.chat_dialog_id === this.chat_dialog_id) {
              unReadCount = res.unread_count[res.user_id];
              serviceId = res.service_id ? res.service_id : ''
              serviceName = res.service_name ? res.service_name : ''
            }
          })
          this.sendMsg = 1;
          this.showLoader1 = false;
          this.chatService.sendMessageDocument(res.data.fileName, this.chat_dialog_id, this.job_id, this.user_id, x, "1", unReadCount, serviceName, serviceId)
        })
      })
  }
  clearChat() {
    let index = this.messageData.length - 1;
    let data = {
      "messageId": this.messageData[index].message_id
    }
    this.api.postData(apiUrl._clearChat, data).subscribe(res => {
      var deleteTime = {};
      deleteTime[this.service_name ? this.service_name : this.job_id] = new Date().getTime();
      deleteTime[this.user_id] = 0;
      var lastMessageArr = {};
      lastMessageArr[this.user_id] = "";
      lastMessageArr[this.service_name ? this.service_name : this.job_id] = "";
      var chatDialog = this.db.object(`${this.FbChats}/${this.chat_dialog_id}`).update({
        "clear_chat_time": deleteTime,
        "last_message": lastMessageArr,
        "last_message_type": ""
      });
      this.db.object(`${this.FbMessages}/${this.chat_dialog_id}`).remove();
      this.getMessages(this.chat_dialog_id)
      this.messageData = [];
      setTimeout(() => {
        this.firebaseMsg = [];                         //<<<---using ()=> syntax
        this.messageData = [];
      }, 10);

    },
      err => {
        console.log(err, "err");
      });
  }
  chnageSearch(e) {
    let searchTxt = e.target.value.trim()
    if (searchTxt.length > 1) {
      this.searchFun();
    }
    if (searchTxt.length == 0) {
      this.refreshPage()
    }
  }
  searchFun() {
    let searchTxt = this.search.trim();
    // this.showSearchBox = true;
    if (searchTxt == "") {
      this.closeSearch = false;
      this.getJobDialogs(this.defultBusinessId);
    }
    else {
      this.closeSearch = true;
      this.dialogListing = this.dialogListing.filter(res => {
        return res.user_name.toLowerCase().match(this.search.toLowerCase()) || res.service_name.toLowerCase().match(this.search.toLowerCase()) || res.lead_id.toLowerCase().match(this.search.toLowerCase())
      })
    }
  }
  filterUser() {
    this.filterIcon = !this.filterIcon;
    if (this.filterIcon) {
      this.dialogListing = this.dialogListing.filter(res => {
        let id = res.service_id ? res.service_id : this.defultBusinessId
        return res.unread_count[id] > 0
      })
    } else {
      this.search="";
      this.getJobDialogs(this.defultBusinessId);
    }
  }
  refreshPage() {
    this.closeSearch = false;
    this.search = '';
    this.getJobDialogs(this.defultBusinessId);
  }
  noteModel(template: TemplateRef<any>) {
    let data = {
      "businessId": this.job_id,
      "userId": this.user_id
    }
    this.api.getData(apiUrl._getLeadDetails, data).subscribe(async (res) => {
      this.takeForm.patchValue({
        "businessId": res.data.businessId,
        leadId: res.data.id,
        tag: res.data.tag,
        status: res.data.status,
      })
      this.showError = false;
      this.modalRef = this.modalService.show(template, {
        animated: true,
        class: "right modal-log-sign"
      });
    },
      err => {
        console.log(err, "err");
      });
  }
  close() {
    this.showError = false;
    this.modalRef.hide();
  }
  submitNote() {
    if (this.takeForm.valid) {
      this.api.postData(apiUrl._addNote, this.takeForm.value).subscribe(res => {
        this.close();
        this.toasterService.success(
          "Note added successfully",
          ""
        );
      },
        err => {
          console.log(err, "err");
        });

    } else {
      this.showError = true;
    }
  }
  closeChat(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: false,
      class: "modal-dialog-centered borderRadius modal-r"
    });
  }
  updateLead(result) {
    let leadId;

    console.log("this.dialogListing", this.dialogListing)
    console.log("this.chat_dialog_id", this.chat_dialog_id)
    let leadIdget = this.dialogListing.filter(res => {
      if (res.chat_dialog_id === this.chat_dialog_id) {
        leadId = res.lead_auto_id
      }
    });
    let leaddata = {
      "result": Number(result),
      "businessId": this.defultBusinessId,
      "leadId": leadId,
    }
    this.api.putData(apiUrl._updateLeadsStatus, leaddata).subscribe(async (res) => {
      let data = {
        "id": this.service_name ? this.service_id : this.job_id,
        "type": this.service_name ? 2 : 1,
        "leadId": leadId,
      }
      this.api.deleteData(apiUrl._deletChat, data).subscribe(async (res) => {
        this.chatDisable = "0";
        var chatDialog = this.db.object(`${this.FbChats}/${this.chat_dialog_id}`).update({
          "is_chat_enable": 0
        });
        this.close();
        // let dataModal={
        //   businessId:this.defultBusinessId,
        //   from:'merchant',
        //   leadId:leadId
        // }
        // this.feedbackModal.openModal(dataModal);
      });
    },
      err => {
        console.log(err, "err");
      });
  }
  showBigsize(img, type) {
    let title;
    if (type === 1) {
      title = ""
    }
    if (type === 2) {
      title = ""
    }
    const data = { "title": title, "file": img, "type": type }
    this.imgModel.openMedia("1", data, '');
  }
  chatTabs(type) {
    this.activeTabs = type;
  }
  selectChat(event) {
    this.activeTabs = "1";
    let dialog = this.dialogListing.find(x => x.chat_dialog_id == event);
    this.getChatHistory(dialog.chat_dialog_id, dialog.job_id, dialog.user_id, dialog.name[dialog.user_id], dialog.pic[dialog.job_id], dialog.lead_id, dialog.service_name, dialog.service_id)
  }
  onCallClick() {
    let params = {
      callerNumber: this.currentBusiness.phoneNumber,
      userId: this.dialog.user_id
    }
    this.api.getData(apiUrl._getAvailableDID, params).subscribe(res => {
      if (this.api.checkDevice() == 'mobile') {
        if(res.data.result.DID){
          //window.open('tel:' + res.data.result.DID);
           window.location.href = 'tel:'  + res.data.result.DID;
        }else{
          const toastConfig: Partial<ToastrConfig> = {
            toastClass: 'error-custom-toast', // Apply custom CSS class
            progressBar: true, // Add progress bar
            timeOut: 10000 ,// Set timeout for auto-dismissal
            enableHtml: true,
            };
      
            var message = "Business is busy on another call , please try calling some other business.";
            this.toastrService.success('<span class="error-toast-icon"><i class="fa fa-close"></i></span> ' + message, '',
            toastConfig);        
        }
      }
      else {
        Swal
          .fire({
            text: "Call is only possible through our mobile application or mobile browser. Please download the app or open the link in the mobile browser.",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#14141f",
            cancelButtonColor: "#939393",
            allowOutsideClick: true,
            confirmButtonText: "Ok",
          });
      }
    });
  }
  selectChatDetails(dialog) {
    this.dialog = dialog;
  }
  getCurrentBusiness()
  {
    this.subscription =this.dataService.currentBusiness.subscribe(value=>{
      if(value)
      {
        this.currentBusiness=value
      }
    })
  }
}
