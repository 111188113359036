import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
@Component({
  selector: 'app-terms-use',
  templateUrl: './terms-use.component.html',
  styleUrls: ['./terms-use.component.css']
})
export class TermsUseComponent implements OnInit {

  constructor(private admin: AdminService,) { }

  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Terms and Conditions for Users", pageImg, "Terms and Conditions for Users", "","");
  }

}
