<section class="pt-4 favorites-page">
    <div class="container">
        <ul class="inner_tabs">
            <li><a href="/">Home </a> ></li>
            <li>
                <a href="javascript:;" routerLink="/myfinndit">My Finndit </a>>
            </li>
            <li>Favorites</li>
        </ul>

        <div class="slider slide">
            <ngx-slick-carousel *ngIf="showCrousel" class="carousel" #slickModal="slick-carousel"
                [config]="slideConfig">
                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                    <img src="{{ slide.img }}" alt="" width="100%">
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="category_lst_sec">
            <div class="row">
                <div class="col-md-12">
                    <div class="head_ttl">
                        <h4> Favorites ({{count}}) </h4>
                        <div class=" sort_by ">
                            <a *ngIf="showCancel" href="javascript:;" class="btn_brdr mr-2" (click)="cancelButton()">
                                Cancel </a>
                            <a href="javascript:;" [ngClass]="showCancel?'text-red':'text-blue'"
                                class="btn_brdr text-blue" (click)="DeleteMultiple()"
                                *ngIf="allData?.length > 0">{{showCancel?'Remove Selected': 'Remove multiple'}} </a>
                            <div class="invlid_error" *ngIf=" showError">
                                *Please select at least one business.
                            </div>
                        </div>
                    </div>
                    <!-- <p *ngIf="showtext" class='invlid_error float-right' >Please select any business</p> -->
                </div>
            </div>
            <div class="row" *ngIf="allData?.length > 0">
                <div class="col-md-4 col-sm-6" *ngFor="let data of allData; let i = index">
                    <div class="card">
                        <div class="responsiveImgBox">
                            <div class="checkboxWrap" *ngIf="showCheckBox">
                                <input type="checkbox" [id]="'check' + i" [name]="'check' + i" value="check"
                                    (click)="selectMultiple($event, data.businessId)" />
                                <label [for]="'check' + i"> </label>
                            </div>
                            <img [src]=" (data?.businessDetails?.logo !=null) ? this.BaseUrl + data?.businessDetails?.logo : '../../../assets/images/img_categories_details.png '"
                                alt="categories" style='cursor: pointer;'
                                (click)="navigateToBusinessDetail(data?.businessId)" />
                            <small class="verified-batch"><img src="../../../assets/images/ic_verify.svg">
                                Verified </small>
                            <a href="javascript:;" class="delete-btn"><img src="../../../assets/images/ic_delete.svg"
                                    (click)="removeFavourite(data.businessId)"></a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title" style="text-transform: capitalize;">
                                {{data?.businessDetails.displayName}} <img src="../../../assets/images/badge.svg"></h4>
                            <p class="card-text">
                                {{data?.businessDetails.addressLineOne}}</p>
                            <ul>
                                <li>
                                    <a href="javascript:;" class='d-flex align-items-center'><img
                                            src="../../../assets/images/distance.svg" alt="" />
                                        {{data.distance}}</a>
                                </li>
                                <li>
                                    <a href="javascript:;" class='d-flex align-items-center'><img
                                            src="../../../assets/images/star_small.png" alt="" /> <strong
                                            class='mr-1'>{{data?.businessDetails.averageRating}}</strong>
                                        ({{data?.businessDetails.numberOfReviews==1?data?.businessDetails.numberOfReviews+'
                                        Review':data?.businessDetails.numberOfReviews +' Reviews'}} )</a>
                                </li>
                            </ul>
                            <ul class="btn_call">
                                <li><a class="btn_brdr" href="javascript:;" (click)="onCallClick(data)">Call</a>
                                </li>
                                <li><a class="btn_brdr"
                                        (click)="onChatClick(data, chatModel)">{{data.businessDetails?.isExpressInterest===0
                                        ? 'Express interest':'Chat'}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane active mt-4 text-center" style='margin: 80px 0px !important'
                *ngIf="allData?.length==0">No Favorites Yet</div>
        </div>

    </div>
</section>
<ng-template #chatModel>
    <div class="modal-header">
        <button type="button" id="chat-pop-close" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <app-chat-model (chatClosed)="closeChat($event)" [businessChatInfo]="businessChatInfo"></app-chat-model>
    </div>
</ng-template>
<ng-template #confirmModal>
    <div class="modal-header pb-0 pt-0">
        <div class=" col-md-12 text-center">
            <h4>Remove from favourites</h4>
        </div>
    </div>
    <div class="modal-body pb-0">
        <div class=" text-center">
            <p>{{this.data.businessIds.length==1?this.data.businessIds.length+' business':this.data.businessIds.length
                +' businesses'}} will be removed from your favorites. Are you sure want to continue?</p>
        </div>
        <div class="bottom text-center mt-2">
            <button class="confrm btn-main " (click)="confirmDelete()">Confirm</button>
            <a class="confrm btn-main borderButton" href="javascript:;" (click)="cancelDelete()">Cancel</a>
        </div>
    </div>
</ng-template>