import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ChatService } from '../../../../../services/chat.service';
import { MessagingService } from '../../../../../services/messaging.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService } from "src/app/services/admin.service";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { ToastrService } from "ngx-toastr";
import { apiUrl } from "../../../../../global/global";
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { GallerySliderComponent } from '../gallery-slider/gallery-slider.component';
import { TitleCasePipe } from '@angular/common';
import { chatEnvironment } from 'src/environments/environment';
import { FeedbackPopupComponent } from 'src/app/common/feedback-popup/feedback-popup.component';
@Component({
  selector: 'app-chat-model',
  templateUrl: './chat-model.component.html',
  styleUrls: ['./chat-model.component.css']
})
export class ChatModelComponent implements OnInit {
  fbChats = chatEnvironment.chats;
  fbJobs = chatEnvironment.Jobs;
  fbMessages = chatEnvironment.Messages;
  fbNotifications = chatEnvironment.Notifications;
  fbUsers = chatEnvironment.Users; 
  @Input() businessChatInfo: any;
  dialogListing: any = [];
  showLoader1 = false;
  messageData: any = [];
  firebaseMsg: any = [];
  baseUrl = environment.BaseUrl;
  userMessage1: any;
  service_id: any;
  user_name;
  profile_pic;
  sendMsg: any;
  chat_dialog_id;
  job_id;
  chatDisable = "78";
  user_id;
  userAllData: any;
  businessId: any;
  bussionName: any;
  Chats: Observable<any[]>;
  Messages: Observable<any[]>;
  public userMessage: string = '';
  chatFileName: any;
  businessUserName: any;
  chatForm: FormGroup;
  defultMsg: any;
  isExpressInterest: any;
  serviceName: any;
  merchantSettings: any;
  serviceId: any;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('feedbackModal') private feedbackModal: FeedbackPopupComponent;
  @ViewChild('imgGallery') private imgModel: GallerySliderComponent;
  @Output() chatClosed= new EventEmitter();
  leadId: any;
  constructor(private chatService: ChatService, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private titlecasePipe: TitleCasePipe, private messagingService: MessagingService, private formBuilder: FormBuilder, private storage: AngularFireStorage, private api: AdminService, private http: HttpClient, private handler: HttpBackend, private toastrService: ToastrService) {
    this.chatForm = formBuilder.group({
      message: ['',],
    });
  } 
  ngOnChanges(businessChatInfo: any): void {
    this.businessId = businessChatInfo.businessChatInfo.currentValue?.id;
    this.bussionName = businessChatInfo.businessChatInfo.currentValue?.Name;
    this.businessUserName = businessChatInfo.businessChatInfo.currentValue?.businessName;
    this.isExpressInterest = businessChatInfo.businessChatInfo.currentValue?.isExpressInterest;
    this.defultMsg = businessChatInfo.businessChatInfo.currentValue?.defultMsg;
    this.serviceName = businessChatInfo.businessChatInfo.currentValue?.serviceName;
    this.serviceId = businessChatInfo.businessChatInfo.currentValue?.serviceID;
    this.merchantSettings = businessChatInfo.businessChatInfo.currentValue?.merchantSettings ? businessChatInfo.businessChatInfo.currentValue?.merchantSettings:'';
  }
  ngOnInit(): void {
    const userData = this.api.getUserSaveData();
    if (userData) {
      let userInfo = userData;
      this.userAllData = userInfo
    }

    this.getChatHistory(this.businessId, this.userAllData);
    let userID = this.userAllData.id
    this.getJobDialogs(userID)
  }
  ngAfterViewChecked() {
    // this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }
  updateVale(event) {
    let message = this.chatForm.value.message ? this.chatForm.value.message : '';
    let text
    if (message) {
      text = message.charAt(0).toUpperCase() + message.slice(1);
      this.chatForm.patchValue({ "message": text })
    }
  }

  async sendMessage() {
    if (this.chatForm.value.message) {
      let unReadCount = 0;
      if (this.chat_dialog_id.length > 0) {
        let filterData = this.dialogListing.filter(res => {
          if (res.chat_dialog_id === this.chat_dialog_id) {
            unReadCount = res.unread_count[res.service_id ? res.service_id : res.job_id];
          }
        });
      };

      let message = this.chatForm.value.message ? this.chatForm.value.message.trim() : '';
      if (message) {
        const text = message.charAt(0).toUpperCase() + message.slice(1);
        this.chatForm.reset();
        this.scrollToBottom();
        this.sendMsg = 1;
        this.chatService.sendMessage(text, this.chat_dialog_id, this.job_id, this.user_id, "0", unReadCount, this.serviceName, this.serviceId);
      }

    }
  }
  getJobDialogs(user_id) {
    this.db.list(this.fbChats,
      query => query
        .orderByChild("user_id")
        .equalTo(user_id)
      //.limitToLast(20)
    ).valueChanges().subscribe(listNew => {
      this.dialogListing = Object.assign([], listNew)
      if (this.dialogListing.length > 0) {
        //this.readMsg();
      }
      this.leadId = this.dialogListing[0]?.lead_auto_id;
    });
  }

  getChatHistory(businessId, userData) {
    this.chat_dialog_id = businessId + ',' + userData.id;
    this.user_name = userData.fullName;
    this.profile_pic = "";
    this.job_id = businessId;
    this.user_id = userData.id;
    if (this.chat_dialog_id != '') {
      this.getchatFromAPI(this.chat_dialog_id);
    }
  }
  readMsg() {
    let anotherUserCount = 0;
    let filterData = this.dialogListing.filter(res => {
      if (res.chat_dialog_id === this.chat_dialog_id) {
        this.chatDisable = res.is_chat_enable.toString();
        anotherUserCount = res.unread_count[res.service_id ?
          res.service_id : res.job_id];
      };
      this.chatService.updateMsgCount(this.chat_dialog_id, this.job_id, this.user_id, "0", "", anotherUserCount)
    });
  }
  getchatFromAPI(chat_dialog_id) {
    let data = {
      "chatDialogId": chat_dialog_id
    }
    this.api.getData(apiUrl._chatHistory, data).subscribe(async (res) => {
      this.messageData = res.data;
      let filterData = this.dialogListing.filter(res => {
        if (res.chat_dialog_id === chat_dialog_id) {
          this.chatDisable = res.is_chat_enable.toString();
        }
      });
      if (this.defultMsg === 1) {
        if (this.merchantSettings?.sendGreetingMessage === 1) {
          let message = this.merchantSettings.message;
          this.chatService.sendMessage(message, this.chat_dialog_id, this.job_id, this.user_id, "1", 0, this.serviceName, this.serviceId);
        }

        let text1 = `Hello ${this.titlecasePipe.transform(this.bussionName)}, I am interested in your service '${this.titlecasePipe.transform(this.serviceName)}' and would like to discuss further with you`
        let text = `Hello ${this.titlecasePipe.transform(this.bussionName)}, I am interested in your services and would like to discuss further with you`;
        let msgContent = this.serviceName ? text1 : text;
        this.sendMsg = 1;
        this.chatService.sendMessage(msgContent, this.chat_dialog_id, this.job_id, this.user_id, "0", 0, this.serviceName, this.serviceId);
      }
      this.getMessages(this.chat_dialog_id);
    },
      err => {
        console.log(err, "err");
      });
  }

  getMessages(chat_dialog_id) {
    this.api.loader(true);
    this.db.list(`${this.fbMessages}/${chat_dialog_id}`,
      query => query
        .orderByChild("chat_dialog_id")
        .equalTo(chat_dialog_id)
        .limitToLast(100)
    ).valueChanges().subscribe(list => {
      this.firebaseMsg = Object.assign([], list);
      this.firebaseMsg.filter(item => {
        if (item?.message_id) {
          let count = 0;
          this.messageData.filter(data => {
            if (item?.message_id == data?.message_id) {
              count = 1;
            }
          })
          if (count == 0) {
            if (item?.chat_dialog_id === this.chat_dialog_id) {
              this.messageData.push(item);
              if (this.sendMsg !== 1) {
                this.readMsg();
              }
              this.sendMsg = "";
            }
          }
        }
      })
      let filterData = this.dialogListing.filter(res => {
        if (res.chat_dialog_id === chat_dialog_id) {
          this.chatDisable = res.is_chat_enable.toString();
          this.leadId = res.lead_auto_id;         
        }
      })
      this.api.loader(false);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100)

    });
  }
  onFileSelected(event) {
    if (event.target.files[0].size <= 20000000) {
      const selectedFile = event.target.files[0];
      const idxDot = selectedFile.name.lastIndexOf('.') + 1;
      var extFile = selectedFile.name
        .substr(idxDot, selectedFile.name.length)
        .toLowerCase();
      if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png') {
        const file = event.target.files[0];
        this.uploadFileToS3(file, 2);
        this.showLoader1 = true;
        event.target.value = null;

      } else if (extFile == 'video/mp4' || extFile == 'mp4') {
        const file = event.target.files[0];
        this.uploadFileToS3(file, 3);
        this.showLoader1 = true;
        event.target.value = null;
      } else {
        this.showLoader1 = false;
        this.toastrService.error(
          'Only jpg/jpeg, png, and video files are allowed!',
          '',
          {
            timeOut: 5000,
          }
        );
      }
    } else {
      this.showLoader1 = false;
      this.toastrService.error('File size exceeds maximum limit 20MB.', '', {
        timeOut: 5000,
      });
    }
  }
  ngOnDestroy() {
    this.chat_dialog_id = "";
    this.sendMsg = 1;
    this.messageData = [];
    this.firebaseMsg = [];
  }

  uploadFileToS3(fileUpload, x) {
    this.api.loader(false)
    const data = {
      "directory": 'assets/',
      "fileName": fileUpload.name,
      "contentType": fileUpload.type
    }
    this.api.postData(apiUrl._upload, data)
      .subscribe(res => {
        this.http = new HttpClient(this.handler);
        this.http.put<any>(res.data.uploadURL, fileUpload).subscribe(response => {
          let unReadCount = 0;
          let serviceName = "";
          let serviceId = "";
          if (this.chat_dialog_id.length > 0) {
            let filterData = this.dialogListing.filter(res => {
              if (res.chat_dialog_id === this.chat_dialog_id) {
                unReadCount = res.unread_count[res.service_id ? res.service_id : res.job_id]
                serviceId = res.service_id ? res.service_id : ""
                serviceName = res.service_name ? res.service_name : ""
              }
            });
          };
          this.sendMsg = 1;
          this.showLoader1 = false;
          this.chatService.sendMessageDocument(res.data.fileName, this.chat_dialog_id, this.job_id, this.user_id, x, "0", unReadCount, serviceName, serviceId)
        })
      })
  }

  clearChat(messageData) {
    let index = messageData.length - 1;
    let data = {
      "messageId": messageData[index].message_id
    }
    this.api.postData(apiUrl._clearChat, data).subscribe(res => {
      var deleteTime = {};
      deleteTime[this.job_id] = new Date().getTime();
      deleteTime[this.user_id] = 0;
      var lastMessageArr = {};
      lastMessageArr[this.user_id] = "";
      lastMessageArr[this.serviceName ? this.serviceName : this.job_id] = "";
      var chatDialog = this.db.object(`${this.fbChats}/${this.chat_dialog_id}`).update({
        "clear_chat_time": deleteTime,
        "last_message": lastMessageArr,
        "last_message_type": ""
      });
      this.db.object(`${this.fbMessages}/${this.chat_dialog_id}`).remove();
      this.getMessages(this.chat_dialog_id)
      this.messageData = [];
      setTimeout(() => {
        this.firebaseMsg = [];                         //<<<---using ()=> syntax
        this.messageData = [];
      }, 10);
    },
      err => {
        console.log(err, "err");
      });
  }
  closeChat() {
    let data = {
      "id": this.serviceId ? this.serviceId : this.job_id,
      "type": this.serviceId ? 2 : 1,
    }
    this.api.deleteData(apiUrl._deletChat, data).subscribe(async (res) => {
      this.chatDisable = "0";
      var chatDialog = this.db.object(`${this.fbChats}/${this.chat_dialog_id}`).update({
        "is_chat_enable": 0
      });
      let dataModal = {
        from: 'customer',
        leadId: this.leadId,
        businessName:this.bussionName
      }
      // document.getElementById('chat-pop-close')?.click();
      this.chatClosed.emit(this.businessId);
      this.feedbackModal.openModal(dataModal);
    });
  }
  showBigsize(img, type) {
    let title;
    if (type === 1) {
      title = ""
    }
    if (type === 2) {
      title = ""
    }
    const data = { "title": title, "file": img, "type": type }
    this.imgModel.openMedia("1", data, 'chat-popup');
  }
}
