<div class="modal-header">
    <h4>Voice Capture</h4>
    <button type="button" class="closePopup" (click)="closePopup()"></button>
  </div>
  <div class="modal-body">
    <div class="speechDiv" *ngIf="!browserError">
      <ng-template #mic>    
          <button (click)="start()" class="microphoneBox">
            <i class="fa fa-solid fa-microphone"></i>
          </button>     
      </ng-template>
      <div class="marginAuto" [ngClass]="speechStart===1 ? 'recorder-container':''">
        <div class="outer"></div>
        <div class="outer-2"></div>
        <button class="microphoneBox" *ngIf="listening$ | async; else mic" (click)="stop()">
          <i class="fa fa-solid fa-microphone"></i>
        </button>
      </div>
    </div>
    <span *ngIf="errorMessage$| async as errorMessage" class="notification">{{errorMessage}}</span>
    <section class="speechText" *ngIf="transcript$ | async">
      <!-- <div class="notification mat-elevation-z4">{{transcript$ | async}}</div> -->
      {{totalTranscript}}
    </section>
    <div class="bottom" *ngIf="!browserError">
      <div class="row">
        <div class="col-12 col-md-6"><a href="javascript:;" (click)="clear()"
            class="btn-main borderButton">Clear</a></div>
        <div class="col-12 col-md-6" id="searchSpeechId"> <button class="btn-main" (click)="searchSpeech()">Complete</button></div>
      </div>
    </div>
  </div>