import { ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { defaultLanguage, languages } from '../../model/languages';
import { SpeechError } from '../../model/speech-error';
import { SpeechEvent } from '../../model/speech-event';
import { SpeechRecognizerService } from '../../services/speech-recognizer.service';

@Component({
  selector: 'app-web-speech',
  templateUrl: './web-speech.component.html',
  styleUrls: ['./web-speech.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebSpeechComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  speechStart;
  browserError=false;
  languages: string[] = languages; 
  currentLanguage: string = defaultLanguage;
  totalTranscript?: string;
  transcript$?: Observable<string>;
  listening$?: Observable<boolean>;
  errorMessage$?: Observable<string>;
  defaultError$ = new Subject<string | undefined>();

  constructor(
    private speechRecognizer: SpeechRecognizerService) {}
    ngOnInit(): void {
      const webSpeechReady = this.speechRecognizer.initialize(this.currentLanguage);
      if (webSpeechReady) {
        this.initRecognition();
        //this.start();
      }else {
        this.errorMessage$ = of('Your Browser is not supported. Please try Google Chrome.');
        this.browserError=true;
      }
    }
  
    start(): void {
      if (this.speechRecognizer.isListening) {
        this.stop();
        this.speechStart=2;
        return;
      }  
      this.defaultError$.next(undefined);
      this.speechRecognizer.start();
      this.speechStart=1;
    }
  
    stop(): void {
      this.speechRecognizer.stop();
      this.speechStart=2;
    }
  
    selectLanguage(language: string): void {
      if (this.speechRecognizer.isListening) {
        this.stop();
      }
      this.currentLanguage = language;
      this.speechRecognizer.setLanguage(this.currentLanguage);
    }
    clear(){
      this.totalTranscript="";
    }
    closePopup(){
      this.stop();
      this.childEvent.emit({"popup":'close', 'speech':""});
    }
    searchSpeech(){
      this.stop();
      this.childEvent.emit({"popup":'close', 'speech':this.totalTranscript});
    }
   
    private initRecognition(): void {
      this.transcript$ = this.speechRecognizer.onResult().pipe(
        tap((notification) => {
          if (notification.event === SpeechEvent.FinalContent) {
            this.totalTranscript = this.totalTranscript
              ? `${this.totalTranscript}\n${notification.content?.trim()}`
              : notification.content;
          }
        }),
        map((notification) => notification.content || '')
      );
  
      this.listening$ = merge(
        this.speechRecognizer.onStart(),
        this.speechRecognizer.onEnd()
      ).pipe(map((notification) => notification.event === SpeechEvent.Start));
  
      this.errorMessage$ = merge(
        this.speechRecognizer.onError(),
        this.defaultError$
      ).pipe(
        map((data) => {
          if (data === undefined) {
            return '';
          }
          if (typeof data === 'string') {
            return data;
          }
          let message;
          if(data.error){
            this.speechStart=2;
          }
          switch (data.error) {            
            case SpeechError.NotAllowed:
              message = `Your browser is not authorized to access your microphone.
              Verify that your browser has access to your microphone and try again.`;
              break;
            case SpeechError.NoSpeech:
              message = `No speech has been detected. Please try again.`;
              break;
            case SpeechError.AudioCapture:
              message = `Microphone is not available. Plese verify the connection of your microphone and try again.`;
              break;
            default:
              message = '';
              break;
          }
         
          return message;
        })
      );
    }

}
