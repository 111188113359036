import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataSharingService } from "../../services/data.share.service";
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { MessagingService } from '../../services/messaging.service';
@Component({
  selector: 'app-page-redirection',
  templateUrl: './page-redirection.component.html',
  styleUrls: ['./page-redirection.component.css']
})
export class PageRedirectionComponent implements OnInit {
  userId = "";
  message: any;
  userInfo: any;
  bussinessId='';
  advId='';
  constructor(private route: ActivatedRoute, private api: AdminService
    , private dataSharingService: DataSharingService
    , private router: Router,
    private messagingService: MessagingService,
    private renderer: Renderer2) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.bussinessId=params['businessId'];
      this.advId=params['advId']
    });
    if (this.userId != "" && this.userId) {
      this.messagingService.requestPermission()
      this.messagingService.receiveMessage()
      this.message = this.messagingService.currentMessage;
      this.getDeviceToken();
      this.loginUser();
    } else {
      this.router.navigate(['/']);
    }
  }
  async loginUser() {
    let data = {
      "secret_id": this.userId,
      "deviceToken": await this.getDeviceToken(),
      "deviceType": "web"
    }
    this.api.postData(apiUrl._getUserDetails, data).subscribe(async(res) => {
      if (res.statusCode == 200) {
        localStorage.setItem("webToken", btoa(res.data.accessToken));
        this.userInfo = res.data.user;
        localStorage.setItem("customerDetail", JSON.stringify(this.userInfo));
        let planData;
        planData = await this.api.getSubscriptionPlan("2");
        if (planData) {
          let currentPlanSetting = planData?.subscription_plan_list?.subscription_list;
          localStorage.setItem("userPer", JSON.stringify(currentPlanSetting))
        }
        if(this.advId!=='' && this.bussinessId!=='' && this.advId && this.bussinessId)
        {
          this.renderer.addClass(document.body, 'marchantPenal');
          localStorage.setItem("Marchant", "1");
          localStorage.setItem("defultBusiness",btoa (this.bussinessId));
          this.dataSharingService.isLoginChanged.next(true);
          sessionStorage.setItem("advID", this.advId);
          this.dataSharingService.isHeaderChanged.next(true);
          this.router.navigate(['/advertisement/edit']);
        }
        else if (this.userInfo.usersBusiness[0]?.id) {
          if (this.userInfo.usersBusiness[0]?.status === 1 && this.userInfo.usersBusiness[0]?.isClaimed === 1) {
            this.renderer.addClass(document.body, 'marchantPenal');
            localStorage.setItem("Marchant", "1");
            localStorage.setItem("defultBusiness", btoa(res.data?.user?.usersBusiness[0]?.id));
            this.dataSharingService.isLoginChanged.next(true);
          this.dataSharingService.isHeaderChanged.next(true);
          this.router.navigate(['/dashboard']);
          } else {
            this.dataSharingService.isLoginChanged.next(true);
            localStorage.setItem("defultBusiness", btoa(res.data?.user?.usersBusiness[0]?.id));
          this.dataSharingService.isHeaderChanged.next(true);
          this.router.navigate(['/become-merchant']);
          }
        }
        else {
          this.renderer.removeClass(document.body, 'marchantPenal');
          localStorage.removeItem("Marchant");
          this.dataSharingService.isLoginChanged.next(true);
          this.router.navigate(['/become-merchant']);
        }
      }
    });
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
}