import { Component, EventEmitter, HostListener, AfterViewInit, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { PlanService } from 'src/app/extrnal/subscription-plan/plan.service';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { saveAs } from 'file-saver';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { PhonePeComponent } from 'src/app/common/phone-pe/phone-pe.component';
@Component({
  selector: 'app-buy-leads',
  templateUrl: './buy-leads.component.html',
  styleUrls: ['./buy-leads.component.css']
})
export class BuyLeadsComponent implements OnInit {
  location = '0';
  showError = false;
  stateId = ''
  cityData: any = []
  stateData: any = [];
  selectState: any = []
  selectCity: any = []
  selectedCategory: any = [];
  categoryAllList: any = [];
  searchCity = ''
  searchCategory = '';
  searchStates = ''
  showTgsDropdwon = false
  showTgsDropdwonCity = false;
  showTgsDropdwonCategory = false;
  businessId = '';
  categoryList;
  leadPricesData:any;
  @Output() paymentValue = new EventEmitter()
  @Input() paymentData: any;
  price: any;
  priceList: any = [];
  allData: any;
  matchValue: any = 0;
  configurationData: any;
  parentToChild: any;
  @ViewChild('phonePe') private phonePeModel: PhonePeComponent;
  constructor(private api: AdminService, private planService: PlanService, private toasterService: ToastrService) {}
  @HostListener('window:click') click() {
    if (this.showTgsDropdwon) {
      this.showTgsDropdwon = false;
    }
    if (this.showTgsDropdwonCity) {
      this.showTgsDropdwonCity = false
    }
    if (this.showTgsDropdwonCategory) {
      this.showTgsDropdwonCategory = false
    }
  }
  ngOnInit(): void {
    this.businessId = this.api.getBusinessId();
    this.getBusinessCategory();
    this.getLeadConfigurations();

  }
  ngOnDestroy() {
    this.phonePeModel.remove();
  }
  onLocation(event) {
    if (event.target.checked) {
      this.location = event.target.value;
      this.showError = false;
      if (this.location == '2') {
        this.selectCity = [];
        this.searchCity="" 
      }
      else if (this.location == '1') {
        this.selectState = []; 
        this.searchStates=""
      }
      else {
        this.selectState = [];
        this.selectCity = [];   
      }
    }
  }

  searchCategoryEvent(event) {
    this.categoryList = this.categoryAllList.filter(x => x.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
    this.showTgsDropdwonCategory = true
  }
  focusInput(id: any) {
    document.getElementById(id)?.focus();
  }
  searchLocation(event: any, type: any) {
    let search = event.target.value.trim();
    if (search.length>1) {
      this.getState(type, search)
    } 
  }
  getState(type, search) {
    let data = {
      'type': type,
      'title': search
    }
    this.api.getDataWithoutLoader(apiUrl._getCityStateOnly, data).subscribe(async (res) => {
      this.stateData=[];
      this.cityData=[];     
      if(type==1){
        this.showTgsDropdwon = true     
        this.stateData = res.data?.rows;
      }else{
        this.showTgsDropdwonCity = true   
        this.cityData= res.data?.rows;
    
      }
    });
  }

  selectRelevantStage(name: any) {
    if (this.selectState.length == 0) {
      this.selectState.push({ state: name, city: '' })
      
    } else {
      let findName = this.selectState.filter(x => x.state === name);
      if (findName.length == 0) {
        this.selectState.push({ state: name, city: '' })       
      }else{
        this.toasterService.error("This state already selected", "", { timeOut: 3000 });
      }
    }
    this.searchStates = ''  
  }
  remove(i: any, num: any) {
    if (num == 1) {
      this.selectCity.splice(i, 1);   
    } else if (num == 2) {
      this.selectState.splice(i, 1);
    }
    else if (num == 3) {
      this.selectedCategory.splice(i, 1);
    }
  }
  selectCategory(data) {
    if (this.selectedCategory.filter(x => x.name == data.name).length == 0) {
      this.selectedCategory.push(data);
    }
    this.searchCategory = '';
  }
  selectRelevantCity(name: any, state) {
    if (this.selectCity.length == 0) {
      this.selectCity.push({ city: name, state: state })
    } else {
      let findName = this.selectCity.filter(x => x.city === name);
      if (findName.length == 0) {
        this.selectCity.push({ city: name, state: state })   
      }else{
        this.toasterService.error("This city already selected", "", { timeOut: 3000 });
      }
    }
    this.searchCity = ''   
  }
  getBusinessCategory() {
    let data = {
      "businessId": this.businessId,
      "limit": 100,
      "skip": 0
    }
    this.api.getData(apiUrl._businessCategory, data).subscribe(res => {
      this.categoryAllList = res.data.data;
    });
  }
  getPrices(flag) {
    if ((this.location == '1' && this.selectCity.length == 0) ||
      (this.location == '2' && this.selectState.length == 0) ||
      (this.selectedCategory.length == 0)) {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000)
      return
    }
    let locations: any = [];
    if (this.selectCity.length > 0) {
      this.selectCity.forEach(x => {
        locations.push({"city":x.city,"state": x.state});
      })
    }
    if (this.selectState.length > 0) {
      this.selectState.forEach(x => {
        locations.push({"state":x.state});
      })
    }
    let categories: any = []
    this.selectedCategory.forEach(element => {
      categories.push(element.id)
    });;
    let params = {
      locationSelected: Number(this.location),
      locations: locations,
      subCategories: categories,
      businessId: this.businessId,

    }
    if (!flag) {
      params["isDownload"] = "1";
    }
    this.leadPricesData =params;

    this.api.postData(apiUrl._getLeadPrices, params).subscribe(res => {
      this.allData = res.data.data;
      let csvData = res.data.data
      if (flag) {
        this.payment();
      }
      else {
        this.toasterService.success(res.data);
        // if (csvData.length > 0) {
        //   let data: any = [];
        //   let headers = ["Location", "Sub-category (L2)", "Exact Match Price", "Borad March Price"]
        //   csvData.forEach(x => {
        //     let obj: any;
        //     if (x.matchCasePrice) {
        //       obj = {
        //         "Location": x.location,
        //         "Sub-category_(L2)": x.subcategory_level_two.name,
        //         "Exact_Match_Price": x.matchCasePrice,
        //         "Borad_March_Price": ''
        //       }
        //       // data.push(obj);
        //     }
        //     if (x.broadMatchCasePrice) {
        //       obj["Borad_March_Price"] = x.broadMatchCasePrice
        //     }
        //     data.push(obj);
        //   });
        //   if (res.data.email == false) {
        //     this.downloadFile(data, headers);
        //   } else {
        //     this.toasterService.error('Please Check Your email');
        //   }

        // }
        // else {
        //   swal
        //     .fire({
        //       icon: 'warning',
        //       text: "There are no prices for the current selection.",
        //       showCancelButton: false,
        //       confirmButtonColor: "#14141f",
        //       cancelButtonColor: "#939393",
        //       confirmButtonText: "Ok",
        //     })
        // }
      }
    })
  }
  payment() {
    this.showError = false;
    if (!this.price) {
      this.showError = true;
      return
    }
    let businessId= this.api.getBusinessId()
    let data = {
      price: this.price.finalPrice.toString(),
      // basePrice: this.price.basePrice.toString(),
      businessId: businessId
    }
    this.api.postData(apiUrl._createPaymentIntentLeads, data).subscribe(async (res) => {
      let url = res.data.data?.instrumentResponse?.redirectInfo?.url;
      if(url){
     
      }
      this.parentToChild = url;
       this.phonePeModel.openModel(this.price.finalPrice, this.price.basePrice,businessId, res.data?.merchantTransactionId, "1");
      //window.open(url,"_blank")
    },
      err => {
        console.log(err, "err");
      });
  }

  childMessage(txt) {
    if (txt == "updatePayment") {
      this.addOwnLeadPrices();
      this.api.successMsg();

    }else{
      this.api.errorMsg()
    }
    this.parentToChild = "";
    this.reset();
  }

  downloadFile(data: any, headers) {
    console.log(data)
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = headers;
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName.replaceAll(' ', '_')], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');     
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, "myFile.csv");   
  }
  getLeadConfigurations() {
    let data = {
      businessId: this.businessId
    }
    this.api.getData(apiUrl._getLeadConfigurations, data).subscribe(res => {
      this.configurationData = res.data;
      this.priceList = res.data.leadDenominations;
    })
  }
  priceClick(price) {
    this.price = price;
  }
  addOwnLeadPrices() {
    this.api.postDataWithoutLoader(apiUrl._addOwnLeadPrices, this.leadPricesData).subscribe(res => {
      this.leadPricesData=""
    })
  }
  onMatchChange(event) {
    if (event.target.checked)
      this.matchValue = event.target.value;
  }
  reset() {
    this.getLeadConfigurations();
    this.price = "";
    this.selectCity = [];
    this.selectState = [];
    this.selectedCategory = [];
  }
}
