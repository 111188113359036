import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeedbackPopupComponent } from 'src/app/common/feedback-popup/feedback-popup.component';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import * as CONSTANT from "../../services/constants";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  modalRef: BsModalRef;
  feedbackList: any = [];
  BaseUrl = '';
  pagination = { limit: 5, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  currentPage: number;
  constructor(private api: AdminService
    , private modalService: BsModalService) { }
  businessId;
  @ViewChild('feedbackModal') private feedbackModal: FeedbackPopupComponent;
  ngOnInit(): void {
    this.BaseUrl = environment.BaseUrl + 'assets/';
    const userData = this.api.getUserSaveData();
    if (userData) {
      let userInfo = userData;
      this.businessId = userInfo?.usersBusiness?.id;
    }
    this.getFeedbackList();
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.api.addMetaTag("Feedback Page", pageImg, "Feedback Page", "", "");
  }
  getFeedbackList() {
    let param = {
      limit: this.pagination.limit.toString(),
      skip: this.pagination.skip.toString(),
    }
    this.api.getData(apiUrl._getUserChatFeedbacks, param).subscribe(res => {
      this.feedbackList = res.data;
    },
      err => {
        console.log(err, "err");
      })
  }
  openModal(value) {
    let params = {
      leadId: value.leadId 
    }
    this.api.getData(apiUrl._getChatFeedbackDetail, params).subscribe(res => {
      let data = {
        businessId: this.businessId,
        from: 'customer',
        leadId: value.leadId,
        description: res.data.description,
        type: res.data.type,
        businessName:res.data?.businessDetailsFeedback?.businessName|| ''
      }
      this.feedbackModal.openModal(data);
    })

  }
  close() {
    this.modalRef.hide();
  }
  submitFeedback() {
    let param: any = {}
    this.api.postData(apiUrl._addFeedback, param).subscribe(res => {
    })
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = event.page - 1;
    this.getFeedbackList();
  }
}
