export const firebaseEnvironment = {
        production: false,
        firebase: {
            apiKey: "AIzaSyDuigU9Tjv-I8mTEUkmiHqQB9vGOxeHKZ8",
            authDomain: "finndit-digitech-pvt-ltd.firebaseapp.com",
             //dev and live db chat  url
                //databaseURL: "https://finndit-digitech-pvt-ltd-default-rtdb.firebaseio.com",
    
             // pre production db chat  url
            databaseURL: "https://finndit-digitech-pvt-ltd-live.firebaseio.com",
            
            //live db chat  url
            //databaseURL: "https://finndit-digitech-pvt-ltd-production-live-5sept2023.firebaseio.com/",
    
    
            projectId: "finndit-digitech-pvt-ltd",
            storageBucket: "finndit-digitech-pvt-ltd.appspot.com",
            messagingSenderId: "131536517295",
            appId: "1:131536517295:web:bff1f1ac03c1f843d0bc5a",
            measurementId: "G-3RW1BRR2ZK"
        }
    };
    
    