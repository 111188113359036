<div class="container">
    <div class="termsWrap">
            <h1> TERMS OF USE OF SERVICE BY VENDORS </h1>
            
            <p> This document is an electronic record in terms of the amended Information Technology Act, 2000 and rules and regulations made thereunder. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the Terms for access or usage of Finndit's service via Finndit Wesbite “www.finndit.com”. This document meets the stipulations and conditions mentioned in Section 65B (2) of the Indian Evidence Act, 1872. </p>

            <p> This Terms Of Service guides the contractual relation between Vendors (hereinafter called as "Vendor"/"Service Provider", "You" or "Your") and Finndit Limited (hereinafter called as Finndit,) and it governs the services offered by Finndit through its website (www.finndit.com), phone Search, SMS, WAP, APP or any other medium (collectively called "Portals" ). Finndit offers you its services only on the Terms and any amendments made under this Terms of Service. Finndit’s endeavour will be to send the Terms of Service to you on your registered email ID and/or mobile number via Emails/Sms/WhatsApp and the same is also published on Finndit’s website at <a href="https://www.finndit.com/TermsofService" target="_blank"> https://www.finndit.com/TermsofService </a></p>

            <span class="sub-heading">1. INTRODUCTION </span>
            <p> The Services (defined hereinafter) of 'Finndit' is provided by Finndit Digitech Private Limited, a company incorporated under the Companies Act, 1956 and having its registered office at C-196a, 6th Floor, Times Square, Phase 8b Industrial Area,Sector 74, Mohali ("Finndit"). Finndit carries on the business of providing information about Vendors (defined hereinafter) of various products and services ("Information") in selected towns and cities in India ("Service") to end users. Finndit is a local search engine and it primarily<strong>  provides directory services </strong> to its Callers/Users as per their requirements & searches made on Finndit’s portals in as much as this terms of use is concerned as applicable to the vendors. Finndit search service is available to users through multiple platform viz., Internet, mobile internet, telephone (voice, text & SMS). Finndit is merely an aggregator which connects the general public with vendor’s goods and services listed on Finndit, however, at the same time it does not intend to guarantee any business to such Vendors. The Vendor details as provided by them are specified in the listing available at Finndit's portals. Finndit's objective is to provide a quicker guide for up-to-date Information in a user friendly manner and via a medium that the end user is comfortable with. The end users ("hereinafter referred as Callers/Users") of the Service are persons who may want to purchase goods / avail services and are seeking information about the Vendors of such goods and services. These Terms of Service (defined below) and the invoice mentioned in Clause 10 constitute the entire understanding/Contract) between Finndit and the Vendors.</p>



            <span class="sub-heading">2. INTERPRETATION </span>
            <ul>
                <li> Contract means this Terms of Service together with all Schedules and Annexures (if any); </li>
                <li> References to a party hereunder shall include such party’s successors, permitted assignees and any persons deriving title or part thereof under it; </li>
                <li> The descriptive headings of Clauses are inserted solely for convenience of reference and are not intended as complete or accurate descriptions of the content of such Clauses; </li>

                <li> The use of words in the singular or plural, or with a particular gender, shall not limit the scope or exclude the application of any provision of this Contract to such person or persons unless the context otherwise permits; </li>

                <li> Any grammatical form of a defined term herein shall have the same meaning as that of such term; and The words "including" and "includes" herein shall always mean "including, without limitation" and "includes, without limitation", respectively. </li>

            </ul>



             <span class="sub-heading">3. SUBSCRIPTION </span>
             <p> Vendors may be business houses, small medium enterprises, corporate entities, semi-corporate entities, establishments or individuals, or any other person or persons authorised under law to conduct activities of business for profit, who subscribe to the Service in order to list their company or business. Finndit will provide only the Information about the Vendor to the Users in the manner provided for in these Terms. Vendor agrees and acknowledges that Finndit does not guarantee any business to the vendor and is merely a medium through which information is made available to the general public. </p>


            <span class="sub-heading"> GENERAL TERMS  </span>
            <ul>
                <li> This Terms of Service, including any Invoice, Annexures etc. together will be referred as "Contract/ Terms of Service". This Terms of Service will override any previous understanding (whether written or oral) between the Vendor and Finndit on the subject matter hereof. The Terms Of Service shall be valid and effective for 1 (one) year or more from the Effective Date unless otherwise agreed by the parties and in accordance with the terms of Service subject to the timely receipt of the payment. </li>

                <li> The Service would automatically stand renewed unless 
                    <ul>
                        <li> The Vendor intimates Finndit by prior written notice (either by email or through registered post) at least 3 (three) months before the expiry of the 1 (one) year tenure, expressing their intention not to renew the Service; or </li>

                        <li> Is terminated by either Party prior to renewal in accordance with these Terms. In the absence of any written communication by the Vendor as to non-renewal after the expiry of the 1 (one) year tenure or unless terminated earlier by either Party, the Service would stand automatically renewed on terms specified herein.  </li>

                    </ul>

                </li>

                <li> By agreeing to the terms of the Contract, the Vendor shall be deemed to have consented unconditionally to all such addendums and amendments to the Contract without requirement of any specific notice or signature thereto. Finndit is entitled to modify the Terms of Service as it deems fit and will not be required to provide a specific notice of such change to the Vendors. It shall be the sole responsibility of the Vendors to visit the Website and update themselves of the modifications/changes to the Terms of Service. Any changes to or amendment to these Terms or the Terms of Service will be deemed to take effect from the Effective Date and will be binding on the Vendor. </li>


                <li> Subject to terms set out in Clause above, it is clarified that the automatic renewal of the Service will also be subject to sufficient credit balance of Vendor being available in the Vendor’s account with Finndit. It is further clarified that in ECS/ CCSI mandate should be active and the payment should be honoured on the stipulated date for the purposes of renewal of the Service. </li>

                <li> The automatic renewal of the Service is subject to the absolute discretion of Finndit. The amount mentioned in the Invoice along with any other document which forms a part of this Terms of Service, Total Fee is the decided fee for the provision of Services and shall not be reviewed at any point except at the discretion of Finndit. The Vendor’s by making the payment as raised in the invoice confirms the acceptance of Terms of Service as set out herein, for availing the service from Finndit. </li>


                <li> The vendor hereby authorizes that upon the execution of ECS / CCSI MANDATE Finndit is authorized to DEDUCT minimum of 3 months ECS, thereafter Vendor has the option of terminating the Service. The Vendor shall only terminate the Service upon the completion of minimum tenure of 3 months from effective date which can be terminated by giving prior 1 (one) month written notice (either by email or through registered post) to its bill due date, expressing intentions of discontinuing the Services. </li>


                <li> Notwithstanding anything contained anywhere in this contract, The Vendor undertakes that if any concession is availed by Vendor while availing Finndit services it shall be under obligation to honour the payment (through any mode as opted) under any circumstances for the total tenure of the services as availed by the Vendor subject to terms of service as mentioned in this document. The Vendor hereby authorizes that upon the execution of ECS / CCSI MANDATE Finndit is authorized to DEDUCT the ECS/CCSI or any other payment mode for the total tenure as per the services availed especially where concession is availed by Vendor. </li>

                <li> In respect of Premium listings or any other Listings, where the payment mode and manner opted by the Vendor is Electronic Clearing Service (ECS) or Credit Card Standing Instruction (CCSI), the Service would be automatically renewed as mentioned in this clause of these Terms, however such automatic renewal is subject to the condition that same will be so renewed at revised values (commercials) as existing on the date of automatic renewal and on such other terms as may be determined solely by Finndit. Notwithstanding anything contained in this Term of Service, the automatic renewal and the terms of such renewal will be at the absolute discretion of Finndit. </li>


                <li> It is clarified that, irrespective of whether the Vendor has registered or not registered their entity/ firm's contact numbers in the "Do Not Call" registry of Telecom Regulatory Authority of India (TRAI), or any other statutory body, upon acceptance of the Terms Of Service, the Vendor would be deemed to have given their consent and authorized Finndit to share the Vendor’s details and contact numbers for business and promotional purpose with Users of the Service and for other business purposes of Finndit. Further the Vendor, by accepting the Terms of Service, has given his consent to Finndit to contact him for any business promotion of Finndit. The Vendor confirms that Vendor shall carry out all the obligations as specified in the Terms of Service. The Vendor also undertakes to take all such measures as may be necessary to ensure that the Users do not encounter any difficulty in contacting the Vendor. In the event the Users are unable to contact the Vendor it would not be deemed to any deficiency in the Services rendered by Finndit. The Vendor undertakes to comply with all the present and future TRAI regulations or regulations of any other regulatory bodies on subject matter covered under this clause and would ensure that User or Finndit will not face any difficulty in contacting the Vendors. </li>

            </ul>


            <span class="sub-heading"> 5. INFORMATION DISSEMINATION </span>
            <p> Finndit currently disseminates Information to the Users through different platforms, viz., telephone, internet, WAP (Wireless Access Protocol) and Wireless (SMS short code) SMS and Whatsapp and IVR service. Finndit may, at its discretion cease providing Information over any of the above platforms or provide Information over other platforms or modify the manner in which Information is provided over any of the existing platforms, as it may deem fit, from time to time. </p>


            <span class="sub-heading"> 6. LISTING/ SERVICE AND OTHER FEES </span>
            <ul>
                <li> The Vendor agrees to the Terms of Service and shall be bound to it in the event that they make use of the service, even if made available at no cost i.e. for free. </li>

                <li> The Vendor agrees/undertakes to pay advance and non-refundable Listing/Service Fee and other applicable charges/fees for any additional feature/upgraded services /facility etc. as availed by the vendor from time to time. The applicable charges/commercials will be determined by Finndit at its sole discretion. Finndit on the receipt of such fees from the Vendor, will send the e-invoice to the registered mobile number & email ID of the respective Vendor or their authorized person on their registered contact details. Finndit reserves its right to recover such applicable fees, cost, expenses and taxes etc from time to time. Vendors agrees/understands that Finndit may present the payment mandates i.e. ECS / CCSI / NACH any number of times to receive its just dues / payments & to provide uninterrupted services. </li>

                <li> The vendor/service provider can pay the fee, either by way of upfront payment (payment of entire fee), or by way of the easy instalments payment options as available. If vendor/service provider opts to pay the fee/consideration by paying instalments, under such payment schemes, the vendor/service provider shall be liable to pay to Finndit the initial amount (including applicable Tax), at the time of registration of listing services and the balance payment can be paid through the various payment modes such as ECS (Electronic Clearing Service); CCSI (Credit Card Standing Instruction) & NACH (National Automated Clearing House). Finndit reserves the right to charge an interest amount for future instalments determined at its sole discretion. It is hereby clarified that the services shall only be activated once the first ECS/ CCSI/ NACH payment is credited on Finndit’s account as per the payment plan or the payment as received for the services/products as availed. Under no circumstances shall Finndit be liable to make any refund any amount as paid by vendor/service provider on account of deactivation/non-activation of services due to non-receipt of any payment through ECS/ CCSI/ NACH/Cheques etc.. Finndit reserves the right, at its sole discretion, to change, modify and amend the Terms pertaining to the activation of your services at any time by publishing the amended Terms on our portals at INSERT EMAIL with or without notice to you. Vendor/Service Provider are advised to check the Terms at regular interval to received uninterrupted services.</li>

                <li> The vendors shall furnish the KYC documents or any other documents, as may be required by Finndit to be extended finndit’s services, on or before the first ECS/ CCSI/ NACH clearance, or even if provided at no cost, i.e. for free. For the purpose of clarity, KYC documents include, (1) the passport; (2) the driving licence; (3) Voter's Identity Card issued by the Election Commission of India; (4) Copy of Aadhar Cards; (5) Job card issued by NREGA duly signed by an officer of the State Government; (6) letter issued by the National Population Register containing details of name and address; along with PAN Card. In addition to aforesaid, the vendors also needs to furnish the documents relating to the business listing such as, Certificate of incorporation, PAN Cards of the company, Registration certificate, GST certificate, Shop & Establishment certificate, or any other Government certificate issued to running their business. The vendor agrees/understands that it may be required to also verify its business credentials through trusted third parties.  </li>


                <li> Finndit reserves the right to exercise sole discretion over any monetized services offered to vendors by Finndit, the terms of payment for the same will be governed identically with this clause. </li>

            </ul>


            <span class="sub-heading">7. PAYMENT MODES </span>
            <ul>
                <li> Finndit allows the Vendor to make the payments for the Services, including the payment of the Service/Listing Fee, i.e., the Total Fee excluding Service Fee/ Subscription Fee by way any of the following modes: (a) Cheque; (b) Demand Draft; (c) RTGS (Real Time Gross Settlement); (d) ECS (Electronic Clearing Service); and, (e) CCSI (Credit Card Standing Instruction) (f) NACH (National Automated Clearing House) (e) Unified Payments Interface (UPI) </li>


                <li> It is hereby clarified that Finndit does not encourage/prefer that payments be made in cash. Any cash payments made by the Vendor pursuant to Terms of Service with Finndit, shall be at the sole risk of the Vendor, without any recourse to Finndit.<strong> Vendors shall not share to any employee of Finndit Limited any banking related information or any information that is considered to be sensitive personal information within the meaning of Information Technology Act, 2000 as amended or any other law for the time being in force. </strong> </li>


                <li> Payments can be made by the Vendor either weekly, fortnightly, monthly, quarterly and half-yearly as directed by Finndit. Finndit shall make best efforts to activate the Terms Of Service within 14 (Fourteen) working days from the date of receipt of the Service Fee and relevant documents into its bank account, for the respective Listing (subject to delay due to technical malfunctions). However Finndit will not be liable in any manner for any delay in activating the Service of Vendor. </li>


            </ul>



            <span class="sub-heading">8. CONSEQUENCES OF NON-PAYMENT OF SERVICE FEE / ECS AMOUNT </span>
            <ul>
                <li> The Vendor hereby agrees that it shall make the payment via such mode which is mentioned on the Clause 7 of these Terms. Any dishonor of payments will attract provisions of Section 138 of the Negotiable Instruments Act 1881 including other appropriate legal proceedings to recover its just dues. The Vendor shall also be liable to pay interest @18 % per annum during that period. Finndit reserve its right to recover its just dues as per the services or the packages availed by the Vendor. </li>

                <li> In the event if the Vendor fails to make any payment for the services as availed, including the Service Fee or the ECS amount, in time and as per the payment plan chosen by him, Finndit may also, in its sole discretion, suspend the Vendor’s Listing or access to the Service, as the case may be, until the Vendor makes necessary payments. Further, the Vendor agrees and acknowledges that the Vendor shall be deemed to have waived his rights to the Service and the Vendor’s listing for the period during which the Service and the Listing is suspended for non-payment. The right of Finndit to determine the listing, suspend the Service and the Listing shall be without prejudice to Finndit’s right to terminate the services for any material breach committed by the Vendor. Vendor hereby acknowledges that the opted position/listing will be released to other Vendors in the event of non-payment of Service fee or ECS amount without any notice/intimation from Finndit. </li>

            </ul>


            <span class="sub-heading">9. LUMP SUM PAYMENT MODE </span>
            <p> Vendors who pays the lump sum amount while availing the services under such payment mode the tenure of the Service will be equivalent to the amount paid by the Vendor. </p>


            <span class="sub-heading"> 10. ISSUANCE OF INVOICE/ANNEXURES </span>
            <ul>
                <li> On receipt of the consideration, the services shall be activated Once the services is activated Finndit shares the invoice to the Vendors on their registered email ID and/or mobile number via email/sms & other electronic modes along with an invoice & other details as may be required. </li>
                <li> Invoice is valid subject to timely realization of the payment. </li>
            </ul>


             <span class="sub-heading"> PRIORITY OF INFORMATION ABOUT VENDORS </span>
             <p>For Finndit, providing Information that is relevant to the User is a priority. The User may or may not identify the location and category in respect of which he is seeking Information and Finndit will provide such Information (subject to availability of such information) based on such choice of the Users. Amongst the Vendors who fall within the parameters identified by the User, Information about Vendors registered with Finndit, that is, the Vendors is first provided on best effort basis, subject to delays due to any technical malfunction. Priority among Vendors i.e., determined on the basis of the listing selected by the Vendor and on the basis of Total Fee to be paid by the Vendor. Nothing contained in the herein, shall be deemed to restrict or prevent Finndit from providing Users with Information about other Vendors whether in priority to the Vendors or otherwise, as Finndit may in its sole discretion determine. The Vendor acknowledges that Finndit will merely convey the Information to the Users on demand and does not guarantee that any of such User requests will result in business prospects for the Vendor. </p>


             <span class="sub-heading"> NO GUARANTEE OF BUSINESS </span>
             <p>Finndit does not guarantee that enquiries/leads will be generated by it for the Vendor or that any of such enquiries/leads will be converted into business for the Vendor. </p>


             <span class="sub-heading">NO MARKETING </span>
             <p>Finndit is not obliged and does not market the offerings of Vendor and shall not be obliged to recommend Vendor to the Users. Finndit’s obligation under the contract is limited to the obligations explicitly set out herein and in no event does Finndit undertake to generate or guarantee Enquiries or business to the Vendor. </p>


             <span class="sub-heading">INDEMNIFICATION </span>
             <p>Vendor shall defend, indemnify, and hold harmless Finndit, its parent, subsidiaries, affiliates, officers, employees, agents, Representatives from and against any claims, liability(ies), demands, losses, damage, deficiencies, actions, judgments or cause of action, assessments, interests, penalties and other costs or expenses (without limitation to reasonable attorneys’ fees and expenses) arising out of or in relation to or in connection with: </p>
             <ul>
                 <li> breach of any provision or non-performance of any of its obligations under this Terms of Service; </li>
                 <li> by reason of any infringement of third party rights (including intellectual property rights); </li>
                 <li> any representation and warranty given by Vendor under this Terms of Service being found to be untrue, false or incorrect; or </li>
                 <li> anything done or omitted to be done due to gross negligence, wilful default or wilful misconduct of the Vendor or any of its officers, directors, employees or agents or </li>
                 <li> any third party claims or liability arising out of or related to Vendors' Ads or Services. </li>
             </ul>

             <p> Finndit reserves the right to, and exercise sole discretion over visibility of listings of vendors and regulate the same in view of services availed by competing vendors, performance of such competing vendors or </p>



             <span class="sub-heading">CONFIDENTIALITY AND RELATED OBLIGATIONS </span>
             <p>The Vendor shall keep any information regarding the Users of the Service ("User Information") confidential both, during the subsistence of this Terms Of Service and after its termination. The Vendor shall not, without the prior written consent of Finndit, transfer (whether for consideration or otherwise) User Information to any third party for any reason whatsoever. The Vendor is specifically prohibited from using the User Information for purposes of seeking any commercial gain out of said User Information. In the event Finndit is made aware of any such practice of the Vendor in violation of the terms of this Terms Of Service, Finndit shall be entitled to terminate the Services as well as initiate such legal proceedings against the Vendor, as it may deem fit at its sole discretion and without prejudice to rights available to it under applicable law.  </p>

             <p> Finndit reserves the right against, and shall not be obligated to, share User Information of any user, or class of users with any Vendor unless at its sole discretion within the applicable limitations of competition/anti-trust law prevalent and in force at the time in the varying jurisdictions of vendors </p>


             <span class="sub-heading"> VENDORS’ OBLIGATIONS TOWARDS END USERS </span>
             <ul>
                 <li> The vendor shall be responsible for any delivery, after-sales service, payment, invoicing or collection, sales enquiries, support maintenance services and/or any other obligations or services relating to or in respect of the Products/services provided by Vendors to end users. Such services shall be the sole responsibility of the Vendor/vendors. </li>

                 <li> If any dispute raised by the End Users regarding the products/services, the vendor shall be obliged, forthwith, either to take back or withdraw defective goods or to withdraw or discontinue deficient services, or if fails to rectify defective goods or deficient services, then to refund the consideration within the period stipulated in the bill or cash memo or receipt or in the absence of such stipulation, within a period of Two days; </li>

                 <li> In the event vendor is unable to resolve the grievances of any End User as per the satisfaction of End User, then Finndit as per its discretion reserves the right to suspend the services/terminate the services or take any other measures as it may deem fit and proper. </li>

                 <li> The vendor acknowledges and accepts that vendor is solely responsible 

                    <ul>
                        <li> for falsely describing its product or service; or  </li>
                        <li> to give a false guarantee to, or is likely to mislead the consumers as to the nature, substance, quantity or quality of such product or service; or  </li>
                        <li> to deliberately concealing important information in relation to any product or service; or </li>
                        <li> for falsely representing about the standard, quality, quantity, grade, composition, style or model of goods/products; or </li>
                        <li> for falsely represents about the standard, quality or grade of services; or  </li>
                        <li> for falsely representing any re-built, second-hand, renovated, reconditioned or old goods as new goods; or  </li>

                        <li> for falsely representing about the sponsorship, approval, performance, characteristics, accessories, uses or benefits of goods or services; or </li>

                        <li> for falsely representing about the sponsorship or approval or affiliation; or </li>

                        <li> makes a false or misleading representation concerning the need for, or the usefulness of, any goods or services; or </li>

                        <li> gives to the public any false warranty or guarantee of the performance, efficacy or length of life of a product or of any goods: or  </li>

                        <li> gives false or misleading facts disparaging the goods, services or trade; or  </li>

                        <li> for the goods do not comply with the standards prescribed by the competent authority relating to performance, composition, contents, design, constructions, finishing or packaging as are necessary to prevent or reduce the risk of injury to the person using the goods; </li>


                        <li> for manufacturing of spurious goods or offering such goods for sale or adopting deceptive practices in the provision of services;  </li>

                        <li> for not issuing bill or cash memo or receipt for the goods sold or services rendered in such manner as may be prescribed; </li>


                        <li> for disclosing to other person any personal information given in confidence by the user/consumer unless such disclosure is made in accordance with the provisions of any law for the time being in force. </li>



                    </ul>   
                 </li>

                 <li> The vendors shall be solely responsible for the accuracy of all information and/or validity of the Prices and any other charges and/or other information relating to the Products and/or services. The vendors shall be solely responsible for the price to be charged by them in accordance with law time being in force. </li>
             </ul>


            <span class="sub-heading"> VENDORS’ OBLIGATIONS TOWARDS FINNDIT LIMITED </span>
             <ul>
                 <li>The vendor acknowledges that it shall be solely responsible for all materials/information and its use of Finndit’s directory services. The Vendor/vendor agrees that nothing in its Materials/information or its use of the Directory Services will be false, misleading, defamatory, harassing, or threatening, will constitute unfair competition or unfair commercial practice, will violate any applicable Law, or will infringe or misappropriate the Intellectual Property Rights of any third party. </li>

                 <li> The vendor is solely responsible for the activities of any contractor, representative, or any of its agents or other persons acting on its behalf in connection with this contract. The Vendor/vendor will ensure that it’s complying with all applicable Law, including Law related to data protection and privacy. </li>

                 <li> The vendor represent and warrants that it will not commit, or cause to commit, or omit, or cause to omit any such act which will violate the privacy policy maintained by Finndit with Users and Vendors alike  </li>

                 <li> The vendor acknowledges that Finndit is essentially an aggregating platform/search engine and does not seek any fulfilment of the product / services delivered or not, and it is the vendor’s responsibility to deliver the goods & services whose business is listed on Finndit’s platform. </li>


                 <li> The vendor acknowledges that Finndit is not responsible for the goods or services, or any dispute between the vendors & end users. It is the responsibility of the vendors to resolve any dispute or claim raised by end users. </li>


                 <li> The vendor acknowledges that Finndit warrants that any proof/ intimation of dispatch of such Proof of Fulfilment provided will be authentic, whether electronic or otherwise. Provided that, if Finndit so requires, the physical proof of dispatch of the Proof of Fulfilment of the Product shall be made available to Finndit. All Proof of Fulfilment of Products shall be maintained by the vendor for a period of at least 3 (Three) year from the date of such fulfilment. </li>


                 <li> The vendor acknowledges that Finndit shall also not be responsible for any non-delivery/non-fulfilment of any Products/Services sold by sellers/vendors to the end users. All risks associated with the delivery/fulfilment of the Products/Services shall be solely that of the Vendors. Any and all disputes regarding quality, merchantability, non-delivery/non-fulfilment and delay in delivery/fulfilment of the Products/services or otherwise will be dealt with by and between the Vendors directly and End users and Finndit shall not be made party to such disputes. The Vendors shall hold Finndit saved and harmless from any such actions or claims that may be initiated against it. The vendors shall also indemnify and hold indemnified Finndit at all times against all such actions and claims. </li>


                 <li> The vendor acknowledges that Finndit shall not be responsible for any fault, imperfection, shortcoming or inadequacy in the quality, quantity, potency, purity or standard or nature and manner of performance of any product or of any goods/services, sold by sellers/Vendors listed with Finndit’s directory service, which is required to be maintained by or under any law. </li>

                 <li> The vendor acknowledges and accepts that it restrains itself from: 

                    <ul>
                        <li> unreasonable security deposits from end users for the performance of contractual obligations; or  </li>

                        <li> imposing any unreasonable penalty on the end users, for the breach of contract; or </li>

                        <li> refusing to accept early repayment of debts on payment of applicable penalty; or  </li>

                        <li> entitling a party to the contract to terminate such contract unilaterally, without reasonable cause; or </li>

                        <li> permitting or has the effect of permitting one party to assign the contract to the detriment of the end users, without his consent; or </li>

                        <li> imposing on the end users any unreasonable charge, obligation or condition; or  </li>


                        <li> manipulation of price or its conditions of delivery or to affect flow of supplies with intent to impose unjustified costs or restrictions. </li>
                    </ul>

                 </li>

             </ul>


             <span class="sub-heading">18.  VENDORS’ GENERAL OBLIGATIONS</span>
             <ul>
                 <li> Vendor represents and warrants that  

                    <ul>
                        <li> it is a bona fide business organization carrying on business in relation to the items disclosed to Finndit;  </li>

                        <li> it has the rights to use the trademarks;  </li>

                        <li> the business carried on by vendor does not violate or infringe upon any law or regulation and all registrations, authorizations and /permission necessary approvals required for carrying on business have been procured by it; and </li>

                        <li> all information provided about itself (Vendor) to Finndit, is and shall at all times be accurate, valid and complete; </li>

                        <li> it would be solely responsible and liable for the information provided (including but not limited to the content or details pertaining to any intellectual property provided by the Vendor to Finndit) to Finndit. </li>

                        <li> Vendor shall not have right to terminate the Service up to 9 months from the date of activation of the Service. For the purpose of clarity it is agreed between the parties that Finndit will not be liable to refund any ECS amount which has been deducted during the validity of the contract. </li>

                    </ul>

                 </li>

                 <li><strong>  Additional Covenants </strong>  


                    <ul>
                        <li> The vendor acknowledges and accepts that any grievance as to the Services will be entertained only as long as the Service remains in force and that Finndit will not entertain such grievances post-expiry or termination of the Service; </li>

                        <li> Vendor accepts that it is his/her responsibility to update contact information, information pertaining to its products/services using the online edit option available in their listing and keep Finndit updated in this regard. In the event the relevant information is not updated accurately, the Vendor would, by default, be bound by the explanation or description of the product/service or any other details of the vendor communicated by Finndit to the Users or any other third party; </li>


                        <li> Vendor hereby agrees to keep proper receipts, invoices and details for the transactions entered into by it (Vendor) and the User; </li>

                        <li> The Vendor undertakes to provide a copy of the licenses/ registrations or any other documents including but not limited to valid Identity proofs such as ration card, aadhar card, pan card, passport, voter id card and residential proofs such as electricity bill, telephone bill, bank passbook etc, as required by it (Vendor) to run the business. The vendor acknowledges that any breach of the covenants set forth herein may cause delay in activation, non-activation or termination of the services by Finndit, at its sole discretion.  </li>


                        <li> Vendor further acknowledges that vendor shall not be entitled for any refund, compensation, damages, expenses, interest arising of out of business loss, claims, actions, inconvenience suffered by vendor from delay in activation of services, non-activation of services or termination of the services due to their (Vendor’s) fault of not providing documents, delay in providing the relevant documents or providing incomplete documents to Finndit. It is further acknowledged by a vendor that Finndit will be at no obligation to follow-up with vendor for the documents as mentioned herein and it will be vendor’s sole responsibility including but not limited to share/upload all the relevant documents confirming their identity, address and business registration details with Finndit. </li>



                        <li> Vendor hereby agrees and undertakes that during the term of this Contract and after its termination of this Contract (for any reason), it will not directly or indirectly forward leads/enquiries to any third party, mis-utilize, cheat, solicit, or usurp or attempt to solicit, divert or take away any of Finndit’s enquiries/leads. Any such conduct by vendor will lead to an immediate termination of the services and Finndit at its discretion will initiate proceedings against vendor before appropriate forum.  </li>


                        <li> Vendor hereby agrees and confirms that during the term of this Contract and after its termination (for any reason), vendor will not directly or indirectly either for itself or for any other commercial enterprise, solicit, divert, hire or attempt to solicit, divert or usurp, any of Finndit’s employees, business or prospective users/callers. </li>

                        <li> Vendor shall not advertise or perform any solicitation, including but not limited to solicitation of users to use the services of Vendor directly or promoting its services by any means whereby user are motivated to not to use the Finndit website/services or to use the Vendor website/services directly. Any conduct by vendor that in Finndit’s discretion restricts or inhibits any other user/customer from using or enjoying the Finndit services will not be permitted. </li>

                        <li> During the term of this Contract and for 5 (five) years after any termination of this Contract, Vendor will not directly or indirectly, in any capacity:- 

                            <ul>
                                <li>  solicit any users of Finndit by means of providing discounts, gift coupons etc in the event users uses the website of vendor directly. </li>


                                <li> divert, entice, or otherwise take away from Finndit the business of any user/customer, or attempt to do so, or </li>


                                <li> solicit or induce any user/customer to divert or reduce its relationship with the Finndit. Vendor will not, and will not authorize any third party to generate automated, fraudulent or otherwise invalid enquiries, clicks or conversions, ratings, reviews etc.; conceal enquiries, conversions for Service where they are required to be disclosed or use any automated means or form of scraping or data extraction to access, query or otherwise collect listing service related information from any Property except as expressly permitted by Finndit. Vendor will direct communications regarding Service under the Term of Service only to Finndit. </li>

                            </ul>
                        </li>

                        <li> It is vendor’s sole responsibility to keep his id/password/login id safely so that no one can misuse it and do the changes in the listing visible on Finndit portals to cause any wrongful harm to Finndit or its users. Vendor represents that in event the loss is caused due to negligence of vendor, such as where he has shared the payment credentials, OTP details etc., then vendor will bear the entire loss and Finndit shall not be responsible for the same. </li>
                    </ul>
                 </li>
             </ul>




              <span class="sub-heading">19. DISCLAIMER AND LIMITATION OF LIABILITY </span>
              <p> To the fullest extent permitted by law, Finndit disclaims all warranties, express or implied, including without limitation for non-infringement, merchantability, satisfactory quality and fitness for purpose. </p>

              <p> To the fullest extent permitted by law, Finndit disclaims all guarantees regarding accurate Listing of the Vendor. Vendor understands that there may be errors in such positioning. Neither Finndit nor the Vendor will be liable for any consequential, special, indirect, exemplary, or punitive damages (including without limitation loss of profits, revenue, interest, goodwill) whether in contract, tort (including negligence) or any other legal theory, even if advised of the possibility of such damages and notwithstanding any failure of essential purpose or of any remedy except for vendor’s indemnification obligation. Finndit’s aggregate liability to the vendor is limited to amounts paid to Finndit by vendor during the 12 (twelve) months immediately preceding the date of the claim. Finndit is also not liable for any claim owing to any misrepresentation of the information pertaining to the Vendor so long as the information exhibited/ communicated by Finndit conforms to the Information made available by the vendor or its authorized representative. </p>
             




              <span class="sub-heading">20. ADDITIONAL DISCLAIMER </span>
              <ul>
                  <li>Vendor confirms that it will be solely responsible and liable for all matters between User and the Vendor, including but not limited to transactions entered into between such User and the Vendor. Further, it is hereby declared that Finndit does not verify the identity of the User of the Service and that the information provided to Vendors is the information received from the User and will not be independently verified by Finndit. </li>

                  <li> In the event of receipt of any complaints from the Users regarding the Vendor or Vendor’s product/service, Finndit reserves the right to discontinue the provision of Service to the Vendor or take any other action as deemed appropriate. Notwithstanding anything to the contrary contained in this Term, Finndit may also forfeit the amount lying to the credit of such Vendor and terminate the services at its sole discretion. </li>

                  <li> Vendor also agrees that at no time shall Finndit vouch for or guarantee the performance of services or delivery of products by the vendor and Finndit will not be liable for any non-payment of amounts due to the vendor by any User. </li>

              </ul>


              <span class="sub-heading">21. DISPUTE RESOLUTION </span>

              <ul>
                  <li> If the dispute raised by the Vendor regarding the defective or deficient services, Finndit shall be obliged, forthwith, to repair or to remove the defects in products or deficiencies in the services in question or to replace or maintain the products with new products of similar description which shall be free from any defect. Provided that Finndit shall not be liable to refund, if the Vendor: 

                    <ul>
                        <li> act of omission or commission or negligence or conscious withholding any information, incomplete, and inaccurate information; </li>

                          <li> does not follow the instructions or warnings issues by Finndit;  </li>

                          <li> has misused, altered, or modified the product ;  </li>

                          <li> fails to inform Finndit about the defective products or deficient services, within the period of 48 hrs., from the receipt of such products/services; </li>

                          <li> Finndit provides the products/services as per Term of Service. </li>
                    </ul>
                  </li>


                  <li> Additionally, if Finndit is providing defective or deficient services evidently, due to any system error, technical issue, human error or any other acts or omissions which is beyond its control, during the tenure of the contract, then it will not be considered as material breach of the terms of this contract. In such events, Vendor will bring it to the notice of Finndit and thereafter Finndit will conduct necessary investigation and based on the outcome of investigation may extend the services for equivalent days without any additional cost to vendor or provide other alternate solutions. The calculation of such extended period will be from the date of receipt of such intimation by Finndit from vendor. However, Finndit will have all the rights to give the details/information/system generated report evidencing that there were no default on the part of the Finndit, hence Finndit is not responsible for the extension of the services or any other alternate solutions. As mentioned under this clause, it will be the Vendor sole and exclusive remedy in the event of Finndit’s failure to perform the services as specified under this contract.  </li>


                  <li> Any complaints or concerns with regard to content and or comment or breach of these terms, you can email to us at <a target="_blank" href="https://www.vendorgrievance@finndit.com"> vendorgrievance@finndit.com </a> , with brief details of your complaint or call on our no. +911726670512. Our customer service staff will acknowledge and redress your complaint on best effort basis and as per applicable Terms of Service. </li>

              </ul>



              <span class="sub-heading">22. TERMINATION </span>
              <ul>
                  <li> The term of this Contract begins upon Vendor’s registration or use of the Services and will end when terminated by any party (the “Term”). The Vendor may only terminate the Service, by giving 3 (three) months written notice (either by email or through registered post), prior to the automatic renewal of the same, stating his intentions of discontinuing the Services. </li>

                  <li> Finndit reserves the right to terminate the Service at any time, either with or without cause. If the Vendor commits a breach of a material duty owed to Finndit, Finndit may, at its discretion, call upon the vendor to rectify the breach within 3 (three) days of the receipt of notice, failing which Finndit may terminate the relationship between Vendor and Finndit. </li>

                  <li> Upon termination of the Service, Finndit will not be bound to delist the Vendor as a Vendor and cease disseminating Information of the Vendor to the Users. However upon termination, Finndit shall have the right to delist the Vendor without prior intimation thereof to the vendor. </li>


                  <li> In case of any technical difficulties in continuing the services, Finndit reserves the right to terminate the services by giving written/oral intimation to the vendor. </li>


                  <li> If the Vendor terminates the Service, either by efflux of time or by determination under this Terms of Service, the balance of the deposit placed by the vendor with Finndit, if any, (after deducting payments for the period/tenure for which Services have already been provided by Finndit) shall be retained by Finndit. Under no circumstances, shall the Vendor be entitled to claim a refund of amounts already paid to Finndit. If Finndit terminates the service for reasons attributable solely to Finndit, in any manner whatsoever, it shall either refund the amounts on proportionate basis or provide any alternate services. </li>


                  <li> Finndit may terminate the Service if vendor engages in fraud or other illegal or unethical activities, or in any activities which Finndit in its reasonable judgment believes is in contravention, of any laws as may be applicable from time to time or of accepted industry practice and which under the circumstances could adversely affect the reputation or business of Finndit. </li>

                  <li> This contract will be terminated forthwith by Finndit on the happening of any of the following events: 

                    <ul>
                        <li>  If the vendor becomes or is declared bankrupt or goes in liquidation voluntary or compulsory, except for the purpose of amalgamation or reconstruction; </li>


                        <li> If the vendor ceases to carry on its business or suspends all or substantially all of its operations; </li>


                        <li> If a liquidator, receiver or administrator or any encumbrances takes possession of or is appointed over the whole or any part of the assets of the Vendor; </li>

                        <li> If any attachment or distress is levied against any of the assets of the Vendor. </li>

                        <li> If there is any material adverse change or any change in applicable law, rules, regulations, directives or guidelines, which prevents the continuing of the arrangement under this Contract. </li>
                    </ul>   

                  </li>

              </ul>





              <span class="sub-heading">23. NOTICES </span>

              <p> Finndit shall send notices to Vendors in writing through email or Whatsapp/SMS and may be followed by registered post and dispatched through a reputed courier. Any notice refused by the Vendor would be deemed to have been legally delivered and Vendor will be deemed to have received such notice. Vendors can send notices to Finndit in writing on customer support email id or to company's registered office address. </p>



              <span class="sub-heading">24. MODIFICATIONS TO TERMS OF SERVICE </span>
              <p> Finndit reserves the right to change the Terms of Service at any time without any prior notice to the Vendor. Changes to the terms of Service or the Terms of Service shall come into effect upon from the time it is put up on the Website or by any other mode of communication as may be determined by Finndit. For Terms of Service with Vendor visit <a href="http://www.finndit.com/TermsofUse" target="_blank"> http://www.finndit.com/TermsofUse </a>. </p>



              <span class="sub-heading">25. TAXES </span>
              <p>
                 Finndit is entitled to charge the vendor for all the taxes and charges (now in force or enacted in future) that are or may be imposed on the said Services and Listing fees (including bids arising out of Vendor's relationship with Finndit) and vendor hereby agrees to pay the said taxes and charges promptly without raising any objections. Vendor also agrees that in the event the said taxes and charges are not charged by Finndit the same shall be paid by the Vendor directly to the authorities concerned without raising any objection. The vendor further agrees that the taxes and charges payable under this Clause is in addition to the fee paid by the vendor for the Listing and Services rendered by Finndit.  
                <ul>
                    <li> All TDS deductions, form no. 16A should be sent at <a href="mailto:vendorgrievance@finndit.com">vendorgrievance@finndit.com</a>. </li>

                    <p> Goods and Service Tax is levied as per existing government policy. </p>

                    <p> Pursuant to Income Tax circular No 1/2014 dated 13-01-2014 TDS should not be deducted on Goods and Service tax. It is the responsibility of the Vendor to provide the correct Goods and Service tax number before the payments are made. If Vendor fails to provide the GST number or provide incorrect number before making the payment then the Vendor will not get due GST credit for which vendor shall be solely responsible. </p>
                </ul>



              <span class="sub-heading">26. MISCELLANEOUS </span>

              <ul>
                  <li> Finndit's interpretation of the Terms of Service shall be final and binding on Vendor. </li>

                  <li>Vendor agrees that no joint venture, partnership, employment, or agency exists between vendor and Finndit and that the Vendor is not entitled to bind Finndit by its actions. </li>

                  <li> Finndit is subject to existing laws and legal process and nothing contained in the Terms of Service is in derogation of Finndit's right and obligation to comply with the law. </li>

                  <li> If any clause or part thereof of the Terms of Service is held to be invalid or unenforceable then the invalid or unenforceable clause/ provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and is nevertheless valid and enforceable and the remainder of the Terms of Service shall continue in effect. Such revision to the Terms of Service will be deemed to have been in effect from the Effective Date. </li>


                  <li>Vendor may not assign any rights or obligations against Finndit without Finndit's prior written consent. Finndit reserves the right to transfer any right or obligation against Vendor by issuance of notice of such assignment to the Vendor. Upon such assignment, the assignee shall be bound by the Terms of Service in the same manner as Finndit and Finndit shall cease to have any liability to Vendor. However, the Parties agree that Finndit has an unfettered right to assign the Terms of Service and the Vendor is only entitled to a notice of such assignment. The Parties further agree that assignment of the Terms of Service by Finndit will not be subject to Vendor's consent. </li>


                  <li> Finndit shall not be responsible for any delay or deficiency in services due to any force majeure events such technical glitch, server issue, natural disasters, acts of terrorism, civil labor strike, labor and transportation strikes or any other acts beyond its control. During a force majeure event, the obligations of Finndit under the Terms of Service will stand suspended. </li>


                  <li> Nothing in the Terms of Service obliges or will be deemed to oblige Finndit to provide any credit to the Vendor. </li>

                  <li> Vendors agrees that Finndit reserves its right to present the payment mandates i.e. ECS / CCSI / NACH any number of times to receive its just dues / payments for the services rendered under this terms of service. </li>


                  <li> Finndit has its own Reviews, Ratings and Comments on its portals which will be posted by the users of Finndit. However, Finndit takes no responsibility and assumes no liability for any content posted by any caller/third party on Finndit site or on any mediums of Finndit. </li>

              </ul>


              <span class="sub-heading">27. ARBITRATION </span>

              <p> All disputes, differences and/or claims arising out of the Terms of Service shall be settled by Arbitration in accordance with the provisions of Arbitration and Conciliation Act, 1996 or any statutory amendment thereof. The dispute shall be referred to a single arbitrator who shall be appointed by the authorized representative/ Director of Finndit. The Vendor shall not challenge the nomination of Arbitrator or his award on the ground that the nomination is made by authorized representative/ Director of Finndit. The arbitration proceedings shall be held at Mohali or New Delhi, at the sole discretion of Finndit and the arbitration shall be conducted in English Language. The award of the Arbitration shall be final and binding on the Vendor and Finndit. The arbitrator will pass a written and reasoned award and will be entitled to award cost of the proceedings. </p>


              <span class="sub-heading">28. ENTIRE CONTRACT </span>
              <p> This Contract, including any Invoice, Annexures, along with the Terms of Service hereto forms a single Contract between the Parties hereto and constitute the entire understanding between the Parties with regard to the subject matter hereof and supersede any other TERMS between the Parties relating to the subject matter hereof. </p>


              <span class="sub-heading">29. AMENDMENTS AND WAIVERS </span>
              <p> Unless otherwise expressly stated in this Terms of Service, the failure on the part of Finndit to exercise or delay in exercising a right or remedy under this Terms shall not constitute a waiver of the right or remedy or a waiver of any other rights or remedies, and no single or partial exercise of any right or remedy under this Terms of Service shall prevent any further exercise of the right or remedy or the exercise of any other right or remedy available to Finndit. </p>
    </div>
</div>