import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-terms-service',
  templateUrl: './terms-service.component.html',
  styleUrls: ['./terms-service.component.css']
})
export class TermsServiceComponent implements OnInit {

  constructor(private admin: AdminService) { }

  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Terms of use of service by Vendors", pageImg, "Terms of use of service by Vendors","","");
  }

}
