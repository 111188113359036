import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AdminService } from "./admin.service";
import { environment } from "src/environments/environment";
import { apiUrl } from "../global/global";
@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private admin: AdminService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //let token = "Bearer "+ localStorage.getItem("adminToken");
    const token = localStorage.getItem("webToken")
      ? localStorage.getItem("webToken")
      : "";
    let noToken = localStorage.getItem("noToken")
      ? localStorage.getItem("noToken")
      : "";
    if (token && req.url !== environment.jsonURL) {
      req = req.clone({ headers: req.headers.set("authorization", atob(token)) });
    }

    if (this.admin.showLoader) this.admin.loader(true);
    else this.admin.showLoader = true;

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.admin.loader(false);
          }
        },
        (err: any) => {
          this.admin.loader(false);
          if (err instanceof HttpErrorResponse) {
            if (err.error?.statusCode == 502) {
              let msg = "Implementation error"
              this.admin.showErrorMsg(msg)
            }
            if (err.error?.statusCode == 520) {
              this.admin.errorAlert1(err.error.message, err.error.responseType.data, true);
            }
            if (err.error?.statusCode == 401) {
              this.admin.errorAlert(err.error.message, true);            
            }
            if(err.error?.statusCode == 400){
              if (err.error.message) {
              let errMsg = err.error?.message 
              if(errMsg=="Request failed with status code 417"){
                errMsg= "The entered UPI ID is not correct. Please enter valid UPI ID"
              }
              this.admin.showErrorMsg(errMsg)              
              }
            }           
            else {            
              if (err.error.message) {
                this.admin.showErrorMsg(err.error.message)               
              }
            }
          }
        }
      )
    );
  }
}