<ng-template #galleryModel>
    <div class="modal-header">
        <h2>{{slides.title}}</h2>
        <button type="button" class="closePopup" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" [ngClass]="slides.media?.length>3?'':'noSlider'">
            <div ngxSlickItem *ngFor="let slide of slides.media" class="sliderImage">
                <!-- <img src="{{ slide.img }}" alt="" width="100%"> -->
                <img *ngIf="slide.type===1" (click)="showBigSize(slide.file, slide.type, 'Business Images')"
                    [src]="(BaseUrl + slide.file)">
                <div *ngIf="slide.type===2" class="play-icon" (click)="showBigSize(slide.file, slide.type, 'Business Videos')">
                    <video controls class="cropped_img">
                        <source  [src]="(BaseUrl + slide.file)" type="video/mp4">
                    </video>
                </div>
                <a *ngIf="slide.type===3" style="cursor: pointer;" (click)="showBigSize(slide.file, slide.type, 'Business Audios')" >
                    <div class="play-icon audio"></div>
                    <div class="audio-bg"></div>
                </a>

            </div>
        </ngx-slick-carousel>
    </div>
</ng-template>
<ng-template #singleMedia>
    <div *ngIf="showBg" class="overLayerMedia"></div>
    <div class="modal-header" style="z-index:2"  [ngClass]="fromPage=='chat-popup'?'cross-align':''">
        <h2>{{mediaFile.title}}</h2>
        <button type="button" class="closePopup" [ngClass]="fromPage=='chat-popup'?'ml-5':''" (click)="mediaClose()"></button>
    </div>
    <div class="modal-body" style="z-index:2">
        <div class="mediaBox">
            <img *ngIf="mediaFile.type===1" [src]="(BaseUrl + mediaFile.file)">
            <video *ngIf="mediaFile.type===2" controls class="cropped_img">
                <source [src]="(BaseUrl + mediaFile.file)" type="video/mp4">
            </video>
            <audio controls *ngIf="mediaFile.type===3">
                <source [src]="(BaseUrl + mediaFile.file)" type="audio/mpeg">
            </audio>
        </div>
    </div>
</ng-template>