import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {GooglePlacesService} from "src/app/services/google-places.service";
import {MouseEvent} from "@agm/core";
@Component({
    selector: "app-google-map",
    templateUrl: "./google-map.component.html",
    styleUrls: ["./google-map.component.css"],
})
export class GoogleMapComponent implements OnInit {
    @Input() locationData: any = {};
    @Output() pinChange = new EventEmitter();
    zoom: number = 14;
    latitude: any;
    longitude: any;
    countryRestriction = {
        // latLngBounds: {
        //     east: 68.1766451354,
        //     north: 7.96553477623,
        //     south: 97.4025614766,
        //     west: 35.4940095078,
        // },
        // strictBounds: true,
    };
    constructor(private placesApi: GooglePlacesService) {}
    ngOnChanges() {
        if (this.locationData) {
            this.latitude = this.locationData.latitude ? Number(this.locationData.latitude) : "";
            this.longitude = this.locationData.longitude ? Number(this.locationData.longitude) : "";
            this.zoom =  this.locationData.zoom ? Number(this.locationData.zoom ) : 12;
        }
    }
    ngOnInit(): void {}
    async markerDragEnd(event: MouseEvent) {
        let latitude = event.coords.lat;
        let longitude = event.coords.lng;
        let userFullAddress;
        let userAddressList = await this.placesApi.getPlacesList(latitude, longitude);
        console.log("userAddress", userAddressList);
        if (userAddressList) {
            userFullAddress = await this.placesApi.getFullAddress(userAddressList, latitude, longitude);
            console.log("userAddress", userFullAddress);
            this.pinChange.emit(userFullAddress)
        }
    }
    
}
