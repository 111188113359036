import { Component, OnInit } from '@angular/core';
import { DataSharingService } from 'src/app/services/data.share.service';
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
  allCategory;
  constructor(private dataSharingService: DataSharingService,public router: Router,) { }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories() {
    let count = 1;
    this.dataSharingService.allCategory.subscribe(data => {
      if (data) {
        this.allCategory = data?.data;
      }
    })
  }

  selectCategory(category) {
    this.dataSharingService.clearSearch.next(true);
    this.dataSharingService.setSelectedCategory(category);
    this.router.navigate(['/category/sub-category']);
  }

  selectSubCategory(category, selectedCategory) {
    this.dataSharingService.clearSearch.next(true);
    this.dataSharingService.search.next(category);
    sessionStorage.setItem('selectedCategory', btoa(JSON.stringify({ id: selectedCategory.id, name: selectedCategory.name })));
    sessionStorage.setItem('selectedSubCategory', btoa(JSON.stringify({ id: category.id, name: category.name })));
    this.dataSharingService.setSelectedSubCategory(category);
    this.router.navigate(['/category/sub-category-lvl']);
  }

  selectCategoryLevel(category){
    this.dataSharingService.clearSearch.next(true);
    category.type = 40;
    this.dataSharingService.search.next(category);
    this.dataSharingService.setSearch(category);
    sessionStorage.setItem('selectedSubCategoryLvl', btoa(JSON.stringify({ id: category.id, name: category.name })));
    this.router.navigate(['/category/sub-category/business']);
  }

}
