import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['./grievance.component.css']
})
export class GrievanceComponent implements OnInit {
  constructor(private admin: AdminService) { }
  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Data Grievance", pageImg, "Data Grievance", "", "");
  }

}
