<ng-template #ExitIntentPopup class="location-popup-position">
  <div class="Rectangle4569">
    <div class="modal-window">

      <div class="div">
        <button (click)="close()" type="button" class="modal-close"><i
            class="fa fa-close fa-2x"></i></button>
        <div class="row" style="margin-left: 0px">

          <div class="col-md-5 d-flex align-items-center justify-content-center"
            style="background-color: #18225a;">
            <!-- <img src="../../../assets/images/stop.png" class="img-fluid"> -->
            <img *ngIf="isImage" src="{{gradient}}" class="img-fluid">
            <div *ngIf="!isImage" class="hideinmobile">
              <div class="circle">
                <div class="text22">
                  <span>10 Lakh+</span> businesses
                </div>
              </div>
              <div class="circle">
                <div class="text22">
                  Free Rewards
                </div>
              </div>
              <div class="circle">
                <div class="text22">
                  Safe and Secure
                </div>
              </div>
            </div>

            <div *ngIf="!isImage" class="hideinmobile">
              <div class="circle">
                <div class="text22">
                  <span>1000+</span> categories
                </div>
              </div>
              <div class="circle">
                <div class="text22">
                  Best Nearby deals
                </div>
              </div>

              <div class="circle">
                <div class="text22">
                  Happy customer
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-7">
            <div class="col-md-12"> <img
                src="../../../assets/images/img_logo.png"
                alt /></div>
            <div class="col-md-12 pr-3">
              <div class="row">
                <h1 class="col-md-12"
                  style="text-align: unset;">{{headertext}}</h1>
                <div class="col-md-12 text" style="text-align: unset;">
                  {{messagecontent}}

                </div>
                <div class="col-md-12" *ngIf="showPopup==='signup'">
                  <form [formGroup]="signupForm" class="flex-column h-100"
                    (ngSubmit)="signupFun()">
                    <div class="form-group">
                      <div class="phoneNo">
                        <div class="contryCode">+91</div>
                        <input (paste)="(false)" autocomplete="off" type="tel"
                          class="form-control" id="tel"
                          placeholder="Phone Number"
                          formControlName="phoneNumber" maxlength="10"
                          (keypress)="onlyNumberKey($event)">
                      </div>
                      <div class="invlid_error"
                        *ngIf="signupForm.controls['phoneNumber'].hasError('required') && showError">
                        *Please enter phone number.</div>

                      <div class="invlid_error"
                        *ngIf="signupForm.controls['phoneNumber'].hasError('cannotContainSpace') && showError">
                        *Please
                        enter phone number.</div>
                      <div class="invlid_error"
                        *ngIf="!showError&&signupForm.controls['phoneNumber'].errors?.minlength">
                        *Please enter valid phone number.</div>

                    </div>

                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"
                        id="customCheckBox1"
                        formControlName="acceptTerms">
                      <label class="custom-control-label"
                        for="customCheckBox1">I
                        agree to the <a class="pointer"
                          (click)="gotoTermsPage('terms')">Terms of
                          Service</a> and <a class="pointer"
                          (click)="gotoTermsPage('Privacy')">Privacy Policy</a>
                      </label>
                      <div class="invlid_error"
                        *ngIf="!signupForm.controls['acceptTerms'].value">
                        * Please agree terms of service and privacy policy.
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn location-box mask-button currentlocation"
                        style="margin: 13px 0px;">
                        {{ buttontext }}
                      </button>
                    </div>
                  </form>
                </div>
                <div class="col-md-12" *ngIf="showPopup==='verification'">
                  <form [formGroup]="otpForm" class="d-flex flex-column">
                    <p style="font-size: 13px;">We have sent the code of
                      verification to your mobile number
                      <a><b>{{phoneNo}}</b>
                        <img (click)="backTo()" style="cursor: pointer;"
                          src="../../../assets/images/edit.png" alt>
                      </a>
                    </p>
                    <div class="d-flex flex-row otp_flds">
                      <input (paste)="(false)" autocomplete="off" id="otpFocus"
                        appAutoFocusDirective type="tel"
                        class="form-control" formControlName="digit1" #input1
                        maxlength="1" autofocus
                        (input)="onInputEntry($event, input2 )"
                        (keydown)="onKeyDown($event, input1)" />

                      <input (paste)="(false)" autocomplete="off" type="tel"
                        class="form-control" formControlName="digit2"
                        #input2 maxlength="1"
                        (input)="onInputEntry($event, input3)"
                        (keydown)="onKeyDown($event, input2)" />

                      <input (paste)="(false)" autocomplete="off" type="tel"
                        class="form-control" formControlName="digit3"
                        #input3 maxlength="1"
                        (input)="onInputEntry($event, input4)"
                        (keydown)="onKeyDown($event, input3)" />

                      <input (paste)="(false)" autocomplete="off" type="tel"
                        class="form-control" formControlName="digit4"
                        #input4 maxlength="1"
                        (input)="onInputEntry($event, input4)"
                        (keydown)="onKeyDown($event, input4)" />
                    </div>
                    <div class="invlid_error"
                      *ngIf="otpForm.controls['digit1'].hasError('required') && showError || otpForm.controls['digit2'].hasError('required') && showError || otpForm.controls['digit3'].hasError('required')  && showError || otpForm.controls['digit4'].hasError('required')  && showError">
                      *Please enter vaild OTP.
                    </div>

                    <div class="middle text-center">
                      <p *ngIf="maxTime >0"
                        class="send_otp clr_blue mt-2">Didn’t get OTP? Wait!
                        <span>00:{{maxTime}}</span>
                      </p>

                      <button *ngIf="maxTime < 1"
                        (click)="resendOtp('otpFocus')"
                        class=" btn_brdr resend_otp mask-button">Resend
                        OTP</button>

                    </div>
                    <div class="text-center">
                      <button class="mask-button bottom"
                        (click)="verifyOtp(updateProfile)">Get Started</button>
                    </div>
                  </form>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #updateProfile>
  <div class="modal-body">
    <img class="upgraded_img" src="../../../assets/images/level.png" alt>
    <h4>Upgrade Your Level</h4>
    <p>Complete your profile and get upgraded to elite level</p>
    <!-- <p class="big_txt">Claim this business and grow<br> your revenue</p> -->
    <a class="btn-main pointer" (click)="editProfile()">Update Profile Now</a>
    <a class="btn_brdr pointer" (click)="closePopup()">Remind me Later</a>
  </div>
</ng-template>
