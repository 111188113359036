import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapComponent } from './google-map.component';
import { AgmCoreModule } from '@agm/core';
import { googleMapsEnvironment } from 'src/environments/geokey';
@NgModule({
  declarations: [
    GoogleMapComponent,
  ],
  imports: [
    CommonModule,    
    AgmCoreModule.forRoot({
      apiKey: googleMapsEnvironment.googleMapsApiKey,
      libraries: ['places'],
      apiVersion: 'weekly',
      language: 'en'
    }),
  ],
  exports: [
    GoogleMapComponent
  ],
})
export class GoogleMapModule { }
