import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[uppercase]',
  host: {
    '(input)': '$event'
  }
})
export class UppercaseInputDirective {
  lastValue: string;
  constructor(public ref: ElementRef) { }
  @HostListener('input', ['$event']) onInput($event)  {
    if ($event.target.value.length === 1) {
      const inputValue = $event.target.value;
      this.ref.nativeElement.value = inputValue.charAt(0).toUpperCase() + inputValue.substring(1);
    }
  }
}