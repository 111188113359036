import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './../extrnal/faq/faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {NgbPaginationModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 

@NgModule({ 
  imports: [
    CommonModule,
    AccordionModule,
    PaginationModule.forRoot(),
    FormsModule, ReactiveFormsModule

  ],
  declarations: [
    FaqComponent,
    
    // CallsComponent
  ],
  exports: [
    FaqComponent,
    PaginationModule, 
    AccordionModule,
    FormsModule, ReactiveFormsModule
  ],
  providers: []
})
export class ShareComponentModule { }