import { Component, OnInit, TemplateRef, Renderer2, Inject } from '@angular/core';
import { DataSharingService } from "../../services/data.share.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { PlanService } from 'src/app/extrnal/subscription-plan/plan.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  paymentData = { "customerType": "1" }
  modalRef: BsModalRef;
  businessId: any;
  hideBtn = false;
  constructor(
    @Inject(DOCUMENT) private document: Document, private router: Router,
    private planService: PlanService, private dataSharingService: DataSharingService, private renderer: Renderer2, private admin: AdminService, private modalService: BsModalService, private toasterService: ToastrService,) {
    this.dataSharingService.isLoginChanged.subscribe(value => {
      if (value) {
        let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
        if (token) {
          this.hideBtn = true;
        }
      }
    });


  }

  ngOnInit(): void {
    this.paymentData = { "customerType": "1" }
  }
  clearSearch() {
    this.dataSharingService.clearSearch.next(true);
    sessionStorage.setItem("searchText", "");
  }
  getPaymentStatus(e) {
    let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
    if (token) {
      if (this.businessId) {
        this.modalRef.hide();        
      }
      else {
        this.toasterService.error('To purchase the subscription plan, kindly list your business with us.', '');
        this.close();
      }

    } else {
      this.toasterService.error('To purchase the subscription plan, kindly list your business with us.', '');
      this.close();
    }

  }

  getFirstBusinessDetails() {
    this.admin.loader(false);
    let data = {}
    this.admin.getData(apiUrl._getFirstBusinessDetails, data).subscribe(async (res) => {
      this.businessId = res.data.id;
    })
  }

  showPlan(template: TemplateRef<any>): void {
    this.clearSearch();
    let token = localStorage.getItem("webToken") ? (localStorage.getItem("webToken")) : '';
    if (token) {
      this.getFirstBusinessDetails();
    }
    this.modalRef = this.modalService.show(template, {
      animated: false,
      backdrop: 'static',
      class: "modal-xl borderRradius10"
    });
  }
  close() {
    this.modalRef.hide();
  }
}
