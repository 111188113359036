  <!-- ======= Sidebar ======= -->
  <aside id="sidebar" class="sidebar" (click)="closeMenuBar()">
    <ul class="sidebar-nav" id="sidebar-nav">
        <li class="nav-item"  routerLinkActive="active" *ngFor="let item of menu_items">
            <a  class="nav-link pointer" [routerLink]="item.link" (click)="goTopage(item.link)" *ngIf="item.show===1"  routerLinkActive="active" #rla="routerLinkActive" >
              <i><img [src]="rla.isActive ? item.image2 : item.image" alt=""></i>
              <span> {{ item.name }}</span>
              <span class="count" *ngIf="item.name ==='Inbox' &&  unReadCount > 0">  {{ unReadCount > 0 ? unReadCount:'' }} </span>
            </a>
        </li>
    </ul>    
  </aside>