import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate,Route, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerchantPermissionGuard implements CanActivate {
  currentPlanSetting:any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router) {
    //this.getFormSetting()
   }
  // canActivate() { //  
  //   
  //   if (token && merchant==='1') {
  //     return true;
  //   } 

  //   this.router.navigate([""]);
  //   return false;
  // }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let permissions;
      if(localStorage.getItem("userPer")!=undefined && localStorage.getItem("userPer")!='undefined')
     {
       permissions = JSON.parse(localStorage.getItem("userPer") || "");
     }
      let token = localStorage.getItem("webToken");
      let merchant = localStorage.getItem("Marchant");
      const { routeConfig } = route; 
      const { path } = routeConfig as Route;     
      if ((token && merchant==='1' && path?.includes('service') && permissions?.listOfServices!==0)
      ||(token && path?.includes('offer-discount') && permissions?.listOfOffers!==0) || (merchant==='1'&& token && path?.includes('dashboard')) || (merchant==='1'&& token && path?.includes('merchant-profile'))
      || (merchant==='1'&& token && path?.includes('leads'))
      || (merchant==='1'&& token && path?.includes('merchant-inbox'))
      || (merchant==='1'&& token && path?.includes('advertisement'))
      || (merchant==='1'&& token && path?.includes('query-management'))
      || (merchant==='1'&& token && path?.includes('rate-review'))
      || (merchant==='1'&& token && path?.includes('feedback-forms'))
      || (merchant==='1'&& token && path?.includes('analytics'))
      || (merchant==='1'&& token && path?.includes('buy-leads'))
      || (merchant==='1'&& token && path?.includes('subscription-management'))) {       
        return true
      }
      else{
        localStorage.removeItem("defultBusiness");
        localStorage.removeItem("Marchant");
        this.router.navigate(['/']);
        return false;
      }
    }
  }

  

 