// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// `ng build --live `  update API url  form serve.ts file.
// `ng build --live `  update firebase DB url form firebase-messaging-sw.js and firebase.ts file.

export const environment = {
	apiUrl: 'https://gosifi.com/',
	BaseUrl: "https://preprod-media.finndit.com/",
	// jsonURL: 'https://preprod-media.finndit.com/staticData/production/categories.json',
	jsonURL: 'assets/css/categories.json',
	production: true

};
export const phonePeUrl = {
	//dev
	url: "https://mercury-uat.phonepe.com"
	//prod
	//url: "https://mercury-t2.phonepe.com"
}

export const razorpayKey = {
	key: ""
};

export const chatEnvironment = {
	Jobs: 'Jobs_Live',
	chats: 'Chats_Live',
	Messages: 'Messages_Live',
	Notifications: 'Notifications_Live',
	Users: 'Users_Live'
};


/*6.0",
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
