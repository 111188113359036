<div class="container">
    <div class="termsWrap">
        <h1>About Us</h1>
        <!-- <p class="update-date">Last Updated 17/2/2022</p> -->
        <p> Welcome to Finndit, the fastest-growing platform for finding local businesses and services in India (C-196A, 6th floor, Times Square, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055). Our
            mission is to connect customers with the products and services they need, while also promoting local
            businesses and fostering a strong community.
        </p>
        <p> Founded in 2020, Finndit has quickly become one of India's most trusted and widely used platforms for
            finding local businesses and services. Whether you're looking for a new restaurant to try, a local plumber
            to fix a leak, or a nearby gym to join, Finndit has you covered. Our platform features a comprehensive
            directory of businesses and services, making it easy for customers to find what they need quickly and
            easily.
        </p>
        <!-- <h5>1. Our Objective </h5> -->
        <p> We understand that searching for local businesses and services can take time and effort. That's why we
            created Finndit – to provide a simple and effective solution for finding what you need when you need it. Our
            platform features a range of search options, including search by category, location, and keywords, to ensure
            that you can find the perfect business or service to meet your needs, also get an opportunity to earn reward
            points by doing various activities on the platform and redeem those points to get exciting vouchers.
        </p>
        <p>At Finndit, we're not just a search-based platform – we're also dedicated to helping local businesses grow
            and thrive. We offer a range of digital marketing services to help businesses enhance their online presence
            and attract new customers. Our team of content writers, bloggers, and video marketing experts can create
            engaging, SEO-optimised content to enhance businesses' digital presence and attract more visitors to explore
            the services provided by the businesses. We also offer business merchant digital ads promotion, banners, and
            360-degree video shoots to further enhance businesses' marketing efforts. Businesses will get access to
            their dashboard through which they can easily manage their business profile and can create banner campaigns
            of their own without any hassle. </p>
        <p>Our commitment to supporting local businesses goes beyond just digital marketing – we also offer various
            other services to help businesses succeed. For example, during the Covid pandemic, we stepped up to support
            our business partners in any way we could, helping them create and promote special offers and deals to
            attract customers during a difficult time. We also launched a blog to highlight local businesses and share
            their stories with the community, building relationships and promoting solidarity.
        </p>
        <p> Our experienced team assists businesses to drive innumerable new customers and reinforce their brand’s
            image. Thus, bringing businesses and customers together. We prioritize providing detailed information and
            specifications of their products and services to show the uniqueness of their brand.
        </p>
        <p> At Finndit, we believe in providing high-quality services with honesty, integrity, and professionalism. We
            understand that our success depends on the success of our users and service providers, which is why we are
            committed to providing the highest level of customer service and support. We work tirelessly to ensure that
            our platform is easy to use, reliable, and up-to-date so that you can always find the businesses and
            services you need.</p>
        <p>Our vision is to be the go-to destination for anyone looking for local businesses and services in India. By
            providing a comprehensive directory of businesses and services and a range of digital marketing services, we
            can help businesses thrive and grow, we work tirelessly to save you time and providing you instant access to
            required service providers.
        </p>
        <p>Thank you for choosing Finndit, and we look forward to helping you find what you're looking for!</p>
    </div>
</div>