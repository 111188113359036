import { DOCUMENT } from '@angular/common';
import { Component, OnInit,Inject, Renderer2, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit, OnDestroy {
  constructor(
    private admin:AdminService,
    @Inject(DOCUMENT) private document: Document,
    private renderer:Renderer2 ) { }
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'showMobMenu');

    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Merchant panel", pageImg, "Merchant panel", "", "");
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'showMobMenu');   
  }
}
