  import { environment } from "../../environments/environment";
  import { AbstractControl } from '@angular/forms';
  export var DATE_REGEX = 'dd/MM/yyyy, hh:mm a';
  export var NAME_REGEX = '^[a-zA-Z ]*$';

  export var MOBILE_REGEX = '^[0-9.]*$';
  export function removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
//   export function noWhitespaceValidator(control: FormControl) {
//     const isSpace = (control.value || '').match(/\s/g);
//     return isSpace ? {'whitespace': true} : null;
// }

  // export var NO_SPACE_REGEX = '[0-9a-zA-Z][0-9a-zA-Z ]+';

  export var EMAIL_REGEX =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // export var SPACE_REGEX =  /^[a-zA-Z][a-zA-Z0-9. ]+$/;
  export var SPACE_REGEX =  /((?!.*[\s])(?=.*[A-Z])(?=.*\d))/;
  export var Percentage_REGEX =  /^([1-9]|[1-9][0-9]|100)$/;

  export var PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&])/;

  export const PASSWORD_REGEX1 = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.()^+-/|`'~{}:><=";_\[\]\\S])[A-Za-z\d@$!%*?&#.()^+-/|`'~{}:><=";_\[\]\\S]{6,20}$/

  export let URL_REGEX = /^(http?|ftp|https):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(app|com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

  export let facebook_REGEX =/((https):\/\/)(www\.)facebook\.com\/[a-zA-Z0-9.]{1,}/;
  export let instagram_REGEX = /(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_.]{1,30}\/?/
  export let twitter_REGEX = /(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]{5,15}(\?(\w+=\w+&?)*)?/
  export let linkedIn_REGEX = /[(https:\/\/www\.linkedin.com)]{20}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)+/
  export let youtube_REGEX = /(?:(?:https?:\/\/)(?:www)?\.?(?:youtu\.?be)(?:\.com)?\/(?:.*[=/])*)([^= &?/\r\n]{8,11})/
  
  // export let linkedIn_REGEX = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/
  // export let youtube_REGEX = /(?:https?:\/\/)?(?:www\.)?youtu(.be\/|be\.com\/watch\?v=)(\w{11})/
  
  export var LIMIT = 10;
  export var MAX_SIZE = 3;
  export var MAX_IMAGE_SIZE = 5000000;
  
  export const timeList = [
    {time:"09:00"},
    {time:"09:30"},
    {time:"10:00"},
    {time:"10:30"},
    {time:"11:00"},
    {time:"11:30"},
    {time:"12:00"},
    {time:"12:30"},
    {time:"13:00"},
    {time:"13:30"},
    {time:"14:00"},
    {time:"14:30"},
    {time:"15:00"},
    {time:"15:30"},
    {time:"16:00"},
    {time:"16:30"},
    {time:"17:00"},
    {time:"17:30"},
    {time:"18:00"},
    {time:"18:30"},
    {time:"19:00"},
    {time:"19:30"},
    {time:"20:00"},
    {time:"20:30"},  
    {time:"21:00"},
    {time:"21:30"},
    {time:"22:00"},
    {time:"22:30"},
    {time:"23:00"},
    {time:"23:30"},
    {time:"24:00"},
    {time:"01:00"},
    {time:"01:30"},
    {time:"02:00"},
    {time:"02:30"},
    {time:"03:00"},
    {time:"03:30"},
    {time:"04:00"},
    {time:"04:30"},
    {time:"05:00"},
    {time:"05:30"},
    {time:"06:00"},
    {time:"06:30"},
    {time:"07:00"},
    {time:"07:30"},
    {time:"08:00"},
    {time:"08:30"},
  ]
  // export const timeList = [  {
  //   startTime:[time],
  //   endTime:[time]
  // },]
  export const  customAction = [
    { day:"Monday",dayNumber:0,isOpen:0,openTime:"",closeTime:""},
    { day:"Tuesday",dayNumber:1,isOpen:0,openTime:"",closeTime:""},
    { day:"Wednesday",dayNumber:2,isOpen:0,openTime:"",closeTime:""},
    { day:"Thursday",dayNumber:3,isOpen:0,openTime:"",closeTime:""},
    { day:"Friday",dayNumber:4,isOpen:0,openTime:"",closeTime:""},
    { day:"Saturday",dayNumber:5,isOpen:0,openTime:"",closeTime:""},
    { day:"Sunday",dayNumber:6,isOpen:0,openTime:"",closeTime:""},
  ]
  export const USER_STATUS_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Active Users', selected: false }, { key: 2, value: 'Deactivated Users', selected: false }];

  export const GENDER_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Male', selected: false },
    { key: 2, value: 'Female', selected: false }];


  export const LIMIT_RECORD_LIST = [{ key: 1, value: 10, selected: true },
    { key: 2, value: 20, selected: false }, { key: 3, value: 50, selected: false }, { key: 4, value: 100, selected: false },
    { key: 5, value: 200, selected: false }];

  export const PLAN_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Free', selected: false },
    { key: 2, value: 'Premium', selected: false }];

  export const AGE_FILTER_TO = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '20', selected: false },
    { key: 2, value: '30', selected: false }];


