export const apiUrl = {
	_loginsignup:"api/v1/auth/loginsignup",
	_login: "api/v1/auth/login",
	_signUp: "api/v1/auth/signup",
	_resendOtp: "api/v1/auth/requestOtp",
	_verifyOtp: "api/v1/user/password/verify-otp",
	_location: "api/v1/user/location",
	_forgot:"api/v1/user/password/forgot-password",
	_setPwd:"api/v1/user/password/reset-new-password",
	_subcategoryLevel2:"api/v1/common/subcategoryLevel2",
	_businessCategory: "api/v1/merchant/business/businessCategories",
	_tags:"api/v1/common/tags",
	_updateBasicDetails:"api/v1/business/updateBasicDetails",
	_updateAdditionalDetails:"api/v1/business/updateAdditionalDetails",
	_getBusiness:"api/v1/business/getBusiness",	
	_verifyPhoneNumberBusiness:"api/v1/business/verifyPhoneNumber",
	_resendOtpBusiness:"api/v1/business/resendOtpForVerification",
	_resendOtpEmail:"api/v1/business/resendEmailForVerification",
	_checkEmail: "api/v1/business/emailForVerification",
	_verifyEmail:"api/v1/business/verifyEmail",
	_otpForVerificationBussion:"api/v1/business/otpForVerification",
	_paymentMethod:"api/v1/common/paymentMethods",
	_modeOfInteraction:"api/v1/common/modeOfInteraction",
	_getAmenities:"api/v1/common/amenities",
	_upload:"api/v1/common/getSignedURL",
	_updateMedia: "api/v1/business/updateMedia",
	_updateDocuments: "api/v1/business/updateDocuments",
	_updateAddress:"api/v1/business/updateAddress",
	_checkStep:"api/v1/business/businessSteps",
	_addService:"api/v1/merchant/business/service",
	_serviceList:"api/v1/merchant/business/service",
	_deleteService:"api/v1/merchant/business/service",
	_updateService:"api/v1/merchant/business/service",
	_offerAdd:"api/v1/merchant/business/offers",
	_getOfferList:"api/v1/merchant/business/offers",
	_deletOffers:"api/v1/merchant/business/offers",
	_activateDeactivate:"api/v1/merchant/business/offers/activateDeactivate",
	_allPlan:"api/v1/common/subscriptionPlans",
	_createPayment:"api/v1/subscription/createPaymentIntent",
	_updatePayment:"api/v1/subscription/updatePayment",
	_getActivePlan:"api/v1/subscription/getActiveSubscription",
	_homeDetails:"api/v1/home/getHomeData",

	_getHomeDataTopCategories:"api/v1/home/getHomeDataTopCategories",

	_homeTopBusinesses:"api/v1/home/getHomeDataTopBusinesses",
	_businessDetails:"api/v1/home/getBusinessDetails",
	_searchRecent:"api/v1/search/recent",
	_userBusinessesListing:"api/v1/business/getUserBusinessesListing",
	_search:"api/v1/search",
	_searchList:"api/v1/search/list",
	_searchListv2:"api/v1/search/listV2",
	_getItemCode:"api/v1/merchant/business/service/getItemCode",
	_getDashboardData:"api/v1/merchant/business/dashboard",
	_getBusinessDetails:"api/v1/home/getBusinessDetails",
	_getServiceListing:"api/v1/home/getServicesList",
	_getServiceDetails:"api/v1/home/getServiceDetail",
	_getOfferListing:"api/v1/home/getOffersList",
	_getOfferdetails:"api/v1/home/getOfferDetail",
	_getUserDetails:"api/v1/common/becomeMerchantAppToWebLinking",
	_getSearchHistory:"api/v1/search/getSearchHistory",
	_deleteSearchHistory:"api/v1/search/deleteSearchHistory",
	_claimBusiness:"api/v1/user/business/claimBusiness",
	_addUpdateRatingAndReview:"api/v1/ratingAndReview/addUpdateRatingAndReview",
	_businessFormSettings:"api/v1/merchant/business/businessFormSettings",
	_documentMaster:"api/v1/common/documentMaster",
	_getAllLeads:"api/v1/merchant/leads",
	_nonusergetAvailableDID:"api/v1/user/leads/nonusergetAvailableDID",
	_getnonusernumber:"api/v1/user/leads/getnonusernumber",
	_getRatingAndReviews:"api/v1/ratingAndReview/getRatingAndReviews",
	_addRatingAndReviewComment:"api/v1/ratingAndReview/addRatingAndReviewComment",
	_likeUnlikeRatingAndReview:"api/v1/ratingAndReview/likeUnlikeRatingAndReview",
	_getAllRatingAndReviews: "api/v1/ratingAndReview/getMyReviewsHistory",
	_updateLeadsStatus:"api/v1/merchant/leads",
	_getAuditTrails:"api/v1/merchant/leads/getLeadAuditTrails",
	_addNote:"api/v1/merchant/leads/notes",
	_getAllNots:"api/v1/merchant/leads/viewLeadNotes",
	_updateNote:"api/v1/merchant/leads/notes",
	_getNotification:"api/v1/notifications",
	_logout:"api/v1/auth/logout",
	_reportAbuse:"api/v1/user/business/reportAbuse",
	_updatefavourite: "api/v1/user/favouriteBusiness",
	_getfavourite: "api/v1/user/favouriteBusiness",
	_chatHistory:"api/v1/chat/chatHistory",
	_postLead:"api/v1/user/leads",
	_getLeadDetails:"api/v1/merchant/leads/getLeadDetailsFromChat",
	_clearChat:"api/v1/chat/clearChat",
	_deletChat:"api/v1/user/leads",
	_queries:"api/v1/user/queries",
	_faqs:"api/v1/common/faqs",
	_querySubjects:"api/v1/common/querySubjects",
	_queriesSubmit:"api/v1/user/queries",
	_getProfileData: "api/v1/home/profile",
	_getUserQueryDetails:"api/v1/user/queries/getUserQueryDetails",
	_queriesStatus:"api/v1/user/queries",
	_addUserReply:"api/v1/user/queries/addUserReply",
	_getCampaignsList:"api/v1/merchant/advertisement/campaigns",
	_getAdvertisementTemplates:"api/v1/merchant/advertisement/getAdvertisementTemplates",
	_getAdvertisementBackgroundImages:"api/v1/merchant/advertisement/getAdvertisementBackgroundImages",
	_getAdvertisementImages:"api/v1/merchant/advertisement/getAdvertisementImages",
	_addAdvertisement:"api/v1/merchant/advertisement/campaigns",
	_searchBusiness:"admin/v1/businessListing/searchBusiness",
	_categories:"api/v1/merchant/advertisement/getCategoriesListing",
	_checkAvailablePositions:"api/v1/merchant/advertisement/checkAvailablePositions",
	_checkCampaignAvailabilit:"api/v1/merchant/advertisement/checkCampaignAvailability",
	_subCategories:"api/v1/merchant/advertisement/getSubCategories1Listing",
	_updateCampaignVisibility:"api/v1/merchant/advertisement/updateCampaignVisibility",
	_getCampaignDetails:"api/v1/merchant/advertisement/getCampaignDetails",
	_demographicSettings:"api/v1/merchant/advertisement/getAdvertisementDemographicSettings",
	_getPositionPrices:"api/v1/merchant/advertisement/getPositionPrices",
	_createPaymentIntent:"api/v1/merchant/advertisement/createPaymentIntent",
	_advertisementPayment:"api/v1/merchant/advertisement/updatePayment",

	_singlePayment:"api/v1/subscription/checkSubscriptionPaymentSinglePayment",
	_getGender: "api/v1/common/genders",
	_getLanguage: "api/v1/common/languages",
	_qualifications: "api/v1/common/qualifications",
	_getReligion: "api/v1/common/religions",
	_getEthnicity: "api/v1/common/ethnicities",
	_familyType: "api/v1/common/familyTypes",
	_getMaritalStatus: "api/v1/common/maritalStatus",
	_getPersonalIncome: "api/v1/common/incomeRanges",
	_getModeOfPayment:"api/v1/common/paymentMethods",
	_getloanTypes:"api/v1/common/loanTypes",	
	_getCompanyType: "api/v1/common/autoMobileCompanies",
	_getLifeInsurance: "api/v1/common/lifeInsuranceCompanies",
	_getHealthInsurance: "api/v1/common/healthInsuranceCompanies",
	_getCreditCardCompanies: "api/v1/common/creditCardCompanies",
	_getVehcileModel : "api/v1/common/vehicleModels",
	_getDropDownData:"api/v1/common/allCommonData",
	_getjobRole:"api/v1/common/jobRoles",
	_getBanner:"api/v1/home/getPageBanners",
	_merchantQueries:"api/v1/merchant/queries",
	_getMerchantQueryDetails:"api/v1/merchant/queries/getMerchantQueryDetails",
	_merchantQueriesSubmit:"api/v1/merchant/queries",
	_merchantUpdate:"api/v1/merchant/queries",
	_addMerchantReply:"api/v1/merchant/queries/addMerchantReply",
	_getChatFeedbacks:"api/v1/merchant/leads/getChatFeedbacks",
	_viewedCountCampaign:"api/v1/home/updateViewedCountCampaign",
	_addFeedback:"api/v1/user/leads/addFeedback",
	_getRatingAndReviewsList:"api/v1/ratingAndReview/getRatingAndReviewsMerchant",
	_getUserChatFeedbacks:"api/v1/user/leads/getChatFeedbacks",
	_merchantReport:"api/v1/ratingAndReview/merchantReport",
	_updateReview:"api/v1/ratingAndReview/updateReview",
	_updateRatingAndReviewComment:"api/v1/ratingAndReview/updateRatingAndReviewComment",
	_updateProfile:"api/v1/home/editProfile",
	_phoneNumberVerification:"api/v1/home/phoneNumberVerification",
	_verifyPhoneNumber:"api/v1/home/verifyPhoneNumber",
	_verifyUserEmail:"api/v1/home/verifyEmail",
	_VerificationUserEmail:"api/v1/home/emailVerification",
	_getReferralCode:"api/v1/home/getReferralCode",
	_userGuide:"api/v1/common/userGuide",
	_getChatFeedbackDetail:"api/v1/user/leads/getChatFeedbackDetail",
	_dashboardConfiguration:"api/v1/merchant/business/dashboardConfiguration",
	_getAccoutSetting:"api/v1/notificationSettings",
	_updateSetting:"api/v1/notificationSettings",
	_changePassword:"api/v1/auth/resetPassword",
	_deleteMerchantAccount:"api/v1/merchant/business/deleteMerchantAccount",
	_deleteAccount:"api/v1/auth/deleteAccount",
	_getEarnedPoints:"api/v1/tierAndRewards/getEarnedPoints",
	_getEarnedExpiredPoints:"api/v1/tierAndRewards/getEarnedExpiredPoints",
	_getStagesData:"api/v1/tierAndRewards/getStagesData",
	_getEnangementActivitiesList:"api/v1/common/getEnangementActivitiesList",
	_getRewardCategories:"api/v1/tierAndRewards/getRewardCategories",
	_getRewardCategoriesAndRewardList:"api/v1/tierAndRewards/getRewardCategoriesAndRewardList",
	_getRewardDetails:"api/v1/tierAndRewards/getRewardDetails",
	_redeemReward:"api/v1/tierAndRewards/redeemReward",
	_getRedeemedPoints:"api/v1/tierAndRewards/getRedeemedPoints",
	_getRewards:"api/v1/tierAndRewards/getRewards",
	_downloadBusinessCertificate:"api/v1/merchant/business/downloadBusinessCertificate",
	_downloadBusinessCard:"api/v1/merchant/business/downloadBusinessCard",
	_invoices:"api/v1/merchant/invoices",
	_downloadInvoice:"api/v1/merchant/invoices/downloadInvoice",
	_getFeaturedCategories:"api/v1/home/getFeaturedCategories",
	_getTopBusinesses:"api/v1/home/getTopBusinesses",
	_downloadBusinessProfile:"api/v1/business/downloadBusinessProfile",
	_analytics:"api/v1/merchant/analytics",
	_getSubscriptionPlanDetails:"api/v1/merchant/business/getSubscriptionPlanDetails",
	_getOfferCategories:"api/v1/home/getOfferCategories",
	_getOfferSubCategories:"api/v1/home/getOfferSubCategories",
	_saveMerchantsMediaViews:"api/v1/merchant/analytics/saveMerchantsMediaViews",
	_saveMerchantsSocialAccountsVisits:"api/v1/merchant/analytics/saveMerchantsSocialAccountsVisits",
	_saveMerchantsWebsiteVisits:"api/v1/merchant/analytics/saveMerchantsWebsiteVisits",
	_sports:"api/v1/common/sports",
	_books:"api/v1/common/books",
	_clothingBrands:"api/v1/common/clothingBrands",
	_ottPlatforms:"api/v1/common/ottPlatforms",
	_saveMerchantsLocationViews:"api/v1/merchant/analytics/saveMerchantsLocationViews",
	_currentPlanStatus:"api/v1/subscription/currentPlanStatus",
	_getPlan:"api/v1/common/getSubscriptionPlanLists",
	_createSubscriptionOnRazorPay:"api/v1/subscription/createSubscriptionOnRazorPay",
	_banners:"api/v1/tierAndRewards/banners",
	_getLeadPrices:"api/v1/merchant/leads/getLeadPrices",
	_createPaymentIntentLeads:"api/v1/merchant/leads/createPaymentIntent",
	_checkPaymentStatus:"api/v1/merchant/leads/checkPaymentStatus",
	_autoRenewSettings:"api/v1/subscription/autoRenewSettings",
	_getBusinessIdFirstTime:"api/v1/business/getBusinessIdFirstTime",
	_getLeadConfigurations:"api/v1/merchant/leads/getLeadConfigurations",
	_updateLeadsPayment:"api/v1/merchant/leads/updatePayment",
	_addOwnLeadPrices:"api/v1/merchant/leads/addOwnLeadPrices",
	_getAllTemplatesData:"api/v1/merchant/advertisement/getAdvertisementTemplates",
	_getOwnLeadPrices:"api/v1/merchant/leads/getOwnLeadPrices",
	_getCommonDataForCampaign:"api/v1/merchant/advertisement/getCommonDataForCampaign",
	_cancelSubscription:"api/v1/subscription/cancelSubscriptionOnRazorPay",
	_getAvailableDID:"api/v1/user/leads/getAvailableDID",
	_getCallHistory:"api/v1/user/leads/getCallHistory",
	_shareBusinessProfile:"api/v1/common/shareBusinessProfile",
	_getHomeDataOfferFeaturedCategories:"api/v1/home/getHomeDataOfferFeaturedCategories",
	_getTierPointsAlert:"api/v1/home/getTierPointsAlert",
	_getCustomerDIDNumber:"api/v1/user/leads/getCustomerDIDNumber",
	_getSeoData:"api/v1/search/getSeoData",
	_getFirstBusinessDetails:"api/v1/common/getFirstBusinessDetails",
	_fireToken:"api/v1/chat/token",
	_checkSubscriptionStatusPhonePe:"api/v1/subscription/checkSubscriptionStatusPhonepe",
	_verifyUpiId:"api/v1/subscription/verifyUpiId",
	_checkPlanMigration:"api/v1/subscription/checkPlanMigration",
	_getCityState:"api/v1/common/getCityState",
	_getCityStateOnly:"api/v1/common/getCityStateOnly",
	_getHomeDataConfigurations:"api/v1/home/getHomeDataConfigurations",
	_rewardPhoneNumberVerification:"api/v1/home/phoneNumberVerificationReward",
	_rewardVerifyPhoneNumber:"api/v1/home/verifyPhoneNumberReward",
}
