import { Component, OnInit, TemplateRef, Renderer2 } from '@angular/core';
import { AdminService } from "../../services/admin.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiUrl } from "../../global/global";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as CONSTANT from "../../services/constants"
import { passwordValidator } from 'src/app/services/passwordValidator';
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  doNotDisturb: any;
  allNotifications: any;
  public pwdForm: FormGroup;
  deletAccount: FormGroup;
  messageForm: FormGroup;
  modalRef: BsModalRef;
  sendGreetingMessage: any;
  show = false;
  merchant: any;
  oldPassword = false;
  deleteAccountPwd = false;
  confrmPassword = false;
  message: any;
  businessId: any;
  showError = false;
  points = 0;
  constructor(private toastrService: ToastrService,
    private modalService: BsModalService,
    private admin: AdminService,
    private dataSharingService: DataSharingService,
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
  ) {
    this.pwdForm = this.formBuilder.group({
      oldPassword: ["", Validators.compose([Validators.required]),],
      newPassword: ["", Validators.compose([Validators.required, Validators.maxLength(16), Validators.pattern(CONSTANT.PASSWORD_REGEX)]),],
      confirmPassword: ["", Validators.compose([Validators.required])],
    }, { validator: passwordValidator("newPassword", "confirmPassword") })

    this.deletAccount = this.formBuilder.group({
      password: ["", Validators.compose([Validators.required])],
    })
    this.messageForm = this.formBuilder.group({
      messageTxt: ["", Validators.compose([Validators.required])],
    })
    this.dataSharingService.isLoginChanged.subscribe(value => {
      if (value) {
        this.merchant = localStorage.getItem("Marchant") ? (localStorage.getItem("Marchant")) : "0";
      }
    });
  }
  ngOnInit(): void {
    let pageImg = "https://www.finndit.com/assets/images/logo300x300.png"
    this.admin.addMetaTag("Setting page", pageImg, "Setting Page", "", "");
    this.businessId = this.admin.getBusinessId();
    this.merchant = localStorage.getItem("Marchant") ? (localStorage.getItem("Marchant")) : "0";
    this.getAllSetting();
  }

  getAllSetting() {
    let data = {}
    if (this.merchant.toString() === "1") {
      data["businessId"] = this.businessId;
    }
    this.admin.getData(apiUrl._getAccoutSetting, data).subscribe(async (res) => {
      this.points = res.data.points;
      this.doNotDisturb = res.data?.doNotDisturb;
      this.allNotifications = res.data?.allNotifications;
      this.sendGreetingMessage = res.data?.sendGreetingMessage;
      this.message = res.data?.message;
      this.messageForm.patchValue({ 'messageTxt': this.message })
    })
  }
  notDisturb(e) {
    if (e.target.checked) {
      this.doNotDisturb = 1
    } else {
      this.doNotDisturb = 0
    }
    this.updateSetting("1");
  }
  allNotificationSetting(e, template: TemplateRef<any>) {
    if (e.target.checked) {
      this.allNotifications = 1
      this.updateSetting("1");
    } else {
      this.allNotifications = 0
      this.modalRef = this.modalService.show(template, {
        animated: true,
        backdrop: 'static',
        class: "success-query modal-dialog-centered"
      });
    }
  }
  greetingMessage(e) {
    if (e === 0) {
      if (this.message) {
        this.sendGreetingMessage = 1
      } else {
        this.sendGreetingMessage = 0
        this.toastrService.error("Please add greeting message first ", "");
        return false;
      }
    } else {
      this.sendGreetingMessage = 0
    }
    this.updateSetting("2");
  }
  upDateMessage() {
    if (this.messageForm.valid) {
      if (this.messageForm.value.messageTxt.trim()) {
        this.updateSetting("2");
      }
      else {
        this.messageForm.patchValue({ 'messageTxt': "" })
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      }
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }

  }
  updateSetting(type) {
    let data = {
      "doNotDisturb": this.doNotDisturb,
      "allNotifications": this.allNotifications,
    }
    if (type === "2") {
      data["sendGreetingMessage"] = this.sendGreetingMessage;
      if (this.messageForm.value.messageTxt) {
        const arr: string[] = this.messageForm.value.messageTxt.split('');
        arr[0] = arr[0].toUpperCase();
        data["message"] = arr.join('');
      }
    }
    if (this.merchant.toString() === "1") {
      data["businessId"] = this.businessId;
    }
    this.admin.putData(apiUrl._updateSetting, data).subscribe(async (res) => {
      if (type === "2") {
        this.message = this.messageForm.value.messageTxt;
        this.close()
      }
    },
      err => {
        this.getAllSetting();
        console.log(err, "err");
      });
  }
  openModel(template: TemplateRef<any>) {
    this.pwdForm.reset();
    this.deletAccount.reset();
    this.messageForm.patchValue({ 'messageTxt': this.message })
    this.modalRef = this.modalService.show(template, {
      animated: false,
      backdrop: 'static',
      class: "right modal-log-sign"
    });
  }

  close() {
    this.showError = false
    this.modalRef.hide();
  }
  setPassword() {
    if (this.pwdForm.valid) {
      delete this.pwdForm.value.confirmPassword
      this.admin.putData(apiUrl._changePassword, this.pwdForm.value)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.toastrService.success("Password changed successfully", '')
            this.close();
          }
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  delectAccount() {
    if (this.deletAccount.valid) {
      let data = {
        "password": this.deletAccount.value.password,
        "businessId": this.businessId
      }
      this.admin.deleteData(apiUrl._deleteMerchantAccount, data).subscribe(async (res) => {
        if (this.merchant === "1") {
          this.merchant = "0";
          this.renderer.removeClass(document.body, 'marchantPenal');
          localStorage.removeItem("Marchant");
          this.dataSharingService.isLoginChanged.next(true);
          this.router.navigate(['/']);
        } else {
          localStorage.clear();
          localStorage.removeItem("Marchant");
          this.renderer.removeClass(document.body, 'marchantPenal');
          this.renderer.removeClass(document.body, 'main_home');
          sessionStorage.removeItem('claimCounter');
          sessionStorage.removeItem('timerCLaimPopUp');
          this.dataSharingService.isLoginChanged.next(true);
          this.router.navigate(["/"]);
        }
        this.close()
        this.toastrService.success("Your business profile is deleted successfully", '')
      },
        err => {
          console.log(err, "err");
        });
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  disable(flag) {
    if (flag) {
      this.allNotifications = 0
      this.updateSetting("1");
    }
    else {
      this.allNotifications = 1
    }
    this.modalRef.hide();
  }
}
