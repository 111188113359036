<header class="header">
  <app-location-popup #locationTemplate (locationAccessGranted)="handleLocationAccess()"></app-location-popup>
  <app-signup-popup #signuppopup></app-signup-popup>


  <div class="container" id="contnr">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top ">
      <div class="leftPenal">
        <a *ngIf="merchant!=='1'" class="meunicon pointer" href="#" (click)="toggleCategories()" aria-label="Toggle categories"></a>
        <a class="logo pointer" (click)="backHomePage()"  href="#">
          <img srcset="../../../assets/images/img_logo.png" src="../../../assets/images/img_logo.png" alt="logo" class="lazyload" 
        >
        </a>
        <span class="marchantToggle  pointer" (click)="showSideBar()" *ngIf="merchant=='1'"><i class="fa fa-bars"
            aria-hidden="true"></i></span>
        <div *ngIf="merchant!=='1'" class="location_slt" dropdown [insideClick]="true">
          <a (click)="sendLocation()" href="#"  aria-controls="dropdown-animated">
          <img src="../../../assets/images/ic_location.svg" dropdownToggle alt="ic_location">
        </a>
        <div id="dropdown-animated" role="region" aria-labelledby="location-button" hidden>
          <!-- Dropdown content -->
        </div>
          <a class="location" href="#" (click)="openLocation()" aria-label="cityName">           
            <span  class="cityName">{{mapCity||''}}</span>
            <i id="button-basic"  class="fa fa-caret-down"></i>
          </a>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu mapWrap" role="menu" placement="left"
            aria-labelledby="button-basic">
            <div role="menuitem">
              <app-google-map  [locationData]="sendLocationData" (pinChange)='pinChange($event)'></app-google-map>            
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-collapse">
        <div class="serchWrap" *ngIf="merchant!=='1'">
          <form class="form-inline ">
            <i class="fa fa-search"></i>

            <input id="search_box" #search_box class="form-control" type="text" placeholder="Search..."
              (click)="pastSearches()" (keyup)="onSearchChangeAfterType($event, searchText)" name="searchClicked"
              [(ngModel)]="searchText" (keyup.enter)="onEnterClick(searchText)" aria-label="Search" autocomplete="off"
              (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp($event)"
              (keydown.ArrowDown)="onArrowDown($event)">

            <div id="search-outer" class="search-outer" *ngIf="searchDataList?.length>0 && showDrop">
              <ul #autoComplete id="search-outer-list" class="autoComplete">
                <li id="search-outer-list" *ngIf="pastData && searchText=='' && !!userInfo"><b>Past Searches</b></li>
                <li id="search-outer-list" *ngFor="let search of filterPastData; let i = index" #item
                  [ngClass]="{'focus-background': focusElement == item.tabIndex}" tabIndex="{{i}}">
                  <a id="search-outer-list" class="pointer" (click)="searchClicked(search)" href="#">{{search.title}}
                    <small>{{search.category}}</small></a>
                </li>
              </ul>

            </div>
          </form>
          <a (click)="clearSearchText()" id="cross-btn" class="cross-btn pointer" *ngIf="searchText!==''"><img
              id="cross-btn" src="../../../assets/images/ic_cross.svg"></a>
          <a class="microphoneWrap pointer" href="#" (click)="openSpeechModal(speechModel,'title')" aria-label="microphone pointer">
            <i class="fa fa-solid fa-microphone"></i>
          </a>
        </div>
        <app-user-business-list *ngIf="loginshow && merchant==='1'"></app-user-business-list>
        <a id="merchant_button_header" class="btn_brdr hideSmallScreen pointer" (click)="switchAccount()"
          *ngIf="loginshow">
          <img *ngIf="merchant!=='1'" class="marchnt" src="../../../assets/images/merchant.svg" alt="">{{merchant==="1"
          ? "Switch to Customer View": (switchToMerchant===1 && alldata.isClaimed===1) ||(switchToMerchant==4) ? "Switch
          to Merchant": "Become a Merchant"}} </a>
         <ul *ngIf="!loginshow" class="navbar-nav ml-auto">
         
          <li class="nav-item hideSmallScreen mr-2" routerLinkActive="activeUrl" [routerLinkActiveOptions]="{exact: true}"
           >
            <a class="nav-link pointer" routerLink="/rewards" (click)="clearSearch()">Rewards</a>
          </li>
          <li class="nav-item showinsmallscreen mr-2" routerLinkActive="activeUrl" [routerLinkActiveOptions]="{exact: true}"
           >
            <a class="nav-link pointer" routerLink="/rewards" (click)="clearSearch()"><img src="assets/images/ic_coin.png" class="ic_coin" srcset="assets/images/ic_coin.png 1x, assets/images/ic_coin@2x.png 2x, assets/images/ic_coin@3x.png 3x"   loading="lazy" alt="coin"></a>
          </li>
          </ul>
        <a href="javascript:;" *ngIf="!loginshow" id="sign-up" class="btn_brdr log_sign btn-demo pointer hideSmallScreen"
        (click)="signUpShow('1', null, '', '')">Login/Signup</a>
        <a href="javascript:;" *ngIf="!loginshow" id="sign-up" class="btn_brdr log_sign btn-demo pointer showinsmallscreen"
        (click)="signUpShow('1', null, '', '')" aria-label="login button small screen"><i class="fa fa-sign-in loginicon" aria-hidden="true"></i></a>
        <ul *ngIf="loginshow" class="navbar-nav ml-auto">
          <li class="nav-item hideSmallScreen" *ngIf="merchant!=='1'">
            <!-- <a class="nav-link hideSmallScreen pointer" >Contests</a> -->
          </li>
          <li class="nav-item hideSmallScreen" routerLinkActive="activeUrl" [routerLinkActiveOptions]="{exact: true}"
            *ngIf="merchant!=='1'">
            <a class="nav-link pointer" routerLink="/rewards" (click)="clearSearch()">Rewards</a>
          </li>
          <li class="nav-item messageCount mob-hide" *ngIf="merchant!=='1'">
            <a class="nav-link pointer" (click)="goTochat()">
              <span class="message" *ngIf="msgUnreadCount > 0"> {{ msgUnreadCount}} </span>
              <img src="../../../assets/images/Ic_chat.svg" alt="">
            </a> 
          <li class="nav-item mob-hide" *ngIf="merchant!=='1'">
            <a routerLink='/favourite' (click)="clearSearch()" class="nav-link pointer"><img
                src="../../../assets/images/Ic_like2.svg" alt=""></a>
          </li>
          <li class="nav-item hideSmallScreen" routerLinkActive="activeUrl" [routerLinkActiveOptions]="{exact: true}"
            *ngIf="merchant==='1'">
            <a class="nav-link pointer" (click)="goToSettingPage('plan')">Subscription Plans</a>
          </li>
          <li class="nav-item hideSmallScreen " routerLinkActive="activeUrl" [routerLinkActiveOptions]="{exact: true}"
            *ngIf="merchant==='1'">
            <a class="nav-link pointer" (click)="goToSettingPage('help')">Help</a>
          </li>
          <li class="nav-item">
            <div ngbDropdown placement="bottom-end" class="d-inline-block">
              <a class="pointer " id="dropdownBasic1" ngbDropdownToggle>
                <img *ngIf="unreadCount>0" src="../../../assets/images/ic_notification.svg" alt="">
                <img *ngIf="unreadCount==0" style="margin-top: -7px;" src="../../../assets/images/ic_notification.png"
                  alt="">
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="notificationsDropdown dropdown-menu">
                <div class="notificationsDropdown">
                  <h2>Notifications ({{unreadCount? unreadCount:0}})</h2>
                  <ul *ngIf="notificationData?.count!==0">
                    <!-- <li role="menuitem" *ngFor="let data of notificationData?.rows; let i =index"
                      [ngClass]="data.status===0? 'unread':''"> -->
                    <li ngbDropdownItem role="menuitem" *ngFor="let data of notificationData?.rows; let i =index"
                      [ngClass]="getClass(data.status, data.isPinned, data.isNew, data.earlier,data.notificationFor)">
                      <h5 *ngIf="data.isPinned===1" class="pinnedHeading fnt"> Pinned </h5>
                      <h5 *ngIf="data.isNew===1" class="newHeading fnt"> New </h5>
                      <h5 *ngIf="data.earlier===1" class="earlierHeading fnt"> Earlier </h5>
                      <div
                        *ngIf="data.type!=7 && data.type!=14 && data.type!=13 && data.type!=15 && data.type!=16 && data.type!==12 &&  data.type!==22 && data.type!==21"
                        class="imgbox"> <img *ngIf="!data.image" src="../../../assets/images/img_logo.png">
                        <img *ngIf="!!data.image" [src]="data.image">
                      </div>
                      <div
                        *ngIf="data.type!=7 && data.type!=14 && data.type!=13 && data.type!=15 && data.type!=16 && data.type!==12 && data.type!==22 && data.type!==21"
                        class="comt-sect">
                        <h6 style="cursor: pointer;" (click)="goToNotification()">{{data.title}}</h6>
                        <p style="cursor: pointer;" (click)="goToNotification()">{{data.message}}</p>
                        <div class="date-time-details"
                          [ngClass]="getClass(data.status, data.isPinned, data.isNew, data.earlier,data.notificationFor)">
                          <span class="date-time">
                            {{ data.date }} | {{data.createdAt | date:'hh:mm a'}}
                          </span>
                          <span class="pinned" *ngIf="data.isPinned===1">
                            <img src="../../../assets/images/ic_pin.svg"> Pinned</span>
                        </div>
                      </div>

                      <div class="comt-sect"
                        *ngIf="data.type==7 && data.type==14 && data.type==13 && data.type==15 && data.type==16 && data.type==12 && data.type==22 && data.type==21">
                        <h6 style="cursor: pointer;" (click)="goToEditProfile(data)">{{data.title}}</h6>
                        <p style="cursor: pointer;" (click)="goToEditProfile(data)">{{data.message}}</p>
                        <div class="date-time-details"
                          [ngClass]="getClass(data.status, data.isPinned, data.isNew, data.earlier,data.notificationFor)">
                          <span class="date-time">
                            {{ data.date }} | {{data.createdAt | date:'hh:mm a'}}
                          </span>
                          <span class="pinned" *ngIf="data.isPinned===1">
                            <img src="../../../assets/images/ic_pin.svg"> Pinned</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="seeAll" *ngIf="notificationData?.count!==0"><a class="pointer" ngbDropdownItem
                      (click)="goToNotification()">See All Notifications </a></div>
                  <div class="No_found" *ngIf="notificationData?.count==0" style="padding: 30px 0; border: none;"> No
                    notification yet! </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item userNav">
            <div ngbDropdown placement="bottom-end" class="d-inline-block nav-link">

              <a (click)="getPointsData()" class="pointer d-flex align-item-center" id="userDropdown" ngbDropdownToggle>
                <div class="userImg"><img src="{{profileImg ? profileImg : '../../../assets/images/user.svg'}}" alt="">
                </div>
                <span class="firstlCaptial">{{userName}}</span>
                <img class="icon" src="../../../assets/images/ic_drop_down.png" alt="">
              </a>
              <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu">
                <div class="outer_space" *ngIf="merchant!=='1'">
                  <div class="dropdown-item rdm_points"><img src="../../../assets/images/coin.svg" alt="">
                    {{earnPoints?.totalEarnedPoints}} Total
                    Points <a class="pointer" routerLink="/redeem-rewards" (click)="clearSearch()">Redeem<img
                        src="../../../assets/images/ic_arrow2.svg" alt=""></a>
                  </div>
                </div>
                <ul *ngIf="merchant!=='1'">
                  <li class="hideDesktop"><a ngbDropdownItem class="pointer"
                      (click)="switchAccount()">{{switchToMerchant===1 ?
                      "Switch to Merchant":
                      "Become a Merchant"}} </a></li>
                  <li><a ngbDropdownItem routerLink="/myfinndit" (click)="clearSearch()">My Finndit</a></li>
                  <li><a ngbDropdownItem routerLink="/myfinndit/update-profile" (click)="clearSearch()">Edit Profile</a>
                  </li>
                  <li><a ngbDropdownItem class="pointer" routerLink="/search-list" (click)="clearSearch()">Search
                      History</a></li>                  
                  <li><a ngbDropdownItem routerLink="/feedback" (click)="clearSearch()">Feedback</a></li>
                  <li class="mob-show"><a ngbDropdownItem (click)="goTochat()">Chats</a></li>
                  <li class="mob-show"><a ngbDropdownItem routerLink="/favourite" (click)="clearSearch()">Favorites</a>
                  </li>                 
                  <li class="showMoblink"><a ngbDropdownItem class="pointer" routerLink="/rewards"
                      (click)="clearSearch()">Rewards</a></li>
                </ul>
                <ul>
                  <li *ngIf="merchant==='1'" class="showMoblink"><a ngbDropdownItem (click)="switchAccount()">Switch
                      to customer view</a></li>
                  <li *ngIf="merchant==='1'"><a class="pointer" ngbDropdownItem (click)="goToSettingPage('profile')">My
                      Finndit Business
                      Profile</a></li>
                  <li *ngIf="merchant==='1' && currentPlanSetting?.listMultipleBusinessUnderSameLogin===1"><a
                      ngbDropdownItem class="pointer" (click)="switchBusiness()">Add Businesses</a></li>
                  <li class="showMoblink" *ngIf="merchant==='1'">
                    <a ngbDropdownItem (click)="goToSettingPage('plan')" class="pointer">Subscriptions Plans</a>
                  </li>
                  <li class="showMoblink" *ngIf="merchant==='1'">
                    <a class="pointer" (click)="goToSettingPage('help')" ngbDropdownItem>Help</a>
                  </li>
                  <li><a ngbDropdownItem (click)="goToSettingPage('setting')" class="pointer">Settings</a></li>

                  <li *ngIf="merchant!=='1'"><a ngbDropdownItem class="pointer"
                      (click)="contactUsModel(referFriend, 2)">Refer A
                      Friend</a>
                  </li>
                  <li *ngIf="merchant!=='1'"><a ngbDropdownItem class="pointer"
                      (click)="contactUsModel(contactus, 1)">Contact us</a></li>
                </ul>
                <ul>
                  <li><a class="pointer" ngbDropdownItem (click)="logout()">Log out</a></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <nav *ngIf="merchant!=='1'"
    class="categoriesNav navbar navbar-expand-lg navbar-light bg-light fixed-top category_nav">
    <div class="container">
      <div class="collapse navbar-collapse" id="navbarNav">
        <span class="drop_cate" (click)="toggleCategories()">All Categories <i class="fa fa-caret-down"></i></span>
        <ul class="navbar-nav categori-nav">
          <li class="nav-item" *ngFor="let category of allCategory">
            <a class="nav-link" [ngClass]="(selectedcateId==category?.id && !searchText)?'green-text':''"
              (click)="categoryClick(category)" href="javascript:;">{{category?.name}}<span class="sr-only"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<app-all-categories [categories]="allCategory" #categories></app-all-categories>
<ng-container>
  <app-signup #signUp></app-signup>
</ng-container>
<ng-template #speechModel>
  <app-web-speech (childEvent)='catchSpeechText($event)'></app-web-speech>
</ng-template>

<ng-template #contactus>
  <div class="modal-header">
    <img src="../../../assets/images/img_logo.png" alt="">
    <button type="button" class="closePopup" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <div class="contact-us">
      <h4 class="mb-4"> Contact Us </h4>
      <ul>
        <li> <i><img src="../../../assets/images/ic_phone.svg"></i> <span> +91-73411-23700 </span> </li>
        <li> <i><img src="../../../assets/images/ic_globe.svg"></i> <span><a
              href="mailto:support@finndit.com">support@finndit.com</a> </span> </li>
        <!-- <li> <i><img src="../../../assets/images/ic_location.svg"></i> <span> C-196, Industrial Area Phase 8 B, Mohali
            Punjab 160055 </span> </li> -->
      </ul>
 
      <div class="find-us">
        <p><strong>Find Us On:</strong></p>
        <a href="https://www.facebook.com/thefinndit?mibextid=LQQJ4d" target="_blank">
          <img src="../../../assets/images/social1.svg" alt="">
        </a>
        <a href="https://www.linkedin.com/company/99146480" target="_blank">
          <img src="../../../assets/images/social2.svg" alt="">
        </a>
        <a href="https://twitter.com/finnditcom" target="_blank">
          <img src="../../../assets/images/twitter-x.png" alt="">
        </a>
        <a href="https://www.pinterest.com/thefinndit/?invite_code=2479efbd702b464bb9c340b724158d39&sender=699958104493659511"
          target="_blank">
          <img src="../../../assets/images/social4.svg" alt="">
        </a>
        <a href="https://www.youtube.com/@TheFinndit" target="_blank">
          <img src="../../../assets/images/social6.svg" alt="">
        </a>
        <a href="https://www.instagram.com/thefinndit_/" target="_blank">
          <img src="../../../assets/images/social5.svg" alt="">
        </a>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #referFriend>
  <div class="modal-header bg_blue text-cente">
    <img src="../../../assets/images/refer-friend-group.png">
    <button type="button" class="closePopup" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <h4> Refer a friend to get {{activityPoints}} reward points </h4>
    <p> Just ask your friends to add this code while signing up on Finndit. You will get {{activityPoints}} reward
      points on every referal and your friends get welcome bonus also </p>
    <div class="form-group mt-4">
      <span>
        <label> Referral code </label>
      </span>
      <input type="text" value={{referralCode}} #referralcode class="form-control readonly" readonly>
      <a class="copy-code" (click)="copyInputMessage(referralcode)"> Copy code </a>
    </div>
  </div>
</ng-template>
